import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import RequestList from '../requestList';
import {useHistory} from 'react-router-dom';
import CreateRequestForApprovalButton from '../buttons/createRequestForApprovalButton';

export const RequestsView = props => {
  const history = useHistory();

  const content = props.requestsError ? <span data-id="error-message"><Translate value="error.generic"/></span> :
    <div className="card col-12">
      <RequestList
        history={history}
        organisations={props.organisations}
        requests={props.requests}
        requestsTotalCount={props.requestsTotalCount}
        invitations={props.invitations}
        onPageChange={props.onPageChange}
        pageNumber={props.pageNumber}
        pageSize={props.pageSize}
        loading={props.requestsLoading}
      />
    </div>;

  return (
    <>
      <div className="row mt-4 mb-5">
        <h1 data-id="active-requests" className="col-md-6 m-0">
          <Translate value="request.requests"/>
          <span className="separator information">{props.requestsTotalCount}</span>
        </h1>
        <div className="col-md-6 inline-container flex-end">
          <CreateRequestForApprovalButton unlimitedRfaProjectExists={props.unlimitedRfaProjectExists}/>
        </div>
      </div>
      {content}
    </>
  );
};

RequestsView.propTypes = {
  organisations: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  requestsTotalCount: PropTypes.number.isRequired,
  invitations: PropTypes.array,
  onPageChange: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  requestsLoading: PropTypes.bool.isRequired,
  requestsError: PropTypes.bool.isRequired,
  unlimitedRfaProjectExists: PropTypes.bool.isRequired
};

export default RequestsView;
