import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import Document from '../requestDocuments/document';
import {DOCUMENT_STATUS, RFA_STATUS} from '../../../utils/constant';

const slash = '/';

export const RequestSpecificDocumentsListView = props => {
  const isSuccess = !props.loading && !props.error && props.uploadedDocCount === props.documents.length;

  return (
    <>
      <div className="flex-container space-between mt-5">
        <h2 className="no-wrap">
          <Translate value="request.page.specificDocuments"/>
          <span className="separator"/>
          <span className={isSuccess ? 'success' : 'warning'}>
            <strong>
              {props.uploadedDocCount}
              {slash}
              {props.documents.length}
            </strong>
          </span>
        </h2>
      </div>
      <div className="card">
        {props.error ?
          <span data-id="error-message"><Translate value="error.generic"/></span> :
          props.loading ?
            <div className="loading-overlay"/> :
            <div className="flex-container column">
              {props.documents.map((document, index) =>
                <div key={index}>
                  <Document
                    document={document}
                    requestId={props.requestId}
                    onDocumentUpload={props.onDocumentUpload}
                    // When the RFA is completely signed, the uploaded is authorized on MISSING document
                    isUploadEnabled={props.rfaStatus === RFA_STATUS.VALIDATED && document.state === DOCUMENT_STATUS.MISSING ? true : props.rfaStatus === RFA_STATUS.EDITABLE}
                  />
                </div>
              )}
            </div>
        }
      </div>
    </>
  );
};

RequestSpecificDocumentsListView.defaultProps = {
  loading: true,
  error: false
};

RequestSpecificDocumentsListView.propTypes = {
  requestId: PropTypes.number.isRequired,
  documents: PropTypes.array.isRequired,
  uploadedDocCount: PropTypes.number.isRequired,
  rfaStatus: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onDocumentUpload: PropTypes.func.isRequired
};

export default RequestSpecificDocumentsListView;
