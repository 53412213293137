import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import ProjectList from '../projectList';
import CreateProjectButton from '../buttons/createProjectButton';

export const ProjectsView = props => {
  const content = props.projectsError ? <span data-id="error-message"><Translate value="error.generic"/></span> :
    (
      <div>
        <div className="card col-12">
          <ProjectList
            organisations={props.organisations}
            projects={props.projects}
            projectsCount={props.projectsTotalCount}
            onPageChange={props.onPageChange}
            pageNumber={props.pageNumber}
            pageSize={props.pageSize}
            loading={props.projectsLoading}
          />
        </div>
      </div>
    );

  return (
    <>
      <div className="row mt-4 mb-5">
        <h1 data-id="project-count" className="col-md-6 m-0">
          <Translate value="project.projects"/>
          <span className="separator information">{props.projectsTotalCount}</span>
        </h1>
        <div className="col-md-6 inline-container flex-end">
          <CreateProjectButton/>
        </div>
      </div>
      {content}
    </>
  );
};

ProjectsView.propTypes = {
  organisations: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  projectsTotalCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  projectsLoading: PropTypes.bool.isRequired,
  projectsError: PropTypes.bool.isRequired
};

export default ProjectsView;
