import React, {Component} from 'react';
import {Translate} from 'react-redux-i18n';
import UserDetails from '../../components/user/UserDetails';
import LanguageSettings from '../../components/settings/LanguageSettings';
import PropTypes from 'prop-types';
import {Icon} from 'front-onceforall-core';

export class SettingsView extends Component {
  render() {
    return (
      <>
        <span className="col-12 inline-container space-between">
          <h1><Translate value="user.account"/></h1>
          <button onClick={this.props.onCancel}><Icon icon="cross" size={20}/></button>
        </span>
        <h2 className="mb-4"><Translate value="user.language"/></h2>
        <LanguageSettings/>
        <h2 className="my-4"><Translate value="user.myProfile"/></h2>
        <UserDetails onCloseRequest={this.props.onCancel}/>
      </>
    );
  }
}

SettingsView.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default SettingsView;
