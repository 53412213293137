import {createBrowserHistory} from 'history';
import configureStore from '../../store/configureStore';
import {
  a404HttpError,
  aCheckedResponse,
  aDocumentDetailsVM,
  anHttpNoContent,
  anOFASubcontractorDocumentsList,
  aRequestCreated,
  aRequestParentDetailsVM,
  aRequestVM,
  aRequestWorkflowVM,
  aSignTask,
  aSubcontractorDocumentsListVM,
  aSynchronizedOFASubcontractorDocumentsList,
  aVerifyTask
} from '../../utils/fixtures';
import {DOCUMENT_STATUS, PAYMASTER_DOCUMENT_TYPE, RFA_STATUS, TASK_TYPE} from '../../utils/constant';
import {findDocumentType, getDocumentTypes} from '../../utils/database/documentTypes';
import {
  findRequest,
  findRequestNode,
  getFakeRequestNodesData,
  getRequestsFakeData,
  getRequestsTree
} from '../../utils/database/requests';
import {findProject} from '../../utils/database/projects';
import moment from 'moment';

const history = createBrowserHistory();
configureStore.init(history);

export default class FakeRequestService {
  create(request) {
    const project = findProject(request.projectId);
    const response = Object.assign(aRequestCreated(), request, {
      id: 10000 + getRequestsFakeData().length,
      project,
      amount: Number(request.amount),
      documents: [],
      subcontractorInvitedUser: undefined
    });
    getRequestsFakeData().push(response);
    getFakeRequestNodesData().push({id: response.id, parentId: request.parentId});
    return new Promise(resolve => {
      setTimeout(() => resolve(response), 1000);
    });
  }

  delete(requestId) {
    const index = getRequestsFakeData().findIndex(request => request.id === requestId);
    if (index < 0) {
      return new Promise((resolve, reject) => {
        setTimeout(() => reject(a404HttpError()), 100);
      });
    }

    getRequestsFakeData().splice(index, 1);
    return new Promise(resolve => {
      setTimeout(() => resolve(anHttpNoContent()), 250);
    });
  }

  update(requestId, requestDetails) {
    let request = findRequest(requestId);

    if (request.paymaster !== requestDetails.paymaster) {
      const documents = request.documents.filter(document => document.documentTypeCode !== PAYMASTER_DOCUMENT_TYPE[request.paymaster]);

      request = Object.assign(request, {
        documents: documents
      });
    }

    // Instantiate a new object (instead of modifying current) to indicate to
    // React component that object has changed
    const newRequest = Object.assign({}, request, {
      details: requestDetails.details,
      startingDate: requestDetails.startingDate,
      endingDate: requestDetails.endingDate,
      amount: requestDetails.amount,
      paymaster: requestDetails.paymaster
    });

    return new Promise(resolve => {
      setTimeout(() => resolve(newRequest), 1000);
    });
  }

  submitDocument(requestId, file, documentTypeCode) {
    const request = findRequest(requestId);
    return new Promise(resolve => {
      setTimeout(() => resolve(aDocumentDetailsVM({
        id: Math.floor(Math.random() * Math.floor(100)),
        type: findDocumentType(documentTypeCode),
        state: DOCUMENT_STATUS.UPLOADED,
        isUploaded: true,
        isUploadedAfterRfaValidation: request.status === RFA_STATUS.VALIDATED
      })), 1000);
    });
  }

  downloadDocument() {
    return 'https://github.com/AttestationLegale/front-da/archive/dev.zip';
  }

  downloadAllRequestDocuments() {
    return 'https://github.com/AttestationLegale/front-da/archive/dev.zip';
  }

  findRequestsByOrganisationId(organisationId, page, size, sortBy = [{property: 'creationDate', direction: 'desc'}]) {
    let requests = [];
    for (let i = 0; i < sortBy.length; i++) {
      requests = getRequestsFakeData().sort((a, b) => (a[sortBy[i].property] < b[sortBy[i].property]) ? -1 : (a[sortBy[i].property] > b[sortBy[i].property]) ? 1 : 0);

      if (sortBy[i].direction === 'desc') {
        requests = requests.reverse();
      }
    }

    requests = requests.filter(request => this.authorizeRelatedOrganisation(request, organisationId));

    return Promise.resolve({
      requests: requests.slice(page * size, (page + 1) * size),
      totalCount: requests.length
    });
  }

  findRequestsWithActiveTasksForOrganisation(organisationId, page, size, sortBy = [{property: 'creationDate', direction: 'desc'}]) {
    return this.findRequestsByOrganisationId(organisationId, page, size, sortBy);
  }

  findRequestsTreeByProjectId(projectId, sortBy = [{
    property: 'creatorOrganisationId',
    direction: 'asc'
  }, {property: 'creationDate', direction: 'desc'}]) {
    let requests = [];
    for (let i = 0; i < sortBy.length; i++) {
      requests = getRequestsFakeData().sort((a, b) => (a[sortBy[i].property] < b[sortBy[i].property]) ? -1 : (a[sortBy[i].property] > b[sortBy[i].property]) ? 1 : 0);

      if (sortBy[i].direction === 'desc') {
        requests = requests.reverse();
      }
    }

    const store = configureStore.getStore().getState();
    const currentOrganisationId = store.organisation ? store.organisation.id : null;
    requests = requests.filter(request => this.authorizeRelatedOrganisation(request, currentOrganisationId))
      .filter(request => authorizeRelatedProject(request, projectId));

    const requestsTree = getRequestsTree(requests);

    return Promise.resolve(requestsTree);
  }

  findValidatedSCRequestsByProjectId(projectId) {
    const store = configureStore.getStore().getState();
    const currentOrganisationId = store.organisation ? store.organisation.id : null;
    const requests = getRequestsFakeData().filter(request => hasValidatedSubcontractor(request, currentOrganisationId))
      .filter(request => authorizeRelatedProject(request, projectId));

    return Promise.resolve(requests);
  }

  getRequestById(requestId) {
    return new Promise(resolve => {
      setTimeout(() => resolve(findRequest(requestId)), 500);
    });
  }

  verifyRequest(taskId, condition, requestId) {
    Object.assign(findRequest(requestId), {
      activeTasks: [findRequest(requestId).activeTasks.find(task => task.type === TASK_TYPE.SIGN)]
    });

    if (condition) {
      findRequest(requestId).suspensiveConditions.push({
        organisationRole: findRequest(requestId).activeTasks.find(task => task.type === TASK_TYPE.SIGN).role,
        content: condition
      });
    }

    return aCheckedResponse();
  }

  rejectRequest(task, reason) {
    const request = aRequestVM({
      refusalOrganisationId: task.organisationId,
      refusalDate: moment().toISOString(),
      refusalReason: reason,
      activeTasks: [
        aVerifyTask({organisationId: aRequestVM().subcontractorId}),
        aSignTask({organisationId: aRequestVM().subcontractorId})
      ]
    });

    return aCheckedResponse(request);
  }

  removeSuspensiveCondition(requestId, role) {
    const conditionIndex = findRequest(requestId).suspensiveConditions.findIndex(condition => condition.organisationRole === role);
    Object.assign(findRequest(requestId), {
      suspensiveConditions: findRequest(requestId).suspensiveConditions.splice(conditionIndex, 1)
    });

    return aCheckedResponse();
  }

  getWorkflowByRequestId(requestId) {
    const request = findRequest(requestId);

    let node = findRequestNode(requestId);
    const parentRequests = [];
    while (node.parent) {
      parentRequests.push(findRequest(node.parent?.rfaId || node.parent));
      node = node.parent;
    }

    const activeRole = request.activeTasks[0] ? request.activeTasks[0].role : null;

    return new Promise(resolve => {
      setTimeout(() => resolve(aRequestWorkflowVM(request, activeRole, parentRequests)), 500);
    });
  }

  getSubcontractorDocumentsByRequestId(requestId) {
    const request = findRequest(requestId);

    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

    const getDocument = type => {
      const document = request.documents.find(document => document.documentTypeCode === type.code) || {};

      return aDocumentDetailsVM({
        id: isEmpty(document) ? null : document.id,
        type,
        state: isEmpty(document) ? DOCUMENT_STATUS.MISSING : DOCUMENT_STATUS.UPLOADED,
        isUploaded: !isEmpty(document)
      });
    };

    const documents = [20, 21].includes(requestId) ? anOFASubcontractorDocumentsList().documentsDetails :
      request.project.requiredDocumentTypeCodes.filter(code => code !== 'ADD_SUBCONTRACTING_K' && code !== 'ADD_PROJECT_CHARTERS').map(code => {
        return getDocument(getDocumentTypes().find(documentType => documentType.code === code));
      });
    const lastSynchronisationDate =
      requestId === 20 ?
        anOFASubcontractorDocumentsList().lastSynchronisationDate :
        requestId === 21 ?
          aSynchronizedOFASubcontractorDocumentsList().lastSynchronisationDate :
          aSubcontractorDocumentsListVM().lastSynchronisationDate;

    documents.sort((a, b) => a.type.weight - b.type.weight);

    return new Promise(resolve => {
      setTimeout(() => resolve({
        lastSynchronisationDate: lastSynchronisationDate,
        fromOfa: [20, 21].includes(requestId),
        documentsDetails: documents
      }), 500);
    });
  }

  getSpecificDocumentsByRequestId(requestId) {
    const request = findRequest(requestId);

    function isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

    const getDocument = type => {
      if (type.code === 'ADD_PROJECT_CHARTERS') {
        type.complementary = ['The Women\'s Health Strategy for England'];
      }

      const document = request.documents.find(document => document.documentTypeCode === type.code) || {};

      return aDocumentDetailsVM({
        id: isEmpty(document) ? null : document.id,
        type,
        state: isEmpty(document) ? DOCUMENT_STATUS.MISSING : DOCUMENT_STATUS.UPLOADED,
        isUploaded: !isEmpty(document)
      });
    };

    let specificDocuments = [];

    specificDocuments.push(getDocument(getDocumentTypes().find(documentType => documentType.code === PAYMASTER_DOCUMENT_TYPE[request.paymaster])));

    request.project.requiredDocumentTypeCodes.forEach(code => {
      if (['ADD_SUBCONTRACTING_K', 'ADD_PROJECT_CHARTERS'].includes(code)) {
        specificDocuments.push(getDocument(getDocumentTypes().find(documentType => documentType.code === code)));
      }
    });

    specificDocuments.sort((a, b) => a.type.weight - b.type.weight);

    return new Promise(resolve => {
      setTimeout(() => resolve(specificDocuments), 500);
    });
  }

  findParentDetailsById(requestId) {
    const node = findRequestNode(requestId);
    if (node?.parent?.rfaId) {
      return Promise.resolve(aRequestParentDetailsVM(findRequest(node.parent.rfaId)));
    }

    return Promise.resolve(null);
  }

  authorizeRelatedOrganisation(request, organisationId) {
    return organisationId && [
      request.project.clientId,
      request.project.clientDelegateId,
      request.project.clientAssistantId,
      request.project.projectManagerId,
      request.creatorOrganisationId,
      request.project.safetyCoordinatorId,
      request.subcontractorId
    ].includes(organisationId);
  }

  updatePublicProcurementData(_requestId, _data) {
    return Promise.resolve();
  }
}

export const authorizeRelatedProject = (request, projectId) => {
  return request.project.id === projectId;
};

export const hasValidatedSubcontractor = (request, organisationId) => {
  return request.subcontractorId === organisationId && request.status === RFA_STATUS.VALIDATED;
};
