import React from 'react';
import PropTypes from 'prop-types';
import {Translate, I18n} from 'react-redux-i18n';

import Radio from '../../../buttons/Radio';

import {getFieldLabel} from '../../utils';

const links = {
  art219322: 'https://www.legifrance.gouv.fr/affichCode.do%3Bjsessionid%3DB81BA950929BDC11249DDF8C185D1DE4.tplgfr42s_2?idSectionTA=LEGISCTA000037729575&cidTexte=LEGITEXT000037701019&dateTexte=20190401',
  art239340: 'https://www.legifrance.gouv.fr/affichCode.do%3Bjsessionid%3DB81BA950929BDC11249DDF8C185D1DE4.tplgfr42s_2?idSectionTA=LEGISCTA000037728277&cidTexte=LEGITEXT000037701019&dateTexte=20190401'
};

function linkToArticles(key) {
  return (
    <a
      href={links[key]}
      target="_blank"
      rel="noopener noreferrer"
    >
      {I18n.t(`publicProcurement.assignmentOrPledge.links.${key}`)}
    </a>
  );
}

function AssignmentOrPledge({publicProcurementData, onChange}) {
  const selectFirstHypothesis = () => {
    onChange({
      isModificationAct: false,
      withCertificate: null
    });
  };

  const selectSecondHypothesis = () => {
    onChange({
      isModificationAct: true,
      withCertificate: null
    });
  };

  const selectWithCertificate = () => {
    onChange({withCertificate: true});
  };

  const selectWithoutCertificate = () => {
    onChange({withCertificate: false});
  };

  const displayFirstHypothesis = () => {
    const isNotModificationAct = publicProcurementData.isModificationAct === false;
    return (
      <div className="card">
        <div className="pb-3">
          <h4><Translate value="publicProcurement.assignmentOrPledge.first.title"/></h4>
        </div>
        <div className="row pb-3">
          <div className="col-12">
            <Radio
              id="assignmentOrPledge-first-isModificationAct"
              name="assignmentOrPledge-first-isModificationAct"
              label={getFieldLabel('publicProcurement.assignmentOrPledge.first.isModificationAct', true)}
              value={false}
              checked={isNotModificationAct}
              onChange={selectFirstHypothesis}
            />
          </div>
        </div>
        <div className="pb-3">
          <Translate value="publicProcurement.assignmentOrPledge.first.details.part1"/>
          {linkToArticles('art219322')}
          <Translate value="publicProcurement.assignmentOrPledge.first.details.part2"/>
          {linkToArticles('art239340')}
          <Translate value="publicProcurement.assignmentOrPledge.first.details.part3"/>
        </div>
        <div className="pl-5">
          <div className="pt-3">
            <h4><Translate value="publicProcurement.assignmentOrPledge.first.certificates.title"/></h4>
          </div>
          <div className="row pb-3">
            <div className="col-12">
              <Radio
                id="assignmentOrPledge-first-withCertificate"
                name="assignmentOrPledge-first-withCertificate"
                label={getFieldLabel('publicProcurement.assignmentOrPledge.first.certificates.withCertificate', true)}
                value={true}
                checked={isNotModificationAct && Boolean(publicProcurementData.withCertificate)}
                onChange={selectWithCertificate}
                disabled={!isNotModificationAct}
              />
            </div>
          </div>
          <div className="pb-3">
            <Translate value="publicProcurement.assignmentOrPledge.or"/>
          </div>
          <div className="row pb-3">
            <div className="col-12">
              <Radio
                id="assignmentOrPledge-first-withoutCertificate"
                name="assignmentOrPledge-first-withoutCertificate"
                label={getFieldLabel('publicProcurement.assignmentOrPledge.first.certificates.withoutCertificate', true)}
                value={false}
                checked={isNotModificationAct && publicProcurementData.withCertificate === false}
                onChange={selectWithoutCertificate}
                disabled={!isNotModificationAct}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const displaySecondHypothesis = () => {
    const isModificationAct = publicProcurementData.isModificationAct;
    return (
      <div className="card">
        <div className="pb-3">
          <h4><Translate value="publicProcurement.assignmentOrPledge.second.title"/></h4>
        </div>
        <div className="row pb-3">
          <div className="col-12">
            <Radio
              id="assignmentOrPledge-second-isModificationAct"
              name="assignmentOrPledge-second-isModificationAct"
              label={getFieldLabel('publicProcurement.assignmentOrPledge.second.isModificationAct', true)}
              value={true}
              checked={Boolean(publicProcurementData.isModificationAct)}
              onChange={selectSecondHypothesis}
            />
          </div>
        </div>
        <div className="pl-5">
          <div className="row pb-3">
            <div className="col-12">
              <Radio
                id="assignmentOrPledge-second-withCertificate"
                name="assignmentOrPledge-second-withCertificate"
                label={(
                  <div>
                    <Translate value="publicProcurement.assignmentOrPledge.second.certificates.with.part1"/>
                    {linkToArticles('art219322')}
                    <Translate value="publicProcurement.assignmentOrPledge.second.certificates.with.part2"/>
                    {linkToArticles('art239340')}
                    <Translate value="publicProcurement.assignmentOrPledge.second.certificates.with.part3"/>
                    {' *'}
                  </div>
                )}
                value={true}
                checked={isModificationAct && Boolean(publicProcurementData.withCertificate)}
                onChange={selectWithCertificate}
                disabled={!isModificationAct}
              />
            </div>
          </div>
          <div className="pb-3">
            <Translate value="publicProcurement.assignmentOrPledge.or"/>
          </div>
          <div className="row pb-3">
            <div className="col-12">
              <Radio
                id="assignmentOrPledge-second-withoutCertificate"
                name="assignmentOrPledge-second-withoutCertificate"
                label={getFieldLabel('publicProcurement.assignmentOrPledge.second.certificates.without.checkbox', true)}
                value={false}
                checked={isModificationAct && publicProcurementData.withCertificate === false}
                onChange={selectWithoutCertificate}
                disabled={!isModificationAct}
              />
            </div>
          </div>
          <div className="pb-3">
            <Translate value="publicProcurement.assignmentOrPledge.second.certificates.without.part1"/>
          </div>
          <div className="pb-3">
            <Translate value="publicProcurement.assignmentOrPledge.second.certificates.without.part2"/>
          </div>
          <div className="pb-3">
            <Translate value="publicProcurement.assignmentOrPledge.second.certificates.without.part3"/>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <div>
        <h2><Translate value="publicProcurement.titles.assignmentOrPledge"/></h2>
      </div>
      {displayFirstHypothesis()}
      {displaySecondHypothesis()}
    </div>
  );
}

AssignmentOrPledge.propTypes = {
  onChange: PropTypes.func.isRequired,
  publicProcurementData: PropTypes.shape({
    isModificationAct: PropTypes.bool,
    withCertificate: PropTypes.bool
  }).isRequired
};

export default AssignmentOrPledge;
