import config from '../../../config';
import {authFetch, checkStatus} from '../../utils/http';

export default class LiveSignatureService {
  signRequest(taskId, condition) {
    return authFetch(`${config.rfaApi}/api/v1/signatures/${taskId}/initialize`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({content: condition})
    })
      .then(checkStatus)
      .then(response => response.text());
  }

  callbackSignRequest(taskId, transactionId) {
    return authFetch(`${config.rfaApi}/api/v1/signatures/${taskId}/process?transactionId=${transactionId}`, {
      method: 'POST'
    })
      .then(checkStatus)
      .then(response => response.status === 204 ? null : response.json());
  }
}
