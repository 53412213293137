import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import configureStore from '../../store/configureStore';
import * as snackbarsActions from '../../actions/snackbars';
import {removeOrganisation} from '../../actions/organisation';
import {Translate} from 'react-redux-i18n';
import {userService} from '../../services/user/UserService';
import UsersView from './UsersView';
import UserAdd from './userAdd/UserAddView';
import {SidePanel} from 'front-onceforall-core';

export const UsersContainer = props => {
  const [users, setUsers] = React.useState([]);
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);

  let triggerClosePanel = () => {
  };

  const handleInviteUser = () => {
    fetchUsers();
    triggerClosePanel();
  };

  const handleAcceptPendingUser = (event, userId) => {
    event.preventDefault();
    event.stopPropagation();
    userService.acceptUserLinkRequest(props.organisation.id, userId)
      .then(() => {
        props.actions.addSuccess(<Translate value="users.actions.acceptPendingUserRequestSuccess"/>);
        fetchUsers();
      })
      .catch(() => {
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const handleDeclinePendingUser = (event, userId) => {
    event.preventDefault();
    event.stopPropagation();
    userService.declineUserLinkRequest(props.organisation.id, userId)
      .then(() => {
        props.actions.addSuccess(<Translate value="users.actions.declinePendingUserRequestSuccess"/>);
        fetchUsers();
      })
      .catch(() => {
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const handleUnlinkUser = (event, userId) => {
    event.preventDefault();
    event.stopPropagation();
    userService.unlinkUserFromOrganisation(props.organisation.id, userId)
      .then(() => {
        if (userId === props.user.id) {
          handleUnlinkOneself();
          return;
        }

        props.actions.addSuccess(<Translate value="users.actions.unlinkUserSuccess"/>);
        fetchUsers();
      })
      .catch(() => {
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const handleUnlinkOneself = () => {
    props.actions.addSuccess(<Translate value="users.actions.unlinkOneselfSuccess"/>);
    props.history.push('/global-dashboard');
    configureStore.getStore().dispatch(props.actions.removeOrganisation(props.organisation));
  };

  const fetchUsers = () => {
    setLoading(true);
    return Promise.all([
      userService.searchByOrganisation(props.organisation.id),
      userService.getUsersUnlinkabilityStatusByOrganisation(props.organisation.id)
    ])
      .then(([userOrganisations, unlinkStatus]) => {
        const usersWithUnlinkStatus = userOrganisations.map(userOrganisation => {
          userOrganisation.unlinkStatus = unlinkStatus[userOrganisation.user.id].statusCode;
          return userOrganisation;
        });
        setError(false);
        setUsers(usersWithUnlinkStatus);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <>
      <UsersView
        users={users}
        onAcceptPendingUser={handleAcceptPendingUser}
        onDeclinePendingUser={handleDeclinePendingUser}
        onUnlinkUser={handleUnlinkUser}
        onClickInviteButton={setIsPanelOpen}
        error={error}
        loading={loading}
      />
      {isPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsPanelOpen(false)}
        >
          <UserAdd
            actions={props.actions}
            organisation={props.organisation}
            userEmail={props.user.email}
            onSelectUser={handleInviteUser}
            users={users}
            onCancel={() => triggerClosePanel()}
          />
        </SidePanel>
      }
    </>
  );
};

UsersContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
    organisation: state.organisation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions, {removeOrganisation}), dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersContainer));
