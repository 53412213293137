import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';

import {Icon} from 'front-onceforall-core';

import {getFieldLabel} from '../../../utils';

function Contact({onChange, contact, deleteContact}) {
  return (
    <div className="card">
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contact.firstName', true)}
            id="contact-firstName"
            name="contact-firstName"
            value={contact.firstName || ''}
            onChange={onChange('firstName')}
          />
        </div>
        <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contact.lastName', true)}
            id="contact-lastName"
            name="contact-lastName"
            value={contact.lastName || ''}
            onChange={onChange('lastName')}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-10 col-xs-9 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contact.jobTitle', true)}
            id="contact-jobTitle"
            name="contact-jobTitle"
            value={contact.jobTitle || ''}
            onChange={onChange('jobTitle')}
          />
        </div>
        <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3 pb-3 d-flex align-items-center">
          <button
            className="small primary"
            id="delete-contact"
            type="button"
            onClick={deleteContact}
          >
            <Icon icon="trash"/>
          </button>
        </div>
      </div>
    </div>
  );
}

Contact.defaultProps = {
  contact: {
    firstName: '',
    lastName: '',
    jobTitle: ''
  }
};

Contact.propTypes = {
  onChange: PropTypes.func.isRequired,
  deleteContact: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    jobTitle: PropTypes.string
  })
};

export default Contact;
