import {
  anAuthenticatedUser,
  anHttpError,
  anHttpNoContent,
  aCheckedResponse,
  aUserVM,
  aUserOrganisationVM
} from '../../utils/fixtures';
import {USER_ORGANISATION_STATUS, USER_UNLINKABILITY_STATUS} from '../../utils/constant';
import {
  findUser,
  getLoggedUser,
  getUserOrganisationsFakeData,
  getUserOrganisationsProjectFakeData,
  getUsersFakeData
} from '../../utils/database/users';

export default class FakeUserService {
  searchByOrganisation(organisationId) {
    const userOrganisations = getUserOrganisationsFakeData().filter(userOrganisation => userOrganisation.organisationId === organisationId)
      .sort((a, b) => a.user.fullName.localeCompare(b.user.fullName))
      .sort((a, b) => {
        if (a.status === USER_ORGANISATION_STATUS.PENDING) {
          return b.status === USER_ORGANISATION_STATUS.PENDING ? 0 : -1;
        }

        return b.status === USER_ORGANISATION_STATUS.PENDING ? 1 : 0;
      });

    return aCheckedResponse(userOrganisations);
  }

  searchPendingByOrganisation(organisationId) {
    const userOrganisations = getUserOrganisationsFakeData().filter(userOrganisation => userOrganisation.organisationId === organisationId)
      .filter(userOrganisation => userOrganisation.status === USER_ORGANISATION_STATUS.PENDING)
      .sort((a, b) => a.user.fullName.localeCompare(b.user.fullName))
      .sort((a, b) => {
        if (a.status === USER_ORGANISATION_STATUS.PENDING) {
          return b.status === USER_ORGANISATION_STATUS.PENDING ? 0 : -1;
        }

        return b.status === USER_ORGANISATION_STATUS.PENDING ? 1 : 0;
      });

    return aCheckedResponse(userOrganisations);
  }

  searchByEmail(email) {
    const user = getUsersFakeData().filter(user => user.email === email);

    if (user.length > 0) {
      return aCheckedResponse(user[0]);
    }

    return Promise.resolve(null);
  }

  inviteUnknownUserToOrganisation(organisationId, userDetails) {
    const status = USER_ORGANISATION_STATUS.LINKED;
    const userOrganisation = aUserOrganisationVM(aUserVM({
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      email: userDetails.email,
      phoneNumber: userDetails.phoneNumber
    }), organisationId, {status});

    getUserOrganisationsFakeData().push(userOrganisation);

    return new Promise(
      resolve => {
        setTimeout(() => resolve(anHttpNoContent()), 1000);
      }
    );
  }

  checkWhetherUserExists(email) {
    return Promise.resolve(Boolean(getUsersFakeData().find(user => user.email === email)));
  }

  getUserDetailsById(userId) {
    const user = getUsersFakeData().find(user => user.id === userId);
    return user ? Promise.resolve(user) : Promise.reject();
  }

  getUserInfoById(userId) {
    const user = getUsersFakeData().find(user => user.id === userId);
    const userInfo = {
      id: user.id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      fullName: user.fullName
    };
    return userInfo ? Promise.resolve(userInfo) : Promise.reject();
  }

  getUsersInfoListByIds(userIds) {
    // Remove null and duplicated values in list
    const sanitizedList = [...new Set(userIds.filter(Boolean))];
    const users = getUsersFakeData().filter(user => sanitizedList.includes(user.id) ? findUser(user.id) : false);
    const usersInfo = users.map(user => {
      return {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        fullName: user.fullName
      };
    });
    return Promise.resolve(usersInfo);
  }

  createUser(user) {
    return Promise.resolve(user);
  }

  updateUserDetails(userDetails) {
    const index = getUsersFakeData().findIndex(user => user.id === userDetails.id);
    if (index < 0) {
      return Promise.reject();
    }

    const clone = Object.assign({}, userDetails);
    clone.fullName = clone.firstName + ' ' + clone.lastName;
    getUsersFakeData()[index] = clone;
    return Promise.resolve();
  }

  linkOrganisationOnRegister(organisationId) {
    const jsonBodyError = {
      errorCode: 'error.userAlreadyLinked',
      message: `User XXX is already linked to organisation ${organisationId}`,
      description: null,
      additionalData: {}
    };

    const index = getUserOrganisationsFakeData().findIndex(userOrganisation =>
      userOrganisation.organisationId === organisationId && userOrganisation.user.id === getLoggedUser().id);

    if (index > -1) {
      return anHttpError(409, jsonBodyError);
    }

    const status = getUserOrganisationsFakeData().filter(userOrganisation => userOrganisation.organisationId === organisationId).length > 0 ?
      USER_ORGANISATION_STATUS.PENDING :
      USER_ORGANISATION_STATUS.LINKED;
    const userOrganisation = aUserOrganisationVM(getLoggedUser(), organisationId, {status});
    getUserOrganisationsFakeData().push(userOrganisation);
    return aCheckedResponse(userOrganisation);
  }

  linkUserToOrganisation(organisationId, userId) {
    const status = USER_ORGANISATION_STATUS.LINKED;
    const userOrganisation = aUserOrganisationVM(findUser(userId), organisationId, {status});
    getUserOrganisationsFakeData().push(userOrganisation);

    return new Promise(resolve => {
      setTimeout(() => resolve(aCheckedResponse(userOrganisation)), 250);
    });
  }

  acceptUserLinkRequest(organisationId, userId) {
    const index = getUserOrganisationsFakeData().findIndex(userOrganisation =>
      userOrganisation.organisationId === organisationId && userOrganisation.user.id === userId);
    getUserOrganisationsFakeData()[index] = Object.assign(getUserOrganisationsFakeData()[index], {status: USER_ORGANISATION_STATUS.LINKED});

    return new Promise(resolve => {
      setTimeout(() => resolve(anHttpNoContent()), 250);
    });
  }

  declineUserLinkRequest(organisationId, userId) {
    const index = getUserOrganisationsFakeData().findIndex(userOrganisation => userOrganisation.status === USER_ORGANISATION_STATUS.PENDING &&
      userOrganisation.organisationId === organisationId && userOrganisation.user.id === userId);

    getUserOrganisationsFakeData().splice(index, 1);
    return new Promise(resolve => {
      setTimeout(() => resolve(anHttpNoContent()), 250);
    });
  }

  getUsersUnlinkabilityStatusByOrganisation(organisationId) {
    const userOrganisationsStatus = getUserOrganisationsFakeData().filter(userOrganisation => userOrganisation.organisationId === organisationId);

    if (userOrganisationsStatus.length === 1) {
      return aCheckedResponse({[userOrganisationsStatus[0].user.id]: {statusCode: USER_UNLINKABILITY_STATUS.LAST_USER}});
    }

    let userUnlinkabilityStatus = {};

    userOrganisationsStatus.forEach((userOrga, index) => {
      let status;
      if (userOrga.status === USER_ORGANISATION_STATUS.PENDING) {
        status = USER_UNLINKABILITY_STATUS.NOT_LINKED;
      } else if (index % 2 === 0) {
        status = USER_UNLINKABILITY_STATUS.OK;
      } else {
        status = USER_UNLINKABILITY_STATUS.LAST_MANDATORY_ROLES;
      }

      Object.assign(userUnlinkabilityStatus, {[userOrga.user.id]: {statusCode: status}});
    });

    return aCheckedResponse(userUnlinkabilityStatus);
  }

  unlinkUserFromOrganisation(organisationId, userId) {
    const index = getUserOrganisationsFakeData().findIndex(userOrganisation => userOrganisation.status === USER_ORGANISATION_STATUS.LINKED &&
      userOrganisation.organisationId === organisationId && userOrganisation.user.id === userId);

    getUserOrganisationsFakeData().splice(index, 1);
    return new Promise(resolve => {
      setTimeout(() => resolve(anHttpNoContent()), 250);
    });
  }

  getUserPermissionsByOrganisationProject(organisationId, projectId) {
    const userOrganisationProject = getUserOrganisationsProjectFakeData().find(userOrgaProject => userOrgaProject.projectId === projectId)
      .userOrganisations.filter(userOrga => userOrga.userOrganisation.organisationId === organisationId);

    return aCheckedResponse(userOrganisationProject);
  }

  updateUserPermissionsByOrganisationProject(organisationId, projectId, userPermissions) {
    const userOrganisationProject = getUserOrganisationsProjectFakeData().find(userOrgaProject => userOrgaProject.projectId === projectId)
      .userOrganisations.filter(userOrga => userOrga.userOrganisation.organisationId === organisationId)
      .map((userOrgaProject, index) => Object.assign(userOrgaProject, {
        ...userOrgaProject,
        roles: userPermissions[index].roles
      }));

    return aCheckedResponse(userOrganisationProject);
  }

  syncOrganisationsWithOfa() {
    return anHttpNoContent();
  }

  shouldLinkUserToOfa() {
    return false;
  }

  linkUserToOfa() {
    return anHttpNoContent();
  }

  sendActivationEmail() {
    return Promise.resolve();
  }

  updateLanguage() {
    return Promise.resolve();
  }

  requestUpdateEmail() {
    // NOOP has, the email is not changed until the user validate his new email address
    return Promise.resolve();
  }

  /** @Deprecated */
  updateEmail() {
    return Promise.resolve();
  }

  getGeneralTermsLastUpdateTimestamp() {
    return Promise.resolve(anAuthenticatedUser().termsAndConditionsTimestamp - 3600);
  }

  getPrivacyPolicyLastUpdateTimestamp() {
    return Promise.resolve(anAuthenticatedUser().privacyPolicyTimestamp - 3600);
  }

  /** @Deprecated */
  getAcceptationDomainNameAutomaticTieUser() {
    return Promise.resolve([
      {
        id: 1,
        registrationNumber: '11234567458765',
        domainName: 'mail.com',
        groupIdentifier: '123456789'
      },
      {
        id: 2,
        registrationNumber: '11234567458765',
        domainName: 'onceforall.com',
        groupIdentifier: '123456789'
      }
    ]);
  }

  acceptLegalConditions() {
    return new Promise(resolve => {
      setTimeout(() => resolve(anHttpNoContent()), 2000);
    });
  }
}

