const appConfig = {
  keycloak: {
    url: 'https://identity.onceforall.com/auth',
    realm: 'DA',
    clientId: 'front-da',
    ofaProvider: 'keycloak-oidc'
  },

  userOrgaApi: 'https://user-orga.panda-agrement.fr',
  rfaApi: 'https://rfa.panda-agrement.fr',
  enrollmentApi: 'https://enrollment.panda-agrement.fr',
  subscriptionApi: 'https://subscription.panda-agrement.fr',

  blogUrl: 'https://www.panda-agrement.fr/pandactu/',
  maintenanceUrlJSON: 'https://plateforme.panda-agrement.fr/maintenance/maintenance.json',

  sentry: {
    url: 'https://773f019f589044f1a3acd771517ed72a@o229373.ingest.sentry.io/5351544',
    environment: 'production'
  },
  analytics: 'G-K8Y77VM5DD',

  titlePrefix: undefined,
  favicon: undefined,

  featureFlags: {
    showTBFAQ: true,
    isPublicProcurementEnabled: true
  },

  offline: false,
  log: 'sentry'
};

module.exports = appConfig;
