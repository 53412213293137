import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';
import {Tooltip} from '@material-ui/core';
import {TASK_TYPE} from '../../../../utils/constant';
import momentPropTypes from 'react-moment-proptypes';

const slash = '/';

export const ParticipantView = props => {
  const rejectButton = <Tooltip data-id="reject-tooltip" title={<Translate value="request.page.rejectRequest"/>}>
    <button type="button"
      className="large secondary inline-container"
      data-id="reject-button"
      onClick={() => props.onRejectRequestClick()}>
      <Icon icon="cross"/>
    </button>
  </Tooltip>;

  let requestValidationState =
    <div className={props.requestValidationStateClassName}>
      <Icon icon={props.requestValidationStateIcon}/>
      <Translate value={props.requestValidationStateTranslation}/>
    </div>;

  requestValidationState = (props.previousActionDate && props.previousActionUserName) ?
    <Tooltip data-id="validation-tooltip" title={<Translate
      value={props.requestValidationStateType}
      date={props.previousActionDate.format('L')}
      user={props.previousActionUserName}/>}>
      {requestValidationState}
    </Tooltip> : requestValidationState;

  const conditionState = props.condition ?
    <Tooltip data-id="condition-tooltip" title={props.condition}>
      <div className={props.requestValidationStateClassName} style={{whiteSpace: 'nowrap'}}>
        <Translate value={props.conditionTranslation + 'title'}/>
      </div>
    </Tooltip> : null;

  return (
    <>
      <div className={`box border bottom ${props.isLast ? 'mb-0' : ''}`}>
        <div className="card-header">
          <div className="font-size-xl" style={{maxWidth: '65%'}}>
            <div className="inline-container">{props.position}</div>
            &nbsp;{slash}&nbsp;
            <Translate value={`request.page.${props.task?.role}`} level={props.task?.roleLevel}/>
          </div>
          <div className="flex-column">
            {requestValidationState}
            {conditionState}
          </div>
        </div>
        <div>
          <div className="flex-container space-between">
            <div className="flex-column" style={{paddingLeft: 'calc(100% / 25)'}}>
              <b className="font-size-xl">{props.participant?.fullName}</b>
              <div className="information">{props.participant?.registrationNumber}</div>
            </div>
            {props.areUserActionsAllowed && props.isSignatory && props.task.type === TASK_TYPE.SIGN &&
              <div className="inline-container">
                {rejectButton}
                <Tooltip title={<Translate value="request.documents.actions.sign"/>}>
                  <button type="button" className="large secondary inline-container" data-id="sign-button" onClick={() => props.onSignClick(props.task.role)}>
                    <Icon icon="sign"/>
                  </button>
                </Tooltip>
              </div>
            }
            {props.areUserActionsAllowed && props.isVerificatorOnly && props.task.type === TASK_TYPE.VERIFY && !props.isVerified &&
              <div className="inline-container">
                {rejectButton}
                <Tooltip title={<Translate value="request.documents.actions.verify"/>}>
                  <button type="button" className="large secondary inline-container" data-id="verify-button" onClick={() => props.onVerifyClick(props.task.role)}>
                    <Icon icon="check"/>
                  </button>
                </Tooltip>
              </div>
            }
            {(props.isSignatory || props.isVerificatorOnly) && props.canRemoveCondition &&
              <Tooltip title={<Translate value={props.conditionTranslation + 'tooltip'}/>}>
                <button type="button" className="large secondary inline-container" data-id="delete-condition-button" onClick={() => props.onRemoveConditionClick()}>
                  <Icon icon="trash"/>
                </button>
              </Tooltip>
            }
          </div>
        </div>
      </div>
    </>
  );
};

ParticipantView.defaultProps = {
  condition: null,
  position: 1,
  isLast: false,
  requestValidationStateIcon: 'error',
  requestValidationStateTranslation: '',
  previousActionDate: null,
  previousActionUserName: null
};

ParticipantView.propTypes = {
  task: PropTypes.object,
  previousActionDate: momentPropTypes.momentObj,
  previousActionUserName: PropTypes.string,
  participant: PropTypes.object,
  position: PropTypes.number,
  condition: PropTypes.string,
  isLast: PropTypes.bool,
  isVerified: PropTypes.bool,
  isVerificatorOnly: PropTypes.bool,
  isSignatory: PropTypes.bool,
  areUserActionsAllowed: PropTypes.bool,
  canRemoveCondition: PropTypes.bool,
  shouldHaveTooltip: PropTypes.bool,
  requestValidationStateIcon: PropTypes.string,
  requestValidationStateClassName: PropTypes.string,
  requestValidationStateTranslation: PropTypes.string,
  requestValidationStateType: PropTypes.string,
  conditionTranslation: PropTypes.string,
  onSignClick: PropTypes.func.isRequired,
  onVerifyClick: PropTypes.func.isRequired,
  onRejectRequestClick: PropTypes.func.isRequired,
  onRemoveConditionClick: PropTypes.func.isRequired
};

export default ParticipantView;
