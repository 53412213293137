import config from '../../../config';
import {fetchJsonFile} from '../../utils/http';

export default class LiveMaintenanceService {
  get() {
    if (!config.maintenanceUrlJSON) {
      return Promise.resolve(null);
    }

    return fetchJsonFile(config.maintenanceUrlJSON);
  }
}
