import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Tooltip} from '@material-ui/core';
import {Icon} from 'front-onceforall-core';
import {PROJECT_ORGANISATION_ROLE} from '../../../../../utils/constant';

/**
 * Displays a participant showing the role, name and registration number of the org in a box
 */
export const ParticipantView = props => {
  const removeButton = () => {
    if (!props.authorizedActions?.authorizedActions || props.role !== PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR) {
      return;
    }

    const tooltipText = props.authorizedActions.isRemovable ?
      'project.remove.generalContractor.participation.button' :
      'project.remove.generalContractor.participation.impossible';

    return <Tooltip
      title={<Translate value={tooltipText}/>}
      placement="top"
    >
      <span>
        <button
          type="button"
          className="small secondary"
          disabled={!props.authorizedActions.isRemovable}
          onClick={props.onRemoveGC}
        >
          <Icon icon="trash"/>
        </button>
      </span>
    </Tooltip>;
  };

  return (
    <div className="box border bottom" style={{width: '100%'}}>
      {!props.isInAccordion && <div className="card-header font-size-xl"><Translate value={`project.role.${props.role}.title`}/></div>}
      <div className="flex-container space-between">
        <b className="font-size-xl">{props.organisationName}</b>
        <div className="flex-container">
          <div style={{whiteSpace: 'nowrap'}}>{props.registrationNumber}</div>
          {removeButton()}
        </div>
      </div>
    </div>
  );
};

ParticipantView.defaultProps = {
  isInAccordion: false
};

ParticipantView.propTypes = {
  role: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  registrationNumber: PropTypes.string.isRequired,
  authorizedActions: PropTypes.object,
  onRemoveGC: PropTypes.func,
  isInAccordion: PropTypes.bool
};

export default ParticipantView;
