import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty as isEmptyArray} from 'front-onceforall-core/dist/utils/arrays';
import {TASK_STATUS, TASK_TYPE, USER_ORGANISATION_ROLE} from '../../../../utils/constant';
import ParticipantView from './ParticipantView';
import moment from 'moment-timezone';
import ConfirmModal from '../../../modal/ConfirmModal';
import {Translate} from 'react-redux-i18n';
import Icon from 'front-onceforall-core/dist/components/Icon';

export const ParticipantContainer = props => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const isVerificatorOnly = props.userRoles.includes(USER_ORGANISATION_ROLE.VERIFICATOR) && !props.userRoles.includes(USER_ORGANISATION_ROLE.SIGNATORY);
  const isSignatory = props.userRoles.includes(USER_ORGANISATION_ROLE.SIGNATORY);

  const getActiveTaskForUserActions = step => {
    if (isEmptyArray(props.stepTasks)) {
      return null;
    }

    const activeVerifyTask = step.find(task => task.type === TASK_TYPE.VERIFY && task.state === TASK_STATUS.ACTIVE);
    const activeSignTask = step.find(task => task.type === TASK_TYPE.SIGN && task.state === TASK_STATUS.ACTIVE);

    return isVerificatorOnly && activeVerifyTask ? activeVerifyTask : activeSignTask;
  };

  // First option : select the active task for the right user (signatory or verificator)
  // Second option : if all task are validated, then find the signing task (means that the participant signed)
  // Third option : if none of the above, get the first element (since they have no type cause none of this participant's task are active)
  const activeTaskForUserActions = getActiveTaskForUserActions(props.stepTasks) || props.stepTasks.find(task => task.type === TASK_TYPE.SIGN) || props.stepTasks[0];

  const isSigned = props.stepTasks.find(task => task.type === TASK_TYPE.SIGN)?.state === TASK_STATUS.VALIDATED;
  const isVerified = props.stepTasks.length > 1 && props.stepTasks.find(task => task.type === TASK_TYPE.VERIFY)?.state === TASK_STATUS.VALIDATED;

  const areUserActionsAllowed = !isSigned && activeTaskForUserActions.taskId &&
    props.organisationId === props.participant.id && props.organisationId === activeTaskForUserActions.organisationId;

  let previousActionDate = null;
  let previousActionUserName = null;
  let requestValidationStateIcon;
  let requestValidationStateClassName = 'flex-container flex-end ';
  let requestValidationStateTranslation = 'request.documents.states.';
  let requestValidationStateType = 'request.page.validationStateMessage.';
  let conditionTranslation = 'request.page.suspensiveCondition.';
  let canRemoveCondition = false;

  if (isSigned) {
    requestValidationStateIcon = 'sign';
    requestValidationStateClassName += 'success';
    requestValidationStateTranslation += 'SIGNED';
    requestValidationStateType += 'signed';
    previousActionDate = moment(activeTaskForUserActions.endDate);
    previousActionUserName = props.actionUsers.find(user => activeTaskForUserActions.userId === user.id)?.fullName;
  } else if (isVerified) {
    const previousVerifyTask = props.stepTasks.find(task => task.type === TASK_TYPE.VERIFY);
    requestValidationStateIcon = 'check';
    requestValidationStateClassName += 'warning';
    requestValidationStateTranslation += 'VERIFIED';
    requestValidationStateType += 'verified';
    previousActionDate = moment(previousVerifyTask.endDate);
    previousActionUserName = props.actionUsers.find(user => previousVerifyTask.userId === user.id)?.fullName;
  } else {
    requestValidationStateIcon = 'error';
    requestValidationStateClassName += 'error';
    requestValidationStateTranslation += 'UNSIGNED';
  }

  if (props.suspensiveCondition?.content) {
    if (props.isFinalCondition && props.isWorkflowEnded) {
      conditionTranslation += 'final.';
    } else {
      conditionTranslation += 'mention.';
    }

    if (props.isWorkflowEnded && props.organisationId === props.participant.id) {
      canRemoveCondition = true;
    }
  }

  return (
    <>
      <ParticipantView
        task={activeTaskForUserActions}
        previousActionDate={previousActionDate}
        previousActionUserName={previousActionUserName}
        participant={props.participant}
        position={props.position}
        condition={props.suspensiveCondition?.content}
        isLast={props.isLast}
        isVerified={isVerified}
        isVerificatorOnly={isVerificatorOnly}
        isSignatory={isSignatory}
        areUserActionsAllowed={areUserActionsAllowed}
        canRemoveCondition={canRemoveCondition}
        requestValidationStateIcon={requestValidationStateIcon}
        requestValidationStateClassName={requestValidationStateClassName}
        requestValidationStateTranslation={requestValidationStateTranslation}
        requestValidationStateType={requestValidationStateType}
        conditionTranslation={conditionTranslation}
        onSignClick={props.onSignClick}
        onVerifyClick={props.onVerifyClick}
        onRejectRequestClick={props.onRejectRequestClick}
        onRemoveConditionClick={() => setModalOpen(true)}
      />
      <ConfirmModal
        data-id="remove-condition-modal"
        isOpen={modalOpen}
        onConfirm={() => {
          props.onRemoveConditionClick(props.suspensiveCondition.organisationRole, conditionTranslation);
          setModalOpen(false);
        }}
        onClose={() => setModalOpen(false)}
        content={<Translate value={conditionTranslation + 'modal'}/>}
        submitButtonBody={<><Icon icon="check"/><Translate value={conditionTranslation + 'tooltip'}/></>}
      />
    </>
  );
};

ParticipantContainer.defaultProps = {
  position: 1,
  isLast: false
};

ParticipantContainer.propTypes = {
  organisationId: PropTypes.number.isRequired,
  participant: PropTypes.object.isRequired,
  actionUsers: PropTypes.array.isRequired,
  userRoles: PropTypes.array.isRequired,
  suspensiveCondition: PropTypes.object,
  stepTasks: PropTypes.array.isRequired,
  position: PropTypes.number,
  isLast: PropTypes.bool,
  isFinalCondition: PropTypes.bool,
  isWorkflowEnded: PropTypes.bool,
  onSignClick: PropTypes.func.isRequired,
  onVerifyClick: PropTypes.func.isRequired,
  onRejectRequestClick: PropTypes.func.isRequired,
  onRemoveConditionClick: PropTypes.func.isRequired
};

export default ParticipantContainer;
