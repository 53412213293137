import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';

import {TextCheckbox} from 'front-onceforall-core';

import {getFieldLabel} from '../../utils';

import RadioYesNo from '../RadioYesNo';

function PersonalDataProcessing({onChange, personalDataProcessing}) {
  const handleChange = (name, value) => {
    onChange(current => ({
      personalDataProcessing: {
        ...current.personalDataProcessing,
        [name]: value
      }
    }));
  };

  const handleComplexChange = name => event => {
    handleChange(name, event.target.value);
  };

  const handleChangeCheckbox = name => event => {
    handleChange(name, event.target.checked);
  };

  const handleChangeRadio = name => value => {
    handleChange(name, value);
  };

  function dataField(name) {
    return (
      <div className="row pb-3">
        <div className="col-12">
          <TextField
            label={getFieldLabel(`publicProcurement.personalDataProcessing.${name}`, true)}
            id={`personalDataProcessing-${name}`}
            name={`personalDataProcessing-${name}`}
            value={personalDataProcessing?.[name] || ''}
            onChange={handleComplexChange(name)}
          />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex-container pb-3">
        <RadioYesNo
          id="personalDataProcessing"
          name="personalDataProcessing"
          title={getFieldLabel('publicProcurement.titles.personalDataProcessing', true)}
          value={personalDataProcessing?.isSubcontracted}
          onChange={handleChangeRadio('isSubcontracted')}
        />
      </div>
      {personalDataProcessing?.isSubcontracted && (
        <div className="card">
          {dataField('services')}
          {dataField('duration')}
          {dataField('process')}
          {dataField('purpose')}
          {dataField('dataType')}
          {dataField('targets')}
          <div className="row pb-3">
            <div className="col-12 pb-3">
              <Translate value="publicProcurement.personalDataProcessing.declareThat"/>
            </div>
            <div className="col-12 pb-3">
              <TextCheckbox
                id="personalDataProcessing-personalDataProtectionAgreement"
                name="personalDataProcessing-personalDataProtectionAgreement"
                label={getFieldLabel('publicProcurement.personalDataProcessing.personalDataProtectionAgreement', true)}
                checked={Boolean(personalDataProcessing?.personalDataProtectionAgreement)}
                onChange={handleChangeCheckbox('personalDataProtectionAgreement')}
              />
            </div>
            <div className="col-12">
              <TextCheckbox
                id="personalDataProcessing-personalDataRegulationAgreement"
                name="personalDataProcessing-personalDataRegulationAgreement"
                label={(
                  <div>
                    <Translate value="publicProcurement.personalDataProcessing.personalDataRegulationAgreement.part1"/>
                    <a href="https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre4#Article28" target="_blank" rel="noopener noreferrer">
                      <Translate value="publicProcurement.personalDataProcessing.personalDataRegulationAgreement.link"/>
                    </a>
                    <Translate value="publicProcurement.personalDataProcessing.personalDataRegulationAgreement.part2"/>
                    {' *'}
                  </div>
                )}
                checked={Boolean(personalDataProcessing?.personalDataRegulationAgreement)}
                onChange={handleChangeCheckbox('personalDataRegulationAgreement')}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

PersonalDataProcessing.propTypes = {
  onChange: PropTypes.func.isRequired,
  personalDataProcessing: PropTypes.shape({
    isSubcontracted: PropTypes.bool,
    services: PropTypes.string,
    duration: PropTypes.string,
    process: PropTypes.string,
    purpose: PropTypes.string,
    dataType: PropTypes.string,
    targets: PropTypes.string,
    personalDataProtectionAgreement: PropTypes.bool,
    personalDataRegulationAgreement: PropTypes.bool
  })
};

export default PersonalDataProcessing;
