import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Paginator} from 'front-onceforall-core';
import Action from './action';

export const ActiveRequestActionsView = props => {
  if (props.requests.length === 0 && !props.error) {
    return null;
  }

  const rows = props.requests.map(request =>
    <Action
      key={request.id}
      history={props.history}
      request={request}
      organisations={props.organisations}
    />);

  return (
    <div className="card col-12">
      {props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
        <>
          {rows}
          <Paginator
            totalItems={props.requestsCount}
            pageSize={props.pageSize}
            currentPage={props.page}
            onChangePage={props.onPageChange}
          />
        </>
      }
    </div>
  );
};

ActiveRequestActionsView.propTypes = {
  history: PropTypes.object.isRequired,
  organisations: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  requestsCount: PropTypes.number.isRequired,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  error: PropTypes.bool,
  onPageChange: PropTypes.func.isRequired
};

ActiveRequestActionsView.defaultProps = {
  page: 0,
  pageSize: 10,
  error: false
};

export default ActiveRequestActionsView;
