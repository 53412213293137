import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {PROJECT_ORGANISATION_ROLE} from '../../../../../utils/constant';
import {I18n, Translate} from 'react-redux-i18n';
import {Tooltip} from '@material-ui/core';
import {Icon} from 'front-onceforall-core';
import SelectOrganisation from '../../../../selectOrganisation/SelectOrganisation';
import {enrollmentService} from '../../../../../services/enrollment/EnrollmentService';
import {projectService} from '../../../../../services/project/ProjectService';
import {captureError} from '../../../../../utils/log';

const ROLES = [
  PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT,
  PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE,
  PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_ASSISTANT,
  PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER,
  PROJECT_ORGANISATION_ROLE.PROJECT_SAFETY_COORDINATOR
];

const ROLE_FIELD_NAME = {
  [PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT]: 'clientId',
  [PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE]: 'clientDelegateId',
  [PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_ASSISTANT]: 'clientAssistantId',
  [PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER]: 'projectManagerId',
  [PROJECT_ORGANISATION_ROLE.PROJECT_SAFETY_COORDINATOR]: 'safetyCoordinatorId'
};

function buildInitialParticipants() {
  const participants = {};
  ROLES.forEach(role => {
    participants[role] = {role, organisation: null};
  });
  return participants;
}

export const ParticipantsEditionForm = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingForValidation, setIsLoadingForValidation] = useState(false);
  const [allParticipants] = useState(buildInitialParticipants());
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);

  const validateChanges = () => {
    setIsLoadingForValidation(true);
    const participants = {};
    ROLES.forEach(role => {
      // invited participant have their id equals to the invitation id, it's not null and could be confused with organisation id
      participants[ROLE_FIELD_NAME[role]] = allParticipants[role].organisation?.email || !allParticipants[role].organisation?.id ? null : allParticipants[role].organisation.id;
    });

    const promisesForUpdates = [projectService.editParticipants(props.projectId, participants)];

    ROLES.forEach(role => {
      const participant = allParticipants[role];
      // Two sorts of invitations may exist: existing ones and new ones.
      // Both have an associated email that distinguishes them from ordinary orgs.
      // New ones have no id while existing ones have an invitation id.
      if (participant.organisation?.email && !participant.organisation?.id) {
        switch (role) {
          case PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT:
            promisesForUpdates.push(enrollmentService.inviteUnknownClient(participant.organisation, props.projectId, props.organisation.id));
            break;
          case PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE:
            promisesForUpdates.push(enrollmentService.inviteUnknownClientDelegate(participant.organisation, props.projectId, props.organisation.id));
            break;
          case PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_ASSISTANT:
            promisesForUpdates.push(enrollmentService.inviteUnknownClientAssistant(participant.organisation, props.projectId, props.organisation.id));
            break;
          case PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER:
            promisesForUpdates.push(enrollmentService.inviteUnknownProjectManager(participant.organisation, props.projectId, props.organisation.id));
            break;
          case PROJECT_ORGANISATION_ROLE.PROJECT_SAFETY_COORDINATOR:
            promisesForUpdates.push(enrollmentService.inviteUnknownSafetyCoordinator(participant.organisation, props.projectId, props.organisation.id));
            break;
          default:
            break;
        }
      }
    });

    Promise.allSettled(promisesForUpdates)
      .then(values => {
        if (values[0].status === 'rejected') {
          props.actions.addError(<Translate value={'project.edit.participants.failure'}/>);
        }

        if (values.slice(1).some(result => result.status === 'rejected')) {
          props.actions.addError(<Translate value={'project.edit.invitation.add.failure'}/>);
        }

        const allFulfilled = values.filter(result => result.status === 'fulfilled').length === promisesForUpdates.length;
        if (allFulfilled) {
          props.actions.addSuccess(<Translate value={'project.edit.success'}/>);
          props.onValidatedUpdate();
        }

        setIsLoadingForValidation(false);
      });
  };

  const updateParticipant = (role, organisation, isInvited) => {
    // deletes only existing invitations in DB, not newly created (not saved yet in DB)
    if (isInvited && allParticipants[role].organisation?.id) {
      deleteInvited(role);
    }

    setSelectedRole(role);
    setSelectedOrg(organisation ? organisation : undefined);
  };

  const deleteInvited = role => {
    setIsLoadingForValidation(true);
    enrollmentService.deleteProjectParticipantInvitationById(allParticipants[role].organisation.id, props.projectId)
      .then(() => {
        props.actions.addSuccess(<Translate value={'project.edit.invitation.remove.success'}/>);
        props.onDeleteInvitation();
      })
      .catch(error => {
        captureError('', error);
        props.actions.addError(<Translate value={'project.edit.remove.invitation.remove.failure'}/>);
      })
      .finally(() => {
        setIsLoadingForValidation(false);
      });
  };

  const isOrganisationNotProjectOwner = role => {
    return allParticipants[role].organisation?.id !== props.organisation.id;
  };

  const removeButton = (role, isInvited) => {
    return <Tooltip
      title={<Translate value={'project.edit.remove'}/>}
      placement="top"
    >
      <span>
        <button
          data-id={`remove-${role}`}
          type="button"
          className="large"
          onClick={() => updateParticipant(role, null, isInvited)}
        >
          <Icon icon={isLoadingForValidation ? 'loader' : 'trash'}/>
        </button>
      </span>
    </Tooltip>;
  };

  const titleComponent = (role, isInvited) => {
    const shouldDisplayRemoveButton = isOrganisationNotProjectOwner(role);
    return (
      <div className="flex-container">
        <strong className="font-size-l"><Translate value={`project.role.${role}.title`}/></strong>
        {shouldDisplayRemoveButton ? removeButton(role, isInvited) : null}
      </div>
    );
  };

  const aParticipantComponent = participant => {
    const border = participant.role === PROJECT_ORGANISATION_ROLE.PROJECT_SAFETY_COORDINATOR ? 'none' : 'bottom';
    const isInvited = Boolean(participant.organisation?.email);

    return (
      <>
        <div data-id={participant.role} className={`box border ${border}`} style={{width: '100%'}}>
          {titleComponent(participant.role, isInvited)}
          <SelectOrganisation
            organisation={props.organisation} // connected organisation
            selectedOrganisation={participant.organisation}
            onSelectOrganisation={org => updateParticipant(participant.role, org)}
            selectedOrganisationRole={participant.role}
            isParticipantsUpdate={true}
            isEditionEnabled={!isInvited && isOrganisationNotProjectOwner(participant.role)}
          />
        </div>
      </>
    );
  };

  React.useEffect(() => {
    props.participants.forEach(participant => {
      // deep copy to not modify the actual participants configuration
      allParticipants[participant.role] = JSON.parse(JSON.stringify(participant));
    });

    props.invitations.forEach(invited => {
      allParticipants[invited.role] = {role: invited.role, organisation: invited};
    });

    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    if (selectedRole) {
      allParticipants[selectedRole].organisation = selectedOrg ? selectedOrg : null;
    }

    setSelectedRole(null);
  }, [selectedOrg]);

  return isLoading ? <div className="loading-overlay"/> :
    <>
      <div className="box card flex-container">
        <div className="secondary">
          <Icon icon="info"/>
        </div>
        <div>
          {I18n.t('project.edit.participants.part1')}
        </div>
      </div>
      {aParticipantComponent(allParticipants[PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT])}
      {aParticipantComponent(allParticipants[PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE])}
      {aParticipantComponent(allParticipants[PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_ASSISTANT])}
      {aParticipantComponent(allParticipants[PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER])}
      {aParticipantComponent(allParticipants[PROJECT_ORGANISATION_ROLE.PROJECT_SAFETY_COORDINATOR])}
      <div className="mt-4 flex-container justify-content-end">
        <button
          data-id="validation-btn"
          className="large primary inline-container"
          type="button"
          onClick={validateChanges}
        >
          <Translate value="action.confirm"/>
          <Icon icon={isLoadingForValidation ? 'loader' : 'paper-plane'}/>
        </button>
      </div>
    </>;
};

ParticipantsEditionForm.propTypes = {
  organisation: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  participants: PropTypes.array.isRequired,
  invitations: PropTypes.array.isRequired,
  onValidatedUpdate: PropTypes.func.isRequired,
  onDeleteInvitation: PropTypes.func.isRequired,
  actions: PropTypes.object.isRequired
};

export default ParticipantsEditionForm;
