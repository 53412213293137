const initialState = null;

/**
 * WARNING! Organisation state is for now completly store into local storage!
 * Add additionnal information with caution
 */
export default function organisation(state = initialState, action = '') {
  if (action.type === 'selectOrganisation') {
    return action.organisation;
  }

  if (action.type === 'removeOrganisation') {
    return null;
  }

  return state;
}
