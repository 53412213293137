import {subscriptionService} from '../services/subscription/SubscriptionService';
import {captureError} from '../utils/log';
import {REQUEST_SUBSCRIPTION, REQUEST_SUBSCRIPTION_ERROR, REQUEST_SUBSCRIPTION_SUCCESS} from './constant';

const DELAY_BETWEEN_FETCH_SUBSCRIPTION_REQUEST_MS = 5000;

let delayNextFetchingRequests = false;

function requestSubscription() {
  return {
    type: REQUEST_SUBSCRIPTION
  };
}

function requestSubscriptionSuccess(subscription) {
  return {
    type: REQUEST_SUBSCRIPTION_SUCCESS,
    subscription
  };
}

function requestSubscriptionError(error) {
  return {
    type: REQUEST_SUBSCRIPTION_ERROR,
    error
  };
}

export function fetchSubscription(forceUpdate = false) {
  return (dispatch, getState) => {
    const {subscription: previousSubscription, organisation} = getState();
    const organisationId = organisation?.id || null;
    const organisationHasChanged = previousSubscription?.credits ?
      organisationId !== previousSubscription.credits.organisationId :
      false;
    const previousRequestIsFetching = previousSubscription?.isFetching || false;

    // If the organisation has not changed and the update is not forced
    // Avoid making too much requests when previous request is currently fetching or when the delay
    // between 2 requests we required (delayNextFetchingRequests) is not over
    if (!forceUpdate && !organisationHasChanged && (previousRequestIsFetching || delayNextFetchingRequests)) {
      dispatch(requestSubscriptionSuccess(previousSubscription));
      return Promise.resolve();
    }

    dispatch(requestSubscription());
    delayNextFetchingRequests = true;

    return subscriptionService.getOrganisationCredits(organisationId)
      .then(credits => {
        dispatch(requestSubscriptionSuccess({credits}));
      })
      .catch(error => {
        dispatch(requestSubscriptionError(error));
        captureError('[subscription.fetchSubscription] Failed during subscriptionService.getOrganisationCredits() call', error);
        throw new Error(error);
      })
      .finally(() =>
        setTimeout(() => {
          delayNextFetchingRequests = false;
        }, DELAY_BETWEEN_FETCH_SUBSCRIPTION_REQUEST_MS)
      );
  };
}

export function updateSubscriptionCredits(newCredits) {
  return (dispatch, getState) => {
    dispatch(requestSubscription());

    const organisationId = getState().organisation?.id || null;
    return subscriptionService.updateOrganisationCredits(newCredits, organisationId)
      .then(credits => {
        dispatch(requestSubscriptionSuccess({credits}));
      })
      .catch(error => {
        dispatch(requestSubscriptionError(error));
        captureError('[actions/subscription/updateSubscriptionCredits] subscriptionService.updateOrganisationCredits', error);
        throw new Error(error);
      });
  };
}
