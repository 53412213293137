import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';
import {useSelector} from 'react-redux';

import {TextPhoneNumber} from 'front-onceforall-core';

import {getFieldLabel} from '../../utils';

function ClientInformation(props) {
  const currentLocale = useSelector(state => state.i18n.locale);

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.clientInformation"/></h2>
      </div>
      <div className="row">
        <div className="col-lg-9 col-md-8 col-sm-7 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.client.fullName', true)}
            id="client-fullName"
            name="client-fullName"
            value={props.client?.fullName || ''}
            disabled={true}
          />
        </div>
        <div className="col-lg-3 col-md-4 col-sm-5 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.client.registrationNumber', true)}
            id="client-registrationNumber"
            name="client-registrationNumber"
            value={props.client?.registrationNumber || ''}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <TextField
            label={getFieldLabel('publicProcurement.client.address', true)}
            id="client-address"
            name="client-address"
            value={props.client?.address || ''}
            disabled={true}
          />
        </div>
      </div>
      <div className="flex-container pb-3 pt-4">
        <Translate value="publicProcurement.titles.clientContact"/>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.publicProcurementData.clientContact.firstName', true)}
            id="client-firstName"
            name="client-firstName"
            value={props.projectPublicProcurementData.clientContact?.firstName || ''}
            disabled={true}
          />
        </div>
        <div className="col-lg-6 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.publicProcurementData.clientContact.lastName', true)}
            id="client-lastName"
            name="client-lastName"
            value={props.projectPublicProcurementData.clientContact?.lastName || ''}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.publicProcurementData.clientContact.jobTitle', true)}
            id="client-jobTitle"
            name="client-jobTitle"
            value={props.projectPublicProcurementData.clientContact?.jobTitle || ''}
            disabled={true}
          />
        </div>
        <div className="col-lg-4 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.publicProcurementData.clientContact.email', false)}
            id="client-email"
            name="client-email"
            value={props.projectPublicProcurementData.clientContact?.email || ''}
            disabled={true}
          />
        </div>
        <div className="col-lg-4 col-lg-3 col-md-12">
          <TextPhoneNumber
            label={getFieldLabel('publicProcurement.publicProcurementData.clientContact.phoneNumber', false)}
            id="client-phoneNumber"
            value={props.projectPublicProcurementData.clientContact?.phoneNumber || ''}
            currentLocale={currentLocale}
            onChange={() => {}}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
}

ClientInformation.propTypes = {
  client: PropTypes.shape({
    fullName: PropTypes.string,
    registrationNumber: PropTypes.string,
    address: PropTypes.string
  }),
  projectPublicProcurementData: PropTypes.shape({
    clientContact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      jobTitle: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string
    })
  }).isRequired
};

export default ClientInformation;
