import moment from 'moment-timezone';
import {setLocale} from 'react-redux-i18n';
import {setUser} from '../../actions/user';
import {selectOrganisation} from '../../actions/organisation';
import {findOrganisation} from '../../utils/database/organisations';
import {anAuthenticatedUser} from '../../utils/fixtures';
import {getUserOrganisationsFakeData} from '../../utils/database/users';
import {
  BACK_OFFICE_PROJECT_ROLES_KEY,
  OFFLINE_IS_BACK_OFFICE_KEY
} from '../../components/header/organisationHeader/adminActions/AdminActionsView';
import {USER_ORGANISATION_ROLE} from '../../utils/constant';

export const fakeUser = anAuthenticatedUser({
  fromOfa: true,
  userOrganisations: []
});
fakeUser.userOrganisations = getUserOrganisationsFakeData().filter(userOrganisation => userOrganisation.user.id === fakeUser.id)
  .map(userOrganisation => findOrganisation(userOrganisation.organisationId));

export default class FakeAuthenticationService {
  init(store) {
    store.dispatch(setUser(fakeUser));
    store.dispatch(setLocale(fakeUser.language));
    moment.locale(fakeUser.language);
    const localOrganisation = store.getState().organisation?.id ? findOrganisation(store.getState().organisation.id) : fakeUser.userOrganisations[0];
    store.dispatch(selectOrganisation(localOrganisation));
    return Promise.resolve();
  }

  logout() {
  }

  updateTokenKeycloak() {
    return Promise.resolve('tokenKeycloak');
  }

  updateTokenRoles() {
    return Promise.resolve('tokenRoles');
  }

  authRedirect(url) {
    return Promise.resolve(url);
  }

  roles() {
    return {
      getProjectRoles: projectId => this.getProjectRoles(projectId),
      hasProjectRole: (projectId, role) => this.getProjectRoles(projectId).includes(role),
      isBackOffice: () => this.isBackOffice()
    };
  }

  isBackOffice() {
    return localStorage.getItem(OFFLINE_IS_BACK_OFFICE_KEY) === 'true';
  }

  getProjectRoles() {
    try {
      return JSON.parse(localStorage.getItem(BACK_OFFICE_PROJECT_ROLES_KEY)) || [USER_ORGANISATION_ROLE.SIGNATORY];
    } catch (e) {
      return [USER_ORGANISATION_ROLE.SIGNATORY];
    }
  }

  hasProjectRole(role) {
    return localStorage.getItem('offlineMode_hasProjectRole_' + role) === 'true';
  }
}
