import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from '@material-ui/core';

const TooltipButton = ({children, color, disabled, tooltipMessage, onClick, size, style}) => {
  const showTooltip = Boolean(tooltipMessage);

  const disablePointerEventsStyle = {pointerEvents: disabled ? 'none' : 'auto'};
  const mergedStyle = style && typeof style === 'object' ?
    {...disablePointerEventsStyle, ...style} :
    disablePointerEventsStyle;

  const button = (
    <button
      type="button"
      className={`inline-container ${(size)} ${(color)}`}
      disabled={disabled}
      onClick={onClick}
      style={mergedStyle}
    >
      {children}
    </button>
  );

  if (showTooltip) {
    return (
      <Tooltip title={tooltipMessage}>
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};

TooltipButton.defaultProps = {
  size: 'large',
  color: 'primary',
  disabled: false
};

TooltipButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  tooltipMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  onClick: PropTypes.func.isRequired
};

export default TooltipButton;
