import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {Collapse} from '@material-ui/core';
import {TextSimpleAutocomplete} from 'front-onceforall-core';

export const SelectProjectView = props => {
  const suggestions = props.projects.map(project => ({label: project.name, value: project.id}));

  const handleSelectProject = event => {
    const selection = event.target.value;
    if (selection) {
      props.onSelectProject(props.projects.find(project => project.id === selection.value));
    }
  };

  return (
    <div className="card">
      <div className="flex-container space-between">
        <div className="flex-container">
          <h2 className="m-0"><Translate value="request.create.project.title"/></h2>
          {!props.isOpen &&
            <div className="chip-l information-primary ml-4">
              <Translate value={props.project.name}/>
            </div>
          }
        </div>
        {!props.isOpen &&
          <button
            data-id="changeProjectButton"
            className="inline-container small secondary"
            onClick={() => props.onSelectProject(null)}
          >
            <Translate value="request.create.project.change"/>
          </button>
        }
      </div>
      <Collapse in={props.isOpen}>
        <div className="py-4 strong d-flex flex-column">
          <Translate className="py-2" value={'request.create.project.information1'}/>
          <Translate className="py-2" value={'request.create.project.information2'}/>
          <Translate className="py-2" value={'request.create.project.information3'}/>
          <Translate className="py-2" value={'request.create.project.information4'}/>
        </div>
        <div className="py-4 strong">
          <Translate value={'request.create.project.notice'}/>
        </div>
        <TextSimpleAutocomplete
          suggestions={suggestions}
          value={null}
          placeholder={I18n.t(`request.create.project.input${props.loading ? 'Loading' : 'Placeholder'}`)}
          onChange={handleSelectProject}
          minimumCharsToRenderResults={0}
          maxResult={5}
          disabled={props.loading}
        />
      </Collapse>
    </div>
  );
};

SelectProjectView.propTypes = {
  projects: PropTypes.array.isRequired,
  project: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSelectProject: PropTypes.func.isRequired
};

export default SelectProjectView;
