import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as snackbarsActions from '../../actions/snackbars';
import RequestView from './RequestView';
import {requestService} from '../../services/request/RequestService';
import {authenticationService} from '../../services/authentication/AuthenticationService';
import {openOrSaveFile} from '../../utils/http';
import ConfirmModal from '../modal/ConfirmModal';
import {Translate} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';
import {captureError} from '../../utils/log';
import {PROJECT_ORGANISATION_ROLE, RFA_STATUS_TO_WORKFLOW_STATUS, WORKFLOW_STATUS} from '../../utils/constant';

export const RequestContainer = props => {
  const [request, setRequest] = React.useState(null);
  const [rfaOwnerName, setRfaOwnerName] = React.useState('');
  const [subcontractorName, setSubcontractorName] = React.useState('');
  const [isAwaitingSubcontractor, setIsAwaitingSubcontractor] = React.useState(false);
  const [payMasterOrganisationName, setPayMasterOrganisationName] = React.useState('');
  const [isAnyDocumentAvailable, setIsAnyDocumentAvailable] = React.useState(false);
  const [parentRequestDetails, setParentRequestDetails] = React.useState(null);
  const [showOwnerButtons, setShowOwnerButtons] = React.useState(false);
  const [confirmDeleteModalOpen, isConfirmDeleteModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [workflowStatus, setWorkflowStatus] = React.useState(WORKFLOW_STATUS.IN_PROGRESS);
  const [suspensiveConditions, setSuspensiveConditions] = React.useState([]);
  const [lastSignatoryCondition, setLastSignatoryCondition] = React.useState(null);

  const [userRoles, setUserRoles] = React.useState([]);

  const requestId = Number(props.match.params.requestId);

  const copyRequestUrlToClipboard = () => {
    const urlRef = window.location.href;
    const valueToCopy = urlRef.split('?')[0] + `?forceOrganisationId=${props.organisation.id}`;
    navigator.clipboard.writeText(valueToCopy);
    props.actions.addSuccess(<Translate value="request.page.copyRequestUrlSuccess"/>);
  };

  const fetchParentDetails = id => {
    requestService.findParentDetailsById(id)
      .then(details => setParentRequestDetails(details))
      .catch(() => setError(true));
  };

  const getWorkflowStatus = () => {
    if (!request.subcontractorId || isAwaitingSubcontractor) {
      return WORKFLOW_STATUS.WAITING_SC;
    }

    return RFA_STATUS_TO_WORKFLOW_STATUS[request.status];
  };

  const handleRequestData = requestData => {
    setRequest(requestData);
    setSuspensiveConditions(requestData.suspensiveConditions);

    // Only RFA owner can edit & delete the RFA
    setShowOwnerButtons(props.organisation.id === requestData.creatorOrganisationId);
    fetchParentDetails(requestData.id);
  };

  const handleRequestStatusChange = status => {
    setRequest({...request, ...{status}});
  };

  const fetchRequest = requestId => {
    setLoading(true);
    requestService.getRequestById(requestId)
      .then(response => handleRequestData(response))
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  const handleDownloadAllDocuments = () => {
    authenticationService.authRedirect(requestService.downloadAllRequestDocuments(requestId))
      .then(authUrl => {
        openOrSaveFile(authUrl);
      });
  };

  const handleDownloadPDF = () => {
    const documentId = request.documents.find(document => document.documentTypeCode === 'RFA').id;
    authenticationService.authRedirect(requestService.downloadDocument(documentId))
      .then(authUrl => {
        openOrSaveFile(authUrl);
      });
  };

  const handleDeleteRequestForApproval = requestId => {
    requestService.delete(requestId)
      .then(() => {
        props.actions.addSuccess(<Translate value="request.page.deleteSuccess"/>);
        props.history.replace('/requests');
      })
      .catch(error => {
        isConfirmDeleteModalOpen(false);
        captureError('[RequestContainer] Error while deleting ' + requestId, error);
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  React.useEffect(() => {
    if (requestId) {
      fetchRequest(requestId);
    } else {
      setError(true);
    }
  }, []);

  React.useEffect(() => {
    if (request) {
      setWorkflowStatus(getWorkflowStatus());

      const lastSignatoryRole = request.project.clientId ? PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT : PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE;
      setLastSignatoryCondition(request.suspensiveConditions.find(condition => condition.organisationRole === lastSignatoryRole) || null);
    }
  }, [request, isAwaitingSubcontractor]);

  React.useEffect(() => {
    const projectId = request?.project?.id;
    if (projectId) {
      setUserRoles(authenticationService.roles().getProjectRoles(projectId));
    }
  }, [request?.project?.id]);

  React.useEffect(() => {
    if (suspensiveConditions[suspensiveConditions.length - 1] !== lastSignatoryCondition) {
      setLastSignatoryCondition(null);
    }
  }, [suspensiveConditions]);

  return (
    <>
      <RequestView
        history={props.history}
        actions={props.actions}
        organisationId={props.organisation.id}
        request={request}
        requestId={requestId}
        rfaOwnerName={rfaOwnerName}
        subcontractorName={subcontractorName}
        payMasterOrganisationName={payMasterOrganisationName}
        isAnyDocumentAvailable={isAnyDocumentAvailable}
        workflowStatus={workflowStatus}
        suspensiveConditions={suspensiveConditions}
        lastSignatoryCondition={lastSignatoryCondition}
        parentRequestDetails={parentRequestDetails}
        showOwnerButtons={showOwnerButtons}
        loading={loading}
        error={error}
        onSetSuspensiveConditions={setSuspensiveConditions}
        onCopyRequestUrlToClipboard={copyRequestUrlToClipboard}
        onAnyDocumentAvailable={setIsAnyDocumentAvailable}
        onGetRfaOwnerName={setRfaOwnerName}
        onGetSubcontractorName={setSubcontractorName}
        onGetPayMasterOrganisationName={setPayMasterOrganisationName}
        onIsWaitingSubcontractorChange={setIsAwaitingSubcontractor}
        onPDFDownload={handleDownloadPDF}
        onAllDocumentsDownload={handleDownloadAllDocuments}
        onRequestDelete={() => isConfirmDeleteModalOpen(true)}
        onRequestUpdate={handleRequestData}
        onRequestStatusChange={handleRequestStatusChange}
        userRoles={userRoles}
      />
      {showOwnerButtons &&
        <ConfirmModal
          data-id="delete-confirm-modal"
          isOpen={confirmDeleteModalOpen}
          onConfirm={() => handleDeleteRequestForApproval(requestId)}
          onClose={() => isConfirmDeleteModalOpen(false)}
          title={<Translate value="request.page.deleteInformationModal.title"/>}
          content={<Translate value="request.page.deleteInformationModal.content"/>}
          submitButtonColor="negative"
          submitButtonBody={<><Icon icon="trash"/><Translate value="action.delete"/></>}
        />
      }
    </>
  );
};

RequestContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string.isRequired
    })
  })
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestContainer);
