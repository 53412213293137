import {aCheckedResponse} from '../../utils/fixtures';
import {getUserOrganisationsFakeData} from '../../utils/database/users';
import {USER_ORGANISATION_STATUS} from '../../utils/constant';
import {findOrganisation, getOrganisationsFakeData} from '../../utils/database/organisations';

export default class FakeOrganisationService {
  getOrganisationsList(organisationIds) {
    const organisations = getOrganisationsFakeData().filter(orga => organisationIds.includes(orga.id) ? findOrganisation(orga.id) : false);
    return Promise.resolve(organisations);
  }

  getByRegistrationNumberAndCountry(registrationNumber, country) {
    const organisationFound = getOrganisationsFakeData()
      .find(organisation => (organisation.country === country) && (organisation.registrationNumber === registrationNumber));
    return organisationFound ? Promise.resolve(organisationFound) : Promise.resolve(null);
  }

  search(query) {
    const matchingOrganisations = getOrganisationsFakeData()
      .filter(organisation => Boolean(!query) ||
        organisation.fullName.toLowerCase().includes(query.toLowerCase()) ||
        organisation.registrationNumber.includes(query));

    return new Promise(resolve => {
      setTimeout(() => resolve(aCheckedResponse(matchingOrganisations)), 500);
    });
  }

  searchByUser(userId, query = null) {
    const matchingOrganisations = getUserOrganisationsFakeData()
      .filter(userOrganisation => userOrganisation.user.id === userId)
      .filter(userOrganisation => userOrganisation.status === USER_ORGANISATION_STATUS.LINKED)
      .map(userOrganisation => findOrganisation(userOrganisation.organisationId))
      .filter(organisation => Boolean(!query) ||
        organisation.fullName.toLowerCase().includes(query.toLowerCase()) ||
        organisation.registrationNumber.includes(query)
      );

    return new Promise(resolve => {
      setTimeout(() => resolve(aCheckedResponse(matchingOrganisations)), 500);
    });
  }

  createOrganisation(organisation) {
    const response = Object.assign({}, organisation, {id: getOrganisationsFakeData().length + 1});
    getOrganisationsFakeData().push(response);
    return Promise.resolve(response);
  }

  getDefaultOrganisation(organisationId) {
    return Promise.resolve(findOrganisation(organisationId));
  }
}
