import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {stringUtils} from 'front-onceforall-core';
import {RFA_RESTART_MESSAGE} from '../../../utils/constant';

export const RefusalMessageView = props => {
  const isARestart = RFA_RESTART_MESSAGE.MESSAGE === props.reason;

  const message = isARestart ?
    stringUtils.getStrongs(I18n.t('request.page.rfaRestartMessage', {
      date: I18n.l(props.date, {dateFormat: 'datetime.shortDate'})
    })) :
    stringUtils.getStrongs(I18n.t('request.page.refusalMessage', {
      organisation: props.organisation.fullName,
      date: I18n.l(props.date, {dateFormat: 'datetime.shortDate'})
    }));

  const reason = isARestart ? null :
    <div>
      <strong>{props.reason}</strong>
    </div>;

  return (
    <div className="box rejected mb-5">
      <div>
        {message}
      </div>
      {reason}
    </div>
  );
};

RefusalMessageView.propTypes = {
  organisation: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired
};

export default RefusalMessageView;
