import React from 'react';
import PropTypes from 'prop-types';
import UploadPanel from './uploadPanel';
import {SidePanel} from 'front-onceforall-core';
import {authenticationService} from '../../../../services/authentication/AuthenticationService';
import {requestService} from '../../../../services/request/RequestService';
import {openOrSaveFile} from '../../../../utils/http';
import DocumentView from './DocumentView';
import {DOCUMENT_CATEGORY, DOCUMENT_STATUS} from '../../../../utils/constant';

export const DocumentContainer = props => {
  const [disabledUploadReason, setDisabledUploadReason] = React.useState(null);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);

  let triggerClosePanel = () => {};

  const handleDocumentUploaded = newDocument => {
    triggerClosePanel();
    props.onDocumentUpload(newDocument);
  };

  const downloadDocument = id => {
    authenticationService.authRedirect(requestService.downloadDocument(id))
      .then(authUrl => {
        openOrSaveFile(authUrl);
      });
  };

  const handleUploadDocument = () => {
    setIsPanelOpen(true);
  };

  const handleDownloadDocument = () => {
    downloadDocument(props.document.id);
  };

  React.useEffect(() => {
    if (props.isUploadEnabled) {
      setDisabledUploadReason(null);
    } else if (props.document.type?.category === DOCUMENT_CATEGORY.SPECIFIC && props.document.state === DOCUMENT_STATUS.MISSING) {
      setDisabledUploadReason('request.documents.upload.tooltipDisabled.signatureProcessStartedAndNotFinished');
    } else {
      setDisabledUploadReason('request.documents.upload.tooltipDisabled.signatureProcessStarted');
    }
  }, [props.isUploadEnabled, props.document.type, props.document.state]);

  return (
    <>
      <DocumentView
        document={props.document}
        fromOfa={props.fromOfa}
        onUploadClick={handleUploadDocument}
        onDownloadClick={handleDownloadDocument}
        disabledUploadReason={disabledUploadReason}
      />
      {isPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          big
          onClose={() => setIsPanelOpen(false)}
        >
          <UploadPanel
            document={props.document}
            onSubmit={handleDocumentUploaded}
            onClose={() => triggerClosePanel()}
            requestId={props.requestId}
          />
        </SidePanel>
      }
    </>
  );
};

DocumentContainer.defaultProps = {
  onDocumentUpload: () => {},
  fromOfa: false,
  isUploadEnabled: true
};

DocumentContainer.propTypes = {
  document: PropTypes.object.isRequired,
  fromOfa: PropTypes.bool,
  requestId: PropTypes.number.isRequired,
  isUploadEnabled: PropTypes.bool,
  onDocumentUpload: PropTypes.func
};

export default DocumentContainer;
