import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import ActionBar from '../../layout/ActionBar';
import SelectSubcontractor from './selectSubcontractor';
import SelectProject from './selectProject';
import RequestDetails from './requestDetails';
import SelectRequest from './selectRequest';

export const RequestCreateView = props => {
  return (
    <>
      <ActionBar showBackButton>
        <h1 data-id="request-step" className="m-4 ml-6">
          <Translate value="request.create.title"/>
        </h1>
      </ActionBar>

      <div>
        <>
          <SelectProject
            onSelectProject={props.onSelectProject}
            projectId={props.projectId}
            project={props.project}
            isOpen={props.currentStep === 0}
          />
          {props.showSelectRequestStep && props.project &&
            <SelectRequest
              onSelectRequest={props.onSelectRequest}
              project={props.project}
              request={props.request}
              isOpen={props.currentStep === 1}
            />
          }
          <SelectSubcontractor
            project={props.project}
            subcontractorOrga={props.subcontractor}
            subcontractorUser={props.subcontractorUser}
            isOpen={props.currentStep === 2}
            onSelectOrganisation={props.onSelectSubcontractor}
            onSelectUser={props.onSelectSubcontractorUser}
          />
          <RequestDetails
            onSubmit={props.onSubmitDetails}
            isOpen={props.currentStep === 3}
            loading={props.loading}
          />
        </>
      </div>
    </>
  );
};

RequestCreateView.propTypes = {
  onSelectSubcontractor: PropTypes.func.isRequired,
  onSelectSubcontractorUser: PropTypes.func.isRequired,
  onSelectRequest: PropTypes.func.isRequired,
  onSelectProject: PropTypes.func.isRequired,
  showSelectRequestStep: PropTypes.bool.isRequired,
  onSubmitDetails: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  projectId: PropTypes.number,
  project: PropTypes.object,
  request: PropTypes.object,
  subcontractor: PropTypes.object,
  subcontractorUser: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default RequestCreateView;
