import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';

import {projectService} from '../../../services/project/ProjectService';
import {captureError} from '../../../utils/log';

import ProjectOwnerTagEditionView from './ProjectOwnerTagEditionView';

function ProjectOwnerTagEditionContainer(props) {
  const [projectOwnerTag, setProjectOwnerTag] = React.useState('');
  const [projectUserUpdate, setProjectUserUpdate] = React.useState('');
  const [projectLastUpdate, setProjectLastUpdate] = React.useState('');
  const [ownerError, setOwnerError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);

    projectService.updateOwnerTag(props.projectId, projectOwnerTag)
      .then(() => {
        props.actions.addSuccess(<Translate value="project.owner.updateOwnerTagSuccess"/>);
        props.onClose();
      })
      .catch(error => {
        captureError('[ProjectOwnerTagEditionContainer]#handleSubmit:projectService.updateOwnerTag', error);
        props.actions.addError(<Translate value="error.generic"/>);
        setOwnerError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function fetchProjectOwner() {
    setLoading(true);

    projectService.getOwnerTag(props.projectId)
      .then(project => {
        setProjectOwnerTag(project.projectOwnerTag || '');
        setProjectUserUpdate(project.projectOwnerTagUpdateUser);
        setProjectLastUpdate(project.projectOwnerTagUpdateDate);
        setLoadingError(false);
      })
      .catch(error => {
        captureError('[ProjectOwnerTagEditionContainer]#handleSubmit:projectService.updateOwnerTag', error);
        props.actions.addError(<Translate value="error.generic"/>);
        setLoadingError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  React.useEffect(() => {
    fetchProjectOwner();
  }, []);

  const handleChangeValue = event => {
    setProjectOwnerTag(event.target.value);
  };

  return (
    <ProjectOwnerTagEditionView
      projectOwnerTag={projectOwnerTag}
      projectUserUpdate={projectUserUpdate}
      projectLastUpdate={projectLastUpdate}
      ownerError={ownerError}
      onChangeValue={handleChangeValue}
      onSubmit={handleSubmit}
      onClose={props.onClose}
      loading={loading}
      loadingError={loadingError}
    />
  );
}

ProjectOwnerTagEditionContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ProjectOwnerTagEditionContainer;
