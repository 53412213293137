import 'isomorphic-fetch';
import {authenticationService} from '../services/authentication/AuthenticationService';
import {captureError} from './log';

export function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 403) {
    window.location.pathname = '/access-denied';
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

export function parseHeadersAndJson(response) {
  return response.json()
    .then(json => ({
      json,
      headers: response.headers
    }));
}

export function parseHeadersAndBlob(response) {
  return response.blob()
    .then(blob => ({
      blob,
      headers: response.headers
    }));
}

// Update both Keycloak and roles tokens, then call the url with the required tokens
export function authFetch(url, options = {}, withPermissionToken = true) {
  if (!options) {
    options = {};
  }

  return authenticationService.updateTokenKeycloak().then(tokenKeycloak => {
    options.headers = options.headers || {};
    options.headers.Authorization = 'Bearer ' + tokenKeycloak;

    if (!withPermissionToken) {
      return fetch(url, options);
    }

    return authenticationService.updateTokenRoles().then(tokenRoles => {
      options.headers.Permission = tokenRoles;
      return fetch(url, options);
    });
  });
}

export function fetchJsonFile(url) {
  function loadJSON(url, callback) {
    const xmlHttpRequest = new XMLHttpRequest();
    xmlHttpRequest.overrideMimeType('application/json');
    xmlHttpRequest.open('GET', url, true);
    xmlHttpRequest.setRequestHeader('cache-control', 'no-cache, must-revalidate, post-check=0, pre-check=0');
    xmlHttpRequest.setRequestHeader('cache-control', 'max-age=0');
    xmlHttpRequest.setRequestHeader('expires', '0');
    xmlHttpRequest.setRequestHeader('expires', 'Tue, 01 Jan 1980 1:00:00 GMT');
    xmlHttpRequest.setRequestHeader('pragma', 'no-cache');
    xmlHttpRequest.onreadystatechange = function () {
      if (xmlHttpRequest.readyState === 4 && xmlHttpRequest.status === 200) {
        // Required use of an anonymous callback as .open will NOT return a value but simply returns undefined in asynchronous mode
        callback(xmlHttpRequest.responseText);
      }
    };

    xmlHttpRequest.send(null);
  }

  return new Promise(resolve => {
    loadJSON(url, response => {
      if (response) {
        resolve(JSON.parse(response));
      }
    });
  });
}

// Open file url and handle special file prompt for IE/Edge
export function openOrSaveFile(fileUrl) {
  // Condition to detect if browser is IE/Edge or not by testing if proprietary method msSaveOrOpenBlob exists
  if (window.navigator &&
    window.navigator.msSaveOrOpenBlob) {
    fetch(fileUrl)
      .then(parseHeadersAndBlob)
      .then(({blob, headers}) => {
        let filename = 'filename';
        try {
          filename = headers.get('Content-Disposition').split(';')[1].trim().split('=')[1].replace(/"/g, '');
        } catch (error) {
          captureError(`Error while getting the file name from Content-disposition: ${headers.get('Content-Disposition')}`, error);
        }

        window.navigator.msSaveOrOpenBlob(blob, filename);
      });
  } else {
    window.open(fileUrl);
  }
}

// Export functions for mock purposes
const exportFunctions = {
  checkStatus,
  parseHeadersAndJson,
  parseHeadersAndBlob,
  authFetch
};

export default exportFunctions;
