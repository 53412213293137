import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {TextField} from '@material-ui/core';

import {AbstractFormComponent, Icon, TextDatePicker, CustomTooltip} from 'front-onceforall-core';
import {requiredValidator} from 'front-onceforall-core/dist/utils/validators';
import {COUNTRY} from 'front-onceforall-core/dist/utils/country';

import appConfig from '../../../../config';
import CountrySelector from '../../country/CountrySelector';

import Radio from '../../buttons/Radio';

export const ADDRESS_MAX_LENGTH = 255;
export const PUBLIC_PROCUREMENT_OBJECT_MAX_LENGTH = 1024;

export class ProjectDetailsForm extends AbstractFormComponent {
  constructor(props) {
    super(props);

    if (!global.Intl) {
      global.Intl = require('intl');
      require('intl/locale-data/jsonp/fr');
      require('intl/locale-data/jsonp/fa-IR');
    }
  }

  getFormData() {
    let formData = {
      name: '',
      addressLine: '',
      city: '',
      postCode: '',
      country: '',
      openingDate: null,
      closingDate: null,
      publicProcurement: false,
      publicProcurementObject: ''
    };

    if (this.props.project) {
      const project = this.props.project;
      formData = {
        ...formData,
        name: project.name,
        addressLine: project.address.addressLine,
        city: project.address.city,
        postCode: project.address.postCode,
        country: project.country,
        openingDate: moment(project.openingDate),
        closingDate: moment(project.closingDate),
        publicProcurement: Boolean(project.publicProcurement),
        publicProcurementObject: project.publicProcurementData?.publicProcurementObject ? project.publicProcurementData.publicProcurementObject : ''
      };
    } else {
      formData.country = this.props.organisation.country;
    }

    return formData;
  }

  getFormValidators() {
    return {
      name: requiredValidator,
      addressLine: requiredValidator,
      city: requiredValidator,
      postCode: requiredValidator,
      country: requiredValidator,
      openingDate: requiredValidator,
      closingDate: requiredValidator,
      publicProcurementObject: this.state.formData.publicProcurement ? requiredValidator : null
    };
  }

  handleSubmitCallback() {
    const formData = {...this.state.formData};
    const {addressLine, city, postCode, publicProcurement, publicProcurementObject, ...project} = formData;
    project.address = {
      addressLine,
      city,
      postCode
    };
    project.openingDate = project.openingDate.startOf('day').toISOString();
    project.closingDate = project.closingDate.endOf('day').toISOString();

    project.publicProcurement = Boolean(publicProcurement);
    project.publicProcurementData = {
      publicProcurementObject
    };

    this.props.onValidate(project);
  }

  handleChangeCallback(name, value) {
    if (name === 'publicProcurement') {
      if (value) {
        this.formValidators.publicProcurementObject = requiredValidator;
        this.validateField('publicProcurementObject', this.state.formData.publicProcurementObject);
      } else {
        this.formValidators.publicProcurementObject = null;
        this.setState(currentState => ({
          ...currentState,
          formData: {
            ...currentState.formData,
            publicProcurementObject: ''
          },
          fieldErrors: {
            ...currentState.fieldErrors,
            publicProcurementObject: null
          }
        }));
      }
    }
  }

  handleChangePublicProcurement = value => {
    const event = {target: {value}};
    this.handleChange('publicProcurement')(event);
  }

  render() {
    const publicProcurement = this.state.formData.publicProcurement;
    return (
      <div>
        {this.props.displayEditMessage &&
          <div className="box card flex-container">
            <div className="secondary">
              <Icon icon="info"/>
            </div>
            <div>
              {I18n.t('project.edit.enabledTooltip')}
            </div>
          </div>}
        <form onSubmit={this.handleSubmit}>
          {this.props.title}
          <div>
            <TextField
              label={this.getFieldLabel('project.details.name', true)}
              error={this.isOnError('name')}
              helperText={this.helperText('name')}
              id="name"
              name="name"
              value={this.state.formData.name}
              onChange={this.handleChange('name')}
              autoFocus
            />
          </div>

          <div className="">
            <TextField
              label={this.getFieldLabel('address.addressLine', true)}
              error={this.isOnError('addressLine')}
              helperText={this.helperText('addressLine')}
              id="addressLine"
              name="addressLine"
              multiline
              minRows={3}
              maxRows={20}
              value={this.state.formData.addressLine}
              onChange={this.handleChange('addressLine')}
              inputProps={{
                maxLength: ADDRESS_MAX_LENGTH
              }}
            />
          </div>

          <div className="row">
            <div className="col-sm-4 col-md-3 col-lg-2">
              <CountrySelector
                value={this.state.formData.country}
                onChange={this.handleChange('country')}
                disabledCountrySelector={this.props.disabledCountrySelector}
                countriesToShow={[COUNTRY.FRANCE]}
              />
            </div>

            <div className="col-sm-4 col-md-3 col-lg-2">
              <div className="">
                <TextField
                  label={this.getFieldLabel('address.postCode', true)}
                  error={this.isOnError('postCode')}
                  helperText={this.helperText('postCode')}
                  id="postCode"
                  name="postCode"
                  value={this.state.formData.postCode}
                  onChange={this.handleChange('postCode')}
                />
              </div>
            </div>

            <div className="col-sm-8 col-md-9 col-lg-4">
              <div className="">
                <TextField
                  label={this.getFieldLabel('address.city', true)}
                  error={this.isOnError('city')}
                  helperText={this.helperText('city')}
                  id="city"
                  name="city"
                  value={this.state.formData.city}
                  onChange={this.handleChange('city')}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-xl-3">
              <div className="">
                <TextDatePicker
                  name="openingDate"
                  data-id="field-opening-date"
                  label={this.getFieldLabel('project.details.openingDate', true)}
                  value={this.state.formData.openingDate}
                  onChange={this.handleChange('openingDate')}
                  placeholder={I18n.t('datetime.shortDate')}
                  helperText={this.helperText('openingDate')}
                  error={this.isOnError('openingDate')}
                />
              </div>
            </div>

            <div className="col-sm-6 col-md-4 col-xl-3">
              <div className="">
                <TextDatePicker
                  name="closingDate"
                  data-id="field-closing-date"
                  label={this.getFieldLabel('project.details.closingDate', true)}
                  value={this.state.formData.closingDate}
                  onChange={this.handleChange('closingDate')}
                  placeholder={I18n.t('datetime.shortDate')}
                  helperText={this.helperText('closingDate')}
                  error={this.isOnError('closingDate')}
                />
              </div>
            </div>
          </div>
          {appConfig.featureFlags.isPublicProcurementEnabled &&
          <div>
            <div className="d-flex flex-row">
              <Radio
                disabled={this.props.disableProcurementSelection}
                data-id="field-is-private-procurement"
                value={false}
                checked={publicProcurement === false}
                label={this.getFieldLabel('project.details.privateProcurement')}
                onChange={this.handleChangePublicProcurement}
              />
              <Radio
                disabled={this.props.disableProcurementSelection}
                data-id="field-is-public-procurement"
                className="ml-3"
                value={true}
                checked={Boolean(publicProcurement)}
                label={<div>
                  <CustomTooltip
                    title={I18n.t('project.details.publicProcurementTooltip')}
                    placement="right"
                  >
                    <span className="mr-3">{this.getFieldLabel('project.details.publicProcurement')}</span>
                    <Icon icon="info"/>
                  </CustomTooltip>
                </div>}
                onChange={this.handleChangePublicProcurement}
              />
            </div>
            {publicProcurement &&
            <div className="">
              <TextField
                label={this.getFieldLabel('project.details.publicProcurementObject', this.state.formData.publicProcurement)}
                error={this.isOnError('publicProcurementObject')}
                data-id="field-public-procurement-object"
                name="publicProcurementObject"
                multiline
                minRows={3}
                maxRows={10}
                value={this.state.formData.publicProcurementObject}
                onChange={this.handleChange('publicProcurementObject')}
                inputProps={{
                  maxLength: PUBLIC_PROCUREMENT_OBJECT_MAX_LENGTH
                }}
                placeholder={I18n.t('project.details.publicProcurementObjectPlaceholder')}
              />
            </div>
            }
          </div>
          }

          <div className="actions col-12 inline-container space-between">
            <button
              className="large primary inline-container"
              type="submit"
            >
              <Translate value="action.confirm"/>
              <Icon icon={'check'}/>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ProjectDetailsForm.defaultProps = {
  project: null,
  onCancel: null,
  disabledCountrySelector: true,
  title: null,
  displayEditMessage: false,
  disableProcurementSelection: false
};

ProjectDetailsForm.propTypes = {
  organisation: PropTypes.object,
  title: PropTypes.node,
  onValidate: PropTypes.func.isRequired,
  project: PropTypes.object,
  onCancel: PropTypes.func,
  disabledCountrySelector: PropTypes.bool,
  displayEditMessage: PropTypes.bool,
  disableProcurementSelection: PropTypes.bool
};

export default ProjectDetailsForm;
