import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as snackbarsActions from '../../../actions/snackbars';
import {Translate} from 'react-redux-i18n';
import {userService} from '../../../services/user/UserService';
import PendingUserActionsView from './PendingUserActionsView';

export const PendingUserActionsContainer = props => {
  const [pendingUsers, setPendingUsers] = React.useState([]);
  const [pendingUsersCount, setPendingUsersCount] = React.useState(0);
  const [error, setError] = React.useState(false);

  const handleAcceptUser = (event, userId) => {
    event.preventDefault();
    event.stopPropagation();
    userService.acceptUserLinkRequest(props.organisation.id, userId)
      .then(() => {
        props.actions.addSuccess(<Translate value="dashboard.notification.actions.acceptRequestSuccess"/>);
        fetchPendingUsers();
      })
      .catch(() => {
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const handleDeclineUser = (event, userId) => {
    event.preventDefault();
    event.stopPropagation();
    userService.declineUserLinkRequest(props.organisation.id, userId)
      .then(() => {
        props.actions.addSuccess(<Translate value="dashboard.notification.actions.declineRequestSuccess"/>);
        fetchPendingUsers();
      })
      .catch(() => {
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const fetchPendingUsers = () => {
    props.onDataLoad();

    userService.searchPendingByOrganisation(props.organisation.id)
      .then(response => {
        setError(false);
        setPendingUsers(response);
        setPendingUsersCount(response.length);
        props.onDataUpdate(response.length);
      })
      .catch(() => {
        setError(true);
        props.onError();
      });
  };

  React.useEffect(fetchPendingUsers, []);

  return (
    <PendingUserActionsView
      pendingUsers={pendingUsers}
      pendingUsersCount={pendingUsersCount}
      error={error}
      onAcceptUser={handleAcceptUser}
      onDeclineUser={handleDeclineUser}
    />
  );
};

PendingUserActionsContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  onDataLoad: PropTypes.func,
  onDataUpdate: PropTypes.func,
  onError: PropTypes.func
};

PendingUserActionsContainer.defaultProps = {
  onDataLoad: () => {
  },
  onDataUpdate: () => {
  },
  onError: () => {
  }
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PendingUserActionsContainer));
