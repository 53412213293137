import React from 'react';
import PropTypes from 'prop-types';
import {Translate, I18n} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';
import {useSelector} from 'react-redux';

import {TextPhoneNumber} from 'front-onceforall-core';

import {getFieldLabel} from '../../utils';

function ParentSubcontractor({onChange, parentSubcontractor, errors, rang}) {
  const currentLocale = useSelector(state => state.i18n.locale);

  const handleChange = name => event => {
    onChange(name, event.target.value);
  };

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.subcontractor" count={rang}/></h2>
      </div>
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-7 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.fullName', true)}
            id={`parentSubcontractor-fullName-${rang}`}
            name={`parentSubcontractor-fullName-${rang}`}
            value={parentSubcontractor?.fullName || ''}
            onChange={handleChange('fullName')}
          />
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.legalForm', true)}
            id={`parentSubcontractor-legalForm-${rang}`}
            name={`parentSubcontractor-legalForm-${rang}`}
            value={parentSubcontractor?.legalForm || ''}
            onChange={handleChange('legalForm')}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.registrationNumber', true)}
            id={`parentSubcontractor-registrationNumber-${rang}`}
            name={`parentSubcontractor-registrationNumber-${rang}`}
            value={parentSubcontractor?.registrationNumber || ''}
            onChange={handleChange('registrationNumber')}
          />
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-12">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.address', true)}
            id={`parentSubcontractor-address-${rang}`}
            name={`parentSubcontractor-address-${rang}`}
            value={parentSubcontractor?.address || ''}
            onChange={handleChange('address')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.email', true)}
            id={`parentSubcontractor-email-${rang}`}
            name={`parentSubcontractor-email-${rang}`}
            value={parentSubcontractor?.email || ''}
            onChange={handleChange('email')}
            error={Boolean(errors?.email)}
            helperText={errors?.email ? I18n.t(errors.email) : null}
          />
        </div>
        <div className="col-lg-6 col-md-12 pb-3">
          <TextPhoneNumber
            label={getFieldLabel('publicProcurement.contractor.phoneNumber', false)}
            id={`parentSubcontractor-phoneNumber-${rang}`}
            name={`parentSubcontractor-phoneNumber-${rang}`}
            value={parentSubcontractor?.phoneNumber || ''}
            currentLocale={currentLocale}
            defaultLocal={currentLocale}
            onChange={handleChange('phoneNumber')}
            error={Boolean(errors?.phoneNumber)}
            helperText={errors?.phoneNumber ? I18n.t(errors.phoneNumber) : null}
          />
        </div>
      </div>
    </div>
  );
}

ParentSubcontractor.propTypes = {
  onChange: PropTypes.func.isRequired,
  rang: PropTypes.number.isRequired,
  parentSubcontractor: PropTypes.shape({
    fullName: PropTypes.string,
    registrationNumber: PropTypes.string,
    legalForm: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string
  }).isRequired,
  errors: PropTypes.shape({
    email: PropTypes.string,
    phoneNumber: PropTypes.string
  })
};

export default ParentSubcontractor;
