import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';
import UserMenu from './userMenu';

export const GlobalHeaderView = props => {
  return (
    <div className="global-bar">
      <button
        data-id="button-home"
        type="button"
        className="button-square"
        onClick={props.onLogoClick}
        title={I18n.t(props.isGoBack ? 'action.back' : 'menu.home')}
      >
        <Icon icon={props.isGoBack ? 'go-back' : 'home'}/>
      </button>
      <div className="flex-container column">
        {props.newsUrl &&
          <button
            type="button"
            data-id="news"
            className="button-square"
            onClick={() => window.open(props.newsUrl)}
            title={I18n.t('menu.news')}
          >
            <Icon icon="newspaper"/>
          </button>
        }
        <UserMenu/>
      </div>
    </div>
  );
};

GlobalHeaderView.propTypes = {
  onLogoClick: PropTypes.func.isRequired,
  isGoBack: PropTypes.bool,
  newsUrl: PropTypes.string
};

export default GlobalHeaderView;
