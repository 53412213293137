import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {Translate} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';

export const defaultModalStyle = {
  overlay: {
    backgroundColor: 'rgba(255,255,255,0.7)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1
  },
  content: {
    backgroundColor: '#fff',
    borderRadius: 4,
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 32px 0px',
    minWidth: 300,
    outline: 0,
    padding: 20,
    position: 'relative',
    maxWidth: 800,
    textAlign: 'center'
  }
};

export const ConfirmModal = props => {
  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    props.onConfirm();
  };

  const cancelButton = (
    <button
      data-id="cancel"
      type="button"
      className={`large inline-container ${props.cancelButtonColor ? props.cancelButtonColor : 'secondary'}`}
      onClick={props.onClose}
    >
      {props.cancelButtonBody ?
        props.cancelButtonBody :
        <><Icon icon="go-back"/><Translate value="action.cancel"/></>
      }
    </button>
  );

  const submitButton = (
    <button
      id="submit"
      data-id="submit"
      type="submit"
      className={`large inline-container ${props.submitButtonColor ? props.submitButtonColor : 'primary'}`}
    >
      {props.submitButtonBody ?
        props.submitButtonBody :
        <><Icon icon="check"/><Translate value="action.confirm"/></>
      }
    </button>
  );

  return (
    <Modal
      style={props.modalStyle}
      isOpen={props.isOpen}
      onRequestClose={props.onClose}
      ariaHideApp={false}
      shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
    >
      <div>
        <form onSubmit={handleSubmit}>
          {props.title && <h2 data-id="title">{props.title}</h2>}
          {props.content && <p data-id="content" className="py-3">{props.content}</p>}
          {props.subtext && <p data-id="subtext" className="mt-5">{props.subtext}</p>}
          {props.warning && <p data-id="warning" className="py-3">{props.warning}</p>}
          <div className="actions col-12 inline-container space-between">
            {cancelButton}
            {submitButton}
          </div>
        </form>
      </div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  shouldCloseOnOverlayClick: true,
  modalStyle: defaultModalStyle
};

ConfirmModal.propTypes = {
  modalStyle: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.object,
  content: PropTypes.object,
  subtext: PropTypes.object,
  warning: PropTypes.object,
  cancelButtonColor: PropTypes.string,
  cancelButtonBody: PropTypes.object,
  submitButtonColor: PropTypes.string,
  submitButtonBody: PropTypes.object
};

export default ConfirmModal;
