import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';
import OfaDocument from '../ofaDocument';
import {Tooltip} from '@material-ui/core';

export const DocumentView = props => {
  const complementaryList = (
    props.document.type.complementary?.length > 0 ?
      <ul>
        {props.document.type.complementary.map((complementary, i) =>
          <li className="mt-2 ml-5" key={document.code + i}>{complementary}</li>
        )}
      </ul> :
      null
  );

  const downloadButton = props.document.isUploaded ? (
    <button
      data-id="download"
      className="inline-container large secondary"
      onClick={props.onDownloadClick}
    >
      <Icon icon="download"/>
      <Translate value="request.documents.actions.see"/>
    </button>
  ) : null;

  let uploadButton = (
    <button
      data-id="upload"
      className="inline-container large secondary"
      onClick={props.onUploadClick}
      disabled={Boolean(props.disabledUploadReason)}
      style={props.disabledUploadReason ? {pointerEvents: 'none'} : {}}
    >
      <Icon icon="upload-document"/>
      <Translate value={`request.documents.actions.${props.document.isUploaded ? 'update' : 'upload'}`}/>
    </button>
  );
  uploadButton = props.disabledUploadReason ? (<Tooltip
    data-id="request-document-tooltip"
    title={<Translate value={props.disabledUploadReason}/>}
  >
    <span>{uploadButton}</span>
  </Tooltip>) : (uploadButton);

  const documentStateText = props.document.isUploaded && props.document.isUploadedAfterRfaValidation ?
    <Translate value={'request.documents.states.UPLOADED_AFTER_RFA_VALIDATION'}/> :
    <Translate value={`request.documents.states.${props.document.state}`}/>;

  return (
    <>
      {props.fromOfa ?
        <OfaDocument
          document={props.document}
          onDownloadClick={props.onDownloadClick}
        /> :
        props.document && props.document.type &&
        <div className="box border bottom" key={props.document.code}>
          <div className="card-header font-size-l">{props.document.type.label}</div>
          {complementaryList}
          <div className="flex-container space-between">
            <div className={`inline-container ${props.document.isUploaded ? 'success' : 'error'}`}>
              <Icon icon={`${props.document.isUploaded ? 'check' : 'error'}`}/>
              {documentStateText}
            </div>
            <div className="inline-container flex-shrink-0">
              {downloadButton}
              {uploadButton}
            </div>
          </div>
        </div>
      }
    </>
  );
};

DocumentView.propTypes = {
  document: PropTypes.object,
  fromOfa: PropTypes.bool,
  onUploadClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  disabledUploadReason: PropTypes.string
};

export default DocumentView;
