import React from 'react';
import PropTypes from 'prop-types';
import MuiAccordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import {Translate} from 'react-redux-i18n';
import {withStyles} from '@material-ui/core';
import InviteeView from '../invitee/InviteeView';
import {PROJECT_ORGANISATION_ROLE as role} from '../../../../../utils/constant';
import ParticipantView from '../participant/ParticipantView';

export const ParticipantsAccordionView = props => {
  if (!props.participants.length && !props.invitations.length) {
    return null;
  }

  const participantsWithInvitees = [...props.participants, ...props.invitations];

  const sortedParticipants = participantsWithInvitees?.sort((a, b) =>
    ((a.organisation?.fullName || a.organisationName) < (b.organisation?.fullName || b.organisationName)) ? -1 :
      ((a.organisation?.fullName || a.organisationName) > (b.organisation?.fullName || b.organisationName)) ? 1 : 0);

  const participantsAccordion = sortedParticipants.length > 1 ?
    sortedParticipants.map((participant, index) => {
      if (participant.organisation) {
        return (<ParticipantView
          key={index}
          role={participant.role}
          organisationName={participant.organisation.fullName}
          registrationNumber={participant.organisation.registrationNumber}
          authorizedActions={{authorizedActions: props.authorizedActions, isRemovable: participant.isRemovable}}
          onRemoveGC={() => props.onRemoveGC(participant.id)}
          isInAccordion
        />);
      }

      return (<InviteeView
        key={index.toString()}
        status={participant.status}
        role={participant.role}
        organisationName={participant.organisationName}
        registrationNumber={participant.registrationNumber}
        onlyBottomBorder
        authorizedActions={props.authorizedActions}
        onRemoveGCInvitee={() => props.onRemoveGCInvitee(participant.id)}
        isInAccordion
      />);
    }) : null;

  const Accordion = withStyles({
    root: {
      boxShadow: 'none',
      '&:before': {
        display: 'none'
      }
    }
  })(MuiAccordion);

  return (
    <>
      {participantsAccordion &&
      <Accordion className="box border bottom">
        <AccordionSummary
          className="p-0"
          expandIcon={<span className="caret-drop"/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <h3>
            <Translate value={`project.role.${role.PROJECT_GENERAL_CONTRACTOR}.plural`} />
            <span className="separator information">{participantsWithInvitees.length}</span>
          </h3>
        </AccordionSummary>
        <AccordionDetails className="row p-0">
          {participantsAccordion}
        </AccordionDetails>
      </Accordion>
      }
    </>
  );
};

ParticipantsAccordionView.propTypes = {
  participants: PropTypes.array,
  invitations: PropTypes.array,
  authorizedActions: PropTypes.bool.isRequired,
  onRemoveGCInvitee: PropTypes.func.isRequired,
  onRemoveGC: PropTypes.func
};

export default ParticipantsAccordionView;
