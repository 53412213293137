import React from 'react';
import PropTypes from 'prop-types';
import ProjectDocumentsView from './ProjectDocumentsView';
import ProjectDocuments from '../../../projectCreate/projectDocuments';
import {projectService} from '../../../../services/project/ProjectService';
import {SidePanel} from 'front-onceforall-core';
import {Translate} from 'react-redux-i18n';

export const ProjectDocumentsContainer = props => {
  const [documents, setDocuments] = React.useState([]);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  let triggerClosePanel = () => {};

  const fetchRequiredDocumentTypes = projectId => {
    setLoading(true);
    projectService.getRequiredDocumentTypesByProjectId(projectId)
      .then(documents => {
        setDocuments(documents);
      })
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const handleDocumentTypesEdition = documentTypes => {
    setLoading(true);
    projectService.editDocumentTypes(props.projectId, Object.assign({}, ...documentTypes.map(doc => ({[doc.code]: doc.complementary}))))
      .then(response => {
        props.actions.addSuccess(<Translate value="project.edit.success"/>);
        setDocuments(documentTypes);
        props.onProjectUpdate(response);
      })
      .catch(() => {
        props.actions.addError(<Translate value="project.edit.failed"/>);
      })
      .finally(() => {
        setLoading(false);
      });

    triggerClosePanel();
  };

  React.useEffect(() => {
    if (props.projectId) {
      fetchRequiredDocumentTypes(props.projectId);
    } else {
      setError(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ProjectDocumentsView
        documents={documents}
        isProjectEditionAllowed={props.isProjectEditionAllowed}
        loading={loading}
        error={error}
        onEditButtonClick={() => setIsPanelOpen(true)}
      />
      {isPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsPanelOpen(false)}
        >
          <ProjectDocuments
            onValidate={handleDocumentTypesEdition}
            isOpen={isPanelOpen}
            loading={loading}
            documents={documents}
            onCancel={triggerClosePanel}
            displayEditMessage={true}
          />
        </SidePanel>
      }
    </>
  );
};

ProjectDocumentsContainer.propTypes = {
  actions: PropTypes.object,
  projectId: PropTypes.number.isRequired,
  isProjectEditionAllowed: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  onEditButtonClick: PropTypes.func,
  onProjectUpdate: PropTypes.func
};

export default ProjectDocumentsContainer;
