import config from '../../../config';
import {authFetch, checkStatus, parseHeadersAndJson} from '../../utils/http';

export default class LiveProjectService {
  get(id) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${id}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  findAllByCurrentOrganisation(page, size, direction = 'DESC', property = 'openingDate') {
    return authFetch(`${config.rfaApi}/api/v1/projects?page=${page}&size=${size}&direction=${direction}&property=${property}`)
      .then(checkStatus)
      .then(parseHeadersAndJson)
      .then(({json, headers}) => ({
        projects: json,
        totalCount: Number(headers.get('X-Total-Count'))
      }));
  }

  findProjectsReadyForRequestCreationByCurrentOrganisation() {
    return authFetch(`${config.rfaApi}/api/v1/projects/ready-for-request-creation`)
      .then(checkStatus)
      .then(response => response.json());
  }

  create(project) {
    return authFetch(`${config.rfaApi}/api/v1/projects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(project)
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  delete(projectId) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${projectId}`, {method: 'DELETE'})
      .then(checkStatus);
  }

  editDetails(projectId, projectInformation) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${projectId}/details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(projectInformation)
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  editDocumentTypes(projectId, documentTypes) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${projectId}/required-document-types`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(documentTypes)
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  editParticipants(projectId, participants) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${projectId}/participants`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(participants)
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  addGeneralContractor(projectId, generalContractorAddition) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${projectId}/add-general-contractor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(generalContractorAddition)
    })
      .then(checkStatus);
  }

  removeGeneralContractor(projectId, generalContractorId) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${projectId}/general-contractor/${generalContractorId}`, {
      method: 'DELETE'
    })
      .then(checkStatus);
  }

  getParticipantsByProjectId(id) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${id}/participants`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getRequiredDocumentTypesByProjectId(id) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${id}/required-document-types`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getOwnerTag(id) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${id}/project-owner-tag`, {
      method: 'GET'
    })
      .then(checkStatus)
      .then(response => response.json())
      .then(({
        projectOwnerTag,
        projectOwnerTagUpdateDate,
        projectOwnerTagUpdateUser
      }) => {
        let firstNameLastName = (`${projectOwnerTagUpdateUser?.firstName || ''} ${projectOwnerTagUpdateUser?.lastName || ''}`).trim();
        return {
          projectOwnerTag,
          projectOwnerTagUpdateDate,
          projectOwnerTagUpdateUser: firstNameLastName
        };
      });
  }

  updateOwnerTag(id, projectOwnerTag) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${id}/project-owner-tag`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({projectOwnerTag})
    })
      .then(checkStatus);
  }

  editProjectUnlimitedRfa(id, unlimitedRfa) {
    return authFetch(`${config.rfaApi}/api/v1/projects/${id}/unlimited-rfa`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(unlimitedRfa)
    })
      .then(checkStatus)
      .then(response => response.json());
  }
}
