import {anEmptyOrganisationCredits, anOrganisationCreditsVM} from '../fixtures';

const subscriptionsFakeData = [
  anOrganisationCreditsVM(),
  anEmptyOrganisationCredits({
    organisationId: 1002
  }),
  anOrganisationCreditsVM({
    organisationId: 1003
  }),
  anEmptyOrganisationCredits({
    organisationId: 1004
  }),
  anOrganisationCreditsVM({
    organisationId: 1005
  }),
  anOrganisationCreditsVM({
    organisationId: 1101,
    projectCredits: 3,
    requestForApprovalCredits: 4
  }),
  anOrganisationCreditsVM({
    organisationId: 1102
  }),
  anOrganisationCreditsVM({
    organisationId: 1201
  }),
  anEmptyOrganisationCredits({
    organisationId: 1202
  }),
  anOrganisationCreditsVM({
    organisationId: 1301
  }),
  anEmptyOrganisationCredits({
    organisationId: 1302
  }),
  anEmptyOrganisationCredits({
    organisationId: 1303
  }),
  anOrganisationCreditsVM({
    organisationId: 1401
  }),
  anOrganisationCreditsVM({
    organisationId: 1402
  }),
  anOrganisationCreditsVM({
    organisationId: 1403
  }),
  anEmptyOrganisationCredits({
    organisationId: 1404
  }),
  anOrganisationCreditsVM({
    organisationId: 1501
  }),
  anOrganisationCreditsVM({
    organisationId: 1601
  }),
  anEmptyOrganisationCredits({
    organisationId: 10001
  })
];

export const getSubscriptionsFakeData = () => subscriptionsFakeData;

export const findSubscription = organisationId => {
  const subscription = subscriptionsFakeData.find(data => data.organisationId === organisationId);
  if (!subscription || subscription === {}) {
    throw new Error(`Subscription for organisation ${organisationId} not found`);
  }

  return subscription;
};
