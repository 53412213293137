import {getIdentificationNumberTypesFakeData} from '../../utils/database/identificationNumbers';

export default class FakeIdentificationNumberService {
  types(country) {
    const matchingIdentificationNumberTypes = getIdentificationNumberTypesFakeData()
      .filter(identificationNumberType => identificationNumberType.country === country);

    return Promise.resolve(matchingIdentificationNumberTypes);
  }
}
