import React from 'react';
import PropTypes from 'prop-types';
import {SidePanel} from 'front-onceforall-core';
import SettingsView from '../../../../../views/settings/SettingsView';

export const UserSettingsView = props => {
  let triggerCloseUserSettingsPanel = () => {};
  return (
    <>
      {props.isUserPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerCloseUserSettingsPanel = closeFunction;
          }}
          big
          onClose={props.onClose}
        >
          <SettingsView onCancel={() => triggerCloseUserSettingsPanel()}/>
        </SidePanel>
      }
    </>
  );
};

UserSettingsView.propTypes = {
  isUserPanelOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UserSettingsView;
