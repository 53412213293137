import React from 'react';
import PropTypes from 'prop-types';
import {projectService} from '../../../../services/project/ProjectService';
import {organisationService} from '../../../../services/organisation/OrganisationService';
import {enrollmentService} from '../../../../services/enrollment/EnrollmentService';
import ProjectParticipantsView from './ProjectParticipantsView';
import {INVITATION_STATUS, PROJECT_ORGANISATION_ROLE} from '../../../../utils/constant';
import SidePanel from 'front-onceforall-core/dist/views/SidePanel';
import ProjectInviteGeneralContractor from './projectInviteGeneralContractor/ProjectInviteGeneralContractor';
import {captureError} from '../../../../utils/log';
import {Translate} from 'react-redux-i18n';
import ParticipantsEdition from './participantsEdition';

export const ProjectParticipantsContainer = props => {
  const [creatorOrganisationId, setCreatorOrganisationId] = React.useState();
  const [participants, setParticipants] = React.useState([]);
  const [invitations, setInvitations] = React.useState([]);
  const [isEditPanelOpen, setIsEditPanelOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  let triggerClosePanel = () => {
  };

  const reloadParticipants = () => {
    fetchProjectParticipants(props.projectId);
    triggerClosePanel();
  };

  const checkInvitation = projectId => {
    return enrollmentService.getInvitationByProjectId(projectId)
      .then(response => {
        setInvitations(response.filter(invitee => invitee.status === INVITATION_STATUS.PENDING));
      });
  };

  const buildParticipants = participantIds => {
    const participants = [];

    if (participantIds.clientId) {
      participants.push({
        role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT,
        id: participantIds.clientId
      });
    }

    if (participantIds.clientDelegateId) {
      participants.push({
        role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE,
        id: participantIds.clientDelegateId
      });
    }

    if (participantIds.clientAssistantId) {
      participants.push({
        role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_ASSISTANT,
        id: participantIds.clientAssistantId
      });
    }

    if (participantIds.projectManagerId) {
      participants.push({
        role: PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER,
        id: participantIds.projectManagerId
      });
    }

    const isGeneralContractorRemovable = generalContractorId => {
      return participantIds.removableGeneralContractorIds?.includes(generalContractorId) && generalContractorId !== participantIds.creatorOrganisationId;
    };

    if (participantIds.generalContractorIds) {
      participantIds.generalContractorIds.forEach(generalContractorId => {
        const isRemovable = isGeneralContractorRemovable(generalContractorId);
        participants.push({
          role: generalContractorId === participantIds.creatorOrganisationId ?
            PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR_OWNER : PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR,
          id: generalContractorId,
          isRemovable
        });
      });
    }

    if (participantIds.safetyCoordinatorId) {
      participants.push({
        role: PROJECT_ORGANISATION_ROLE.PROJECT_SAFETY_COORDINATOR,
        id: participantIds.safetyCoordinatorId
      });
    }

    return participants;
  };

  const fetchParticipantsOrganisations = organisations => {
    return organisationService.getOrganisationsList(organisations.map(orga => orga.id))
      .then(response => {
        organisations.forEach(orga => {
          orga.organisation = response.find(data => data.id === orga.id);
        });
        setParticipants(organisations);
      });
  };

  const fetchProjectParticipants = projectId => {
    setLoading(true);
    projectService.getParticipantsByProjectId(projectId)
      .then(participantIds => {
        const participants = buildParticipants(participantIds);

        if (participantIds.creatorOrganisationId) {
          setCreatorOrganisationId(participantIds.creatorOrganisationId);
        }

        return Promise.all([
          fetchParticipantsOrganisations(participants),
          checkInvitation(projectId)
        ]);
      })
      .then(() => setLoading(false))
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  const removeGCInvitee = invitationId => {
    if (!props.authorizedActions) {
      return;
    }

    enrollmentService.deleteProjectParticipantInvitationById(invitationId, props.projectId)
      .then(() => {
        setInvitations(invitations.filter(invit => invit.id !== invitationId));
        props.actions.addSuccess(<Translate value="project.remove.generalContractor.invitation.success"/>);
      }).catch(err => {
        setError(true);
        captureError('deleteProjectParticipantInvitationById', err);
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const removeGC = generalContractorId => {
    if (!props.authorizedActions) {
      return;
    }

    projectService.removeGeneralContractor(props.projectId, generalContractorId)
      .then(() => {
        setParticipants(participants.filter(participant => participant.id !== generalContractorId));
        props.actions.addSuccess(<Translate value="project.remove.generalContractor.participation.success"/>);
      }).catch(err => {
        setError(true);
        captureError('removeGeneralContractor', err);
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  React.useEffect(() => {
    if (props.projectId) {
      fetchProjectParticipants(props.projectId);
    } else {
      setError(true);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ProjectParticipantsView
        creatorOrganisationId={creatorOrganisationId}
        participants={participants}
        invitations={invitations}
        isProjectEditionAllowed={props.isProjectEditionAllowed}
        loading={loading}
        error={error}
        authorizedActions={props.authorizedActions}
        onRemoveGCInvitee={removeGCInvitee}
        onRemoveGC={removeGC}
        onEditButtonClick={() => setIsEditPanelOpen(true)}
      />
      {props.isPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => props.onPanelAction(false)}
        >
          <ProjectInviteGeneralContractor
            actions={props.actions}
            organisation={props.organisation}
            projectId={props.projectId}
            onSelectOrganisation={reloadParticipants}
            participants={participants.map(participant => participant.id)}
          />
        </SidePanel>
      }
      {isEditPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsEditPanelOpen(false)}
        >
          <ParticipantsEdition
            organisation={props.organisation}
            projectId={props.projectId}
            participants={participants}
            invitations={invitations}
            onValidatedUpdate={reloadParticipants}
            onDeleteInvitation={() => checkInvitation(props.projectId)}
            actions={props.actions}
          />
        </SidePanel>
      }
    </>
  );
};

ProjectParticipantsContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  organisation: PropTypes.object.isRequired,
  isProjectEditionAllowed: PropTypes.bool.isRequired,
  isPanelOpen: PropTypes.bool.isRequired,
  onPanelAction: PropTypes.func.isRequired,
  authorizedActions: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired
};

export default ProjectParticipantsContainer;
