import React from 'react';
import {I18n, Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {AbstractFormComponent, Icon, InfoAdornment, TextPhoneNumber} from 'front-onceforall-core';
import {emailValidator, phoneNumberValidator, requiredValidator} from 'front-onceforall-core/dist/utils/validators';
import {getRegistrationNumberValidatorFunction} from '../../utils/validators';
import TextField from '@material-ui/core/TextField';
import {COUNTRY} from 'front-onceforall-core/dist/utils/country';
import CountrySelector, {getPhoneLocaleFromCountry} from '../country/CountrySelector';
import {organisationService} from '../../services/organisation/OrganisationService';
import {captureError} from '../../utils/log';
import configureStore from '../../store/configureStore';

export class InviteUnknownOrganisation extends AbstractFormComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      loading: false,
      message: null,
      defaultPhoneCountry: getPhoneLocaleFromCountry(props.invitingOrganisationCountry),
      organisationAlreadyExistingFieldError: null,
      isOrganisationInactive: false
    };

    this.renderIdentificationNumberInput = this.renderIdentificationNumberInput.bind(this);
  }

  getFormData() {
    let organisationName = '';
    let registrationNumber = '';

    if (getRegistrationNumberValidatorFunction(this.props.defaultValue, true)) {
      organisationName = this.props.defaultValue;
    } else {
      registrationNumber = this.props.defaultValue;
    }

    return {
      organisationName: organisationName,
      email: '',
      firstName: '',
      lastName: '',
      registrationNumber: registrationNumber,
      country: this.props.invitingOrganisationCountry,
      phoneNumber: ''
    };
  }

  getFormValidators() {
    return {
      organisationName: requiredValidator,
      email: [requiredValidator, emailValidator],
      firstName: requiredValidator,
      lastName: requiredValidator,
      registrationNumber: [
        requiredValidator,
        getRegistrationNumberValidatorFunction,
        () => this.state.organisationAlreadyExistingFieldError
      ],
      country: requiredValidator,
      phoneNumber: [requiredValidator, phoneNumberValidator]
    };
  }

  componentDidMount() {
    if (!getRegistrationNumberValidatorFunction(this.state.formData.registrationNumber)) {
      this.checkIfOrganisationExistsLocallyOrOnIlg(this.state.formData.registrationNumber);
    }
  }

  handleRegistrationNumberChange = event => {
    const value = event.target.value;
    if (getRegistrationNumberValidatorFunction(value, true)) {
      return;
    }

    this.setState({
      formData: {
        ...this.state.formData,
        registrationNumber: value
      },
      message: null,
      isOrganisationInactive: false
    }, () => this.validateField('registrationNumber', value));

    this.checkIfOrganisationExistsLocallyOrOnIlg(value);
  };

  checkIfOrganisationExistsLocallyOrOnIlg = async registrationNumber => {
    let organisationAlreadyExistingFieldError = null;

    if (!getRegistrationNumberValidatorFunction(registrationNumber)) {
      this.setState({loading: true});
      const organisation = await organisationService.getByRegistrationNumberAndCountry(registrationNumber, this.state.formData.country)
        .catch(error => {
          captureError('Error while searching if organisation already exists', error);
        });

      if (organisation) {
        // Autofill the organisationName field when the organisation is found on ILG
        this.setState({
          formData: {
            ...this.state.formData,
            organisationName: organisation.fullName
          },
          message: null
        }, this.validateField('organisationName', organisation.fullName));

        if (!organisation.enabled) {
          this.setState({
            message: I18n.t('organisation.inviteOrganisationDeactivated', {organisationName: organisation.fullName}),
            isOrganisationInactive: true
          });
        }

        if (!organisation.fromProvider) {
          // Display a field error when organisation already exists locally
          organisationAlreadyExistingFieldError = 'error.registrationNumberAlreadyUsed';
        }
      } else {
        this.setState({message: I18n.t('invite.organisationNotFound')});
      }

      this.setState({loading: false});
    }

    this.setState({organisationAlreadyExistingFieldError}, () => this.validateField('registrationNumber', registrationNumber));
  };

  // @TODO : Uncomment when changing country is available
  /* handleChangeCallback(name, value) {
    if (name === 'country') {
      this.setState({
        formData: this.getFormData(),
        submitted: false
      }, () => {
        this.formValidators = this.getFormValidators();
        this.fetchIdentificationNumberTypes(value);
      });
    }
  } */

  handleSubmitCallback() {
    if (this.state.isOrganisationInactive) {
      return;
    }

    this.props.onValidate(this.state.formData);
  }

  renderIdentificationNumberInput() {
    return (
      <TextField
        label={this.getFieldLabel(`registrationNumber.${this.props.invitingOrganisationCountry}.name`, true)}
        value={this.state.formData.registrationNumber}
        onChange={this.handleRegistrationNumberChange}
        error={this.isOnError('registrationNumber')}
        helperText={this.helperText('registrationNumber')}
        data-id="registrationNumber"
        InputProps={{
          endAdornment: (
            <InfoAdornment text={I18n.t(`registrationNumber.${this.props.invitingOrganisationCountry}.tooltip`)}/>
          )
        }}
      />
    );
  }

  render() {
    const currentLocale = configureStore.getStore().getState().i18n.locale;

    return (
      <form onSubmit={this.handleSubmit}>
        {this.state.loading && <div className="loading-overlay"/>}
        <h2>
          <Translate value="invite.title"/>
        </h2>
        {this.state.message &&
          <div className={`box ${this.state.isOrganisationInactive ? 'rejected' : ''} flex-container mb-5`}>
            <span className="flex-shrink-0">
              <Icon icon="info" size={20}/>
            </span>
            <span data-id="form-message">{this.state.message}</span>
          </div>
        }
        <div className="row">
          <div className="col-md-4">
            <CountrySelector
              onChange={this.handleChange('country')}
              value={this.state.formData.country}
              countriesToShow={[COUNTRY.FRANCE]}
              disabledCountrySelector
            />
          </div>
          <div className="col-md-8">
            {this.renderIdentificationNumberInput()}
          </div>
        </div>

        <TextField
          label={this.getFieldLabel('invite.organisationName', true)}
          value={this.state.formData.organisationName}
          onChange={this.handleChange('organisationName')}
          error={this.isOnError('organisationName')}
          helperText={this.helperText('organisationName')}
          data-id="organisationName"
        />
        <div className="row">
          <div className="col-lg-4">
            <TextPhoneNumber
              label={this.getFieldLabel('user.phoneNumber', true)}
              value={this.state.formData.phoneNumber}
              onChange={this.handleChange('phoneNumber')}
              error={this.isOnError('phoneNumber')}
              helperText={this.helperText('phoneNumber')}
              defaultLocal={this.state.defaultPhoneCountry}
              currentLocale={currentLocale}
            />
          </div>
          <div className="col-lg-8">
            <TextField
              label={this.getFieldLabel('invite.email', true)}
              value={this.state.formData.email}
              onChange={this.handleChange('email')}
              error={this.isOnError('email')}
              helperText={this.helperText('email')}
            />
          </div>
        </div>
        <TextField
          label={this.getFieldLabel('invite.firstName', true)}
          value={this.state.formData.firstName}
          onChange={this.handleChange('firstName')}
          error={this.isOnError('firstName')}
          helperText={this.helperText('firstName')}
        />
        <TextField
          label={this.getFieldLabel('invite.lastName', true)}
          value={this.state.formData.lastName}
          onChange={this.handleChange('lastName')}
          error={this.isOnError('lastName')}
          helperText={this.helperText('lastName')}
        />

        <div className="actions col-12 inline-container space-between">
          <button
            type="button"
            className="large secondary inline-container"
            onClick={this.props.onCancel}
            data-id="cancel"
          >
            <Translate value="action.back"/>
            <Icon icon="go-back"/>
          </button>

          <button
            className="large primary inline-container"
            type="submit"
            disabled={this.state.submitting || this.state.isOrganisationInactive}
          >
            <Translate value="action.confirm"/>
            <Icon icon={this.state.submitting ? 'loader' : 'paper-plane'}/>
          </button>
        </div>
      </form>
    );
  }
}

InviteUnknownOrganisation.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  defaultValue: PropTypes.string.isRequired,
  invitingOrganisationCountry: PropTypes.string.isRequired
};

export default InviteUnknownOrganisation;
