import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';
import {useSelector} from 'react-redux';

import {TextPhoneNumber} from 'front-onceforall-core';

import {getFieldLabel} from '../../utils';

import RadioYesNo from '../RadioYesNo';

import ContactList from './ContactList';
import {onFieldChange} from './utils';

function SubcontractorDetails({onChange, publicProcurementData}) {
  const currentLocale = useSelector(state => state.i18n.locale);
  const {
    email: emailError, phoneNumber: phoneNumberError
  } = publicProcurementData.errors?.subcontractor || {};

  const handleComplexChange = name => event => {
    onChange(onFieldChange(name, event.target.value));
  };

  const handleChangeRadio = name => value => {
    onChange({[name]: value});
  };

  const computeRfaLevel = () => {
    if (publicProcurementData?.parentSubcontractors) {
      return publicProcurementData.parentSubcontractors.length + 1;
    }

    return 0;
  };

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.subcontractor" count={computeRfaLevel()}/></h2>
      </div>
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-7 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.fullName', true)}
            id="subcontractor-fullName"
            name="subcontractor-fullName"
            value={publicProcurementData.subcontractor?.fullName || ''}
            onChange={handleComplexChange('fullName')}
          />
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.legalForm', true)}
            id="subcontractor-legalForm"
            name="subcontractor-legalForm"
            value={publicProcurementData.subcontractor?.legalForm || ''}
            onChange={handleComplexChange('legalForm')}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.registrationNumber', true)}
            id="subcontractor-registrationNumber"
            name="subcontractor-registrationNumber"
            value={publicProcurementData.subcontractor?.registrationNumber || ''}
            onChange={handleComplexChange('registrationNumber')}
          />
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-12">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.address', true)}
            id="subcontractor-address"
            name="subcontractor-address"
            value={publicProcurementData.subcontractor?.address || ''}
            onChange={handleComplexChange('address')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.email', true)}
            id="subcontractor-email"
            name="subcontractor-email"
            value={publicProcurementData.subcontractor?.email || ''}
            onChange={handleComplexChange('email')}
            error={Boolean(emailError)}
            helperText={emailError ? I18n.t(emailError) : null}
          />
        </div>
        <div className="col-lg-6 col-md-12 pb-3">
          <TextPhoneNumber
            label={getFieldLabel('publicProcurement.contractor.phoneNumber', false)}
            id="subcontractor-phoneNumber"
            name="subcontractor-phoneNumber"
            value={publicProcurementData.subcontractor?.phoneNumber || ''}
            currentLocale={currentLocale}
            defaultLocal={currentLocale}
            onChange={handleComplexChange('phoneNumber')}
            error={Boolean(phoneNumberError)}
            helperText={phoneNumberError ? I18n.t(phoneNumberError) : null}
          />
        </div>
        <div className="col-12 pb-3">
          <ContactList
            onChange={onChange}
            contacts={publicProcurementData.subcontractorContacts}
          />
        </div>
        <div className="col-12 pb-3">
          <RadioYesNo
            id="subcontractor-isSubcontractorASmallOrganisation"
            title={getFieldLabel('publicProcurement.isSubcontractorASmallOrganisation', true)}
            value={publicProcurementData.isSubcontractorASmallOrganisation}
            onChange={handleChangeRadio('isSubcontractorASmallOrganisation')}
          />
        </div>
        <div className="col-12 pb-3">
          <RadioYesNo
            id="subcontractor-isSubcontractorLinkedToHolder"
            title={getFieldLabel('publicProcurement.isSubcontractorLinkedToHolder', false)}
            value={publicProcurementData.isSubcontractorLinkedToHolder}
            onChange={handleChangeRadio('isSubcontractorLinkedToHolder')}
          />
        </div>
      </div>
    </div>
  );
}

SubcontractorDetails.propTypes = {
  onChange: PropTypes.func.isRequired,
  publicProcurementData: PropTypes.shape({
    isSubcontractorASmallOrganisation: PropTypes.bool,
    isSubcontractorLinkedToHolder: PropTypes.bool,
    parentSubcontractors: PropTypes.array,
    subcontractor: PropTypes.shape({
      fullName: PropTypes.string,
      registrationNumber: PropTypes.string,
      legalForm: PropTypes.string,
      address: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string
    }),
    errors: PropTypes.shape({
      subcontractor: PropTypes.shape({
        email: PropTypes.string,
        phoneNumber: PropTypes.string
      })
    }),
    subcontractorContacts: PropTypes.arrayOf(PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      jobTitle: PropTypes.string
    }))
  }).isRequired
};

export default SubcontractorDetails;
