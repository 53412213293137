import moment from 'moment-timezone';
import {I18n} from 'react-redux-i18n';
import XLSX from 'xlsx';
import {PROJECT_ORGANISATION_ROLE, RFA_STATUS_TO_WORKFLOW_STATUS, WORKFLOW_STATUS} from './constant';

export function exportXlsx(organisations, invitations, requests) {
  const orgsMap = new Map(organisations.map(org => [org.id, org]));
  const header = [
    I18n.t('project.exportExcel.ownerName'),
    I18n.t('project.exportExcel.ownerRegistrationNumber'),
    I18n.t('project.exportExcel.subcontractorName'),
    I18n.t('project.exportExcel.subcontractorRegistrationNumber'),
    I18n.t('request.page.creationDate'),
    I18n.t('request.create.details.title'),
    I18n.t('request.create.details.startingDate'),
    I18n.t('request.create.details.endingDate'),
    I18n.t('request.create.details.amount'),
    I18n.t('project.exportExcel.status'),
    I18n.t('project.exportExcel.suspensiveCondition'),
    I18n.t('project.exportExcel.suspensiveConditionDetails'),
    I18n.t('project.exportExcel.nextSigningRole')
  ];
  const rows = [header];
  const scRoleLabel = [PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR, PROJECT_ORGANISATION_ROLE.RFA_PARENT_SUBCONTRACTOR];

  const buildRow = (request, hasParentRequest) => {
    let subcontractorFullName;
    let subcontractorRegistrationNumber;
    let workflowStatus;
    let lastSignatoryRole;
    let validatedWithCondition;
    let conditionDetails;
    let nextSigningRole;
    let scRankLevel;
    if (orgsMap.has(request.subcontractorId)) {
      subcontractorFullName = orgsMap.get(request.subcontractorId).fullName;
      subcontractorRegistrationNumber = orgsMap.get(request.subcontractorId).registrationNumber;
      workflowStatus = RFA_STATUS_TO_WORKFLOW_STATUS[request.status];

      if (workflowStatus === WORKFLOW_STATUS.APPROVED) {
        lastSignatoryRole = request.project.clientId ? PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT : PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE;
        const validationConditionFromLastSignatory = request.suspensiveConditions.find(condition => condition.organisationRole === lastSignatoryRole);
        validatedWithCondition = Boolean(validationConditionFromLastSignatory);
        conditionDetails = validationConditionFromLastSignatory?.content;
      } else {
        nextSigningRole = request.activeTasks[0]?.role;
        if (scRoleLabel.includes(nextSigningRole) && hasParentRequest) {
          nextSigningRole = PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR_N;
          scRankLevel = request.activeTasks[0].organisationId === request.creatorOrganisationId ? 1 : 2;
        }

        if (nextSigningRole === PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR &&
          request.activeTasks[0].organisationId === request.creatorOrganisationId) {
          nextSigningRole = PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR_OWNER;
        }
      }
    } else {
      const invitation = invitations.find(invit => invit.requestForApprovalId === Number(request.id) && invit.role === PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR);
      subcontractorFullName = invitation.organisationName;
      subcontractorRegistrationNumber = invitation.registrationNumber;
      workflowStatus = WORKFLOW_STATUS.WAITING_SC;
    }

    return [
      orgsMap.get(request.creatorOrganisationId).fullName,
      orgsMap.get(request.creatorOrganisationId).registrationNumber,
      subcontractorFullName,
      subcontractorRegistrationNumber,
      moment(request.creationDate).format('DD/MM/YYYY'),
      request.details,
      moment(request.startingDate).format('DD/MM/YYYY'),
      moment(request.endingDate).format('DD/MM/YYYY'),
      request.amount,
      I18n.t(`request.status.${workflowStatus}`).replace(/^\w/, c => c.toUpperCase()),
      validatedWithCondition ? I18n.t('project.exportExcel.validatedWithCondition') : null,
      conditionDetails ? conditionDetails : null,
      nextSigningRole ? I18n.t(`request.page.${nextSigningRole}`, {level: scRankLevel}) : null
    ];
  };

  const iterRequests = (iteratedRequests, hasParentRequest = false) => {
    iteratedRequests.forEach(request => {
      rows.push(buildRow(request, hasParentRequest));
      if (request.children?.length > 0) {
        iterRequests(request.children, true);
      }
    });
  };

  iterRequests(requests);

  const ws = XLSX.utils.aoa_to_sheet(rows, {skipHeader: true});
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, I18n.t('project.exportExcel.tabname'));
  XLSX.writeFile(wb, I18n.t('project.exportExcel.filename', {projectName: requests[0].project.name}));
}
