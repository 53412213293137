import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Translate} from 'react-redux-i18n';

import {Icon} from 'front-onceforall-core';

import ClientInformation from './components/ClientInformation';
import PublicProcurementObject from './components/PublicProcurementObject';
import RequestForApprovalObject from './components/RequestForApprovalObject';
import GeneralContractor from './components/GeneralContractor';
import ParentSubcontractorsList from './components/ParentSubcontractorsList';
import SubcontractorDetails from './components/SubcontractorDetails';
import SubcontractedServices from './components/SubcontractedServices';
import SubcontractedServicesPrice from './components/SubcontractedServicesPrice';
import PaymentTerms from './components/PaymentTerms';
import SubcontractorContractDuration from './components/SubcontractorContractDuration';
import SubcontractorSwornStatement from './components/SubcontractorSwornStatement';
import AssignmentOrPledge from './components/AssignmentOrPledge';

function PublicProcurementFormView(props) {
  return (
    <>
      <Link to={`/request/${props.rfa?.id}`}>
        <div className="inline-container my-4">
          <Icon icon="arrow-left"/>
          <Translate value="publicProcurement.backToRequest"/>
        </div>
      </Link>
      <div className="flex-container">
        <h1 className=""><Translate value="publicProcurement.titles.form"/></h1>
      </div>
      <ClientInformation
        projectPublicProcurementData={props.projectPublicProcurementData}
        client={props.projectPublicProcurementData.client}
      />
      <PublicProcurementObject
        projectPublicProcurementData={props.projectPublicProcurementData}
      />
      <RequestForApprovalObject
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <GeneralContractor
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <ParentSubcontractorsList
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <SubcontractorDetails
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <SubcontractedServices
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <SubcontractedServicesPrice
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <PaymentTerms
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <SubcontractorContractDuration
        rfa={props.rfa}
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <SubcontractorSwornStatement
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <AssignmentOrPledge
        publicProcurementData={props.publicProcurementData}
        onChange={props.updatePublicProcurementData}
      />
      <div className="actions col-12 inline-container space-between pb-3">
        <button
          className="large primary inline-container"
          type="submit"
          disabled={props.loading || !props.submitAllowed}
          onClick={props.onSubmit}
        >
          <Translate value="action.confirm"/>
          <Icon icon={props.loading ? 'loader' : 'check'}/>
        </button>
      </div>
    </>
  );
}

PublicProcurementFormView.defaultProps = {
  loading: false,
  submitAllowed: false
};

PublicProcurementFormView.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  rfa: PropTypes.object.isRequired,
  projectPublicProcurementData: PropTypes.object.isRequired,
  publicProcurementData: PropTypes.object.isRequired,
  updatePublicProcurementData: PropTypes.func.isRequired,
  submitAllowed: PropTypes.bool
};

export default PublicProcurementFormView;
