import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {I18n, setLocale, Translate} from 'react-redux-i18n';
import {availableLanguages} from '../../translations';
import {userService} from '../../services/user/UserService';
import {parse} from 'query-string';
import Snackbars from '../../layout/Snackbars';
import * as snackbarsActions from '../../actions/snackbars';
import {setUser} from '../../actions/user';
import {authenticationService} from '../../services/authentication/AuthenticationService';
import {enrollmentService} from '../../services/enrollment/EnrollmentService';
import {
  AbstractFormComponent,
  Icon,
  InfoAdornment,
  TextCheckbox,
  TextPhoneNumber,
  validators
} from 'front-onceforall-core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {getPrivacyPolicyPath, getTermsAndConditionsPath} from '../../utils/path';
import {isEmpty} from 'front-onceforall-core/dist/utils/strings';
import config from './../../../config';
import {captureError} from '../../utils/log';
import {INVITATION_STATUS} from '../../utils/constant';

export class UserRegisterView extends AbstractFormComponent {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitError = this.handleSubmitError.bind(this);
    this.useInvitationCode = this.useInvitationCode.bind(this);
    this.validateEmailConfirmation = this.validateEmailConfirmation.bind(this);
    this.validatePasswordConfirmation = this.validatePasswordConfirmation.bind(this);
    this.handleCancel = this.handleCancel.bind(this);

    const mailingVIP = parse(this.props.location.search).utm_source;
    if (mailingVIP && mailingVIP === 'Mailing VIP') {
      window.location.href = '/';
    }
  }

  componentDidMount() {
    if (!isEmpty(this.state.formData.invitationCode)) {
      this.useInvitationCode(this.state.formData.invitationCode);
    }

    this.setState({loading: false});
  }

  getFormData() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      language: this.props.currentLocale,
      phoneNumber: '',
      mobilePhoneNumber: '',
      termsAccepted: false,
      privacyPolicyAccepted: false,
      invitationCode: parse(this.props.location.search).invitationCode
    };
  }

  getFormValidators() {
    function getOrderedPasswordValidators() {
      return [validators.requiredValidator]
        .concat(getSecurePasswordPolicyValidators())
        .concat([value => value !== this.state.formData.passwordConfirmation && 'error.passwordConfirmation']);
    }

    function getSecurePasswordPolicyValidators() {
      return [
        value => (String(value)).length < 8 && 'user.securePasswordPolicy.atLeast8Characters',
        value => !/(?=.*[a-z])/.test(value) && 'user.securePasswordPolicy.atLeast1Lowercase',
        value => !/(?=.*[A-Z])/.test(value) && 'user.securePasswordPolicy.atLeast1Uppercase',
        value => !/(?=.*\d)/.test(value) && 'user.securePasswordPolicy.atLeast1Digit'
      ];
    }

    return {
      firstName: validators.requiredValidator,
      lastName: validators.requiredValidator,
      email: [validators.requiredValidator,
        validators.emailValidator,
        value => value !== this.state.formData.emailConfirmation && 'error.emailConfirmation'],
      emailConfirmation: [validators.requiredValidator,
        validators.emailValidator,
        value => value !== this.state.formData.email && 'error.emailConfirmation'],
      password: getOrderedPasswordValidators.call(this),
      passwordConfirmation: [validators.requiredValidator,
        value => value !== this.state.formData.password && 'error.passwordConfirmation'],
      language: validators.requiredValidator,
      phoneNumber: [validators.requiredValidator, validators.phoneNumberValidator],
      mobilePhoneNumber: validators.mobilePhoneNumberValidator,
      termsAccepted: [validators.requiredValidator, value => !value && 'error.CGURequired'],
      privacyPolicyAccepted: [validators.requiredValidator, value => !value && 'error.privacyPolicyRequired']
    };
  }

  handleChangeCallback(fieldName, value) {
    if (['email', 'emailConfirmation'].includes(fieldName)) {
      this.validateEmailConfirmation(fieldName);
      return;
    }

    if (['password', 'passwordConfirmation'].includes(fieldName)) {
      this.validatePasswordConfirmation(fieldName);
      return;
    }

    if (fieldName === 'language') {
      this.props.actions.setLocale(value);
    }
  }

  validateEmailConfirmation(fieldName) {
    if (fieldName === 'email') {
      this.validateField('emailConfirmation', this.state.formData.emailConfirmation);
    } else {
      this.validateField('email', this.state.formData.email);
    }
  }

  validatePasswordConfirmation(fieldName) {
    if (fieldName === 'password') {
      this.validateField('passwordConfirmation', this.state.formData.passwordConfirmation);
    } else {
      this.validateField('password', this.state.formData.password);
    }
  }

  useInvitationCode(code) {
    enrollmentService.getInvitationByCode(code)
      .then(invitation => {
        // Redirect to login form if the invitation is not currently pending
        if (invitation.status !== INVITATION_STATUS.PENDING) {
          authenticationService.logout();
          return;
        }

        userService.checkWhetherUserExists(invitation.email)
          .then(userExists => {
            if (userExists) {
              this.props.history.push('/register-organisation');
              return;
            }

            this.setState({
              formData: {
                firstName: invitation.firstName,
                lastName: invitation.lastName,
                email: invitation.email,
                language: this.state.formData.language,
                phoneNumber: invitation.phoneNumber,
                mobilePhoneNumber: '',
                invitationCode: this.state.formData.invitationCode,
                termsAccepted: false,
                privacyPolicyAccepted: false
              },
              loading: false
            });
          })
          .catch(error => captureError('userService.checkWhetherUserExists', error));
      })
      .catch(error => {
        // Display a disclaimer if the invitation doesn't exist
        if (error.response && (error.response.status === 404)) {
          this.props.actions.addError(<Translate value="invite.notFound"/>);
        }

        captureError('enrollmentService.getInvitationByCode', error);
      });
  }

  handleSubmitCallback() {
    const {
      emailConfirmation,
      passwordConfirmation,
      termsAccepted,
      privacyPolicyAccepted,
      ...data
    } = this.state.formData;

    userService.createUser(data)
      .then(user => {
        this.props.actions.setUser(Object.assign({}, user, {emailVerified: false}));
        this.props.actions.setLocale(this.state.formData.language);
        this.props.history.push('/activate-account');
      })
      .catch(error => this.handleSubmitError(error));
  }

  handleSubmitError(error) {
    error.response.json().then(body => {
      if (error.response.status === 500) {
        this.props.actions.addError(<Translate value="error.generic"/>);
        this.setState({submitting: false, loading: false});
        captureError('userService.createUser', error);
        return;
      }

      if (error.response.status === 409) {
        const fieldErrors = this.state.fieldErrors;
        fieldErrors.email = body.errorCode === 'error.emailAlreadyUsedOnOfa' ?
          <Translate
            value="error.userAlreadyExistsOnOfa"
            link={`/?authProvider=${config.keycloak.ofaProvider}`}
            dangerousHTML/> :
          'error.userAlreadyExists';
        this.setState({fieldErrors, submitting: false, loading: false});
      }
    });
  }

  handleCancel() {
    this.props.history.push('/');
  }

  /**
   * @override
   * Redefined AbstractFormComponent method to autorize the usage of a Translate object in a fieldError
   *
   * @param fieldName
   * @returns Translate
   */
  helperText(fieldName) {
    // If the fieldError is an instance of Translate, render directly this component
    if (this.state.submitted && this.state.fieldErrors[fieldName] && this.state.fieldErrors[fieldName].type === Translate) {
      return this.state.fieldErrors[fieldName];
    }

    return super.helperText(fieldName);
  }

  render() {
    const termsLabel = (
      <a
        href={getTermsAndConditionsPath(this.props.currentLocale)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={this.isOnError('termsAccepted') ? 'error' : ''}>
          <Translate value="organisation.create.termsAccepted"/>
        </span>
      </a>
    );
    const privacyPolicyLabel = (
      <a
        href={getPrivacyPolicyPath(this.props.currentLocale)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className={this.isOnError('privacyPolicyAccepted') ? 'error' : ''}>
          <Translate value="organisation.create.privacyPolicyAccepted"/>
        </span>
      </a>
    );
    return (
      <div className="main-template background-white no-padding p-5">
        <Snackbars/>

        <div className="m-3">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-xl-6">
              <div className="box emphasis" style={{height: '100%'}}>
                <h1 className="m-0 mb-4 information-primary" style={{textAlign: 'center'}}>
                  <Translate value="user.formTitle"/>
                </h1>
                <form onSubmit={this.handleSubmit}>
                  {this.state.loading && <div className="loading-overlay"/>}
                  <div className="row">
                    <div className="col-12">
                      <TextField
                        select
                        value={this.state.formData.language}
                        onChange={this.handleChange('language')}
                        error={this.isOnError('language')}
                        helperText={this.helperText('language')}
                        label={this.getFieldLabel('user.language', true)}
                        id="language"
                        name="language"
                      >
                        {availableLanguages.map((lang, index) => {
                          return (
                            <MenuItem key={index} value={lang}><Translate value={'language.' + lang}/></MenuItem>
                          );
                        })}
                      </TextField>
                    </div>
                  </div>

                  <hr className="mb-4"/>

                  <div className="row">
                    <div className="col-md-6">
                      <TextField
                        value={this.state.formData.firstName}
                        onChange={this.handleChange('firstName')}
                        error={this.isOnError('firstName')}
                        helperText={this.helperText('firstName')}
                        label={this.getFieldLabel('user.firstName', true)}
                        id="firstName"
                        name="firstName"
                        autoFocus
                      />
                    </div>
                    <div className="col-md-6">
                      <TextField
                        value={this.state.formData.lastName}
                        onChange={this.handleChange('lastName')}
                        error={this.isOnError('lastName')}
                        helperText={this.helperText('lastName')}
                        label={this.getFieldLabel('user.lastName', true)}
                        id="lastName"
                        name="lastName"
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-6">
                      <TextPhoneNumber
                        value={this.state.formData.phoneNumber}
                        onChange={this.handleChange('phoneNumber')}
                        error={this.isOnError('phoneNumber')}
                        helperText={this.helperText('phoneNumber')}
                        label={this.getFieldLabel('user.phoneNumber', true)}
                        defaultLocal={this.state.formData.language}
                        currentLocale={this.props.currentLocale}
                      />
                    </div>
                    <div className="col-sm-6">
                      <TextPhoneNumber
                        value={this.state.formData.mobilePhoneNumber}
                        onChange={this.handleChange('mobilePhoneNumber')}
                        error={this.isOnError('mobilePhoneNumber')}
                        helperText={this.helperText('mobilePhoneNumber')}
                        label={this.getFieldLabel('user.mobilePhoneNumber')}
                        defaultLocal={this.state.formData.language}
                        currentLocale={this.props.currentLocale}
                      />
                    </div>
                  </div>

                  <hr className="mb-4"/>

                  <div className="row">
                    <div className="col-12">
                      <TextField
                        value={this.state.formData.email}
                        onChange={this.handleChange('email')}
                        error={this.isOnError('email')}
                        helperText={this.helperText('email')}
                        label={this.getFieldLabel('user.email', true)}
                        id="email"
                        name="email"
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        value={this.state.formData.emailConfirmation}
                        onChange={this.handleChange('emailConfirmation')}
                        error={this.isOnError('emailConfirmation')}
                        helperText={this.helperText('emailConfirmation')}
                        label={this.getFieldLabel('user.emailConfirmation', true)}
                        id="emailConfirmation"
                        name="emailConfirmation"
                        onPaste={event => event.preventDefault()}
                      />
                    </div>
                  </div>

                  <hr className="mb-4"/>

                  <div className="row">
                    <div className="col-12">
                      <TextField
                        value={this.state.formData.password}
                        onChange={this.handleChange('password')}
                        error={this.isOnError('password')}
                        helperText={this.helperText('password')}
                        label={this.getFieldLabel('user.password', true)}
                        type="password"
                        id="password"
                        name="password"
                        InputProps={{
                          endAdornment: (
                            <InfoAdornment
                              text={I18n.t('user.securePasswordPolicy.tooltip')}
                            />
                          )
                        }}
                      />
                    </div>
                    <div className="col-12">
                      <TextField
                        value={this.state.formData.passwordConfirmation}
                        onChange={this.handleChange('passwordConfirmation')}
                        error={this.isOnError('passwordConfirmation')}
                        helperText={this.helperText('passwordConfirmation')}
                        label={this.getFieldLabel('user.passwordConfirmation', true)}
                        type="password"
                        id="passwordConfirmation"
                        name="passwordConfirmation"
                      />
                    </div>
                  </div>

                  <div>
                    <TextCheckbox
                      checked={this.state.formData.termsAccepted}
                      onChange={this.handleChange('termsAccepted')}
                      error={this.isOnError('termsAccepted')}
                      helperText={this.helperText('termsAccepted')}
                      name="termsAccepted"
                      label={termsLabel}
                    />
                  </div>

                  <div>
                    <TextCheckbox
                      checked={this.state.formData.privacyPolicyAccepted}
                      onChange={this.handleChange('privacyPolicyAccepted')}
                      error={this.isOnError('privacyPolicyAccepted')}
                      helperText={this.helperText('privacyPolicyAccepted')}
                      name="privacyPolicyAccepted"
                      label={privacyPolicyLabel}
                    />
                  </div>

                  <div className="actions col-12 inline-container space-between">
                    <button type="button" className="large secondary" onClick={this.handleCancel} data-id="cancel">
                      <Translate value="action.cancel"/>
                    </button>
                    <button
                      className="large primary inline-container"
                      type="submit"
                    >
                      <Translate value="action.createAccount"/>
                      <Icon icon={this.state.submitting ? 'loader' : 'check'}/>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserRegisterView.propTypes = {
  actions: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string
  }).isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentLocale: state.i18n.locale
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions, {setUser}, {setLocale}), dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserRegisterView));
