import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {Collapse} from '@material-ui/core';
import {stringUtils, TextSimpleAutocomplete} from 'front-onceforall-core';
import moment from 'moment-timezone';

const separator = ' - ';

export const SelectRequestView = props => {
  const getRfaDescription = (request, organisations, withDate = false) => {
    const rfaOwner = organisations.find(organisation => organisation.id === request.creatorOrganisationId);
    const amount = I18n.t('request.create.request.suggestion.amount', {amount: request.amount});

    if (!withDate) {
      return [rfaOwner.fullName, amount].join(separator);
    }

    const dateStart = moment(request.startingDate).format('L');
    const dateEnd = moment(request.endingDate).format('L');
    const date = I18n.t('request.create.request.suggestion.date', {dateStart, dateEnd});

    return [rfaOwner.fullName, amount, date].join(separator);
  };

  const getSuggestions = (requests, organisations) => {
    return requests.map(request => {
      const label = getRfaDescription(request, organisations, true);

      return ({label: label, value: request.id});
    });
  };

  const handleSelectRequest = event => {
    const selection = event.target.value;
    if (selection) {
      props.onSelectRequest(props.requests.find(request => request.id === selection.value));
    }
  };

  return (
    <div className="card">
      <div className="flex-container space-between">
        <div className="flex-container">
          <h2 className="m-0"><Translate value="request.create.request.title"/></h2>
          {!props.isOpen && props.selectedRequest &&
            <div className="chip-l information-primary ml-4">
              {getRfaDescription(props.selectedRequest, props.organisations)}
            </div>
          }
        </div>
        {!props.isOpen && props.selectedRequest &&
          <button
            data-id="changeRequestButton"
            className="inline-container small secondary"
            onClick={() => props.onSelectRequest(null)}
          >
            <Translate value="request.create.request.change"/>
          </button>
        }
      </div>
      <Collapse in={props.isOpen}>
        <div data-id="notice" className="py-4">
          {stringUtils.getStrongs(I18n.t('request.create.request.notice', {projectName: props.project.name}))}
        </div>
        {!props.loading &&
          <TextSimpleAutocomplete
            suggestions={getSuggestions(props.requests, props.organisations)}
            value={null}
            placeholder={I18n.t(`request.create.request.input${props.loading ? 'Loading' : 'Placeholder'}`)}
            onChange={handleSelectRequest}
            minimumCharsToRenderResults={0}
            maxResult={5}
            disabled={props.loading}
          />
        }
      </Collapse>
    </div>
  );
};

SelectRequestView.propTypes = {
  onSelectRequest: PropTypes.func.isRequired,
  requests: PropTypes.array.isRequired,
  organisations: PropTypes.array.isRequired,
  selectedRequest: PropTypes.object,
  project: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default SelectRequestView;
