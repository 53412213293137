import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Paginator} from 'front-onceforall-core';
import Card from './card';
import CreateProjectButton from '../buttons/createProjectButton';

export const ProjectListView = props => {
  function getRow(project) {
    const row = props.organisations.find(orga => orga.id === project.creatorOrganisationId);
    if (row) {
      return <Card
        key={project.id}
        creatorOrganisationName={row.fullName}
        project={project}
        onClick={handleLinkProject(project.id)}
      />;
    }
  }

  function handleLinkProject(projectId) {
    return event => {
      event.preventDefault();
      event.stopPropagation();
      props.history.push(`/project/${projectId}`);
    };
  }

  const rows = props.projects.map(project => getRow(project));

  return (
    <>
      {props.loading && <div className="loading-overlay"/>}
      {rows.length > 0 &&
        <>
          {rows}
          <Paginator
            totalItems={props.projectsCount}
            pageSize={props.pageSize}
            currentPage={props.pageNumber}
            onChangePage={props.onPageChange}
          />
        </>
      }
      {rows.length === 0 &&
        <div className="table-without-result">
          <div className="flex-container column center">
            <div className="no-project-message mb-5">
              <Translate value="project.noProjects"/>
            </div>
            <CreateProjectButton icon="folder-plus"/>
          </div>
        </div>
      }
    </>
  );
};

ProjectListView.propTypes = {
  history: PropTypes.object.isRequired,
  organisations: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  projectsCount: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool.isRequired
};

ProjectListView.defaultProps = {
  pageNumber: 0,
  pageSize: 10
};

export default withRouter(ProjectListView);
