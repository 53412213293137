import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import DocumentState from './documentState/DocumentState';
import moment from 'moment-timezone';
import {DOCUMENT_STATUS} from '../../../../utils/constant';
import {Tooltip} from '@material-ui/core';
import {Icon} from 'front-onceforall-core';

export const OfaDocumentView = props => {
  const metadata = props.document.ofaDocumentMetadata && props.document.ofaDocumentMetadata.label;
  const label = metadata ? props.document.ofaDocumentMetadata.label : props.document.type.label;
  let validityDateStart = null;
  let validityDateEnd = null;
  if (props.document.ofaDocumentMetadata &&
    props.document.ofaDocumentMetadata.validityStartDate &&
    props.document.ofaDocumentMetadata.validityEndDate) {
    validityDateStart = <Translate
      value="request.documents.ofaDocumentValidityDateStart"
      tag="div"
      startDate={moment(props.document.ofaDocumentMetadata.validityStartDate).format('L')}
    />;

    validityDateEnd = <Translate
      value="request.documents.ofaDocumentValidityDateEnd"
      tag="div"
      endDate={moment(props.document.ofaDocumentMetadata.validityEndDate).format('L')}
    />;
  }

  // The document must be from OFA and not be any of the following labels
  const documentHasRightState = props.document.ofaDocumentMetadata &&
    ![
      DOCUMENT_STATUS.REJECTED,
      DOCUMENT_STATUS.AWAITING_VALIDATION,
      DOCUMENT_STATUS.MISSING,
      DOCUMENT_STATUS.AWAITING_SIGNATURE,
      DOCUMENT_STATUS.AWAITING_GATHERING
    ].includes(props.document.ofaDocumentMetadata.documentState);

  const validityPeriod = documentHasRightState ? (
    <>
      {validityDateStart}
      {validityDateEnd}
    </>
  ) : null;

  // uploaded documents
  const shouldDisplayDownloadButton = props.document.isUploaded && documentHasRightState;

  // as documents are retreived unitly some docs may have files but donwload not possible
  const hasDisabledButton = !props.document.isUploaded && documentHasRightState;

  const abstractDownloadButton = (onClickAction, disabled = false) => (
    <button
      data-id="download"
      className="inline-container large secondary"
      onClick={onClickAction}
      disabled={disabled}
      style={disabled ? {pointerEvents: 'none'} : {}}
    >
      <Icon icon="download"/>
      <Translate value="request.documents.actions.see"/>
    </button>
  );

  // the normal button to download files
  const actualDownloadButton = abstractDownloadButton(props.onDownloadClick);

  // the disabled button to display a message when a file isn't available, an empty func is given to the onClick prop
  const disabledDownloadButton = abstractDownloadButton(() => ({}), true);

  const downloadButton = shouldDisplayDownloadButton ? (actualDownloadButton) :
    hasDisabledButton ? (
      <Tooltip
        data-id="request-not-uploaded-document-tooltip"
        title={<Translate value="request.documents.ofaDocumentUpdateInformationValidButNotUploadedTooltip"/>}
      >
        <span>{disabledDownloadButton}</span>
      </Tooltip>
    ) : null;

  return (
    <div className="box border bottom" key={props.document.code}>
      <div className="card-header font-size-l">{label}</div>
      <div className="flex-container">
        <div style={{width: 'calc(100% / 3)'}}><DocumentState document={props.document}/></div>
        <div className="font-size-s d-flex flex-column align-items-center" style={{width: 'calc(100% / 3)'}}>
          {validityPeriod}
        </div>
        <div style={{width: 'calc(100% / 3)'}} className="d-flex justify-content-end">{downloadButton}</div>
      </div>
    </div>
  );
};

OfaDocumentView.propTypes = {
  document: PropTypes.object,
  onDownloadClick: PropTypes.func
};

export default OfaDocumentView;
