import {aCallbackSignRequest} from '../../utils/fixtures';

export default class FakeSignatureService {
  signRequest() {
    return Promise.resolve(aCallbackSignRequest().url);
  }

  callbackSignRequest() {
    return Promise.resolve(aCallbackSignRequest());
  }
}

