import 'isomorphic-fetch';
import config from '../../../config';
import {checkStatus, authFetch} from '../../utils/http';

export default class LiveIdentificationNumberService {
  types(country) {
    return authFetch(`${config.userOrgaApi}/api/v1/identification-numbers/types?country=${country}`)
      .then(checkStatus)
      .then(response => response.json());
  }
}
