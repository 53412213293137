import React from 'react';
import PropTypes from 'prop-types';
import RequestInformationView from './RequestInformationView';
import SidePanel from 'front-onceforall-core/dist/views/SidePanel';
import RequestDetails from '../../requestCreate/requestDetails';
import {requestService} from '../../../services/request/RequestService';
import {Translate} from 'react-redux-i18n';
import moment from 'moment-timezone';

export const RequestInformationContainer = props => {
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  let triggerClosePanel = () => {};

  const haveDetailsChanged = requestInformation => {
    return requestInformation.details !== props.request.details ||
      requestInformation.amount !== props.request.amount.toString() ||
      !moment(requestInformation.startingDate).isSame(moment(props.request.startingDate)) ||
      !moment(requestInformation.endingDate).isSame(moment(props.request.endingDate)) ||
      requestInformation.paymaster !== props.request.paymaster;
  };

  const handleDetailsEdition = requestInformation => {
    if (haveDetailsChanged(requestInformation)) {
      setLoading(true);
      requestService.update(props.requestId, requestInformation)
        .then(response => {
          props.actions.addSuccess(<Translate value="request.update.success"/>);
          props.onRequestUpdate(response);
        })
        .catch(() => {
          props.actions.addError(<Translate value="request.update.failed"/>);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    triggerClosePanel();
  };

  return (
    <>
      <RequestInformationView
        request={props.request}
        isEditable={props.isEditable}
        showOwnerButtons={props.showOwnerButtons}
        payMasterOrganisationName={props.payMasterOrganisationName}
        isPaymasterRequestOwner={props.isPaymasterRequestOwner}
        loading={props.loading}
        error={props.error}
        onEditButtonClick={() => setIsPanelOpen(true)}
      />
      {isPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsPanelOpen(false)}
        >
          <RequestDetails
            onSubmit={handleDetailsEdition}
            isOpen={true}
            loading={loading}
            request={props.request}
            onClose={() => triggerClosePanel()}
          />
        </SidePanel>
      }
    </>
  );
};

RequestInformationContainer.propTypes = {
  actions: PropTypes.object,
  request: PropTypes.object,
  requestId: PropTypes.number,
  isEditable: PropTypes.bool,
  showOwnerButtons: PropTypes.bool,
  payMasterOrganisationName: PropTypes.string,
  isPaymasterRequestOwner: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onRequestUpdate: PropTypes.func
};

export default RequestInformationContainer;
