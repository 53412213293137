import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import Participant from './participant';
import Invitation from './invitation';

const slash = '/';

export const RequestWorkflowView = props => {
  const workflowSize = props.workflow.length;
  const isWorkflowEnded = props.signingStep === workflowSize;

  return (
    <>
      <h2>
        <Translate value="request.page.participants"/>
        <span className="separator"/>
        <span className={!props.loading && !props.error && isWorkflowEnded ? 'success' : 'error'}>
          <strong>
            {props.signingStep}
            {slash}
            {workflowSize}
          </strong>
        </span>
      </h2>
      <div className="card flex-container column">
        {props.error ?
          <span data-id="error-message"><Translate value="error.generic"/></span> :
          props.loading ?
            <div className="loading-overlay"/> :
            <>
              {props.workflow.map((step, index, workflow) => {
                const participant = props.participants.find(participant => participant.id === step[0].organisationId);
                const suspensiveCondition = props.suspensiveConditions?.find(condition => condition.organisationRole === step[0].role);
                const isFinalCondition = workflow[workflow.length - 1][0].role === suspensiveCondition?.organisationRole;

                return participant ?
                  <Participant
                    key={index}
                    organisationId={props.organisationId}
                    actionUsers={props.actionUsers}
                    participant={participant}
                    userRoles={props.userRoles}
                    suspensiveCondition={suspensiveCondition}
                    stepTasks={step}
                    position={index + 1}
                    isLast={workflow.length - 1 === index}
                    isFinalCondition={isFinalCondition}
                    isWorkflowEnded={isWorkflowEnded}
                    onSignClick={props.onSignClick}
                    onVerifyClick={props.onVerifyClick}
                    onRejectRequestClick={props.onRejectRequestClick}
                    onRemoveConditionClick={props.onRemoveConditionClick}
                  /> :
                  props.invitations.length > 0 ?
                    <Invitation
                      key={index}
                      invitation={props.invitations.find(invite => step[0].role === invite.role)}
                      position={index + 1}
                    /> : null;
              })}
            </>
        }
      </div>
    </>
  );
};

RequestWorkflowView.defaultProps = {
  loading: false,
  error: false
};

RequestWorkflowView.propTypes = {
  organisationId: PropTypes.number.isRequired,
  workflow: PropTypes.array.isRequired,
  signingStep: PropTypes.number.isRequired,
  participants: PropTypes.array.isRequired,
  actionUsers: PropTypes.array.isRequired,
  invitations: PropTypes.array.isRequired,
  userRoles: PropTypes.array.isRequired,
  suspensiveConditions: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onSignClick: PropTypes.func.isRequired,
  onVerifyClick: PropTypes.func.isRequired,
  onRejectRequestClick: PropTypes.func.isRequired,
  onRemoveConditionClick: PropTypes.func.isRequired
};

export default RequestWorkflowView;
