import {phoneNumberValidator, emailValidator} from 'front-onceforall-core/dist/utils/validators';

export const onFieldChange = (index, name, value) => current => {
  let errors = {
    ...current.errors,
    parentSubcontractors: {
      ...current.errors?.parentSubcontractors
    }
  };

  if (!errors.parentSubcontractors[index]) {
    errors.parentSubcontractors[index] = {};
  }

  if (name === 'email') {
    errors.parentSubcontractors[index].email = emailValidator(value);
  } else if (name === 'phoneNumber') {
    errors.parentSubcontractors[index].phoneNumber = phoneNumberValidator(value);
  }

  if (!errors.parentSubcontractors[index].email && !errors.parentSubcontractors[index].phoneNumber) {
    delete errors.parentSubcontractors[index];
  }

  if (Object.keys(errors.parentSubcontractors).length === 0) {
    delete errors.parentSubcontractors;
  }

  return {
    parentSubcontractors: current.parentSubcontractors.map((parentContractor, i) => {
      if (i === index) {
        return {
          ...parentContractor,
          [name]: value
        };
      }

      return parentContractor;
    }),
    errors
  };
};
