import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as snackbarsActions from '../../actions/snackbars';
import {Icon} from 'front-onceforall-core';
import {userService} from '../../services/user/UserService';
import {captureError} from '../../utils/log';
import ConfirmModal, {defaultModalStyle} from '../modal/ConfirmModal';

export const LinkOfaAccount = props => {
  const handleConfirm = () => {
    userService.linkUserToOfa()
      .then(response => {
        window.open(response.url, '_self');
      })
      .catch(error => {
        captureError('LinkOfaAccount', error);
        props.actions.addError(<Translate value="error.generic"/>);
        props.onClose();
      });
  };

  const modalStyle = {
    ...defaultModalStyle,
    overlay: {
      ...defaultModalStyle.overlay,
      backgroundColor: 'rgba(0,0,0,0.5)',
      zIndex: undefined
    },
    content: {
      ...defaultModalStyle.content,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      maxWidth: '100%',
      width: 800,
      minWidth: undefined,
      textAlign: undefined
    }
  };

  return (
    <ConfirmModal
      modalStyle={modalStyle}
      isOpen
      shouldCloseOnOverlayClick={false}
      onConfirm={() => handleConfirm()}
      onClose={props.onClose}
      title={<Translate value="linkToOfa.title"/>}
      content={<Translate value="linkToOfa.text" email={props.user.email} dangerousHTML/>}
      subtext={<Translate value="linkToOfa.subtext"/>}
      submitButtonBody={<><Icon icon={'check'}/><Translate value={'linkToOfa.button'}/></>}
    />
  );
};

LinkOfaAccount.propTypes = {
  user: PropTypes.object,
  onClose: PropTypes.func,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkOfaAccount);
