import React from 'react';
import PropTypes from 'prop-types';
import {Translate, I18n} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';

import {getFieldLabel} from '../../utils';

import PersonalDataProcessing from './PersonalDataProcessing';

const SUBCONTRACTED_SERVICES_DETAILS_MAX_LENGTH = 1024;

function SubcontractedServices({onChange, publicProcurementData}) {
  const handleSimpleChange = name => event => {
    onChange({[name]: event.target.value});
  };

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.subcontractedServices"/></h2>
      </div>
      <div className="row">
        <div className="col-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.subcontractedServicesDetails', true)}
            id="subcontractedServicesDetails"
            name="subcontractedServicesDetails"
            value={publicProcurementData.subcontractedServicesDetails || ''}
            onChange={handleSimpleChange('subcontractedServicesDetails')}
            multiline
            minRows={3}
            maxRows={10}
            inputProps={{
              maxLength: SUBCONTRACTED_SERVICES_DETAILS_MAX_LENGTH
            }}
          />
        </div>
      </div>
      <PersonalDataProcessing
        onChange={onChange}
        personalDataProcessing={publicProcurementData.personalDataProcessing}
      />
      <div className="row pb-3">
        <div className="col-12">
          <TextField
            id="subcontractedServicesLocation"
            name="subcontractedServicesLocation"
            label={I18n.t('publicProcurement.subcontractedServicesLocation')}
            value={publicProcurementData.subcontractedServicesLocation || ''}
            onChange={handleSimpleChange('subcontractedServicesLocation')}
          />
        </div>
      </div>
    </div>
  );
}

SubcontractedServices.propTypes = {
  onChange: PropTypes.func.isRequired,
  publicProcurementData: PropTypes.shape({
    subcontractedServicesDetails: PropTypes.string,
    subcontractedServicesLocation: PropTypes.string,
    personalDataProcessing: PropTypes.shape({
      services: PropTypes.string,
      duration: PropTypes.string,
      process: PropTypes.string,
      purpose: PropTypes.string,
      dataType: PropTypes.string,
      targets: PropTypes.string,
      personalDataProtectionAgreement: PropTypes.bool,
      personalDataRegulationAgreement: PropTypes.bool
    })
  }).isRequired
};

export default SubcontractedServices;
