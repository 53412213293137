import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';

import {getFieldLabel} from '../../utils';

function PublicProcurementObject(props) {
  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.publicProcurementObject"/></h2>
      </div>
      <div className="row">
        <div className="col-12">
          <TextField
            label={getFieldLabel('publicProcurement.publicProcurementData.publicProcurementObject', true)}
            id="publicProcurementObject"
            name="publicProcurementObject"
            value={props.projectPublicProcurementData.publicProcurementObject || ''}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
}

PublicProcurementObject.propTypes = {
  projectPublicProcurementData: PropTypes.shape({
    publicProcurementObject: PropTypes.string
  }).isRequired
};

export default PublicProcurementObject;
