import moment from 'moment-timezone';

const maintenanceDate = moment().add(1, 'd').toISOString();

export default class FakeMaintenanceService {
  get() {
    return Promise.resolve({
      'date-format-example': '2020-06-15T17:00:00.000Z',
      da: {
        'maintenance-date': maintenanceDate,
        maintenanceUrl: 'https://platform.integration.panda-agrement.fr/maintenance/index.html'
      }
    });
  }
}
