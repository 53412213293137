import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {Translate} from 'react-redux-i18n';
import {AbstractFormComponent, Icon} from 'front-onceforall-core';
import {defaultModalStyle} from './ConfirmModal';
import {FormControl, FormControlLabel, Radio, RadioGroup, TextField} from '@material-ui/core';
import {requiredValidator} from 'front-onceforall-core/dist/utils/validators';

export class SuspensiveConditionModal extends AbstractFormComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      setCondition: Boolean(this.props.lastSuspensiveCondition)
    };

    this.handleSetConditionChange = this.handleSetConditionChange.bind(this);
  }

  getFormData() {
    return {
      condition: this.props.lastSuspensiveCondition ? this.props.lastSuspensiveCondition.content : ''
    };
  }

  getFormValidators() {
    return {
      condition: this.state.setCondition ? requiredValidator : null
    };
  }

  handleSetConditionChange() {
    this.setState(prevState => ({setCondition: !prevState.setCondition}), () => {
      this.formValidators = this.getFormValidators();
      this.validateAllFields();
    });
  }

  handleSubmitCallback() {
    this.props.onConfirm(this.state.setCondition ? this.state.formData.condition : null);
  }

  render() {
    return (
      <Modal
        style={this.props.modalStyle}
        isOpen={this.props.isOpen}
        onRequestClose={this.props.onClose}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={this.props.shouldCloseOnOverlayClick}
      >
        <div style={{overflow: 'hidden'}}>
          <form onSubmit={this.handleSubmit}>
            <h2 data-id="title">
              <Translate value="request.page.suspensiveConditionModal.title"/>
            </h2>
            <FormControl className="pb-3" component="fieldset">
              <RadioGroup value={this.state.setCondition} onChange={this.handleSetConditionChange}>
                <FormControlLabel value={false} control={<Radio />} label={<Translate value="request.page.suspensiveConditionModal.no"/>} />
                <FormControlLabel value={true} control={<Radio />} label={<Translate value="request.page.suspensiveConditionModal.yes"/>} />
              </RadioGroup>
            </FormControl>
            <TextField
              label={this.getFieldLabel('request.page.suspensiveConditionModal.condition', this.state.setCondition)}
              value={this.state.formData.condition}
              onChange={this.handleChange('condition')}
              error={this.isOnError('condition')}
              helperText={this.helperText('condition')}
              disabled={!this.state.setCondition}
              data-id="suspensiveCondition"
              inputProps={{maxLength: 1024}}
              multiline
              minRows={3}
              maxRows={20}
            />
            <div className="actions col-12 inline-container space-between">
              <button
                data-id="cancel"
                type="button"
                className="large inline-container secondary"
                onClick={this.props.onClose}
              >
                <><Icon icon="go-back"/><Translate value="action.cancel"/></>
              </button>
              <button
                id="submit"
                data-id="submit"
                type="submit"
                className="large inline-container primary"
              >
                {this.props.isSignatory ?
                  <><Icon icon="sign"/><Translate value="request.documents.actions.sign"/></> :
                  <><Icon icon="check"/><Translate value="action.confirm"/></>
                }
              </button>
            </div>
          </form>
        </div>
      </Modal>
    );
  }
}

SuspensiveConditionModal.defaultProps = {
  shouldCloseOnOverlayClick: true,
  modalStyle: defaultModalStyle,
  suspensiveConditions: []
};

SuspensiveConditionModal.propTypes = {
  modalStyle: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isSignatory: PropTypes.bool,
  lastSuspensiveCondition: PropTypes.object
};

export default SuspensiveConditionModal;
