// @TODO Bring back when english is ready
// import en from './en';
import fr from './fr';

/**
 * Define the available translations in the application
 */
export const availableTranslations = {
  fr
  // @TODO Bring back when english is ready
  // en
};

/**
 * The available languages are derived from the names of the available translations
 */
export const availableLanguages = Object.keys(availableTranslations);

/**
 * The default language is the first of the available languages
 */
const defaultLanguage = availableLanguages[0];

function getLanguageFromBrowser() {
  // Define user's language. Different browsers have the user locale defined
  // on different fields on the `navigator` object, so we make sure to account
  // for these different by checking all of them
  const language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage;

  // Split locales with a region code
  return language ? language.toLowerCase().split(/[_-]+/)[0] : undefined;
}

export default function getAvailableLanguageOrDefault(language = undefined) {
  // Get the language from browser if not defined in args
  language = language || getLanguageFromBrowser();

  // Return the language if available in application
  // Or the default language else
  return language && availableLanguages.includes(language) ? language : defaultLanguage;
}
