import 'isomorphic-fetch';
import config from '../../../config';
import {authFetch, checkStatus} from '../../utils/http';
import {USER_ORGANISATION_STATUS} from '../../utils/constant';

export default class LiveUserService {
  searchByOrganisation(organisationId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users?organisationId=${organisationId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  searchPendingByOrganisation(organisationId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users?organisationId=${organisationId}&status=${[USER_ORGANISATION_STATUS.PENDING]}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  searchByEmail(email) {
    return authFetch(`${config.userOrgaApi}/api/v1/users?email=${email}`)
      .then(checkStatus)
      .then(response => response.status === 204 ? null : response.json());
  }

  inviteUnknownUserToOrganisation(organisationId, userDetails) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/organisation/${organisationId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: userDetails.email,
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        phoneNumber: userDetails.phoneNumber
      })
    })
      .then(checkStatus);
  }

  checkWhetherUserExists(email) {
    return fetch(`${config.userOrgaApi}/api/v1/users/check/${email}`)
      .then(checkStatus)
      .then(() => true)
      .catch(error => {
        if (error.response && (error.response.status === 404)) {
          return false;
        }

        throw error;
      });
  }

  getUserDetailsById(userId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/${userId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getUserInfoById(userId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/user-info/${userId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getUsersInfoListByIds(userIds) {
    // Remove null and duplicated values in list
    const sanitizedList = [...new Set(userIds.filter(Boolean))];
    return sanitizedList.length === 0 ?
      Promise.resolve([]) :
      authFetch(`${config.userOrgaApi}/api/v1/users/user-info?userIds=${sanitizedList}`)
        .then(checkStatus)
        .then(response => response.json());
  }

  createUser(user) {
    return fetch(`${config.userOrgaApi}/api/v1/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(user)
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  updateUserDetails(userDetails) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/${userDetails.id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userDetails)
    }).then(checkStatus);
  }

  linkOrganisationOnRegister(organisationId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/link/${organisationId}`, {
      method: 'POST'
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  linkUserToOrganisation(organisationId, userId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/link/${userId}/${organisationId}`, {
      method: 'POST'
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  acceptUserLinkRequest(organisationId, userId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/link/${userId}/${organisationId}/accept`, {
      method: 'POST'
    })
      .then(checkStatus);
  }

  declineUserLinkRequest(organisationId, userId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/link/${userId}/${organisationId}/decline`, {
      method: 'POST'
    })
      .then(checkStatus);
  }

  getUsersUnlinkabilityStatusByOrganisation(organisationId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/unlink/status?organisationId=${organisationId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  unlinkUserFromOrganisation(organisationId, userId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/unlink/${userId}/${organisationId}`, {
      method: 'POST'
    })
      .then(checkStatus);
  }

  getUserPermissionsByOrganisationProject(organisationId, projectId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users?organisationId=${organisationId}&projectId=${projectId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  updateUserPermissionsByOrganisationProject(organisationId, projectId, userPermissions) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/permissions/${organisationId}/${projectId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userPermissions)
    })
      .then(checkStatus);
  }

  syncOrganisationsWithOfa(userId, withPermissionToken = true) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/sync-ofa-organisations/${userId}`, null, withPermissionToken)
      .then(checkStatus);
  }

  shouldLinkUserToOfa() {
    return authFetch(`${config.userOrgaApi}/api/v1/users/shouldLinkUserToOfa`)
      .then(checkStatus)
      .then(response => response.status === 200);
  }

  linkUserToOfa() {
    return authFetch(`${config.userOrgaApi}/api/v1/users/linkUserToOfa`, {
      method: 'GET'
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  sendActivationEmail(userId) {
    return fetch(`${config.userOrgaApi}/api/v1/users/${userId}/activation`, {
      method: 'POST'
    })
      .then(checkStatus);
  }

  updateLanguage(userId, lang) {
    const data = {
      language: lang
    };
    return authFetch(`${config.userOrgaApi}/api/v1/users/${userId}/language`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(checkStatus);
  }

  requestUpdateEmail(userId, email) {
    const data = {
      email
    };
    return authFetch(`${config.userOrgaApi}/api/v1/users/${userId}/email`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(checkStatus);
  }

  /** @Deprecated */
  updateEmail(token) {
    const data = {
      token
    };
    return fetch(`${config.userOrgaApi}/api/v1/users/email`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(checkStatus);
  }

  getGeneralTermsLastUpdateTimestamp() {
    return authFetch(`${config.userOrgaApi}/api/v1/users/general-terms`)
      .then(checkStatus)
      .then(response => response.json())
      .then(json => json.lastUpdateTimestamp);
  }

  getPrivacyPolicyLastUpdateTimestamp() {
    return authFetch(`${config.userOrgaApi}/api/v1/users/privacy-policy`)
      .then(checkStatus)
      .then(response => response.json())
      .then(json => json.lastUpdateTimestamp);
  }

  /** @Deprecated */
  getAcceptationDomainNameAutomaticTieUser(organisationId) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/domain-name?organisationId=${organisationId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  acceptLegalConditions(legalConditions) {
    return authFetch(`${config.userOrgaApi}/api/v1/users/legal-conditions`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(legalConditions)
    }).then(checkStatus);
  }
}
