import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import moment from 'moment-timezone';
import {Icon} from 'front-onceforall-core';
import Tooltip from '@material-ui/core/Tooltip';

export const RequestInformationView = props => {
  const formatAmount = amount => {
    const formatter = new Intl.NumberFormat('fr-EU', {
      style: 'currency',
      currency: 'EUR'
    });

    return formatter.format(amount);
  };

  let editButton = <button
    data-id="edit"
    className="large secondary inline-container"
    onClick={props.onEditButtonClick}
    disabled={!props.isEditable}
    style={props.isEditable ? {} : {pointerEvents: 'none'}}
  >
    <Icon icon="edit"/>
  </button>;

  editButton = props.isEditable ? editButton :
    <Tooltip
      data-id="edit-disabled-tooltip"
      title={<Translate value={'request.page.buttonsDisabledTooltip'}/>}
    >
      <span>{editButton}</span>
    </Tooltip>;

  let paymasterTranslateValue = 'request.page.paymaster';
  if (!props.isPaymasterRequestOwner) {
    paymasterTranslateValue = props.payMasterOrganisationName ? 'request.page.paymasterKnownClient' : 'request.page.paymasterUnknownClient';
  }

  return (
    <>
      <h2 className="no-wrap">
        <Translate value="request.page.details"/>
      </h2>
      <div className="card pt-0">
        {props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
          props.loading ? <span className="loading-overlay"/> :
            props.request &&
              <>
                <div className="box border bottom">
                  <div className="flex-container space-between">
                    <div className="card-header font-size-l"><Translate value="address.addressLine"/></div>
                    {props.showOwnerButtons && editButton}
                  </div>
                  <div className="information">
                    {`${props.request.project.address.addressLine},`}
                    <br/>
                    {`${props.request.project.address.postCode} ${props.request.project.address.city}`}
                  </div>
                </div>
                <div className="box border bottom">
                  <div className="card-header font-size-l"><Translate value="request.page.creationDate"/></div>
                  <div className="information">{moment(props.request.creationDate).format('L')}</div>
                </div>
                <div className="box border bottom">
                  <div className="card-header font-size-l"><Translate value="request.page.estimatedDatesTitle"/></div>
                  <div className="information">
                    <Translate
                      value="request.page.estimatedDates"
                      dateStart={moment(props.request.startingDate).format('L')}
                      dateEnd={moment(props.request.endingDate).format('L')}
                    />
                  </div>
                </div>
                <div className="box border bottom">
                  <div className="card-header font-size-l"><Translate value="request.page.serviceProvision"/></div>
                  <div className="information">{props.request.details}</div>
                </div>
                <div className="box border bottom">
                  <div className="card-header font-size-l"><Translate value="request.page.amount"/></div>
                  <div className="information mb-4">{formatAmount(props.request.amount)}</div>
                  <div className="information">
                    <Translate data-id="paymasterName" value={paymasterTranslateValue} paymasterName={props.payMasterOrganisationName}/>
                  </div>
                </div>
              </>
        }
      </div>
    </>
  );
};

RequestInformationView.defaultProps = {
  loading: true,
  error: false,
  isEditable: false,
  showOwnerButtons: false
};

RequestInformationView.propTypes = {
  request: PropTypes.object,
  isEditable: PropTypes.bool,
  showOwnerButtons: PropTypes.bool,
  payMasterOrganisationName: PropTypes.string,
  isPaymasterRequestOwner: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onEditButtonClick: PropTypes.func
};

export default RequestInformationView;
