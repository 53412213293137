import {createBrowserHistory} from 'history';
import configureStore from '../../store/configureStore';
import {findSubscription} from '../../utils/database/subscriptions';

const history = createBrowserHistory();
configureStore.init(history);

export default class FakeSubscriptionService {
  getOrganisationCredits(organisationId) {
    return new Promise(resolve => {
      setTimeout(() => resolve(findSubscription(organisationId)), 1000);
    });
  }

  updateOrganisationCredits(updatedCredits, organisationId) {
    let credits = findSubscription(organisationId);
    credits = Object.assign(credits, {
      projectCredits: updatedCredits.projectCredits,
      requestForApprovalCredits: updatedCredits.requestForApprovalCredits
    });

    return new Promise(resolve => {
      setTimeout(() => resolve(credits), 1000);
    });
  }
}
