import {applyMiddleware, compose, createStore} from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducers/index';
import getAvailableLanguageOrDefault, {availableTranslations} from '../translations';
import persistState from 'redux-localstorage';
import {routerMiddleware} from 'connected-react-router';
import {loadTranslations, setLocale, syncTranslationWithStore} from 'react-redux-i18n';

var store = null;

function configureStore(history, initialState) {
  const persistedState = localStorage.getItem('redux') ? JSON.parse(localStorage.getItem('redux')) : {};

  const enhancer = compose(
    applyMiddleware(routerMiddleware(history), thunkMiddleware),
    persistState([], {
      // eslint-disable-next-line no-unused-vars
      slicer: paths => state => ({
        organisation: state.organisation ? {id: state.organisation.id} : null,
        i18n: {locale: state.i18n.locale}
      })
    })
  );

  const store = createStore(rootReducer(history), initialState, enhancer);

  // Persist app translations and user's language
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(availableTranslations));
  store.dispatch(setLocale(getAvailableLanguageOrDefault(persistedState.i18n ? persistedState.i18n.locale : undefined)));

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = rootReducer(history);
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default {
  init(history) {
    store = configureStore(history);
  },
  getStore() {
    return store;
  }
};
