import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import SelectOrganisation from '../../selectOrganisation/SelectOrganisation';
import SelectUser from '../../selectUser/SelectUser';
import ProcurementContact from '../../ProcurementContact/ProcurementContact';
import {PROJECT_ORGANISATION_ROLE as orgaRole} from '../../../utils/constant';
import {MenuItem, TextField} from '@material-ui/core';
import Icon from 'front-onceforall-core/dist/components/Icon';

export class ProjectInviteMandatoryView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      selectedOrga: null,
      selectedUser: null,
      selectedRole: orgaRole.PROJECT_CLIENT,
      procurementContact: null,
      invitee: null
    };
  }

  needProcurementContact() {
    return this.props.projectDetails?.publicProcurement;
  }

  sendSelectInvitee() {
    if (this.state.invitee && (!this.needProcurementContact() || this.state.procurementContact)) {
      this.props.onSelectInvitee(this.state.invitee);
    }
  }

  handleSubmitCallback() {
    const invitee = {
      ...this.state.selectedOrga,
      role: this.state.selectedRole
    };

    if (this.state.selectedUser) {
      invitee.user = this.state.selectedUser;
    }

    this.setState({invitee}, () => this.sendSelectInvitee());
  }

  handleOrganisationSelect(organisation) {
    // checks if the organisation is unknown to skip the user selection
    if (organisation && !organisation?.id) {
      const invitee = {
        ...organisation,
        role: this.state.selectedRole
      };
      this.setState({
        selectedOrga: organisation,
        selectedUser: null,
        invitee: invitee
      }, () => this.sendSelectInvitee());
    } else {
      this.setState({selectedOrga: organisation});
    }
  }

  handleSelectedRole(selectedRole) {
    this.setState({selectedRole});
  }

  handleUserSelect(user) {
    const mandatoryInvitedUser = user ? {
      id: user?.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber
    } : null;

    if (mandatoryInvitedUser) {
      this.setState(s => ({
        selectedUser: mandatoryInvitedUser,
        invitee: {
          ...s.selectedOrga,
          role: s.selectedRole,
          user: mandatoryInvitedUser
        }
      }), () => this.sendSelectInvitee());
    } else {
      this.setState({
        selectedUser: null
      });
    }
  }

  handleProcurementContact(pc) {
    this.props.onProcurementContact(pc);
    this.setState({
      procurementContact: pc
    }, () => this.sendSelectInvitee());
  }

  render() {
    return (
      <>
        <div data-id="notice-orga" className="py-4">
          <b><Translate value="project.invite.noticeRoleInvite" /></b>
        </div>
        <div className="flex-container align-items-baseline">
          <div className="col-2 mt-4">
            <TextField
              select
              data-id="mandatory-role"
              value={this.state.selectedRole}
              onChange={event => this.handleSelectedRole(event.target.value)}
            >
              <MenuItem value={orgaRole.PROJECT_CLIENT}>
                <Translate value={`project.role.${orgaRole.PROJECT_CLIENT}.title`} />
              </MenuItem>
              <MenuItem value={orgaRole.PROJECT_CLIENT_DELEGATE}>
                <Translate value={`project.role.${orgaRole.PROJECT_CLIENT_DELEGATE}.title`} />
              </MenuItem>
            </TextField>
          </div>
          <div className="col-9">
            <SelectOrganisation
              organisation={this.props.organisation}
              selectedOrganisation={this.state.selectedOrga}
              onSelectOrganisation={orga => this.handleOrganisationSelect(orga)}
              selectedOrganisationRole={this.state.selectedRole}
              isRequired
            />
          </div>
        </div>
        {this.state.selectedOrga?.id &&
          <SelectUser
            organisation={this.props.organisation}
            selectedUser={this.state.selectedUser}
            onSelectUser={user => this.handleUserSelect(user)}
            invitedOrgaName={this.state.selectedOrga.fullName}
          />
        }
        {// Check if the orga is selected and unknown or with a user to display validate button
          (!this.needProcurementContact() && this.state.selectedOrga && (!this.state.selectedOrga?.id || this.state.selectedUser)) &&
          <div className="actions mt-5">
            <button
              data-id="invite-mandatory"
              className="large primary inline-container"
              type="submit"
              onClick={() => this.handleSubmitCallback()}
            >
              <Translate value="action.confirm" />
              <Icon icon="check" />
            </button>
          </div>
        }
        { this.needProcurementContact() && <ProcurementContact
          onProcurementContact={c => this.handleProcurementContact(c)}
          organisation={this.props.organisation}
        />
        }
      </>
    );
  }
}

ProjectInviteMandatoryView.propTypes = {
  organisation: PropTypes.object.isRequired,
  onSelectInvitee: PropTypes.func.isRequired,
  projectDetails: PropTypes.object,
  onProcurementContact: PropTypes.func.isRequired
};

export default ProjectInviteMandatoryView;

