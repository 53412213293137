import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import SelectOrganisation from '../../selectOrganisation/SelectOrganisation';
import SelectUser from '../../selectUser/SelectUser';
import SelectedInviteBlock from './selectedInviteBlock/';
import {MenuItem, TextField, Tooltip} from '@material-ui/core';
import {Icon} from 'front-onceforall-core';

const classes = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    background: '#fff',
    position: 'relative'
  },
  line: {
    height: '1px',
    content: '',
    width: '90%',
    position: 'absolute',
    backgroundColor: '#000',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  text: {
    background: '#fff',
    position: 'relative',
    padding: '0 30px'
  }
};

export const ProjectInviteOptionalView = props => {
  const [value, setValue] = useState('');
  const [selectedRole, setSelectedRole] = useState(props.remainingRoles[0]);
  const [selectedOrga, setSelectedOrga] = useState(null);
  const [shouldShowForm, setShouldShowForm] = useState(false);
  const [invitees, setInvitees] = useState([]);
  const [availableRoles, setAvailableRoles] = useState(props.remainingRoles);
  const blockValidate = Boolean(value);

  const removeSelectedInvitee = organisation => {
    let array = [...invitees];
    const index = array.findIndex(orga => orga.role === organisation.role);
    if (index !== -1) {
      array.splice(index, 1);
      setInvitees(array);
      setAvailableRoles(roles => [...roles, organisation.role]);
    }
  };

  const updateAvailableRoles = role => {
    let array = [...availableRoles];
    const index = array.indexOf(role);
    if (index !== -1) {
      array.splice(index, 1);
      setAvailableRoles(array);
    }
  };

  const validateStatesOnFormConfirm = selection => {
    setInvitees(list => [...list, selection]);
    setValue('');
    setSelectedOrga(null);
    setSelectedRole(null);
    updateAvailableRoles(selection.role);
    setShouldShowForm(false);
  };

  const handleOrganisationSelect = (organisation, role) => {
    setSelectedOrga(organisation);

    if (organisation && !organisation?.id) {
      const orgaInvite = {
        ...organisation,
        role: role
      };

      validateStatesOnFormConfirm(orgaInvite);
    }
  };

  const handleUserSelect = user => {
    const optionalInvitedUser = user ? {
      id: user?.id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phoneNumber: user.phoneNumber
    } : null;

    if (optionalInvitedUser) {
      const invitee = {
        ...selectedOrga,
        role: selectedRole,
        user: optionalInvitedUser
      };

      validateStatesOnFormConfirm(invitee);
    }
  };

  React.useEffect(() => {
    if (!props.selectedInvitees.length) {
      setInvitees([]);
      setSelectedOrga(null);
      setSelectedRole(props.remainingRoles[0]);
      setValue('');
    }
  }, [props.selectedInvitees]);

  React.useEffect(() => {
    if (props.remainingRoles.length > 0) {
      setSelectedRole(props.remainingRoles[0]);
      setAvailableRoles(props.remainingRoles);
    }
  }, [props.remainingRoles]);

  React.useEffect(() => {
    if (availableRoles.length > 0) {
      setSelectedRole(availableRoles[0]);
    }
  }, [availableRoles]);

  const getInviteBlock = (organisation, role, key) => {
    return (
      <div key={key}>
        {availableRoles.includes(role) ?
          <div className="box border none">
            <div data-id="notice-orga" className="py-4">
              <b><Translate value="project.invite.noticeRoleInvite"/></b>
            </div>
            <div className="flex-container align-items-baseline">
              <div className="col-2 mt-4">
                <TextField
                  select
                  data-id="optional-role"
                  value={role}
                  onChange={event => setSelectedRole(event.target.value)}
                >
                  {availableRoles.map((role, index) => (
                    <MenuItem key={index} value={role}>
                      <Translate value={`project.role.${role}.title`} />
                    </MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-9">
                <SelectOrganisation
                  organisation={props.organisation}
                  selectedOrganisation={organisation}
                  onChangeValue={setValue}
                  onSelectOrganisation={orga => handleOrganisationSelect(orga, role)}
                  selectedOrganisationRole={role}
                />
              </div>
            </div>
            {organisation?.id &&
              <SelectUser
                organisation={props.organisation}
                selectedUser={organisation.user}
                onSelectUser={user => handleUserSelect(user)}
                invitedOrgaName={organisation.fullName}
              />
            }
          </div> :
          organisation &&
            <div className="box border none">
              <SelectedInviteBlock
                invitee={organisation}
                onRemoveInviteeClick={removeSelectedInvitee}
              />
            </div>
        }
      </div>
    );
  };

  let inviteButton = <button
    data-id="invite-optional"
    className="large primary inline-container"
    type="submit"
    disabled={blockValidate}
    style={blockValidate ? {pointerEvents: 'none'} : {}}
    onClick={() => {
      props.onSelectInvitees(invitees);
    }}
  >
    <Translate value="project.invite.confirmOptionalsInviteButton" inviteNumber={invitees.length}/>
    <Icon icon="check"/>
  </button>;

  return (
    <>
      {invitees.length > 0 &&
          <>
            {invitees.map((invitee, index) => getInviteBlock(invitee, invitee.role, index))}
            {!shouldShowForm && availableRoles.length > 0 &&
              <div data-id="add-new-invitee" style={classes.container} className="clickable" onClick={() => {
                setSelectedRole(availableRoles[0]);
                setShouldShowForm(true);
              }}>
                <div style={classes.line}/>
                <p className="flex-container" style={classes.text} >
                  <Translate value="project.invite.addNewInvitee"/><Icon icon="rounded-plus"/>
                </p>
              </div>
            }
          </>
      }
      {(availableRoles.length > 0 && (shouldShowForm || invitees.length === 0)) && getInviteBlock(selectedOrga, selectedRole)}
      <div className="actions mt-5">
        {blockValidate ?
          <Tooltip
            data-id="invite-optional-disabled-tooltip"
            title={<Translate value="project.invite.missingOrgaTooltip"/>}
          >
            <span>{inviteButton}</span>
          </Tooltip> : inviteButton}
      </div>
    </>
  );
};

ProjectInviteOptionalView.propTypes = {
  organisation: PropTypes.object.isRequired,
  onSelectInvitees: PropTypes.func.isRequired,
  selectedInvitees: PropTypes.array.isRequired,
  remainingRoles: PropTypes.array.isRequired
};

export default ProjectInviteOptionalView;

