/* eslint-disable camelcase */

export default {
  menu: {
    projects: 'Les opérations',
    requests: 'Les demandes',
    dashboard: 'Tableau de bord',
    users: 'Les utilisateurs',
    organisation: 'L\'entreprise',
    settings: 'Paramètres',
    home: 'Tableau de bord',
    news: 'Le Mag\'',
    help: 'Aide et Contact',
    profile: 'Mon profil'
  },
  initialLoading: {
    message: 'Un instant, PanDA démarre ...',
    error: 'Nous sommes désolés de l\'attente. Nos développeurs vont analyser le problème.'
  },
  datetime: {
    datePickerMask: ['d', 'd', '/', 'd', 'd', '/', 'd', 'd', 'd', 'd'],
    shortDate: 'DD/MM/YYYY',
    shortDatetime: 'DD/MM/YYYY HH:mm',
    undefined: '-'
  },
  dashboard: {
    myActiveActions: 'Mes actions à traiter',
    empty: {
      title: 'Félicitations !',
      message: 'Vous avez traité toutes vos actions'
    },
    notification: {
      user: {
        pending: 'L\'utilisateur __s%{firstName} %{lastName}__s souhaite rejoindre votre entreprise.'
      },
      actions: {
        acceptRequest: 'Accepter cette demande',
        acceptRequestSuccess: 'L\'utilisateur a rejoint l\'entreprise',
        declineRequest: 'Décliner cette demande',
        declineRequestSuccess: 'L\'utilisateur n\'a pas rejoint l\'entreprise'
      }
    }
  },
  project: {
    projects: 'Opérations',
    noProjects: 'Aucune opération trouvée',
    unlimitedRfa: 'Formule tout compris',
    noCredits: 'Vous n’avez pas assez de crédits opération. Veuillez contacter le service client PanDA au 04 72 38 32 58 afin de créditer votre compte.',
    startFrom: 'Débute le __s%{openingDate}__s',
    page: {
      backToProjects: 'Retour à la liste des opérations',
      participants: 'Participants',
      requests: 'Demandes',
      deleteInformationModal: {
        title: 'Supprimer l\'opération',
        content: 'Êtes-vous sûr de vouloir supprimer cette opération ?'
      },
      deleteSuccess: 'L\'opération a été supprimée',
      buttonsDisabledTooltip: 'L\'opération ne doit pas comprendre de demande d\'agrément'
    },
    create: {
      button: 'Créer une nouvelle opération',
      title: 'Créer une opération',
      confirm: 'Créer l\'opération',
      creationSuccess: 'L\'opération %{name} a été créée!',
      step: 'Étape %{currentStep}',
      step1: 'Détails',
      step2: 'Documents',
      step3: 'Rôle',
      step4: 'Invitation',
      step5: 'Invitation',
      summary: 'Récapitulatif'
    },
    edit: {
      enabledTooltip: 'Toute modification de l\'opération entraîne un rejet des demandes d\'agrément en cours de signature. Toutefois, elle ne s\'appliquera pas à celles déjà validées',
      success: 'L\'opération a été mise à jour !',
      remove: 'Suppimer le participant de l\'opération',
      participants: {
        failure: 'Une erreur est survenue lors de la modification des participants.',
        part1: 'Une seule modification (un seul participant) à la fois est possible pour le moment.',
        part2: 'Dans le cas d\'une invitation d\'entreprise, après validation des changements, il faudra rafraichir la page pour la voir apparaître dans la colonne des participants.'
      },
      invitation: {
        add: {
          failure: 'Une erreur est survenue lors de l\'invitation d\'un des participants.'
        },
        remove: {
          success: 'L\'invitation du participant a bien été supprimée',
          failure: 'L\'invitation du participant n\'a pas pu être supprimée'
        }
      }
    },
    details: {
      title: 'Détails',
      name: 'Nom de l\'opération',
      ownerOrganisation: 'Propriétaire',
      openingDate: 'Date de DOC',
      closingDate: 'Date de clôture de chantier',
      change: 'Changer les détails de l\'opération',
      publicProcurement: 'Marché public',
      privateProcurement: 'Marché privé',
      publicProcurementTooltip: 'La demande d‘agrément est créée au format Cerfa DC4',
      publicProcurementObject: 'Objet du marché public',
      publicProcurementObjectPlaceholder: 'Contenu de la mention figurant dans l‘avis d‘appel public à la concurrence ou l‘invitation à confirmer l‘intérêt'
    },
    documents: {
      title: 'Documents',
      change: 'Changer les documents de l\'opération',
      mandatoryDocuments: 'Documents Légaux obligatoires (requis par défaut)',
      optionalDocuments: 'Sélectionnez les documents optionnels',
      specificDocuments: 'Sélectionnez les documents spécifiques',
      requiredDocuments: 'Documents requis pour cette opération',
      complementaryPlaceholderAdd: 'Ajouter un type de document',
      complementaryPlaceholder: 'Préciser le document souhaité'
    },
    role: {
      title: 'Rôle',
      change: 'Changer le rôle dans l\'opération',
      notice: 'Veuillez choisir votre rôle dans l\'opération',
      choose: 'Choisir',
      chosenRole: 'Rôle de mon entreprise pour cette opération',
      PROJECT_CLIENT: {
        title: 'Maître d\'ouvrage',
        firstPoint: 'Invitation des participants à l\'opération  (MOAD, AMO, MOE, CSPS)',
        secondPoint: 'Invitation des entreprises principales',
        thirdPoint: 'Validation des demandes d\'agréments',
        tooltip: 'Choisir Maître d\'ouvrage comme rôle pour cette opération'
      },
      PROJECT_CLIENT_DELEGATE: {
        title: 'Maître d’ouvrage délégué',
        firstPoint: 'Invitation des participants à l\'opération (MOA, AMO, MOE, CSPS)',
        secondPoint: 'Invitation des entreprises principales',
        thirdPoint: 'Signature ou validation (s\'il n\'y a pas de MOA) des demandes d\'agrément',
        tooltip: 'Choisir Maître d\'ouvrage délégué comme rôle pour cette opération'
      },
      PROJECT_CLIENT_ASSISTANT: {
        title: 'Assistant à la maîtrise d’ouvrage',
        firstPoint: 'Invitation des participants à l\'opération  (MOA, MOAD, MOE, CSPS)',
        secondPoint: 'Invitation des entreprises principales',
        thirdPoint: 'Signature des demandes d\'agrément',
        tooltip: 'Choisir Assistant à la maîtrise d\'ouvrage comme rôle pour cette opération'
      },
      PROJECT_MANAGER: {
        title: 'Maître d\'oeuvre',
        firstPoint: 'Invitation des participants à l\'opération (MOA, MOAD, AMO, CSPS)',
        secondPoint: 'Invitation des entreprises principales',
        thirdPoint: 'Signature des demandes d\'agrément',
        tooltip: 'Choisir Maître d\'oeuvre comme rôle pour cette opération'
      },
      PROJECT_GENERAL_CONTRACTOR: {
        title: 'Entreprise Principale',
        plural: 'Entreprises Principales'
      },
      PROJECT_GENERAL_CONTRACTOR_OWNER: {
        title: 'Entreprise Générale',
        firstPoint: 'Invitation des participants à l\'opération (MOA, MOAD, AMO, MOE, CSPS)',
        secondPoint: 'Création des demandes d\'agrément',
        thirdPoint: 'Signature des demandes d\'agrément',
        tooltip: 'Choisir Entreprise Générale comme rôle pour cette opération'
      },
      PROJECT_SAFETY_COORDINATOR: {
        title: 'Coordonnateur de Sécurité et de Protection de la Santé'
      }
    },
    invite: {
      mandatory: 'Invitation du __s%{role}__s',
      optional: 'Autres invitations',
      invitations: 'Invitations',
      missingOrgaTooltip: 'Terminer l\'invitation pour confirmer',
      noticeUser: 'Renseignez l\'email de votre contact chez %{orgaName} qui recevra l\'invitation',
      noticeRoleInvite: 'Sélectionnez le rôle de l\'entreprise à inviter pour cette opération',
      mandatoryNoticeFieldError: 'L\'opération requiert un maître d\'ouvrage ou maître d\'ouvrage délégué',
      changeOptionalsButton: 'Modifier les invitations',
      addNewInvitee: 'Inviter une entreprise supplémentaire',
      confirmOptionalsInviteButton: 'Confirmer les invitations (%{inviteNumber})',
      PROJECT_CLIENT: {
        notice: 'Inviter un maître d\'ouvrage pour cette opération',
        invite: 'Inviter l\'organisation "%{name}" en tant que maître d\'ouvrage',
        change: 'Changer le maître d\'ouvrage de l\'opération',
        invitedNotice: '<strong>Maître d\'ouvrage</strong> invité à rejoindre l\'opération'
      },
      PROJECT_CLIENT_DELEGATE: {
        notice: 'Inviter un maître d\'ouvrage délégué pour cette opération',
        invite: 'Inviter l\'organisation "%{name}" en tant que maître d\'ouvrage délégué',
        change: 'Changer le maître d\'ouvrage délégué de l\'opération',
        invitedNotice: '<strong>Maître d\'ouvrage délégué</strong> invité à rejoindre l\'opération'
      },
      PROJECT_CLIENT_ASSISTANT: {
        notice: 'Inviter un assistant à la maîtrise d\'ouvrage pour cette opération',
        invite: 'Inviter l\'organisation "%{name}" en tant qu\'assistant à la maîtrise d\'ouvrage',
        change: 'Changer l\'assistant à la maîtrise d\'ouvrage de l\'opération',
        invitedNotice: '<strong>Assistant à la maîtrise d\'ouvrage</strong> invité à rejoindre l\'opération'
      },
      PROJECT_MANAGER: {
        notice: 'Inviter un maître d\'oeuvre pour cette opération',
        invite: 'Inviter l\'organisation "%{name}" en tant que maître d\'oeuvre',
        change: 'Changer le maïtre d\'oeuvre de l\'opération',
        invitedNotice: '<strong>Maître d\'oeuvre</strong> invité à rejoindre l\'opération'
      },
      PROJECT_GENERAL_CONTRACTOR: {
        button: 'Inviter une Entreprise Principale',
        title: 'Ajouter une Entreprise Principale à cette opération',
        invite: 'Inviter l\'organisation "%{name}" en tant qu\'entreprise principale',
        addSuccess: 'L\'entreprise principale a été ajoutée à l\'opération',
        inviteSuccess: 'L\'entreprise principale %{organisationName} a été invitée'
      },
      PROJECT_SAFETY_COORDINATOR: {
        title: 'Invitation du Coordonnateur de Sécurité et de Protection de la Santé',
        notice: 'Inviter un Coordonnateur de Sécurité et de Protection de la Santé pour cette opération',
        invite: 'Inviter l\'organisation "%{name}" en tant que Coordonnateur de Sécurité et de Protection de la Santé',
        change: 'Changer le coordonnateur de l\'opération',
        invitedNotice: '<strong>Coordonnateur de Sécurité et de Protection de la Santé</strong> invité à rejoindre l\'opération'
      },
      PENDING: {
        PROJECT_MANAGER: 'Maître d\'oeuvre <strong class="%{color}">en attente</strong>',
        PROJECT_CLIENT: 'Maître d\'ouvrage <strong class="%{color}">en attente</strong>',
        PROJECT_CLIENT_DELEGATE: 'Maître d\'ouvrage délégué <strong class="%{color}">en attente</strong>',
        PROJECT_CLIENT_ASSISTANT: 'Assistant à la maîtrise d\'ouvrage <strong class="%{color}">en attente</strong>',
        PROJECT_GENERAL_CONTRACTOR: 'Entreprise principale <strong class="%{color}">en attente</strong>',
        PROJECT_SAFETY_COORDINATOR: 'Coordonnateur de Sécurité et de Protection de la Santé <strong class="%{color}">en attente</strong>',
        RFA_SUBCONTRACTOR: 'Sous-traitant <strong class="%{color}">en attente</strong>',
        RFA_SUBCONTRACTOR_N: 'Sous-traitant de rang %{level} <strong class="%{color}">en attente</strong>',
        tooltip: 'Envoyé à %{firstName} %{lastName} : %{email}'
      }
    },
    remove: {
      generalContractor: {
        invitation: {
          button: 'Supprimer l\'invitation',
          success: 'L\'invitation de l\'entreprise principale a été supprimée de l\'opération.'
        },
        participation: {
          button: 'Supprimer la participation',
          success: 'La participation de l\'entreprise principale a été supprimée de l\'opération.',
          impossible: 'Cette entreprise ne peut pas être supprimée car elle possède au moins une demande d\'agrément.'
        }
      }
    },
    userPermissions: {
      title: 'Modifier les rôles',
      user: 'Utilisateur',
      verificator: 'Vérificateur',
      signatory: 'Signataire',
      verificator_signatory: 'Vérificateur & Signataire',
      none: 'Aucun rôle',
      permissionsError: 'Au moins un viseur et un signataire doivent être présents sur l\'opération.',
      updatePermissionsSuccess: 'Les nouveaux rôles ont bien été pris en compte'
    },
    owner: {
      owner: 'Propriétaire',
      title: 'Modifier le propiétaire',
      updateInfo: 'Dernière mise à jour par __s%{user}__s le __s%{date}__s',
      updateOwnerTagSuccess: 'Le propriétaire a été enregistrer'
    },
    exportExcel: {
      title: 'Exporter au format Excel',
      filename: 'Demandes d\'agréments dans l\'opération (%{projectName}).xlsx',
      tabname: 'Demandes',
      status: 'Statut',
      ownerName: 'Raison sociale créateur de la demande',
      ownerRegistrationNumber: 'SIRET créateur de la demande',
      subcontractorName: 'Raison sociale sous-traitant',
      subcontractorRegistrationNumber: 'SIRET sous-traitant',
      suspensiveCondition: 'Réserve',
      validatedWithCondition: 'Sous réserve',
      suspensiveConditionDetails: 'Motif de réserve',
      nextSigningRole: 'Prochain participant à signer'
    }
  },
  request: {
    requests: 'Demandes',
    createdFrom: 'Créée le <strong>%{creationDate}</strong> par <strong>%{creatorOrganisation}</strong> sur l\'opération <strong>%{projectName}</strong>',
    noRequest: 'Aucune demande',
    noCredits: 'Vous n’avez pas assez de crédits demande d’agrément. Veuillez contacter le service client PanDA au 04 72 38 32 58 afin de créditer votre compte.',
    unlimitedRfa: 'Les demandes d’agréments sont prises en charge par le propriétaire de cette opération, si vous avez des crédits ils ne seront pas utilisés',
    page: {
      dc4: {
        requestLabel: 'DC4',
        requestSubtitle: 'Compléter les informations du DC4',
        completedText: 'Complété',
        unCompletedText: 'A compléter',
        noRights: 'Vous n\'avez pas les droits nécessaires pour compléter le formulaire DC4.',
        action: {
          complete: 'Compléter',
          see: 'Consulter'
        }
      },
      backToRequests: 'Retour à la liste des demandes',
      subtitle: 'Demande d\'agrément <strong class="%{color}">%{status}%{condition}</strong> pour le sous-traitant <strong class="active">%{subcontractor}</strong>',
      downloadPDF: 'Télécharger la demande d\'agrément validée',
      downloadAllDocuments: 'Télécharger tous les documents',
      noDocuments: 'Il n\'y a aucun document',
      refusalMessage: 'L\'entreprise __s%{organisation}__s a refusé de signer la demande le __s%{date}__s pour la raison suivante :',
      rfaRestartMessage: 'À la demande du propriétaire, l\'opération a été modifiée le __s%{date}__s. Cette modification nécessite de resigner la demande d\'agrément.',
      creationDate: 'Date de la demande',
      estimatedDatesTitle: 'Intervention prévue',
      estimatedDates: 'Du %{dateStart} au %{dateEnd} ',
      serviceProvision: 'Détails des prestations',
      amount: 'Montant de la prestation',
      paymaster: 'Paiement par l\'entreprise %{paymasterName}',
      paymasterKnownClient: 'Paiement direct par l\'entreprise %{paymasterName}',
      paymasterUnknownClient: 'Paiement direct par le maître d\'ouvrage',
      parentRequestSubtitle: 'Rappel des prestations sous-traitées initialement à <strong>%{rfaOwnerName}</strong>',
      RFA_SUBCONTRACTOR: 'Sous-traitant',
      RFA_SUBCONTRACTOR_N: 'Sous-traitant de rang %{level}',
      PROJECT_GENERAL_CONTRACTOR: 'Entreprise principale',
      PROJECT_GENERAL_CONTRACTOR_OWNER: 'Entreprise générale',
      PROJECT_CLIENT: 'Maître d\'ouvrage',
      PROJECT_CLIENT_DELEGATE: 'Maître d\'ouvrage délégué',
      PROJECT_CLIENT_ASSISTANT: 'Assistant à la maîtrise d\'ouvrage',
      PROJECT_MANAGER: 'Maître d\'oeuvre',
      details: 'Détails',
      participants: 'Participants',
      documents: 'Documents',
      specificDocuments: 'Documents spécifiques',
      buttonsDisabledTooltip: 'La demande ne doit pas être signée',
      copyRequestUrlTooltip: 'Copier le lien de la demande',
      copyRequestUrlSuccess: 'Lien de la demande ajouté au presse-papier',
      deleteInformationModal: {
        title: 'Supprimer la demande d\'agrément',
        content: 'Êtes-vous sûr de vouloir supprimer cette demande d\'agrément ?'
      },
      deleteSuccess: 'La demande d\'agrément a été supprimée',
      rejectRequest: 'Refuser la demande d\'agrément',
      rejectRequestReasonLabel: 'Veuillez décrire la raison de votre refus',
      rejectRequestSuccess: 'La demande a été refusée',
      signConfirmModal: {
        title: 'Signer la demande',
        content: 'Êtes-vous sûr que le contenu de la demande est correct ?'
      },
      verifyConfirmModal: {
        title: 'Vérifier la demande',
        content: 'Je confirme que le contenu de la demande d’agrément est correct et qu’elle est prête à être signée.'
      },
      suspensiveConditionModal: {
        title: 'Validation de la demande d\'agrément',
        no: 'Je valide cette demande d\'agrément en l\'état',
        yes: 'Je souhaite valider cette demande d\'agrément avec réserve',
        condition: 'Conditions de la réserve'
      },
      validationStateMessage: {
        verified: 'Cette demande a été vérifiée le %{date} par %{user}',
        signed: 'Cette demande a été signée le %{date} par %{user}'
      },
      suspensiveCondition: {
        mention: {
          title: '(Mention de réserve)',
          tooltip: 'Lever la mention de réserve',
          modal: 'Souhaitez-vous lever la mention de réserve pour cette demande d\'agrément ?',
          removal: 'Les conditions de la mention de réserve ont été levées'
        },
        final: {
          title: '(sous réserve)',
          tooltip: 'Lever la réserve',
          modal: 'Souhaitez-vous lever la réserve pour cette demande d\'agrément ?',
          removal: 'Les conditions de la réserve ont été levées'
        }
      }
    },
    create: {
      button: 'Créer une demande d\'agrément',
      title: 'Nouvelle demande d\'agrément',
      creationSuccess: 'La demande d\'agrément a été créée!',
      tooltip: 'Certaines entreprises signataires ne sont pas encore présentes.',
      project: {
        title: 'Opération',
        information1: 'Les opérations affichées sont :',
        information2: '- Les opérations avec demandes illimitées',
        information3: '- Les autres opérations, si crédit suffisant',
        information4: 'Si vous ne trouvez pas une opération à laquelle vous êtes invités alors veuillez contacter le service client PanDA au 04 72 38 32 58.',
        notice: 'Veuillez sélectionner l\'opération concernée par cette demande',
        inputPlaceholder: 'Rechercher une opération',
        inputLoading: 'Chargement des opérations ...',
        change: 'Modifier l\'opération'
      },
      request: {
        title: 'Demande d\'agrément',
        notice: 'Veuillez sélectionner la demande d\'agrément concernée pour l\'opération __s%{projectName}__s',
        inputPlaceholder: 'Rechercher une demande d\'agrément',
        inputLoading: 'Chargement des opérations ...',
        change: 'Modifier la demande',
        suggestion: {
          amount: 'Montant: %{amount} euros',
          date: 'Intervention prévue du %{dateStart} au %{dateEnd}'
        }
      },
      subcontractor: {
        title: 'Sous-traitant',
        notice: 'Veuillez sélectionner un sous-traitant à inviter pour l\'opération __s%{projectName}__s',
        change: 'Modifier le sous-traitant',
        invite: 'Inviter l\'organisation "%{name}" en tant que sous-traitant'
      },
      details: {
        title: 'Détails des prestations',
        change: 'Modifier les détails',
        details: 'Description',
        startingDate: 'Date de début prévisionnelle',
        endingDate: 'Date de fin prévisionnelle',
        amount: 'Montant HT',
        paymaster: {
          title: 'Condition de paiement du sous-traitant',
          RFA_OWNER: 'Paiement par l\'entreprise principale',
          CLIENT: 'Paiement direct par le maître d\'ouvrage'
        },
        edit: {
          paymentDocumentInformation: 'Si un document a été déposé, il sera supprimé.'
        }
      },
      documentsNotice: 'Votre demande d\'agrément a bien été créée ! Vous pouvez dès maintenant fournir les documents requis pour la validation de la demande.'
    },
    update: {
      success: 'La demande d\'agrément a été mise à jour !',
      failed: 'La demande d\'agrément n\'a pas pu être mise à jour !'
    },
    verify: {
      badgeTooltip: 'Vous pouvez vérifier les informations de cette demande',
      success: 'La demande d\'agrément a été vérifiée !'
    },
    documents: {
      title: 'Pièces',
      ownerTitle: 'À fournir par l\'entreprise qui fait la demande',
      subcontractorTitle: 'À fournir par le sous-traitant',
      pandaDocumentsInformation: 'Ces documents n\'ont pas été vérifiés par une plateforme de gestion documentaire.',
      ofaDocumentsInformation: 'Ces documents sont issus de OnceForAll. Ils ne peuvent être mis à jour que par le sous-traitant sur OnceForAll.',
      ofaDocumentUpdateInformationTooltip: 'Les documents ont été actualisés le %{date} à %{time}.',
      ofaDocumentUpdateInformationValidButNotUploadedTooltip: 'Le document n\'a malheureusement pas pu être récupéré. Vous pouvez le retrouver sur OnceForAll.',
      ofaDocumentUpdateDisabledTooltip: 'Impossible d\'actualiser les documents car cette demande d\'agrément a été signée. Les documents ont été actualisés le %{date} à %{time}.',
      ofaDocumentValidityDateStart: 'Du %{startDate}',
      ofaDocumentValidityDateEnd: 'au %{endDate}',
      states: {
        UPLOADED: 'Déposé',
        UPLOADED_AFTER_RFA_VALIDATION: 'Déposé après validation de la demande',
        MISSING: 'Manquant',
        UNSIGNED: 'Non-signé',
        SIGNED: 'Signé',
        VERIFIED: 'Vérifié'
      },
      ofaStates: {
        CREATED: 'Inconnu',
        NOT_APPLICABLE: 'Non applicable',
        AWAITING_GATHERING: 'En cours de collecte',
        MISSING: 'Manquant',
        AWAITING_COMPLETION: 'En attente de renseignement',
        AWAITING_SIGNATURE: 'En attente de signature',
        AWAITING_VALIDATION: 'En attente de vérification',
        VALID: 'Valide',
        REJECTED: 'Refusé',
        EXPIRED: 'Expiré',
        ALMOST_EXPIRED: 'Bientôt expiré',
        SIGNED: 'Signé'
      },
      actions: {
        download: 'Ouvrir',
        upload: 'Déposer',
        update: 'Mettre à jour',
        see: 'Consulter',
        verify: 'Vérifier',
        sign: 'Signer'
      },
      upload: {
        title: 'Envoyer un document',
        notice: 'Veuillez déposer le fichier au format __sPDF__s pour le document __s%{documentName}__s',
        tooltipDisabled: {
          signatureProcessStarted: 'Impossible de déposer un document car cette demande d’agrément est en cours de validation.',
          signatureProcessStartedAndNotFinished: 'Impossible de déposer un document car cette demande est en cours de validation. Cela vous sera à nouveau possible après validation complète de la demande ou refus de celle-ci.'
        },
        uploadedTime: 'Il y a %{time}'
      }
    },
    sign: {
      badgeTooltip: 'C\'est au tour de votre entreprise de signer',
      successMessage: 'Vous avez signé cette demande d\'agrément',
      cancelledMessage: 'Vous avez refusé de signer cette demande d\'agrément'
    },
    status: {
      WAITING_SC: 'en attente du sous-traitant',
      IN_PROGRESS: 'en cours de traitement',
      APPROVED: 'validée',
      awaited: {
        participant: 'chez %{awaitedOrgRole} <strong>%{awaitedOrgName}</strong>',
        self: 'dans <strong>votre entreprise</strong>',
        RFA_SUBCONTRACTOR: 'le sous-traitant',
        RFA_PARENT_SUBCONTRACTOR: 'le sous-traitant',
        PROJECT_GENERAL_CONTRACTOR: 'l\'entreprise principale',
        PROJECT_GENERAL_CONTRACTOR_OWNER: 'l\'entreprise générale',
        PROJECT_CLIENT: 'le maître d\'ouvrage',
        PROJECT_MANAGER: 'le maître d\'oeuvre',
        PROJECT_CLIENT_DELEGATE: 'le maître d\'ouvrage délégué',
        PROJECT_CLIENT_ASSISTANT: 'l\'assistant à la maîtrise d\'ouvrage'
      }
    }
  },
  document: {
    chooseOnComputer: 'Choisissez sur votre ordinateur',
    dragDropHere: 'Glisser et déposer ici votre document',
    filename: 'Nom du fichier : '
  },
  organisation: {
    accessCode: 'Code d\'accès',
    businessLine: {
      title: 'Secteur d\'activité',
      CLEANING: 'Nettoyage',
      CONSTRUCTION: 'BTP - Construction',
      OTHER: 'Autre secteur',
      SAFETY: 'Sécurité privée',
      TEMPORARY_WORK: 'Travail temporaire',
      TRANSPORTATION: 'Transports'
    },
    registrationNumber: 'Numéro de SIRET',
    fullName: 'Raison sociale',
    viewDetails: 'Voir les détails',
    details: 'Détails de l\'entreprise',
    remainingUserAccounts: '%{count} utilisateurs restants',
    users: 'Utilisateurs',
    create: {
      title: 'Renseigner mon entreprise',
      termsAccepted: 'J\'ai lu et j\'accepte les conditions d\'utilisation *',
      privacyPolicyAccepted: 'J\'ai lu et j\'accepte la politique de confidentialité *',
      requiredNotice: '* Champs obligatoires',
      step1: 'Étape 1',
      step1Text: 'Entrer mon SIRET',
      step2: 'Étape 2',
      step2Text: 'Vérifier ou compléter les informations',
      errors: {
        sirenNotMatching: 'Le SIREN ne correspond pas au SIRET renseigné'
      }
    },
    page: {
      credits: 'Les crédits',
      remainingProjectCredits: 'crédits opération restants',
      remainingRequestForApprovalCredits: 'crédits demande d\'agrément restants',
      modifyCredits: 'Modifier les crédits',
      projectCreditsModifier: 'Crédits opération restants :',
      requestForApprovalCreditsModifier: 'Crédits demande d\'agrément restants :',
      updateSuccess: 'Les crédits de %{organisationName} ont été modifiés'
    },
    search: {
      placeholder: 'Raison sociale, SIREN, SIRET ou TVA intracommunautaire',
      notfound: 'Cette entreprise ne vous est pas rattachée',
      alreadySelected: 'Cette entreprise est déjà sélectionnée',
      alreadyPartOfProject: 'Cette entreprise fait déjà partie de l\'opération',
      alreadySubcontractor: 'Cette entreprise fait déjà partie de vos fournisseurs',
      alreadyClient: 'Cette entreprise fait déjà partie de vos clients',
      alreadyInvited: 'Vous avez déjà invité ce fournisseur',
      self: 'Ne vous ajoutez pas vous-même !',
      specifySearch: 'Il y a peut-être plus de 10 résultats. Précisez votre recherche.',
      lastSelected: 'Dernières organisations consultées :'
    },
    select: {
      myOrganisations: 'Mes entreprises',
      title: 'Rechercher une entreprise',
      all: 'Toutes vos entreprises',
      showAll: 'Voir toutes les entreprises',
      notFound: 'Aucune organisation n\'a été trouvée',
      selectOrganisation: {
        button: 'Inviter l\'organisation "%{name}"'
      }
    },
    created: 'Cette organisation a été créée.',
    isUserLinkRequestSuccessful: 'Vous avez rejoint l\'entreprise',
    createOrganisation: 'Sélectionner une entreprise',
    createOrganisationFoundInProvider: 'Cette entreprise n\'existe pas encore sur PanDA. Créez-la en cliquant sur "Continuer".',
    createOrganisationFound: 'Cette entreprise existe déjà sur PanDA. Cliquez sur "Continuer" pour la rejoindre.',
    createOrganisationNotFound: 'Cette entreprise n\'est pas connue. Veuillez vérifier votre numéro SIRET.',
    createOrganisationDeactivated: '%{organisationName} est inactive. Vous ne pouvez pas inscrire cette entreprise.',
    inviteOrganisationDeactivated: '%{organisationName} est inactive. Vous ne pouvez pas inviter cette entreprise.',
    inactive: 'Cette entreprise est inactive. Veuillez contacter notre service client pour plus d\'informations.',
    isUserLinkRequestPending: {
      line1: 'Votre demande est en cours de traitement.',
      line2: 'Vous serez notifié par email une fois le traitement de votre demande terminé.'
    }
  },
  invite: {
    title: 'Inviter une organisation',
    organisationName: 'Entreprise',
    email: 'Email',
    firstName: 'Prénom',
    lastName: 'Nom',
    customMessage: 'Message',
    inviteSuccess: 'L\'organisation %{organisationName} a été invitée',
    inviteFailure: 'Une erreur est survenue lors de l\'invitation',
    notFound: 'Les informations de votre invitation n\'ont pas été trouvées',
    organisationNotFound: 'Cette entreprise n\'est pas connue. Veuillez vérifier le numéro SIRET.'
  },
  address: {
    addressLine: 'Adresse',
    city: 'Ville',
    postCode: 'Code postal',
    country: 'Pays'
  },
  language: {
    fr: 'Français',
    en: 'English'
  },
  registrationNumber: {
    FRANCE: {
      name: 'SIRET',
      tooltip: 'Numéro à 14 chiffres'
    },
    GERMANY: {
      name: 'Betriebsnummer',
      tooltip: 'Numéro à 8 chiffres'
    }
  },
  identificationNumber: {
    VAT_IDENTIFICATION_NUMBER: {
      name: 'TVA intracommunautaire',
      tooltip: 'FR + Numéro à 11 chiffres'
    },
    GROUP_NUMBER: {
      name: 'SIREN',
      tooltip: 'Numéro à 9 chiffres'
    }
  },
  subscription: {
    credits: {
      error: 'Oups ! Nous rencontrons actuellement un problème concernant les crédits, merci de renouveler l\'opération ultérieurement.'
    }
  },
  action: {
    confirm: 'Valider',
    cancel: 'Annuler',
    continue: 'Continuer',
    returnToHome: 'Aller vers la page d\'accueil',
    logout: 'Déconnexion',
    createAccount: 'Création du compte',
    resendEmail: 'Renvoyer l\'email d\'activation',
    invite: 'Inviter',
    confirmAndInvite: 'Valider et inviter',
    expandDetails: 'Plus de détails',
    collapseDetails: 'Moins de détails',
    settings: 'Profil',
    organisation: 'Entreprise',
    openSwitchOrganisation: 'Sélectionner une entreprise :',
    close: 'Fermer',
    back: 'Retour',
    delete: 'Supprimer',
    yes: 'Oui',
    no: 'Non'
  },
  error: {
    generic: 'Une erreur est survenue',
    required: 'Ce champ est requis',
    organisationNotFound: 'Aucune entreprise n\'existe avec ce numéro de SIRET',
    validation: 'La valeur saisie n\'est pas valide',
    organisationAlreadyExists: 'L\'entreprise est déjà présente sur l\'opération',
    notYetSupported: 'Cette fonctionnalité n\'est pas encore disponible',
    clientAlreadyExists: 'Impossible d\'ajouter plus d\'un maître d\'ouvrage, veuillez vérifier votre saisie',
    registrationNumberAlreadyUsed: 'Une organisation avec ce numéro d\'identification existe déjà',
    BOUserLinkForbidden: 'Un utilisateur du BO ne peut pas se rattacher à une organisation',
    userAlreadyExists: 'Un compte avec cette adresse email existe déjà',
    userAlreadyExistsOnOfa: 'Un compte avec cette adresse email existe déjà sur la plateforme OnceForAll. Veuillez vous <a href="%{link}">connecter via OnceForAll</a>.',
    userAlreadyLinked: 'Votre compte est déjà lié à cette organisation',
    userAlreadyRequestedToJoinOrganisation: 'Une demande est déjà en cours',
    emailConfirmation: 'L\'email de confirmation ne correspond pas à l\'email renseigné',
    passwordConfirmation: 'Le mot de passe de confirmation ne correspond pas au mot de passe renseigné',
    invalidDate: 'Le format de la date est erroné',
    invalidEmailFormat: 'Le format de l\'email est erroné',
    invalidPhoneNumber: 'Le format du numéro de téléphone est erroné',
    invalidMobilePhoneNumber: 'Le format du numéro de téléphone mobile est erroné',
    invalidNumberFormat: 'Ce champ doit contenir des chiffres',
    invalidRegistrationNumber: {
      FRANCE: 'Format du numéro SIRET invalide',
      GERMANY: 'Format du numéro Betriebsnummer invalide'
    },
    invalidAmount: 'Format du montant invalide',
    CGURequired: 'Les CGU doivent être acceptées',
    privacyPolicyRequired: 'La politique de confidentialité doit être acceptée',
    invalidIdentificationNumber: 'Numéro d\'identification incorrect',
    documentWrongSize: 'La taille du fichier doit être entre 1KB et 15MB',
    documentWrongType: 'Le fichier doit être au format PDF',
    documentUnknown: 'Impossible de déposer le fichier ! Veuillez réessayer ou changer de fichier.',
    insufficientCredits: 'Vous n’avez pas assez de crédits.',
    signatureBlockUpload: 'Impossible de déposer un document car cette demande d\'agrément a été signée',
    organisationIdentityRespondingWithError: 'Impossible de récupérer les informations de l\'entreprise pour le moment. Vous pouvez les compléter vous-même ou réessayer plus tard.'
  },
  users: {
    users: 'Utilisateurs',
    organisation: {
      linkingDate: 'Date d\'accès',
      actions: 'Actions',
      noUsers: 'Aucun utilisateur n\'a été trouvé'
    },
    status: {
      LINKED: 'Membre',
      PENDING: 'En attente',
      DEACTIVATED: 'Désactivé'
    },
    actions: {
      acceptPendingUserRequest: 'Accepter cette demande',
      acceptPendingUserRequestSuccess: 'L\'utilisateur a rejoint l\'entreprise',
      declinePendingUserRequest: 'Décliner cette demande',
      declinePendingUserRequestSuccess: 'L\'utilisateur n\'a pas rejoint l\'entreprise',
      unlinkUser: 'Supprimer son accès',
      unlinkUserImpossibility: 'L\'accès de l\'utilisateur ne peut être supprimé',
      unlinkUserLastMandatoryRoles: 'L\'accès ne peut pas être supprimé car l’utilisateur est le dernier à être Vérificateur ou Signataire sur une opération',
      unlinkUserSuccess: 'L\'utilisateur n\'a plus accès à l\'entreprise',
      unlinkOneselfSuccess: 'Vous n\'avez plus accès à l\'entreprise'
    }
  },
  user: {
    account: 'Informations personnelles',
    formTitle: 'Inscrivez-vous gratuitement !',
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Email',
    emailConfirmation: 'Confirmer l\'email',
    password: 'Mot de passe',
    passwordConfirmation: 'Confirmer le mot de passe',
    securePasswordPolicy: {
      tooltip: 'Utilisez au moins 8 caractères avec au moins 1 minuscule, 1 majuscule et 1 chiffre',
      atLeast8Characters: 'Au moins 8 caractères',
      atLeast1Lowercase: 'Au moins 1 minuscule',
      atLeast1Uppercase: 'Au moins 1 majuscule',
      atLeast1Digit: 'Au moins 1 chiffre'
    },
    myProfile: 'Données',
    canNotUpdateProfile: 'Pour modifier votre profil, veuillez ',
    canNotUpdateProfileLinkText: 'contacter le service client.',
    language: 'Langue',
    phoneNumber: 'Numéro de téléphone',
    mobilePhoneNumber: 'Numéro de portable',
    changeEmailButton: 'Changer l\'adresse email',
    position: 'Poste',
    invite: {
      title: 'Ajouter un utilisateur à l\'entreprise',
      button: 'Ajouter un utilisateur',
      tooltip: 'Vérifier l\'email pour valider',
      checkEmail: 'Vérifier',
      canAdd: 'Cet utilisateur est connu. Vous pouvez l’inviter.',
      self: 'Ne vous ajoutez pas vous-même !',
      notRecognized: 'Cet utilisateur ne possède pas de compte sur PanDA. Vous pouvez l\'inviter à rejoindre la plateforme.',
      internalUser: 'Vous ne pouvez pas ajouter ce type d\'utilisateur à rejoindre votre entreprise.',
      alreadyPartOfOrganisation: 'Cet utilisateur a déjà accès à votre entreprise.',
      alreadyWaitingToJoin: 'Cet utilisateur a déjà demandé à rejoindre votre entreprise.',
      addSuccess: 'L\'utilisateur a été ajouté',
      inviteSuccess: 'Un email d\'invitation a été envoyé à %{email}',
      inviteTooltip: 'Un email d\'invitation sera envoyé pour rejoindre PanDA'
    }
  },
  procurementContact: {
    title: 'Identifier la personne habilitée à donner les renseignements pour cette opération',
    firstName: 'Prénom',
    lastName: 'Nom',
    email: 'Email',
    phoneNumber: 'Numéro de téléphone',
    jobTitle: 'Fonction'
  },
  activateUser: {
    message: 'Nous avons envoyé un email à %{email}',
    subMessage: 'Veuillez cliquer sur le lien de confirmation pour activer votre compte.',
    resendMessage: 'Je n\'ai pas reçu d\'email, ',
    button: 'renvoyer l\'email d\'activation',
    messageLink: 'J\'ai déjà cliqué sur le bouton, ',
    linkLabel: ' accéder à mon compte',
    emailSent: 'Nous avons envoyé un nouvel email'
  },
  textsToValidate: {
    termsAndConditions: 'Conditions générales d\'utilisation',
    privacyPolicy: 'Politique de confidentialité',
    title: 'Votre plateforme évolue, les Conditions Générales d’Utilisation qui l’accompagnent également.',
    text: 'La principale évolution réside dans leur simplification et la séparation en deux textes distincts afin de vous en faciliter la lecture et la compréhension :',
    button: 'Accepter',
    accept: {
      termsAndConditions: 'Les Conditions Générales d’Utilisation, portent sur l’utilisation et l’accès à la plateforme',
      privacyPolicy: 'La Politique de Confidentialité vous donne une meilleure visibilité sur la gestion et le traitement de vos données personnelles'
    },
    tooltip: 'Cliquez pour ouvrir le texte dans un nouvel onglet',
    subtext: 'Nous vous invitons à prendre connaissance de ces documents et les valider avant l’utilisation de nos services. Nous restons à votre écoute pour toute précision.'
  },
  linkToOfa: {
    title: 'Lier les comptes PanDA et OnceForAll',
    text: 'Vous avez un compte PanDA et un compte OnceForAll à l\'adresse suivante : <strong>%{email}</strong>',
    subtext: 'Nous vous conseillons de lier les deux pour bénéficier de certaines fonctionnalités.',
    button: 'Lier les comptes'
  },
  message: {
    moreResults: 'Afficher %{results} autres résultats',
    pageAccessDenied: 'Désolé, vous n’avez pas accès à cette page.',
    pageNotFound: 'Désolé, la page que vous demandez est introuvable.'
  },
  pagination: {
    items: 'Éléments',
    of: 'à',
    to: 'sur'
  },
  colon: ' : ',
  publicProcurement: {
    backToRequest: 'Retour à la demande d\'agrément',
    update: {
      success: 'Le formulaire a été mis à jour !',
      failed: 'Le formulaire n\'a pas pu être mis à jour !'
    },
    titles: {
      form: 'Formulaire DC4',
      clientInformation: 'Identification de l’acheteur',
      clientContact: 'Personne habilitée à donner les renseignements',
      publicProcurementObject: 'Objet du marché public',
      requestForApprovalObject: 'Objet de la déclaration du sous-traitant',
      generalContractor: 'Identification du soumissionnaire ou du titulaire du marché public',
      subcontractor: 'Identification du sous-traitant de rang %{count}',
      subcontractor_0: 'Identification du sous-traitant',
      subcontractorContacts: 'Personne(s) physique(s) ayant le pouvoir d’engager le sous-traitant',
      subcontractedServices: 'Nature des prestations sous-traitées',
      personalDataProcessing: 'Sous-traitance de traitement de données à caractère personnel',
      subcontractedServicesPrice: 'Prix des prestations sous-traitées',
      paymentTerms: 'Conditions de paiement',
      subcontractorContractDuration: 'Durée du contrat de sous-traitance en nombre de mois',
      subcontractorSwornStatement: 'Attestations sur l’honneur du sous-traitant au regard des exclusions de la procédure',
      assignmentOrPledge: 'Cession ou nantissement des créances résultant du marché public'
    },
    client: {
      fullName: 'Raison sociale',
      address: 'Adresse',
      registrationNumber: 'SIRET'
    },
    publicProcurementData: {
      clientContact: {
        firstName: 'Prénom',
        lastName: 'Nom',
        jobTitle: 'Fonction',
        email: 'Email',
        phoneNumber: 'Téléphone'
      },
      publicProcurementObject: 'Description de l’objet du marché public'
    },
    requestForApprovalObject: {
      SPECIAL_ACT_AGREEMENT: 'un acte spécial portant acceptation du sous-traitant et agrément de ses conditions de paiement',
      SPECIAL_ACT_MODIFICATION: 'un acte spécial modificatif ; il annule et remplace la déclaration de sous-traitance du',
      originalDate: 'Date de la déclaration précédente'
    },
    contractor: {
      fullName: 'Raison sociale',
      address: 'Adresse',
      registrationNumber: 'SIRET',
      legalForm: 'Forme juridique',
      email: 'Email',
      phoneNumber: 'Téléphone',
      delegateData: 'Mandataire du groupe'
    },
    contact: {
      firstName: 'Prénom',
      lastName: 'Nom',
      jobTitle: 'Fonction'
    },
    isSubcontractorASmallOrganisation: 'Le sous-traitant est-il une micro, une petite ou une moyenne entreprise ?',
    isSubcontractorLinkedToHolder: 'Pour les MDS, le sous-traitant est-il lié au titulaire ?',
    subcontractedServicesDetails: 'Description des prestations sous-traitées',
    personalDataProcessing: {
      services: 'Le sous-traitant est autorisé à traiter les données à caractère personnel nécessaires pour fournir le ou les service(s) suivant(s) :',
      duration: 'La durée du traitement est',
      process: 'La nature des opérations réalisées sur les données est',
      purpose: 'La ou les finalité(s) du traitement sont',
      dataType: 'Les données à caractère personnel traitées sont',
      targets: 'Les catégories de personnes concernées sont',
      declareThat: 'Le soumissionnaire/titulaire déclare que',
      personalDataProtectionAgreement: 'le sous-traitant présente des garanties suffisantes pour la mise en œuvre de mesures techniques et organisationnelles propres à assurer la protection des données personnelles',
      personalDataRegulationAgreement: {
        part1: 'le contrat de sous-traitance intègre les clauses obligatoires prévues par ',
        link: 'l’article 28 du règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016',
        part2: ' relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel et à la libre circulation de ces données et abrogeant la directive 95/46/CE (RGPD)'
      }
    },
    subcontractedServicesLocation: 'Dans les marchés de défense et de sécurité (MDS), lieu d’exécution des prestations sous-traitées',
    subcontractedServicesPrice: {
      details: 'Dans le cas où le sous-traitant a droit au paiement direct, le montant des prestations sous-traitées indiqué ci-dessous, revalorisé le cas échéant par application de la formule de variation des prix indiquée infra, constitue le montant maximum des sommes à verser par paiement direct au sous-traitant.',
      a: 'a) Montant du contrat de sous-traitance dans le cas de prestations ne relevant pas du b)',
      b: {
        start: 'b) Montant du contrat de sous-traitance dans le cas de travaux sous-traités relevant du ',
        link: '2 nonies de l’article 283 du code général des impôts'
      },
      fieldsA: {
        vatRate: 'Taux de TVA',
        amountExcludingVAT: 'Montant HT',
        amountIncludingVAT: 'Montant TTC'
      },
      fieldsB: {
        vatRate: 'Taux de la TVA : auto-liquidation (la TVA est due par le titulaire) :',
        amountExcludingVAT: 'Montant hors TVA'
      }
    },
    priceVariation: 'Modalités de variation des prix',
    directPayment: 'Le titulaire déclare que son sous-traitant remplit les conditions pour avoir droit au paiement direct',
    iban: 'Compte à créditer',
    bankName: 'Nom de la banque',
    accountNumber: 'Numéro de compte',
    advancePayRequired: 'Le sous-traitant demande à bénéficier d’une avance',
    subcontractorContractDurationNotApplicable: 'Sans objet',
    subcontractorContractDurationInMonths: 'La durée du contrat de sous-traitance en nombre de mois est de',
    subcontractorContractDurationDetails: '(Nota : Si la durée indiquée dans le contrat de sous-traitance ne correspond pas à un nombre entier, arrondir au nombre entier supérieur. Ex : 20 jours = 1 mois, 1 mois et 2 semaines = 2 mois, etc.)',
    subcontractorSwornStatement: {
      links: {
        art21411To21415: 'articles L. 2141-1 à L. 2141-5',
        art21417To214110: 'articles L. 2141-7 à L. 2141-10',
        art23411To23413: 'articles L. 2341-1 à L. 2341-3'
      },
      subtitle: 'Le sous-traitant déclare sur l’honneur (*) :',
      line1: {
        part1: 'a) dans l’hypothèse d’un marché public autre que de défense ou de sécurité, ne pas entrer dans l’un des cas d’exclusion prévus aux ',
        part2: ' ou aux ',
        part3: ' du code de la commande publique (**) ;'
      },
      line2: {
        part1: 'b) dans l’hypothèse d’un marché public de défense ou de sécurité, ne pas entrer dans l’un des cas d’exclusion prévus aux ',
        part2: ' ou aux ',
        part3: ' du code de la commande publique.'
      },
      line3: {
        part1: '(*) Lorsqu’un opérateur économique est, au cours de la procédure de passation d’un marché, placé dans l’un des cas d’exclusion mentionnés aux ',
        part2: ', aux ',
        part3: ' ou aux ',
        part4: ' du code de la commande publique, il informe sans délai l’acheteur de ce changement de situation.'
      },
      line4: {
        part1: '(**) Dans l’hypothèse où le sous-traitant est admis à la procédure de redressement judiciaire, son attention est attirée sur le fait qu’il devra prouver qu’il a été habilité à poursuivre ses activités pendant la durée prévisible d’exécution du marché public.'
      }
    },
    subcontractorNotExcludedSwornStatement: 'Afin d’attester que le sous-traitant n’est pas dans un de ces cas d’exclusion, cocher la case',
    assignmentOrPledge: {
      or: 'OU',
      links: {
        art219322: 'l’article R. 2193-22',
        art239340: 'l’article R. 2393-40'
      },
      first: {
        title: '1ère hypothèse',
        isModificationAct: 'La présente déclaration de sous-traitance constitue un acte spécial.',
        details: {
          part1: 'Le titulaire établit qu’aucune cession ni aucun nantissement de créances résultant du marché public ne font obstacle au paiement direct du sous-traitant, dans les conditions prévues à ',
          part2: ' ou à ',
          part3: ' du code de la commande publique.'
        },
        certificates: {
          title: 'En conséquence, le titulaire produit avec le DC4 :',
          withCertificate: 'l’exemplaire unique ou le certificat de cessibilité du marché public qui lui a été délivré',
          withoutCertificate: 'une attestation ou une mainlevée du bénéficiaire de la cession ou du nantissement de créances'
        }
      },
      second: {
        title: '2ème hypothèse',
        isModificationAct: 'La présente déclaration de sous-traitance constitue un acte spécial modificatif.',
        certificates: {
          with: {
            part1: 'le titulaire demande la modification de l’exemplaire unique ou du certificat de cessibilité, prévus à ',
            part2: ' ou à ',
            part3: ' du code de la commande publique, qui est joint au présent DC4 ;'
          },
          without: {
            checkbox: 'l’exemplaire unique ou le certificat de cessibilité ayant été remis en vue d’une cession ou d’un nantissement de créances et ne pouvant être restitué, le titulaire justifie :',
            part1: '-	soit que la cession ou le nantissement de créances concernant le marché public ne fait pas obstacle au paiement direct de la partie sous-traitée,',
            part2: '-	soit que son montant a été réduit afin que ce paiement soit possible.',
            part3: 'Cette justification est donnée par une attestation ou une mainlevée du bénéficiaire de la cession ou du nantissement de créances résultant du marché qui est jointe au présent document.'
          }
        }
      }
    }
  },
  informationBanner: {
    text1: 'Cher(e) adhérent(e),',
    text2: 'Afin d’améliorer votre expérience sur OnceForAll, nous vous informons que votre plateforme sera en maintenance le mardi 2 Avril 2024 de 12h à 14h.',
    text3: 'Nous vous remercions de votre compréhension.',
    text4: 'Le Service Clients.'
  }
};
