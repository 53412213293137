export function dismiss(id) {
  return {
    type: 'dismiss',
    id
  };
}

export function dismissAll() {
  return {
    type: 'dismissAll'
  };
}

export function addSuccess(message) {
  return {
    type: 'add',
    kind: 'success',
    message
  };
}

export function addError(message) {
  return {
    type: 'add',
    kind: 'error',
    message
  };
}
