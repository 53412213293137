import React from 'react';
import PropTypes from 'prop-types';
import ProjectRequestListView from './ProjectRequestListView';
import {organisationService} from '../../../../services/organisation/OrganisationService';
import {requestService} from '../../../../services/request/RequestService';
import {enrollmentService} from '../../../../services/enrollment/EnrollmentService';
import {exportXlsx} from '../../../../utils/exportData';
import {projectPropertiesPropTypes} from '../../../../utils/propTypes';

export const ProjectRequestListContainer = props => {
  const [requests, setRequests] = React.useState([]);
  const [organisations, setOrganisations] = React.useState([]);
  const [invitations, setInvitations] = React.useState([]);
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const checkInvitation = requestId => {
    enrollmentService.getInvitationByRequestId(requestId)
      .then(response => {
        if (response.length > 0) {
          setInvitations(invitations => [...invitations, response[0]]);
        }
      });
  };

  const getOrganisationsList = (request, requestsIdCounter, organisationsIdList) => {
    requestsIdCounter.push(request.id);

    organisationsIdList.push(request.creatorOrganisationId);

    if (request.subcontractorId) {
      organisationsIdList.push(request.subcontractorId);
    } else {
      checkInvitation(request.id);
    }

    if (request.children?.length) {
      request.children.forEach(children => {
        getOrganisationsList(children, requestsIdCounter, organisationsIdList);
      });
    }

    return organisationsIdList;
  };

  const fetchOrganisations = requests => {
    if (requests.length > 0) {
      let requestsIdCounter = [];
      let organisationsIdList = [];
      const project = requests[0].project;
      organisationsIdList.push(project.clientId);
      organisationsIdList.push(project.clientDelegateId);
      organisationsIdList.push(project.clientAssistantId);
      organisationsIdList.push(project.projectManagerId);
      organisationsIdList.push(...project.generalContractorIds);
      requests.forEach(request => {
        getOrganisationsList(request, requestsIdCounter, organisationsIdList);
      });

      organisationService.getOrganisationsList([...new Set(organisationsIdList)].filter(Boolean))
        .then(response => {
          setOrganisations(response);
          setRequestsCount(requestsIdCounter.length);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setRequestsCount(0);
          setRequests([]);
          setOrganisations([]);
          setError(true);
        });
    }
  };

  const fetchProjectRequests = projectId => {
    setLoading(true);

    requestService.findRequestsTreeByProjectId(projectId)
      .then(response => {
        setRequests(response);
        fetchOrganisations(response);
      })
      .catch(() => {
        setLoading(false);
        setRequestsCount(0);
        setRequests([]);
        setError(true);
      });
  };

  function exportRequests() {
    exportXlsx(organisations, invitations, requests);
  }

  React.useEffect(() => {
    if (props.projectId) {
      fetchProjectRequests(props.projectId);
    }
  }, []);

  return (
    <ProjectRequestListView
      organisations={organisations}
      requests={requests}
      invitations={invitations}
      requestsCount={requestsCount}
      project={props.project}
      onDownloadXlsx={exportRequests}
      loading={loading}
      error={error}
    />
  );
};

ProjectRequestListContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    properties: projectPropertiesPropTypes().isRequired
  })
};

export default ProjectRequestListContainer;
