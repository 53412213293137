import config from '../../../config';
import http from '../../utils/http';
import moment from 'moment-timezone';
import {consoleLog} from '../../utils/log';
import jwtDecode from 'jwt-decode';
import {BACKOFFICE_ROLE, DEFAULT_ROLE} from '../../utils/constant';
import {BACK_OFFICE_PROJECT_ROLES_KEY} from '../../components/header/organisationHeader/adminActions/AdminActionsView';

export default class RolesAdapter {
  /**
   * Decoded JWT permission token from api-user-orga
   *
   * @typedef {Object} JwtPermissionToken
   * @property {string} userId - the user ID
   * @property {string} expirationDate - the expiration date of token
   * @property {string} issuedAtTime - the creation date of token
   * @property {('DEFAULT'|'BACK_OFFICE')} role - the global role of user
   * @property {UserOrganisationPermission|null} userOrganisationPermission - the user-organisation permission, when link exists
   */

  /**
   * User-organisation permission
   *
   * @typedef {Object} UserOrganisationPermission
   * @property {string} userId - the user ID
   * @property {number} organisationId - the organisation ID
   * @property {('VERIFICATOR'|'SIGNATORY')[]} globalRoles - the global user roles list in the organisation
   * @property {Object.<number, ('VERIFICATOR'|'SIGNATORY')[]>} rolesByProjectId - the user roles list in each project related to its organisation
   */

  /**
   * Permission token with improved date type and extended metadata
   *
   * @typedef {JwtPermissionToken} PermissionToken
   * @property {moment.Moment} expirationDate - the parsed expiration date of JwtPermissionToken
   * @property {moment.Moment} issuedAtTime - the parsed creation date of JwtPermissionToken
   * @property {number} timeSkew - the skew time between server and client (in ms)
   */

  constructor(store) {
    /** @type Object */
    this.store = store;

    /** @type string|null */
    this.token = null;

    /** @type PermissionToken|null */
    this.decodedToken = null;
  }

  /**
   * update the token if expired or if the organisation has changed, then return the adapter
   * @param minValidity the minimum validity required before update in seconds
   */
  updateToken(minValidity) {
    function decodeToken() {
      /** @type JwtPermissionToken */
      const decodedToken = jwtDecode(this.token);

      // timeSkew is the estimated difference between server and client in millisecond
      const timeSkew = moment().diff(moment(decodedToken.issuedAtTime));
      consoleLog('[ROLES] Estimated time difference between browser and server is ' + timeSkew + ' ms');

      /** @type PermissionToken */
      this.decodedToken = Object.assign({}, decodedToken, {
        issuedAtTime: moment(decodedToken.issuedAtTime),
        expirationDate: moment(decodedToken.expirationDate),
        timeSkew
      });
    }

    function getUserId() {
      return this.store.getState().user?.id;
    }

    function getOrganisationId() {
      return this.store.getState().organisation?.id;
    }

    function shouldFetchToken() {
      // If token not yet fetched then decoded
      if (!this.decodedToken) {
        return true;
      }

      /** @type PermissionToken */
      const decodedToken = this.decodedToken;

      // If is expired...
      if (decodedToken.expirationDate.isBefore(
        moment()
          .add(minValidity, 's')
          .add(decodedToken.timeSkew, 'ms'))) {
        return true;
      }

      // ... Or if the organisation context has changed
      const tokenOrganisationId = decodedToken.userOrganisationPermission?.organisationId || null;
      return getOrganisationId.call(this) !== tokenOrganisationId;
    }

    function fetchToken() {
      const userId = getUserId.call(this);
      const organisationId = getOrganisationId.call(this);

      let url = `${config.userOrgaApi}/api/v1/permissions/${userId}`;
      if (organisationId) {
        url += `/${organisationId}`;
      }

      if (this.getGlobalRole() === BACKOFFICE_ROLE) {
        let projectRolesInLocalStorage;
        try {
          projectRolesInLocalStorage = JSON.parse(localStorage.getItem(BACK_OFFICE_PROJECT_ROLES_KEY)) || null;
        } catch (e) {
          projectRolesInLocalStorage = null;
        }

        if (projectRolesInLocalStorage) {
          url += `?withRoles=${projectRolesInLocalStorage}`;
        }
      }

      return http.authFetch(url, {method: 'GET'}, false)
        .then(http.checkStatus)
        .then(response => response.json());
    }

    return new Promise((resolve, reject) => {
      if (shouldFetchToken.call(this)) {
        fetchToken.call(this).then(response => {
          this.token = response.token;
          decodeToken.call(this);
          resolve(this);
          // @TODO: new Error()
          // eslint-disable-next-line prefer-promise-reject-errors
        }).catch(error => reject({error, userId: getUserId.call(this)}));
      } else {
        resolve(this);
      }
    });
  }

  /**
   * Return the raw token fetch from the server
   */
  getToken() {
    return this.token;
  }

  /**
   * Return the decoded permission token
   * @return PermissionToken|null
   */
  getDecodedToken() {
    return this.decodedToken;
  }

  /**
   * Return global role of the user (DEFAULT or BACK_OFFICE)
   * @return ('DEFAULT'|'BACK_OFFICE')
   */
  getGlobalRole() {
    return this.decodedToken?.role || DEFAULT_ROLE;
  }

  /**
   * Return user roles (VERIFICATOR and/or SIGNATORY) in all projects
   * @return {Object.<number, ('VERIFICATOR'|'SIGNATORY')[]>}
   */
  getAllProjectRoles() {
    return this.decodedToken?.userOrganisationPermission?.rolesByProjectId || {};
  }

  /**
   * Return user roles (VERIFICATOR and/or SIGNATORY) in specific project
   *
   * Back-office users are not linked to any organisation, their roles on every project
   * are located in 'globalRoles' attribute
   *
   * @return ('VERIFICATOR'|'SIGNATORY')[]>
   */
  getProjectRoles(projectId) {
    if (this.getGlobalRole() === BACKOFFICE_ROLE) {
      return this.decodedToken.userOrganisationPermission?.globalRoles || [];
    }

    return projectId !== null && projectId in this.getAllProjectRoles() ?
      this.decodedToken.userOrganisationPermission.rolesByProjectId[projectId] :
      [];
  }
}
