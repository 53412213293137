/* eslint-disable no-console */
import config from '../../config';
import * as Sentry from '@sentry/browser';
import {Severity} from '@sentry/browser';
import configureStore from '../store/configureStore';

const capture = (localLogger, severity, message, error) => {
  if (!config.log) {
    return;
  }

  let messageToPrint = '[Captured error]: ' + message;
  localLogger(messageToPrint, error);

  if (config.log !== 'sentry') {
    return;
  }

  Sentry.withScope(scope => {
    // Add user & organisation information
    const state = configureStore.getStore().getState();
    if (state) {
      if (state.user) {
        scope.setUser({
          id: state.user.id,
          email: state.user.email,
          username: state.user.fullName
        });
      }

      if (state.organisation) {
        scope.setExtra('organisation', state.organisation);
      }
    }

    scope.setTag('message', message);

    Sentry.captureMessage(message, severity);
    if (error) {
      Sentry.captureException(error);
    }
  });
};

export const captureError = (message, error) => capture(console.error, Severity.Error, message, error);
export const captureLog = (message, error) => capture(console.log, Severity.Log, message, error);
export const consoleLog = message => {
  if (config.log) {
    console.log(message);
  }
};

export const consoleError = error => {
  if (config.log) {
    console.error(error);
  }
};
