import {INVITATION_STATUS} from '../constant';
import {
  aClientAssistantInvitation,
  aClientDelegateInvitation,
  aClientInvitation,
  aProjectGeneralContractorInvitation,
  aProjectManagerInvitation,
  aSafetyCoordinatorInvitation,
  aSubcontractorInvitation
} from '../fixtures';
import {findRequest} from './requests';
import {findProject} from './projects';
import {findUser} from './users';

const invitationsFakeData = [
  // REQUESTS
  aSubcontractorInvitation(),
  aSubcontractorInvitation({
    id: 2,
    email: 'test2@email.com',
    firstName: 'firstname2',
    lastName: 'lastname2',
    invitationCode: 'AZERTY2',
    requestForApprovalId: findRequest(1).id
  }),
  aSubcontractorInvitation({
    id: 4,
    status: INVITATION_STATUS.PENDING,
    email: findUser('9231a32d-1163-444d-8587-e2680ee4a3ab').email,
    firstName: 'firstname4',
    lastName: 'lastname4',
    invitationCode: 'AZERTY4',
    requestForApprovalId: findRequest(2).id
  }),
  // PROJECTS
  aProjectGeneralContractorInvitation({
    id: 41,
    email: 'test41@email.com',
    firstName: 'firstname41',
    lastName: 'lastname41',
    invitationCode: 'AZERTY41',
    projectId: findProject(4).id
  }),
  aProjectGeneralContractorInvitation({
    id: 42,
    email: 'test42@email.com',
    firstName: 'firstname42',
    lastName: 'lastname42',
    invitationCode: 'AZERTY42',
    projectId: findProject(4).id
  }),
  aClientInvitation({
    id: 6,
    email: 'test6@email.com',
    firstName: 'firstname6',
    lastName: 'lastname6',
    invitationCode: 'AZERTY6',
    projectId: findProject(6).id
  }),
  aClientAssistantInvitation({
    id: 71,
    email: 'test71@email.com',
    firstName: 'firstname71',
    lastName: 'lastname71',
    invitationCode: 'AZERTY71',
    projectId: findProject(7).id
  }),
  aSafetyCoordinatorInvitation({
    id: 72,
    email: 'test72@email.com',
    firstName: 'firstname72',
    lastName: 'lastname72',
    invitationCode: 'AZERTY72',
    projectId: findProject(7).id
  }),
  aProjectGeneralContractorInvitation({
    id: 12,
    email: 'gc12@email.com',
    firstName: 'GC_firstname12',
    lastName: 'GC_lastname12',
    invitationCode: 'GC_invitationCode12',
    projectId: findProject(12).id
  }),
  aProjectGeneralContractorInvitation({
    id: 131,
    email: 'gc131@email.com',
    firstName: 'GC_firstname131',
    lastName: 'GC_lastname131',
    invitationCode: 'GC_invitationCode131',
    projectId: findProject(13).id
  }),
  aProjectGeneralContractorInvitation({
    id: 132,
    email: 'gc131@email.com',
    firstName: 'GC_firstname132',
    lastName: 'GC_lastname132',
    invitationCode: 'GC_invitationCode132',
    projectId: findProject(13).id
  }),
  aClientInvitation({
    id: 133,
    email: 'client133@email.com',
    firstName: 'C_firstname133',
    lastName: 'C_lastname133',
    invitationCode: 'GC_invitationCode133',
    projectId: findProject(13).id
  }),
  aProjectManagerInvitation({
    id: 311,
    email: 'test311@email.com',
    firstName: 'firstname311',
    lastName: 'lastname311',
    invitationCode: 'AZERTY311',
    projectId: findProject(31).id
  }),
  aProjectGeneralContractorInvitation({
    id: 312,
    email: 'test312@email.com',
    firstName: 'firstname312',
    lastName: 'lastname312',
    invitationCode: 'AZERTY312',
    projectId: findProject(31).id
  }),
  aClientDelegateInvitation({
    id: 313,
    email: 'test313@email.com',
    firstName: 'firstname313',
    lastName: 'lastname313',
    invitationCode: 'AZERTY313',
    projectId: findProject(31).id
  }),
  aClientInvitation({
    id: 401,
    email: 'test401@email.com',
    firstName: 'firstname401',
    lastName: 'lastname401',
    invitationCode: 'AZERTY401',
    projectId: findProject(40).id
  }),
  aClientAssistantInvitation({
    id: 402,
    email: 'test402@email.com',
    firstName: 'firstname402',
    lastName: 'lastname402',
    invitationCode: 'AZERTY402',
    projectId: findProject(40).id
  }),
  aClientDelegateInvitation({
    id: 403,
    email: 'test403@email.com',
    firstName: 'firstname403',
    lastName: 'lastname403',
    invitationCode: 'AZERTY403',
    projectId: findProject(40).id
  }),
  aProjectManagerInvitation({
    id: 404,
    email: 'test404@email.com',
    firstName: 'firstname404',
    lastName: 'lastname404',
    invitationCode: 'AZERTY404',
    projectId: findProject(40).id
  }),
  aSafetyCoordinatorInvitation({
    id: 405,
    email: 'test405@email.com',
    firstName: 'firstname405',
    lastName: 'lastname405',
    invitationCode: 'AZERTY405',
    projectId: findProject(40).id
  })
];

export const getInvitationsFakeData = () => invitationsFakeData;
export const findInvitation = id =>
  Object.assign({}, invitationsFakeData.find(invitation => invitation.invitationId === id));
