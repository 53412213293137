import React from 'react';
import PropTypes from 'prop-types';
import ActionBar from '../../layout/ActionBar';
import ProjectDetails from './projectDetails';
import ProjectDocuments from './projectDocuments';
import ProjectRole from './projectRole';
import ProjectSummary from './projectSummary';
import {Icon, stringUtils} from 'front-onceforall-core';
import {I18n, Translate} from 'react-redux-i18n';
import {Collapse} from '@material-ui/core';
import {PROJECT_ORGANISATION_ROLE as orgaRole} from '../../utils/constant';
import ProjectInviteMandatory from './projectInviteMandatory';
import ProjectInviteOptional from './projectInviteOptional';

export const ProjectCreateView = props => {
  function getMandatoryStep() {
    return (
      <div className="card">
        <div className="flex-container space-between">
          <div className="flex-container">
            <h2 className="m-0">
              {stringUtils.getStrongs(I18n.t('project.invite.mandatory',
                {role: I18n.t(`project.role.${props.mandatoryInvitee?.role || orgaRole.PROJECT_CLIENT}.title`)}))}
            </h2>
            {props.currentStep > 4 && props.mandatoryInvitee &&
              <div data-id="mandatory chip" className="chip-l information-primary ml-4">
                {props.mandatoryInvitee.fullName || props.mandatoryInvitee.organisationName}
                <span className="separator"/>
                {props.mandatoryInvitee?.user?.email || props.mandatoryInvitee?.email}
              </div>
            }
          </div>
          {props.currentStep > 4 &&
            <button
              className="inline-container small secondary"
              data-id="changeClientButton"
              onClick={props.onChangeMandatory}
            >
              {I18n.t(`project.invite.${props.mandatoryInvitee?.role || orgaRole.PROJECT_CLIENT}.change`)}
            </button>
          }
        </div>
        <Collapse in={props.currentStep === 4}>
          <ProjectInviteMandatory
            organisation={props.organisation}
            onSelectInvitee={props.onSelectMandatory}
            selectedInvitee={props.mandatoryInvitee}
            projectDetails={props.projectDetails}
            projectRole={props.projectRole}
            onProcurementContact={props.onDefineProcurementContact}
          />
        </Collapse>
      </div>
    );
  }

  function getOptionalsStep(step) {
    return (
      <div className="card">
        <div className="flex-container space-between">
          <div className="flex-container md-4">
            <h2 className="m-0"><Translate value="project.invite.optional"/></h2>
            {props.currentStep > step && props.optionalInvitees.length > 0 &&
            <div data-id="optionals chip" className="chip-l information-primary ml-4">
              {props.optionalInvitees.length}
            </div>
            }
          </div>
          {props.currentStep > step &&
          <button
            className="inline-container small secondary"
            data-id="changeOptionalsButton"
            onClick={() => props.onChangeOptionals(step)}
          >
            {I18n.t('project.invite.changeOptionalsButton')}
          </button>
          }
        </div>
        <Collapse in={props.currentStep === step}>
          <ProjectInviteOptional
            organisation={props.organisation}
            onSelectInvitees={props.onSelectOptionals}
            selectedInvitees={props.optionalInvitees}
            remainingRoles={props.remainingRoles}
          />
        </Collapse>
      </div>
    );
  }

  const isRoleMandatory = [
    orgaRole.PROJECT_CLIENT,
    orgaRole.PROJECT_CLIENT_DELEGATE
  ].includes(props.projectRole);

  return (
    <div>
      <ActionBar showBackButton>
        <h1 data-id="project-step" className="m-4 ml-6">
          <Translate value="project.create.title"/>
          <span className="separator information">
            <Translate data-id="project-step-value" value="project.create.step" currentStep={props.currentStep}/>
          </span>
        </h1>
      </ActionBar>

      <div className="card">
        <div className="flex-container space-between">
          <div className="flex-container">
            <h2 className="m-0">
              <Translate value="project.details.title"/>
            </h2>
          </div>
          {props.currentStep > 1 &&
          <button
            className="inline-container small secondary"
            data-id="changeDetailsButton"
            onClick={props.onChangeProjectDetails}
          >
            {I18n.t('project.details.change')}
          </button>
          }
        </div>
        <Collapse in={props.currentStep === 1}>
          <ProjectDetails
            organisation={props.organisation}
            onValidate={props.onSelectProjectDetails}
          />
        </Collapse>
      </div>

      <div className="card">
        <div className="flex-container space-between">
          <div className="flex-container">
            <h2 className="m-0"><Translate value="project.documents.title"/></h2>
          </div>
          {props.currentStep > 2 &&
          <button
            className="inline-container small secondary"
            data-id="changeDocumentsButton"
            onClick={props.onChangeProjectRequiredDocuments}
          >
            {I18n.t('project.documents.change')}
          </button>
          }
        </div>
        <Collapse in={props.currentStep === 2}>
          <ProjectDocuments
            onValidate={props.onSelectProjectRequiredDocuments}
          />
        </Collapse>
      </div>

      <div className="card">
        <div className="flex-container space-between">
          <div className="flex-container">
            <h2 className="m-0"><Translate value="project.role.title"/></h2>
            {props.currentStep > 3 &&
            <div data-id="role chip" className="chip-l information-primary ml-4">
              {I18n.t(`project.role.${props.projectRole}.title`)}
            </div>
            }
          </div>
          {props.currentStep > 3 &&
          <button
            className="inline-container small secondary"
            data-id="changeRoleButton"
            onClick={props.onChangeProjectRole}
          >
            {I18n.t('project.role.change')}
          </button>
          }
        </div>
        <Collapse in={props.currentStep === 3}>
          <ProjectRole
            onValidate={props.onSelectProjectRole}
            organisation={props.organisation}
            projectRole={props.projectRole}
            projectDetails={props.projectDetails}
            onProcurementContact={props.onDefineProcurementContact}
          />
        </Collapse>
      </div>

      {isRoleMandatory ? getOptionalsStep(4) :
        <>
          {getMandatoryStep()}
          {getOptionalsStep(5)}
        </>
      }

      <div className="card">
        <div className="flex-container space-between">
          <div className="flex-container">
            <h2 className="m-0"><Translate value="project.create.summary"/></h2>
          </div>
        </div>
        <Collapse in={isRoleMandatory ? props.currentStep === 5 : props.currentStep === 6}>
          <ProjectSummary
            projectDetails={props.projectDetails}
            projectDocuments={props.projectDocuments}
            projectRole={props.projectRole}
            participants={[props.mandatoryInvitee, ...props.optionalInvitees].filter(Boolean)}
          />
          <div className="actions mt-5">
            <button
              className="large primary inline-container"
              type="submit"
              disabled={props.loading}
              onClick={props.onProjectCreation}
            >
              <Translate value="project.create.confirm"/>
              <Icon icon={props.loading ? 'loader' : 'check'}/>
            </button>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

ProjectCreateView.defaultProps = {
  projectDocuments: [],
  optionalInvitees: [],
  remainingRoles: [],
  onSelectProjectDetails: () => {},
  onChangeProjectDetails: () => {},
  onSelectProjectRequiredDocuments: () => {},
  onChangeProjectRequiredDocuments: () => {},
  onSelectProjectRole: () => {},
  onChangeProjectRole: () => {},
  onSelectMandatory: () => {},
  onChangeMandatory: () => {},
  onSelectOptionals: () => {},
  onChangeOptionals: () => {},
  onProjectCreation: () => {},
  onDefineProcurementContact: () => {}
};

ProjectCreateView.propTypes = {
  organisation: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  projectDetails: PropTypes.object,
  projectDocuments: PropTypes.array,
  projectRole: PropTypes.oneOf(Object.values(orgaRole)),
  mandatoryInvitee: PropTypes.object,
  optionalInvitees: PropTypes.array,
  remainingRoles: PropTypes.array,
  onSelectProjectDetails: PropTypes.func.isRequired,
  onChangeProjectDetails: PropTypes.func.isRequired,
  onSelectProjectRequiredDocuments: PropTypes.func.isRequired,
  onChangeProjectRequiredDocuments: PropTypes.func.isRequired,
  onSelectProjectRole: PropTypes.func.isRequired,
  onChangeProjectRole: PropTypes.func.isRequired,
  onSelectMandatory: PropTypes.func.isRequired,
  onChangeMandatory: PropTypes.func.isRequired,
  onSelectOptionals: PropTypes.func.isRequired,
  onChangeOptionals: PropTypes.func.isRequired,
  onProjectCreation: PropTypes.func.isRequired,
  onDefineProcurementContact: PropTypes.func.isRequired
};
