import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as snackbarsActions from '../../actions/snackbars';
import Modal from 'react-modal';
import {Icon, AbstractFormComponent, TextCheckbox} from 'front-onceforall-core';
import {Tooltip} from '@material-ui/core';
import {userService} from '../../services/user/UserService';

export class TextsValidationForm extends AbstractFormComponent {
  constructor(props) {
    super(props);

    this.getCheckboxes = this.getCheckboxes.bind(this);
  }

  componentDidMount() {
    this.setState({loading: false});
  }

  getFormData() {
    let formData = {};
    this.props.texts.forEach(text => {
      formData = {...formData, [text.name]: false};
    });
    return formData;
  }

  getFormValidators() {
    let formValidators = {};
    this.props.texts.forEach(text => {
      formValidators = {...formValidators, [text.name]: this.validateCheckbox.bind(this)};
    });
    return formValidators;
  }

  validateCheckbox(value) {
    if (!value) {
      return 'error.required';
    }
  }

  handleSubmitCallback() {
    const privacyPoliciesRequest = {};
    this.props.texts.forEach(text => {
      privacyPoliciesRequest[text.updateProperty] = true;
    });

    userService.acceptLegalConditions(privacyPoliciesRequest)
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        this.props.actions.addError(<Translate value="error.generic"/>);
      });
  }

  getCheckboxes() {
    const checkboxes = this.props.texts.map(text => {
      const label = (
        <Tooltip title={<Translate value="textsToValidate.tooltip"/>}>
          <a
            href={text.getUrl(this.props.currentLocale)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className={this.isOnError(text.name) ? 'error' : ''}>
              <Translate value={`textsToValidate.accept.${text.name}`}/>
            </span>
          </a>
        </Tooltip>
      );
      return (
        <div key={text.name} className="mt-4">
          <TextCheckbox
            checked={this.state.formData[text.name]}
            onChange={this.handleChange(text.name)}
            error={this.isOnError(text.name)}
            helperText={this.helperText(text.name)}
            name={text.name}
            label={label}
          />
        </div>
      );
    });

    return <>{checkboxes}</>;
  }

  render() {
    return (
      <Modal
        defaultStyles={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          },
          content: {
            backgroundColor: '#fff',
            borderRadius: 4,
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 32px 0px',
            display: 'flex',
            flexDirection: 'column',
            outline: 0,
            overflowY: 'auto',
            padding: 20,
            position: 'relative',
            maxWidth: '100%',
            width: 800
          }
        }}
        isOpen
        onRequestClose={this.props.onClose}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
      >
        <form onSubmit={this.handleSubmit}>
          <h2><Translate value="textsToValidate.title"/></h2>
          <p><Translate value="textsToValidate.text"/></p>
          {this.getCheckboxes()}
          <p className="mt-5"><Translate value="textsToValidate.subtext"/></p>
          <div className="flex-container flex-end mt-4">
            <button
              data-id="accept-button"
              className="primary large flex-container"
              type="submit"
              disabled={this.state.submitting}
            >
              <Translate value="textsToValidate.button"/>
              <Icon icon={this.state.submitting ? 'loader' : 'check'}/>
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

TextsValidationForm.propTypes = {
  texts: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  currentLocale: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    currentLocale: state.i18n.locale,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextsValidationForm);
