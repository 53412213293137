import React from 'react';
import PropTypes from 'prop-types';
import SelectRequestView from './SelectRequestView';
import {captureError} from '../../../utils/log';
import {organisationService} from '../../../services/organisation/OrganisationService';
import {requestService} from '../../../services/request/RequestService';

export const SelectRequestContainer = props => {
  const [loading, setLoading] = React.useState(false);
  const [requests, setRequests] = React.useState([]);
  const [organisations, setOrganisations] = React.useState([]);

  const fetchOrganisations = requests => {
    const organisationsList = [];
    requests.forEach(request => {
      if (!organisationsList.includes(request.creatorOrganisationId)) {
        organisationsList.push(request.creatorOrganisationId);
      }
    });
    return organisationService.getOrganisationsList(organisationsList)
      .then(response => {
        setOrganisations(response);
      });
  };

  React.useEffect(() => {
    setLoading(true);

    requestService.findValidatedSCRequestsByProjectId(props.project.id)
      .then(response => {
        setRequests(response);
        return response;
      })
      .then(requests => fetchOrganisations(requests))
      .catch(error => {
        captureError('Error while fetching requests', error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <SelectRequestView
      onSelectRequest={props.onSelectRequest}
      requests={requests}
      organisations={organisations}
      selectedRequest={props.request}
      project={props.project}
      loading={loading}
      isOpen={props.isOpen}
    />
  );
};

SelectRequestContainer.propTypes = {
  onSelectRequest: PropTypes.func.isRequired,
  project: PropTypes.object.isRequired,
  request: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};

export default SelectRequestContainer;
