import React from 'react';
import PropTypes from 'prop-types';
import Action from './action';
import {Translate} from 'react-redux-i18n';

export const PendingUserActionsView = props => {
  if (props.pendingUsers.length === 0 && !props.error) {
    return null;
  }

  return (
    <div className="card col-12">
      {props.error ?
        <span data-id="error-message"><Translate value="error.generic"/></span> :
        props.pendingUsers.map(pendingUser =>
          <Action
            key={pendingUser.user.id}
            pendingUser={pendingUser}
            onAcceptUser={props.onAcceptUser}
            onDeclineUser={props.onDeclineUser}
          />)
      }
    </div>
  );
};

PendingUserActionsView.propTypes = {
  pendingUsers: PropTypes.array.isRequired,
  pendingUsersCount: PropTypes.number.isRequired,
  error: PropTypes.bool,
  onAcceptUser: PropTypes.func.isRequired,
  onDeclineUser: PropTypes.func.isRequired
};

PendingUserActionsView.defaultProps = {
  error: false
};

export default PendingUserActionsView;
