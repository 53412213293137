import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import Checkbox from '@material-ui/core/Checkbox';
import {Icon} from 'front-onceforall-core';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {documentTypeService} from '../../../services/documentType/DocumentTypeService';
import {captureError} from '../../../utils/log';
import {DOCUMENT_CATEGORY} from '../../../utils/constant';
import {TextField} from '@material-ui/core';

const classes = {
  container: {
    border: '1px solid grey'
  },
  item: {
    borderBottom: '1px solid lightgrey',
    paddingBottom: '4px'
  },
  containerAddComplementary: {
    display: 'flex',
    justifyContent: 'center',
    width: '90%',
    background: '#fff',
    position: 'relative'
  },
  line: {
    height: '1px',
    content: '',
    width: '90%',
    position: 'absolute',
    backgroundColor: '#000',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  text: {
    background: '#fff',
    position: 'relative',
    padding: '0 30px'
  }
};

export class ProjectDocumentsForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requiredDocumentTypes: null,
      loading: true
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.getDocumentType = this.getDocumentType.bind(this);
  }

  componentDidMount() {
    this.getDocumentType();
  }

  handleSubmit(event) {
    event.preventDefault();

    const updatedDocumentTypes = this.state.requiredDocumentTypes.filter(doc => doc.requiredInProject);
    this.props.onValidate(updatedDocumentTypes);
  }

  getDocumentType() {
    documentTypeService.getDocumentTypesForProjectCreation()
      .then(documents => {
        this.setState({
          requiredDocumentTypes: documents.map(doc => {
            const requiredDoc = this.props.documents?.find(d => d.code === doc.code) || null;
            doc.requiredInProject = Boolean(doc.category === DOCUMENT_CATEGORY.MANDATORY || requiredDoc);
            doc.complementary = requiredDoc?.complementary || [];
            return doc;
          }),
          loading: false
        });
      })
      .catch(error => {
        if (!error.response || error.response.status !== 404) {
          captureError('Error while fetching document types', error);
        }
      });
  }

  handleCheckboxChange(doc) {
    doc.requiredInProject = !doc.requiredInProject;

    if (!doc.requiredInProject) {
      doc.complementary = [];
    }

    this.updateRequiredDocumentTypesState(doc);
  }

  handleAddComplementary(doc) {
    doc.complementary.push('');
    this.updateRequiredDocumentTypesState(doc);
  }

  handleRemoveComplementary(i, doc) {
    doc.complementary.splice(i, 1);
    this.updateRequiredDocumentTypesState(doc);
  }

  handleChangeValue(value, i, doc) {
    doc.complementary.splice(i, 1, value);
    this.updateRequiredDocumentTypesState(doc);
  }

  updateRequiredDocumentTypesState(newDoc) {
    this.setState({
      requiredDocumentTypes: this.state.requiredDocumentTypes.map(doc => doc.code === newDoc.code ? newDoc : doc)
    });
  }

  renderComplementaryRow(i, value, doc) {
    return <div key={i} className="flex-container space-between mt-2">
      <TextField
        data-id={doc.code + '-complementary-' + i}
        label={<span><Translate value="project.documents.complementaryPlaceholder"/></span>}
        value={value}
        onChange={event => this.handleChangeValue(event.target.value, i, doc)}
      />
      <button
        type="button"
        data-id={doc.code + '-delete-complementary-' + i}
        className="small secondary"
        onClick={() => this.handleRemoveComplementary(i, doc)}
      >
        <Icon icon="trash"/>
      </button>
    </div>;
  }

  renderComplementaryInputs(doc) {
    if (doc.code === 'ADD_PROJECT_CHARTERS' && doc.requiredInProject) {
      return <div>
        <div data-id={doc.code + '-complementary-list'} className="mt-5 ml-3 mr-3">
          {doc.complementary.map((complementary, i) => {
            return this.renderComplementaryRow(i, complementary, doc);
          })}
        </div>
        <div
          data-id={doc.code + '-add-complementary'}
          style={classes.containerAddComplementary}
          className="clickable"
          onClick={() => this.handleAddComplementary(doc)}
        >
          <div style={classes.line}/>
          <p className="flex-container" style={classes.text} >
            <Translate value="project.documents.complementaryPlaceholderAdd"/><Icon icon="rounded-plus"/>
          </p>
        </div>
      </div>;
    }

    return null;
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    const documentGrid = category => {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6">
              <Translate value={`project.documents.${category.toLowerCase()}Documents`}/>
            </Typography>
            <div>
              {this.state.requiredDocumentTypes &&
                <List dense={true} style={classes.container}>
                  {this.state.requiredDocumentTypes.map((doc, i) => {
                    return doc.category === category ?
                      <ListItem key={i} code={doc.code + i} alignItems="flex-start">
                        <ListItemText
                          data-id={category.toLowerCase() + i}
                          value={doc.code}
                          style={classes.item}
                          primary={<Typography variant="body2">{doc.label}</Typography>}
                          secondary={this.renderComplementaryInputs(doc)}
                          disableTypography={true}
                        />
                        {category === DOCUMENT_CATEGORY.MANDATORY ?
                          <Checkbox
                            data-id={'check-' + category.toLowerCase() + i}
                            disabled
                            checked
                          /> :
                          <Checkbox
                            data-id={'check-' + category.toLowerCase() + i}
                            onChange={() => this.handleCheckboxChange(doc)}
                            checked={doc.requiredInProject}
                          />}
                      </ListItem> :
                      null;
                  })}
                </List>}
            </div>
          </Grid>
        </Grid>
      );
    };

    return (
      <div>
        {this.props.displayEditMessage &&
          <div className="box card flex-container">
            <div className="secondary">
              <Icon icon="info"/>
            </div>
            <div>
              {I18n.t('project.edit.enabledTooltip')}
            </div>
          </div>}
        <form onSubmit={this.handleSubmit}>
          {documentGrid(DOCUMENT_CATEGORY.MANDATORY)}
          {documentGrid(DOCUMENT_CATEGORY.OPTIONAL)}
          {documentGrid(DOCUMENT_CATEGORY.SPECIFIC)}
          <div className="actions col-12 inline-container space-between">
            <button
              className="large primary inline-container"
              type="submit"
            >
              <Translate value="action.confirm"/>
              <Icon icon={'check'}/>
            </button>
          </div>
        </form>
      </div>
    );
  }
}

ProjectDocumentsForm.defaultProps = {
  displayEditMessage: false
};

ProjectDocumentsForm.propTypes = {
  documents: PropTypes.array,
  onValidate: PropTypes.func.isRequired,
  displayEditMessage: PropTypes.bool
};

export default ProjectDocumentsForm;
