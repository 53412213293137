import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {withRouter} from 'react-router-dom';
import {Translate} from 'react-redux-i18n';
import {selectOrganisation} from '../../actions/organisation';
import {organisationService} from '../../services/organisation/OrganisationService';
import OrganisationItem from './OrganisationItem';
import {DebouncedSearch, Icon} from 'front-onceforall-core';
import {TextField} from '@material-ui/core';
import {getCountryByLanguage} from 'front-onceforall-core/dist/utils/country';
import {isEmpty} from 'front-onceforall-core/dist/utils/strings';
import {isEmpty as isArrayEmpty} from 'front-onceforall-core/dist/utils/arrays';
import organisationHistory from '../../utils/organisationHistory';
import {captureError} from '../../utils/log';
import {authenticationService} from '../../services/authentication/AuthenticationService';

export class OrganisationSelector extends React.Component {
  constructor(props) {
    super(props);

    this.isBackOffice = authenticationService.roles().isBackOffice();
    const country = getCountryByLanguage(props.currentLocale);

    this.state = {
      showAllOrganisations: false,
      country,
      query: '',
      loading: false,
      organisations: [],
      lastOrganisations: [],
      error: false,
      helperText: null,
      showLastOrganisations: this.isBackOffice
    };

    this.setOrganisationsForUser = this.setOrganisationsForUser.bind(this);
    this.doSearch = this.doSearch.bind(this);
    this.sortOrganisations = this.sortOrganisations.bind(this);
    this.organisationsWithHistorySorter = this.organisationsWithHistorySorter.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleOrganisationSelect = this.handleOrganisationSelect.bind(this);
    this.handleClickAddOrganisation = this.handleClickAddOrganisation.bind(this);
    this.handleClickShowAllOrganisations = this.handleClickShowAllOrganisations.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.debouncedSearch = new DebouncedSearch(this.doSearch);
    this.setOrganisationsForUser();
    if (this.isBackOffice) {
      Promise.resolve(
        organisationService.search('', this.state.country, true)
          .then(response => this.setState({organisations: response}))
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.userOrganisations !== this.props.user.userOrganisations) {
      this.setOrganisationsForUser();
    }
  }

  setOrganisationsForUser() {
    if (this.isBackOffice) {
      return;
    }

    const organisations = this.sortOrganisations(this.props.user.userOrganisations) || [];
    this.setState({organisations});
  }

  getOrganisationHistory() {
    return organisationHistory.get().map(organisationHistory => organisationHistory.id);
  }

  doSearch(query) {
    this.setState({helperText: null, error: false, showLastOrganisations: false});
    if (isEmpty(query)) {
      this.setState({
        organisations: this.sortOrganisations(this.props.user.userOrganisations) || [],
        showAllOrganisations: false
      });
      return;
    }

    this.setState({loading: true});
    let promise;
    if (this.isBackOffice) {
      promise = organisationService.search(query, this.state.country, true);
    } else {
      promise = organisationService.searchByUser(this.props.user.id, query, true);
    }

    promise.then(this.sortOrganisations)
      .then(organisations => {
        this.setState({organisations, loading: false, showAllOrganisations: false});
        if (isArrayEmpty(organisations)) {
          this.setState({helperText: 'organisation.search.notfound', error: true});
        }
      })
      .catch(error => {
        captureError('organisationService.search or organisationService.searchByUser', error);
      });
  }

  sortOrganisations(organisations) {
    return organisations.sort(this.organisationsWithHistorySorter);
  }

  organisationsWithHistorySorter(organisation1, organisation2) {
    const organisationHistory = this.getOrganisationHistory();
    const indexOrganisation1 = organisationHistory.indexOf(organisation1.id);
    const indexOrganisation2 = organisationHistory.indexOf(organisation2.id);

    if (indexOrganisation1 < 0) {
      return 1;
    }

    if (indexOrganisation2 < 0) {
      return -1;
    }

    return indexOrganisation1 - indexOrganisation2;
  }

  handleChange(event) {
    const query = event.target.value;
    this.setState({query});
    this.debouncedSearch.update(query);
  }

  handleItemClick(organisation) {
    return () => {
      this.handleOrganisationSelect(organisation);
    };
  }

  handleOrganisationSelect(organisation) {
    // We push to the dashboard in order to avoid any linked error to the organisation in redux store
    this.props.history.push('/');
    this.props.actions.selectOrganisation(organisation);
    this.props.onAction();
    this.setState({organisations: this.sortOrganisations(this.state.organisations)});
  }

  handleClickAddOrganisation() {
    this.props.onAction();
    this.props.history.push('/register-organisation');
  }

  handleClickShowAllOrganisations() {
    if (this.props.showAllOrganisationsOnClick) {
      this.setState({showAllOrganisations: true});
    } else {
      this.props.history.push('/global-dashboard');
      this.props.onAction();
    }
  }

  render() {
    const rows = this.state.organisations.map((organisation, index) => {
      return (
        <div key={index} onClick={this.handleItemClick(organisation)} data-id="selectButton">
          <OrganisationItem organisation={organisation}/>
        </div>
      );
    });

    const slicedRows = [...rows].slice(0, this.props.slicedRowsNumber);

    return (
      <form>
        {slicedRows.length > 0 &&
          <p><Translate value="action.openSwitchOrganisation"/></p>
        }
        {(this.isBackOffice || (this.props.user.userOrganisations && this.props.user.userOrganisations.length > this.props.slicedRowsNumber)) &&
          <div className="row">
            <div className={'mb-4 col-12'}>
              <TextField
                label={<Translate value="organisation.search.placeholder"/>}
                autoFocus
                value={this.state.query}
                onChange={this.handleChange}
                error={this.state.error}
                helperText={this.state.helperText && <Translate value={this.state.helperText}/>}
              />
            </div>
          </div>
        }

        <div style={{position: 'relative'}}>
          {this.state.loading && <span className="loading-overlay"/>}
          {this.state.showLastOrganisations && !isArrayEmpty(this.state.organisations) &&
            <em><Translate value="organisation.search.lastSelected"/></em>
          }
          {this.state.showAllOrganisations ? rows : slicedRows}
          {this.isBackOffice && this.state.showAllOrganisations && this.state.organisations.length > 9 &&
            <em><Translate value="organisation.search.specifySearch"/></em>
          }

          {rows.length > this.props.slicedRowsNumber && !this.state.showAllOrganisations &&
            <div className="flex-container column center mt-4">
              <button
                type="button"
                className="clickable"
                data-id="show-all-organisations"
                onClick={this.handleClickShowAllOrganisations}
              >
                <Translate value="organisation.select.showAll"/>
              </button>
            </div>
          }

          <div className="row">
            <div className="inline-container mt-5 flex-end col-12">
              <button
                type="button"
                className="large primary inline-container"
                data-id="button-create-organisation"
                onClick={this.handleClickAddOrganisation}
              >
                <Translate value="organisation.createOrganisation"/>
                <Icon icon="plus"/>
              </button>
            </div>
          </div>

        </div>
      </form>
    );
  }
}

OrganisationSelector.defaultProps = {
  onAction: () => {},
  slicedRowsNumber: 6,
  showAllOrganisationsOnClick: false
};

OrganisationSelector.propTypes = {
  user: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onAction: PropTypes.func,
  slicedRowsNumber: PropTypes.number,
  showAllOrganisationsOnClick: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    user: state.user,
    currentLocale: state.i18n.locale
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, {selectOrganisation}), dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganisationSelector));
