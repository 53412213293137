import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as snackbarsActions from '../../../../../actions/snackbars';
import {Translate} from 'react-redux-i18n';
import UploadPanelView from './UploadPanelView';
import {AbstractFormComponent, validators} from 'front-onceforall-core';
import {requestService} from '../../../../../services/request/RequestService';
import {captureError} from '../../../../../utils/log';

const DOCUMENT_MAX_SIZE = 15000000;
const DOCUMENT_MIN_SIZE = 1000;

export class UploadPanelContainer extends AbstractFormComponent {
  getFormData() {
    return {
      file: null
    };
  }

  getFormValidators() {
    return {
      file: [validators.requiredValidator, this.fileValidator.bind(this)]
    };
  }

  fileValidator(value) {
    if (value === 'WRONG_SIZE') {
      return 'error.documentWrongSize';
    }

    if (value === 'WRONG_TYPE') {
      return 'error.documentWrongType';
    }

    if (value === 'UNKNOWN') {
      return 'error.documentUnknown';
    }
  }

  handleDrop = (acceptedFiles, rejectedFiles) => {
    this.setState({submitted: true});
    if (acceptedFiles.length > 0) {
      this.handleChange('file')({target: {value: acceptedFiles[0]}});
    } else if (rejectedFiles.length > 0) {
      this.handleDropRejected(rejectedFiles[0].errors[0]);
    }
  };

  handleDropRejected = error => {
    if (error.code === 'file-too-small' || error.code === 'file-too-large') {
      this.handleChange('file')({target: {value: 'WRONG_SIZE'}});
    } else if (error.code === 'file-invalid-type') {
      this.handleChange('file')({target: {value: 'WRONG_TYPE'}});
    } else {
      this.handleChange('file')({target: {value: 'UNKNOWN'}});
    }
  };

  handleSubmitCallback() {
    requestService.submitDocument(this.props.requestId, this.state.formData.file, this.props.document.type.code)
      .then(response => this.props.onSubmit(response))
      .catch(error => this.handleSubmitError(error));
  }

  handleSubmitError(error) {
    error.response.json().then(body => {
      if (error.response.status === 409 && ['error.documentFile.failure', 'error.rfaCannotBeEdited'].includes(body.errorCode)) {
        this.props.actions.addError(<Translate value="error.signatureBlockUpload"/>);
      } else {
        this.props.actions.addError(<Translate value="error.generic"/>);
      }

      this.setState({loading: false, submitting: false});
      captureError('Error while submitting new document', error);
    });
  }

  render() {
    const fileName = this.state.formData?.file && typeof (this.state.formData.file) === 'object' ? this.state.formData.file.name : '';

    return <UploadPanelView
      document={this.props.document}
      onClose={this.props.onClose}
      onSubmit={this.handleSubmit}
      onDrop={this.handleDrop}
      errorText={this.helperText('file') || ''}
      fileName={fileName}
      submitting={this.state.submitting}
      minSize={DOCUMENT_MIN_SIZE}
      maxSize={DOCUMENT_MAX_SIZE}
    />;
  }
}

UploadPanelContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  document: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  requestId: PropTypes.number.isRequired
};

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadPanelContainer);
