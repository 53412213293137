import {availableLanguages} from '../../translations';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {setLocale, Translate} from 'react-redux-i18n';
import {userService} from '../../services/user/UserService';
import MenuItem from '@material-ui/core/MenuItem';
import {TextField} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as snackbarsActions from '../../actions/snackbars';
import {captureError} from '../../utils/log';

export class LanguageSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: this.props.currentLocale,
      hasChanged: false
    };

    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleLanguageChange(event) {
    this.setState({
      language: event.target.value,
      hasChanged: this.props.currentLocale !== event.target.value
    }, this.handleSubmit);
  }

  handleSubmit() {
    if (!this.state.hasChanged) {
      return;
    }

    this.setState({hasChanged: false});

    userService.updateLanguage(this.props.user.id, this.state.language)
      .then(() => {
        this.props.actions.setLocale(this.state.language);
        this.props.actions.addSuccess(<Translate value="message.changesSaved"/>);
      })
      .catch(error => {
        captureError('userService.updateLanguage', error);
        this.props.actions.addError(<Translate value="error.generic"/>);
      });
  }

  render() {
    const languageOptions = availableLanguages.map((lang, index) => {
      return (
        <MenuItem key={index} value={lang}>
          <Translate value={'language.' + lang}/>
        </MenuItem>
      );
    });

    return (
      <div className="inline-container col-md-4">
        <TextField
          select
          input={<Input name="language" id="language"/>}
          value={this.state.language}
          onChange={this.handleLanguageChange}
        >
          {languageOptions}
        </TextField>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions, {setLocale}), dispatch)
  };
}

function mapStateToProps(state) {
  return {
    user: state.user,
    currentLocale: state.i18n.locale
  };
}

LanguageSettings.propTypes = {
  actions: PropTypes.object,
  user: PropTypes.object,
  currentLocale: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSettings);
