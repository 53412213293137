import React from 'react';
import PropTypes from 'prop-types';
import RequestRow from '../../../requestRow/RowView';

export const ActionView = props => {
  return <RequestRow
    history={props.history}
    request={props.request}
    organisations={props.organisations}
  />;
};

ActionView.propTypes = {
  history: PropTypes.object.isRequired,
  organisations: PropTypes.array.isRequired,
  request: PropTypes.object.isRequired
};

export default ActionView;
