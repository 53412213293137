import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {CountryFlag as CoreCountryFlag} from 'front-onceforall-core';

const CountryFlag = props => {
  const label = <Translate value={'country.' + props.country}/>;

  return (
    <div className="flex-container" style={{width: '100%'}}>
      <CoreCountryFlag country={props.country} size={props.size}/>
      {!props.hideLabel && <span className="ellipsis" style={{width: 'calc(100% - 22px)'}}>{label}</span>}
    </div>
  );
};

CountryFlag.defaultProps = {
  hideLabel: false,
  size: 16
};

CountryFlag.propTypes = {
  country: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  size: PropTypes.number
};

export default CountryFlag;
