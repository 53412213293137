import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import RequestWorkflowView from './RequestWorkflowView';
import {captureError} from '../../../utils/log';
import {requestService} from '../../../services/request/RequestService';
import {organisationService} from '../../../services/organisation/OrganisationService';
import {signatureService} from '../../../services/signature/SignatureService';
import {
  PAYMASTER,
  PROJECT_ORGANISATION_ROLE as orgaRole,
  RFA_STATUS,
  TASK_STATUS,
  TASK_TYPE,
  USER_ORGANISATION_ROLE
} from '../../../utils/constant';
import {enrollmentService} from '../../../services/enrollment/EnrollmentService';
import SidePanel from 'front-onceforall-core/dist/views/SidePanel';
import RejectRequest from '../rejectRequest';
import ConfirmModal, {defaultModalStyle} from '../../modal/ConfirmModal';
import Icon from 'front-onceforall-core/dist/components/Icon';
import {authenticationService} from '../../../services/authentication/AuthenticationService';
import {userService} from '../../../services/user/UserService';
import SuspensiveConditionModal from '../../modal/SuspensiveConditionModal';

const canSetConditionRoles = [
  orgaRole.PROJECT_CLIENT,
  orgaRole.PROJECT_CLIENT_DELEGATE,
  orgaRole.PROJECT_CLIENT_ASSISTANT,
  orgaRole.PROJECT_MANAGER
];

const modalStyle = {
  ...defaultModalStyle,
  content: {
    ...defaultModalStyle.content,
    maxWidth: 600
  }
};

export const RequestWorkflowContainer = props => {
  const [workflow, setWorkflow] = React.useState([]);
  const [participants, setParticipants] = React.useState([]);
  const [actionUsers, setActionUsers] = React.useState([]);
  const [rfaOwner, setRfaOwner] = React.useState(null);
  const [client, setClient] = React.useState(null);
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [signingStep, setSigningStep] = React.useState(0);
  const [invitations, setInvitations] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState([]);
  const [lastSuspensiveCondition, setLastSuspensiveCondition] = React.useState(null);
  const [signModalOpen, isSignModalOpen] = React.useState(false);
  const [verifyModalOpen, isVerifyModalOpen] = React.useState(false);
  const [suspensiveModalOpen, isSuspensiveModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  let triggerClosePanel = () => {
  };

  const handleRejectRequestSubmit = () => {
    fetchWorkflow(Number(props.requestId));
    triggerClosePanel();
  };

  const handleRejectRequestClick = () => {
    setIsPanelOpen(true);
  };

  const handleRemoveConditionClick = (role, conditionTranslation) => {
    requestService.removeSuspensiveCondition(props.requestId, role)
      .then(() => {
        props.onSetSuspensiveConditions(props.suspensiveConditions.filter(condition => condition.organisationRole !== role));
        props.actions.addSuccess(<Translate value={conditionTranslation + 'removal'}/>);
      })
      .catch(exception => {
        captureError('handleRemoveConditionClick', exception);
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const getActiveTaskFromWorkflowByType = type =>
    workflow.find(step => step.some(task => task.state === TASK_STATUS.ACTIVE))
      ?.find(task => task.type === type && task.state === TASK_STATUS.ACTIVE);

  const getActiveTaskForUserActions = () => {
    if (userRoles.includes(USER_ORGANISATION_ROLE.SIGNATORY)) {
      return getActiveTaskFromWorkflowByType(TASK_TYPE.SIGN);
    }

    return (userRoles.includes(USER_ORGANISATION_ROLE.VERIFICATOR)) ? getActiveTaskFromWorkflowByType(TASK_TYPE.VERIFY) : null;
  };

  const handleVerifyConfirm = (condition = null) => {
    // Retrieve activeTasks array in workflow and find the verify task
    const task = getActiveTaskFromWorkflowByType(TASK_TYPE.VERIFY);

    // Only send requestId for fake service
    requestService.verifyRequest(task.taskId, condition, props.requestId)
      .then(() => {
        fetchWorkflow(Number(props.requestId));
        props.actions.addSuccess(<Translate value="request.verify.success"/>);
        props.onRequestStatusChange(RFA_STATUS.READY);
        if (condition) {
          props.onSetSuspensiveConditions(prevConditions => [...prevConditions, {organisationRole: task.role, content: condition}]);
        }
      })
      .catch(exception => {
        captureError('handleRequestVerify', exception);
        props.actions.addError(<Translate value="error.generic"/>);
      })
      .finally(() => {
        isVerifyModalOpen(false);
        isSuspensiveModalOpen(false);
      });
  };

  const handleSignConfirm = (condition = null) => {
    // Retrieve activeTasks array in workflow and find the sign taskId
    const taskId = getActiveTaskFromWorkflowByType(TASK_TYPE.SIGN).taskId;

    setLoading(true);
    signatureService.signRequest(taskId, condition)
      .then(response => window.open(response, '_self'))
      .catch(error => {
        captureError('handleRequestSign', error);
        props.actions.addError(<Translate value="error.generic"/>);
      })
      .finally(() => {
        isSignModalOpen(false);
        setLoading(false);
        isSuspensiveModalOpen(false);
      });
  };

  const fetchParticipants = workflow => {
    const participantIds = workflow.map(step => step[0].organisationId)
      // Remove null value (when subcontractor is invited)
      .filter(Boolean);
    return organisationService.getOrganisationsList(participantIds)
      .then(response => {
        setParticipants(response);
        return response;
      })
      .catch(() => setError(true));
  };

  const fetchSubcontractorInvitation = requestId => {
    return enrollmentService.getInvitationByRequestId(requestId)
      .then(response => {
        const subcontractorInvitation = response.find(invitation => invitation.role === orgaRole.RFA_SUBCONTRACTOR);

        return subcontractorInvitation;
      })
      .catch(() => setError(true));
  };

  const fetchUsers = workflowResponse => {
    /**
     * Filter each user array tasks to get last recorded action of each organisation
     * First we removed each task that doesn't include an userId
     * Second we removed the empty array step that was filtered
     * Finally we map over the filtered array to get only the last userId in step
     */
    const userIds = workflowResponse.map(step => step.filter(task => task.userId)).filter(step => step.length).map(step => step[step.length - 1]?.userId);
    return userService.getUsersInfoListByIds(userIds)
      .then(response => setActionUsers(response))
      .catch(exception => {
        setError(true);
        throw exception;
      });
  };

  const fetchWorkflow = requestId => {
    setLoading(true);
    requestService.getWorkflowByRequestId(requestId)
      .then(workflowResponse => {
        // If N-tier RFA, define an attribute "roleLevel" to store the subcontractor rank and change the role used as a translation in <ParticipantView>
        const subcontractorParentsLength = workflowResponse.filter(step => step[0].role === orgaRole.RFA_PARENT_SUBCONTRACTOR).length;

        if (subcontractorParentsLength > 0) {
          let nTierLevel = subcontractorParentsLength + 1;
          workflowResponse.filter(step => step[0].role === orgaRole.RFA_SUBCONTRACTOR || step[0].role === orgaRole.RFA_PARENT_SUBCONTRACTOR)
            .forEach(step => {
              step.map(task => {
                task.role = orgaRole.RFA_SUBCONTRACTOR_N;
                task.roleLevel = nTierLevel;

                return task;
              });
              nTierLevel--;
            });
        }

        setWorkflow(workflowResponse);

        const activeTaskIndex = workflowResponse.findIndex(step => step.some(task => task.state === TASK_STATUS.ACTIVE));
        setSigningStep(activeTaskIndex < 0 ? workflowResponse.length : activeTaskIndex);

        fetchUsers(workflowResponse)
          .then(() =>
            fetchParticipants(workflowResponse)
              .then(participantResponse => {
                // Store client in state to compute paymaster
                const clientStep = workflowResponse.find(step => step[0].role === orgaRole.PROJECT_CLIENT);
                if (clientStep && clientStep[0]?.organisationId) {
                  setClient(participantResponse.find(participant => participant.id === clientStep[0].organisationId));
                }

                // Send subcontractor information and fetch invitation if subcontractor is unknown
                const subcontractorStep = subcontractorParentsLength > 0 ?
                  workflowResponse.find(step => step[0].roleLevel === subcontractorParentsLength + 1) :
                  workflowResponse.find(step => step[0].role === orgaRole.RFA_SUBCONTRACTOR);

                if (subcontractorStep[0]?.organisationId) {
                  const subcontractor = participantResponse.find(participant => participant.id === subcontractorStep[0].organisationId);
                  props.onGetSubcontractorName(subcontractor?.fullName);
                  props.onIsWaitingSubcontractorChange(false);
                } else {
                  fetchSubcontractorInvitation(props.requestId)
                    .then(invitation => {
                      if (invitation) {
                      // If N-tier RFA, define an attribute "roleLevel" to store the subcontractor rank and change the role used as a translation in <InvitationView>
                        if (subcontractorParentsLength > 0) {
                          invitation.role = orgaRole.RFA_SUBCONTRACTOR_N;
                          invitation.roleLevel = subcontractorParentsLength + 1;
                        }

                        setInvitations([invitation]);
                        return invitation;
                      }
                    })
                    .then(invitation => props.onGetSubcontractorName(invitation?.organisationName));
                  props.onIsWaitingSubcontractorChange(true);
                }
              })
          );
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    fetchWorkflow(Number(props.requestId));
  }, []);

  // Use the prop "projectCreatorOrganisationId" to define the General contractor role:
  // * "PROJECT_GENERAL_CONTRACTOR_OWNER", if General contractor is the project owner
  // * "PROJECT_GENERAL_CONTRACTOR" (default value), else
  // Its role is used in <ParticipantView> to display proper translation
  React.useEffect(() => {
    if (workflow.length > 0 && props.projectCreatorOrganisationId) {
      setWorkflow(workflow.map(step => step.map(task => {
        if (task.role === orgaRole.PROJECT_GENERAL_CONTRACTOR && task.organisationId === props.projectCreatorOrganisationId) {
          task.role = orgaRole.PROJECT_GENERAL_CONTRACTOR_OWNER;
        }

        return task;
      })));
    }
  }, [workflow.length, props.projectCreatorOrganisationId]);

  // Use the prop "requestCreatorOrganisationId" to find RFA owner, store it in state to compute paymaster and send information
  // and send information about it to parent Components
  // As both the props and the participants list are set asynchronously, we use useEffect() to trigger asynchronous behaviour
  React.useEffect(() => {
    if (participants.length > 0 && props.requestCreatorOrganisationId) {
      const rfaOwner = participants.find(participant => participant.id === props.requestCreatorOrganisationId);
      setRfaOwner(rfaOwner);

      if (rfaOwner?.fullName) {
        props.onGetRfaOwnerName(rfaOwner.fullName);
      }
    }
  }, [participants.length, props.requestCreatorOrganisationId]);

  // Use the prop "paymaster" to find Paymaster organisation and send information about it to parent Components
  // As both this props and these organisations are set asynchronously, we trigger completed response by using useEffect()
  React.useEffect(() => {
    if ((rfaOwner || client) && props.paymaster) {
      const payMasterOrganisation = props.paymaster === PAYMASTER.CLIENT ? client : rfaOwner;
      props.onGetPayMasterOrganisationName(payMasterOrganisation?.fullName || null);
    }
  }, [rfaOwner, client, props.paymaster]);

  // Use the prop "projectId" to get user permissions on this project then compute it to allow signatory or verificator actions
  // As the project id is retrieved with request's data, this useEffect avoids errors while the project id is undefined
  React.useEffect(() => {
    if (props.projectId) {
      setUserRoles(authenticationService.roles().getProjectRoles(props.projectId));
    }
  }, [props.projectId]);

  // Use the prop "suspensiveConditions" to check which one is the last suspensive condition that will set it in the suspensive condition modal
  // As the page doesn't reload upon verification, this allows to update the last suspensive condition without reloading the component
  React.useEffect(() => {
    for (let i = 0; i < canSetConditionRoles.length; i++) {
      let last = props.suspensiveConditions?.find(condition => condition.organisationRole === canSetConditionRoles[i]);
      if (last) {
        setLastSuspensiveCondition(last);
        break;
      }
    }
  }, [props.suspensiveConditions]);

  return (
    <>
      <RequestWorkflowView
        organisationId={props.organisationId}
        workflow={workflow}
        signingStep={signingStep}
        participants={participants}
        actionUsers={actionUsers}
        invitations={invitations}
        userRoles={userRoles}
        suspensiveConditions={props.suspensiveConditions}
        loading={loading}
        error={error}
        onSignClick={role => canSetConditionRoles.includes(role) ? isSuspensiveModalOpen(true) : isSignModalOpen(true)}
        onVerifyClick={role => canSetConditionRoles.includes(role) ? isSuspensiveModalOpen(true) : isVerifyModalOpen(true)}
        onRejectRequestClick={handleRejectRequestClick}
        onRemoveConditionClick={handleRemoveConditionClick}
      />
      <ConfirmModal
        data-id="sign-confirm-modal"
        isOpen={signModalOpen}
        onConfirm={() => handleSignConfirm()}
        onClose={() => isSignModalOpen(false)}
        title={<Translate value="request.page.signConfirmModal.title"/>}
        content={<Translate value="request.page.signConfirmModal.content"/>}
        submitButtonBody={<><Icon icon="sign"/><Translate value="request.documents.actions.sign"/></>}
        modalStyle={modalStyle}
      />
      <ConfirmModal
        data-id="verify-confirm-modal"
        isOpen={verifyModalOpen}
        onConfirm={() => handleVerifyConfirm()}
        onClose={() => isVerifyModalOpen(false)}
        title={<Translate value="request.page.verifyConfirmModal.title"/>}
        content={<Translate value="request.page.verifyConfirmModal.content"/>}
        modalStyle={modalStyle}
      />
      {// Wait user's button click to mount the component and allow to wait for lastSuspensiveCondition props
        suspensiveModalOpen &&
          <SuspensiveConditionModal
            data-id="suspensive-condition-modal"
            isOpen={suspensiveModalOpen}
            onConfirm={condition => userRoles.includes(USER_ORGANISATION_ROLE.SIGNATORY) ? handleSignConfirm(condition) : handleVerifyConfirm(condition)}
            onClose={() => isSuspensiveModalOpen(false)}
            isSignatory={userRoles.includes(USER_ORGANISATION_ROLE.SIGNATORY)}
            lastSuspensiveCondition={lastSuspensiveCondition}
          />
      }
      {(isPanelOpen && workflow && userRoles.length > 0) &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsPanelOpen(false)}
        >
          <RejectRequest
            actions={props.actions}
            activeTask={getActiveTaskForUserActions()}
            onRejectRequestSubmit={handleRejectRequestSubmit}
            onRequestReasonUpdate={props.onRequestReasonUpdate}
            onClose={() => triggerClosePanel()}
          />
        </SidePanel>
      }
    </>
  );
};

RequestWorkflowContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
  requestId: PropTypes.number.isRequired,
  projectId: PropTypes.number,
  projectCreatorOrganisationId: PropTypes.number,
  requestCreatorOrganisationId: PropTypes.number,
  paymaster: PropTypes.string,
  suspensiveConditions: PropTypes.array,
  onSetSuspensiveConditions: PropTypes.func.isRequired,
  onGetRfaOwnerName: PropTypes.func.isRequired,
  onGetSubcontractorName: PropTypes.func.isRequired,
  onGetPayMasterOrganisationName: PropTypes.func.isRequired,
  onIsWaitingSubcontractorChange: PropTypes.func.isRequired,
  onRequestStatusChange: PropTypes.func.isRequired,
  onRequestReasonUpdate: PropTypes.func.isRequired
};

export default RequestWorkflowContainer;
