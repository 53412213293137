import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import moment from 'moment-timezone';
import ProjectEditionButton from '../projectEditionButton/ProjectEditionButton';

export const ProjectInformationView = props => {
  return (
    <div className="col-4">
      <h2><Translate value="project.details.title"/></h2>
      <div className="card h-100 flex-container column">
        {props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
          props.loading ? <span className="loading-overlay"/> :
            props.project &&
              <>
                <div className="box border bottom">
                  <div className="flex-container space-between">
                    <div className="card-header font-size-xl"><Translate value="address.addressLine"/></div>
                    {props.isProjectEditionAllowed && <ProjectEditionButton onEditButtonClick={props.onEditButtonClick}/>}
                  </div>
                  <div className="information">
                    {`${props.project.address.addressLine},`}
                    <br/>
                    {`${props.project.address.postCode} ${props.project.address.city}`}
                  </div>
                </div>
                <div className="box border bottom">
                  <div className="card-header font-size-xl"><Translate value="project.details.openingDate"/></div>
                  <div className="information">{moment(props.project.openingDate).format('DD/MM/YYYY')}</div>
                </div>
                <div className="box border bottom">
                  <div className="card-header font-size-xl"><Translate value="project.details.closingDate"/></div>
                  <div className="information">{moment(props.project.closingDate).format('DD/MM/YYYY')}</div>
                </div>
              </>
        }
      </div>
    </div>
  );
};

ProjectInformationView.defaultProps = {
  isProjectEditionAllowed: false,
  loading: true,
  error: false
};

ProjectInformationView.propTypes = {
  project: PropTypes.object,
  isProjectEditionAllowed: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  onEditButtonClick: PropTypes.func
};

export default ProjectInformationView;
