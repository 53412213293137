import React from 'react';
import {Translate} from 'react-redux-i18n';
import TropheIcon from './TropheIcon';

function EmptyDashboard() {
  return (
    <div className="card">
      <div className="col-12 inline-container column center mt-4 justify-content-center">
        <TropheIcon/>
        <strong style={{fontSize: '30px'}}>
          <Translate value="dashboard.empty.title"/>
        </strong>
        <p><Translate value="dashboard.empty.message"/></p>
      </div>
    </div>
  );
}

export default EmptyDashboard;
