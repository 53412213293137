import React from 'react';
import {Translate} from 'react-redux-i18n';
import LinkButtonView from '../buttons/link';
import OverlayMessage from '../overlayMessage';

const AccessDeniedView = () => (
  <OverlayMessage
    type="error"
    message={<Translate value="message.pageAccessDenied"/>}
  >
    <LinkButtonView
      link="/"
      buttonLabel={<Translate value="action.returnToHome"/>}
    />
  </OverlayMessage>
);

export default AccessDeniedView;
