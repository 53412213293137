import React from 'react';
import PropTypes from 'prop-types';
import {Icon} from 'front-onceforall-core';
import {useHistory} from 'react-router-dom';

function LinkButtonView(props) {
  const history = useHistory();

  return (
    <button
      type="button"
      className="large primary flex-container mt-3"
      onClick={() => history.push(props.link)}
    >
      {props.buttonLabel}
      <Icon icon="arrow-right"/>
    </button>
  );
}

LinkButtonView.propTypes = {
  link: PropTypes.string.isRequired,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default LinkButtonView;
