import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {Link} from 'react-router-dom';
import {Icon} from 'front-onceforall-core';
import RequestInformation from './requestInformation';
import RequestDC4Status from './requestDC4Status/RequestDC4Status';
import RequestSpecificDocumentsList from './requestSpecificDocumentsList';
import RequestWorkflow from './requestWorkflow';
import RequestDocumentsList from './requestDocumentsList';
import RefusalMessage from './refusalMessage';
import {RFA_STATUS, WORKFLOW_STATUS_COLOR} from '../../utils/constant';
import Tooltip from '@material-ui/core/Tooltip';
import moment from 'moment-timezone';
import appConfig from '../../../config';

export const RequestView = props => {
  const isEditable = props.request?.status === RFA_STATUS.EDITABLE;
  const isValidated = props.request?.status === RFA_STATUS.VALIDATED;

  const showRefusalMessage = props.request?.refusalReason && !isValidated;
  const hasParentInfo = props.parentRequestDetails && props.rfaOwnerName;

  const isRFAValidatedWithLastSignatoryCondition = (isValidated && props.lastSignatoryCondition);

  const showDC4Status = appConfig.featureFlags.isPublicProcurementEnabled && props.request?.project.publicProcurement;

  let requestSubtitle =
    <Translate
      className="information"
      value="request.page.subtitle"
      color={WORKFLOW_STATUS_COLOR[props.workflowStatus]}
      status={I18n.t(`request.status.${props.workflowStatus}`)}
      condition={isRFAValidatedWithLastSignatoryCondition ? ' ' + I18n.t('request.page.suspensiveCondition.final.title') : ''}
      subcontractor={props.subcontractorName}
      dangerousHTML
    />;

  requestSubtitle = isRFAValidatedWithLastSignatoryCondition ?
    <Tooltip
      data-id="request-subtitle-with-condition"
      title={props.lastSignatoryCondition.content}
    >
      <span>{requestSubtitle}</span>
    </Tooltip> : requestSubtitle;

  let downloadAllDocumentsButton = <button
    data-id="download-all-documents"
    className="large inline-container primary"
    onClick={props.onAllDocumentsDownload}
    disabled={!props.isAnyDocumentAvailable}
    style={props.isAnyDocumentAvailable ? {} : {pointerEvents: 'none'}}
  >
    <Icon icon="download"/>
    <Translate value="request.page.downloadAllDocuments"/>
  </button>;

  downloadAllDocumentsButton = props.isAnyDocumentAvailable ? downloadAllDocumentsButton :
    <Tooltip
      data-id="download-all-documents-disabled-tooltip"
      title={<Translate value={'request.page.noDocuments'}/>}
    >
      <span>{downloadAllDocumentsButton}</span>
    </Tooltip>;

  let deleteButton = <button
    data-id="delete"
    className="large secondary inline-container"
    onClick={props.onRequestDelete}
    disabled={!isEditable}
    style={isEditable ? {} : {pointerEvents: 'none'}}
  >
    <Icon icon="trash"/>
  </button>;

  deleteButton = isEditable ? deleteButton :
    <Tooltip
      data-id="delete-disabled-tooltip"
      title={<Translate value={'request.page.buttonsDisabledTooltip'}/>}
    >
      <span>{deleteButton}</span>
    </Tooltip>;

  return (
    <>
      <Link to="/requests">
        <div className="inline-container my-4">
          <Icon icon="arrow-left"/>
          <Translate value="request.page.backToRequests"/>
        </div>
      </Link>
      <>
        {!props.loading && !props.error && props.request &&
          <div className="flex-container space-between">
            <div className={`${hasParentInfo ? ' mb-3' : 'mb-5'}`}>
              <h1 data-id="request-title" className="ml-6 mb-0">
                <Link to={'/project/' + props.request.project.id}>
                  <div className="inline-container show-on-hover-container">
                    <span>{props.request.project.name}</span>
                    <span className="show-on-hover-element"><Icon icon="go-back"/></span>
                  </div>
                </Link>
              </h1>
              {props.subcontractorName &&
              <h2 data-id="request-subtitle" className="ml-6">
                {requestSubtitle}
              </h2>}
            </div>

            <div className="inline-container flex-shrink-0">
              {downloadAllDocumentsButton}

              <button
                data-id="download-pdf"
                className="large inline-container primary"
                onClick={props.onPDFDownload}
                disabled={!isValidated}
              >
                <Icon icon="download"/>
                <Translate value="request.page.downloadPDF"/>
              </button>

              <Tooltip
                data-id="copy-url-tooltip"
                title={<Translate value={'request.page.copyRequestUrlTooltip'}/>}
              >
                <button
                  data-id="copy-url"
                  className="secondary large"
                  onClick={props.onCopyRequestUrlToClipboard}
                >
                  <Icon icon="document-copy"/>
                </button>
              </Tooltip>

              {props.showOwnerButtons && deleteButton}
            </div>
          </div>
        }

        {showRefusalMessage &&
          <RefusalMessage
            organisationId={props.request.refusalOrganisationId}
            date={props.request.refusalDate}
            reason={props.request.refusalReason}
          />
        }

        {hasParentInfo &&
          <div className="row mb-3">
            <div className="col-12">
              <div className="card flex-container">
                <div className="box border">
                  <div data-id="parent-request-subtitle">
                    <h2 className="mb-1">
                      <Translate
                        value="request.page.parentRequestSubtitle"
                        rfaOwnerName={props.rfaOwnerName}
                        dangerousHTML
                      />
                    </h2>
                    <ul className="information mt-0 mb-1">
                      <li className="mb-1">
                        <Translate value="request.page.estimatedDatesTitle"/>&nbsp;
                        <Translate
                          value="request.page.estimatedDates"
                          dateStart={moment(props.parentRequestDetails.startingDate).format('L')}
                          dateEnd={moment(props.parentRequestDetails.endingDate).format('L')}
                          style={{textTransform: 'lowercase'}}
                        />
                      </li>
                      <li>
                        <Translate value="request.page.serviceProvision"/>
                        <Translate value="colon"/>
                        {props.parentRequestDetails.details}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        <div className="row">
          <div className="col-4">
            <div className="flex-container column">
              <RequestInformation
                actions={props.actions}
                request={props.request}
                requestId={props.requestId}
                isEditable={isEditable}
                showOwnerButtons={props.showOwnerButtons}
                payMasterOrganisationName={props.payMasterOrganisationName}
                isPaymasterRequestOwner={props.rfaOwnerName === props.payMasterOrganisationName}
                loading={props.loading}
                error={props.error}
                onRequestUpdate={props.onRequestUpdate}
              />
              { showDC4Status && <RequestDC4Status
                request={props.request}
                history={props.history}
                onPDFDownload={props.onPDFDownload}
                userRoles={props.userRoles}
                organisationId={props.organisationId}
              />
              }
              <RequestSpecificDocumentsList
                requestId={props.requestId}
                paymaster={props.request?.paymaster}
                rfaStatus={props.request?.status}
                onAnyDocumentAvailable={props.onAnyDocumentAvailable}
              />
            </div>
          </div>
          <div className="col-4">
            <RequestDocumentsList
              requestId={props.requestId}
              isEditable={isEditable}
              onAnyDocumentAvailable={props.onAnyDocumentAvailable}
            />
          </div>
          <div className="col-4">
            <RequestWorkflow
              actions={props.actions}
              organisationId={props.organisationId}
              requestId={props.requestId}
              projectId={props.request?.project.id}
              projectCreatorOrganisationId={props.request?.project.creatorOrganisationId}
              requestCreatorOrganisationId={props.request?.creatorOrganisationId}
              paymaster={props.request?.paymaster}
              suspensiveConditions={props.suspensiveConditions}
              onSetSuspensiveConditions={props.onSetSuspensiveConditions}
              onGetRfaOwnerName={props.onGetRfaOwnerName}
              onGetSubcontractorName={props.onGetSubcontractorName}
              onGetPayMasterOrganisationName={props.onGetPayMasterOrganisationName}
              onIsWaitingSubcontractorChange={props.onIsWaitingSubcontractorChange}
              onRequestReasonUpdate={props.onRequestUpdate}
              onRequestStatusChange={props.onRequestStatusChange}
            />
          </div>
        </div>
      </>
    </>
  );
};

RequestView.defaultProps = {
  isAnyDocumentAvailable: false,
  showOwnerButtons: false,
  loading: true,
  error: false
};

RequestView.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object,
  organisationId: PropTypes.number,
  request: PropTypes.object,
  requestId: PropTypes.number.isRequired,
  rfaOwnerName: PropTypes.string,
  subcontractorName: PropTypes.string,
  payMasterOrganisationName: PropTypes.string,
  isAnyDocumentAvailable: PropTypes.bool,
  workflowStatus: PropTypes.string,
  suspensiveConditions: PropTypes.array,
  lastSignatoryCondition: PropTypes.object,
  parentRequestDetails: PropTypes.object,
  showOwnerButtons: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onSetSuspensiveConditions: PropTypes.func.isRequired,
  onCopyRequestUrlToClipboard: PropTypes.func.isRequired,
  onAnyDocumentAvailable: PropTypes.func.isRequired,
  onGetRfaOwnerName: PropTypes.func.isRequired,
  onGetSubcontractorName: PropTypes.func.isRequired,
  onGetPayMasterOrganisationName: PropTypes.func.isRequired,
  onIsWaitingSubcontractorChange: PropTypes.func.isRequired,
  onPDFDownload: PropTypes.func.isRequired,
  onAllDocumentsDownload: PropTypes.func.isRequired,
  onRequestDelete: PropTypes.func.isRequired,
  onRequestUpdate: PropTypes.func.isRequired,
  onRequestStatusChange: PropTypes.func.isRequired,
  userRoles: PropTypes.array.isRequired
};

export default RequestView;
