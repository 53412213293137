import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';
import TooltipButton from '../../buttons/TooltipButton';
import {RFA_STATUS, USER_ORGANISATION_ROLE} from '../../../utils/constant';

const authorizedRoles = [
  USER_ORGANISATION_ROLE.SIGNATORY,
  USER_ORGANISATION_ROLE.VERIFICATOR,
  USER_ORGANISATION_ROLE.VERIFICATOR_SIGNATORY
];

function RequestDC4Status(props) {
  const dc4Completed = props.request.publicProcurementData.isCompleted;
  const isEditable = props.request?.status === RFA_STATUS.EDITABLE;

  function getActionButton() {
    if (!isEditable) {
      return (
        <button
          data-id="actions-see-button"
          className="inline-container large secondary"
          onClick={() => {
            props.onPDFDownload();
          }}
        >
          <Icon icon="download" />
          <Translate value="request.page.dc4.action.see" />
        </button>
      );
    }

    const hasAuthorizedRole = props.userRoles.some(r => authorizedRoles.includes(r));
    const isInAuthorizedOrganization = [
      props.request.subcontractorId,
      props.request.creatorOrganisationId
    ].includes(props.organisationId);

    const disabled = !isInAuthorizedOrganization || !hasAuthorizedRole;
    const tooltipMessage = disabled ? <Translate value="request.page.dc4.noRights" /> : null;
    return (
      <TooltipButton
        data-id="actions-fill-button"
        className="inline-container large secondary"
        onClick={() => {
          props.history.push(`/request/${props.request.id}/dc4`);
        }}
        disabled={disabled}
        tooltipMessage={tooltipMessage}
      >
        <Icon icon="upload" />
        <Translate value="request.page.dc4.action.complete" />
      </TooltipButton>
    );
  }

  function getStateIndicatort() {
    if (dc4Completed) {
      return (
        <div data-id="dc4-completed-indicator"
          className="inline-container success">
          <Icon icon="check" />
          <Translate value="request.page.dc4.completedText" />
        </div>
      );
    }

    return (
      <div data-id="dc4-uncompleted-indicator"
        className="inline-container error">
        <Icon icon="error" />
        <Translate value="request.page.dc4.unCompletedText" />
      </div>
    );
  }

  return (
    <>
      <h2 className="no-wrap">
        <Translate value="request.page.dc4.requestLabel" />
      </h2>
      <div className="card pt-0">
        <div className="box border bottom">
          <div className="card-header font-size-l">
            <Translate value="request.page.dc4.requestSubtitle" />
          </div>
          <div className="flex-container space-between">
            {getStateIndicatort()}
            <div className="inline-container flex-shrink-0">
              {getActionButton()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

RequestDC4Status.propTypes = {
  request: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  onPDFDownload: PropTypes.func.isRequired,
  userRoles: PropTypes.array.isRequired,
  organisationId: PropTypes.number.isRequired
};

export default RequestDC4Status;
