import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import moment from 'moment';

export const ProjectSummaryView = props => {
  const getInviteeRow = invitee => (
    <>
      <div className="card-header font-size-m pb-3"><Translate value={`project.invite.${invitee.role}.invitedNotice`} dangerousHTML/></div>
      <div className="information">
        <div>
          {invitee.fullName || invitee.organisationName}
          <strong>{` (${invitee.registrationNumber})`}</strong>
        </div>
        <div className="pt-3">{invitee.user?.email || invitee?.email}</div>
      </div>
    </>
  );

  return (
    <>
      <div className="box border bottom">
        <div className="inline-container col-12">
          <div className="col-3">
            <div className="card-header font-size-m"><Translate value="project.details.name"/></div>
            <div className="information">
              {props.projectDetails?.name}
            </div>
          </div>

          <div className="col-3">
            <div className="card-header font-size-m"><Translate value="address.addressLine"/></div>
            <div className="information">
              {`${props.projectDetails?.address.addressLine},`}
              <br/>
              {`${props.projectDetails?.address.postCode} ${props.projectDetails?.address.city}`}
            </div>
          </div>
        </div>
      </div>

      <div className="box border bottom">
        <div className="inline-container col-12">
          <div className="col-3">
            <div className="card-header font-size-m"><Translate value="project.details.openingDate"/></div>
            <div className="information">{moment(props.projectDetails?.openingDate).format('DD/MM/YYYY')}</div>
          </div>

          <div className="col-3">
            <div className="card-header font-size-m"><Translate value="project.details.closingDate"/></div>
            <div className="information">{moment(props.projectDetails?.closingDate).format('DD/MM/YYYY')}</div>
          </div>

          <div className="col-6 pl-4">
            <div className="card-header font-size-m"><Translate value="project.role.chosenRole"/></div>
            <strong>{I18n.t(`project.role.${props.projectRole}.title`)}</strong>
          </div>
        </div>
      </div>

      <div className="box border bottom">
        <div className="inline-container col-12">
          <div className="col-6">
            <div className="card-header font-size-m"><Translate value="project.documents.requiredDocuments"/></div>
            <div className="information">
              <ul>
                {props.projectDocuments?.map((document, index) =>
                  <li className="mb-2" key={index}>{document.label}
                    {document.complementary?.length > 0 ?
                      <ul style={{listStyleType: 'circle'}} >
                        {document.complementary.map((complementary, j) =>
                          <li className="mt-2 ml-4" key={document.code + j}>{complementary}</li>
                        )}
                      </ul> :
                      null
                    }
                  </li>
                )}
              </ul>
            </div>
          </div>

          <div className="col-6">
            {props.participants.map((participant, index) => {
              let className = 'box border bottom pb-5';
              if (index === props.participants.length - 1) {
                className = 'box border pb-5';
              }

              return (
                <div key={index} className={className}>
                  {getInviteeRow(participant)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

ProjectSummaryView.defaultProps = {
  participants: []
};

ProjectSummaryView.propTypes = {
  projectDetails: PropTypes.object,
  projectDocuments: PropTypes.array,
  projectRole: PropTypes.string,
  participants: PropTypes.array.isRequired
};

export default ProjectSummaryView;
