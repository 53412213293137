import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import * as subscriptionActions from '../../../actions/subscription';
import {projectPropertiesPropTypes} from '../../../utils/propTypes';
import {PROJECT_ASPECT} from '../../../utils/constant';
import CreateRequestForApprovalButtonView from './CreateRequestForApprovalButtonView';

function computeDisabledAndTooltipsMessages(subscription, unlimitedRfaProjectExists, project, hasProjectReadyAspect) {
  const credits = subscription.credits || null;
  const creditsLoading = subscription.isFetching || false;
  const creditsError = subscription.error || false;
  const hasEnoughCredits = credits && !isNaN(credits.requestForApprovalCredits) && credits.requestForApprovalCredits > 0;

  // The button is disabled when loading data or current organisation has not enough credits
  let disabled = creditsLoading || creditsError || !hasEnoughCredits;
  let tooltipMessageTranslation = null;

  // When the "project" context is given, the button is disabled if project
  // is not ready. This "disabled" context has priority over others
  if (project && !hasProjectReadyAspect) {
    tooltipMessageTranslation = 'request.create.tooltip';
    disabled = true;
  } else if (unlimitedRfaProjectExists) {
    disabled = false;
  } else if (project?.unlimitedRfa) {
    tooltipMessageTranslation = 'request.unlimitedRfa';
    disabled = false;
  } else if (disabled) {
    tooltipMessageTranslation = creditsError ? 'subscription.credits.error' : 'request.noCredits';
  }

  return [disabled, tooltipMessageTranslation];
}

function CreateRequestForApprovalButtonContainer({color, icon, project, unlimitedRfaProjectExists}) {
  const history = useHistory();
  const globalDispatch = useDispatch();
  const subscription = useSelector(state => state.subscription) || {};

  let hasProjectReadyAspect = false;
  let hasRfaCreationAllowedAspect = false;
  if (project?.properties?.aspects?.length > 0) {
    hasProjectReadyAspect = project.properties.aspects.includes(PROJECT_ASPECT.PROJECT_READY);
    hasRfaCreationAllowedAspect = project.properties.aspects.includes(PROJECT_ASPECT.RFA_CREATION_ALLOWED);
  }

  // When the "project" context is given, button exists only if current organisation
  // has the aspect to create a request for approval
  const hideComponent = project && !hasRfaCreationAllowedAspect;

  const [
    disabled, tooltipMessageTranslation
  ] = computeDisabledAndTooltipsMessages(subscription, unlimitedRfaProjectExists, project, hasProjectReadyAspect);

  const fetchOrganisationCredits = () => {
    globalDispatch(subscriptionActions.fetchSubscription());
  };

  const handleCreateRequestForApprovalButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    const projectQueryParameter = project?.id ? `?projectId=${project.id}` : '';
    history.push(`/requests/new${projectQueryParameter}`);
  };

  React.useEffect(() => {
    if (hideComponent) {
      return;
    }

    fetchOrganisationCredits();
  }, []);

  if (hideComponent) {
    return null;
  }

  return (
    <CreateRequestForApprovalButtonView
      icon={icon}
      color={color}
      disabled={disabled}
      tooltipMessage={tooltipMessageTranslation && <Translate value={tooltipMessageTranslation}/>}
      onClick={handleCreateRequestForApprovalButtonClick}
    />
  );
}

CreateRequestForApprovalButtonContainer.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    properties: projectPropertiesPropTypes().isRequired,
    unlimitedRfa: PropTypes.bool.isRequired
  }),
  unlimitedRfaProjectExists: PropTypes.bool
};

export default CreateRequestForApprovalButtonContainer;
