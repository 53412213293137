import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import * as snackbarsActions from '../../actions/snackbars';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {userService} from '../../services/user/UserService';

export class UserActivationView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false
    };

    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.submitted) {
      return;
    }

    this.setState({submitted: true});

    userService.sendActivationEmail(this.props.user.id)
      .then(() => {
        this.setState({submitted: false});
        this.props.actions.addSuccess(<Translate value="activateUser.emailSent"/>);
      })
      .catch(() => {
        this.setState({submitted: false});
        this.props.actions.addError(<Translate value="error.generic"/>);
      });
  }

  render() {
    return (
      <div className="main-template background-white no-padding p-5" style={{height: '100vh'}}>
        <div className="row justify-content-center align-items-center" style={{height: '100%'}}>
          <div
            className="box emphasis flex-container column col-xl-6 col-lg-8 col-md-10 center space-between p-4"
            style={{minHeight: 450}}
          >
            <div className="inline-container center column success">
              <div style={{height: 200}}>
                <img alt="activation email" src="/resources/svg/activation-email.svg" style={{height: '100%'}}/>
              </div>

              <h2 className="m-0" style={{fontWeight: 600, textAlign: 'center'}}>
                {I18n.t('activateUser.message', {email: this.props.user.email})}
              </h2>
            </div>

            <strong>
              <Translate value="activateUser.subMessage"/>
            </strong>

            <div>
              <div className="mb-4">
                <Translate value="activateUser.resendMessage"/>
                <a data-id="resend-link" onClick={this.handleButtonClick} href="#something">
                  <Translate value="activateUser.button"/>
                </a>
              </div>

              <span>
                <Translate value="activateUser.messageLink"/>
                <Link to="/">
                  <Translate value="activateUser.linkLabel"/>
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserActivationView.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserActivationView);
