import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import {userService} from '../../services/user/UserService';
import {bindActionCreators} from 'redux';
import * as snackbarsActions from '../../actions/snackbars';
import {connect} from 'react-redux';
import ChangeEmail from './ChangeEmail';
import {AbstractFormComponent, Icon, TextPhoneNumber, validators} from 'front-onceforall-core';
import TextField from '@material-ui/core/TextField';
import Modal from 'react-modal';
import {captureError} from '../../utils/log';
import configureStore from '../../store/configureStore';

export class UserDetails extends AbstractFormComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      isModalOpen: false,
      canNotUpdateProfile: true
    };

    // this.handleClickContact = this.handleClickContact.bind(this);
    this.handleSwitchModal = this.handleSwitchModal.bind(this);
  }

  componentDidMount() {
    this.fetchUserDetails();
  }

  getFormData() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      mobilePhoneNumber: ''
    };
  }

  getFormValidators() {
    return {
      firstName: validators.requiredValidator,
      lastName: validators.requiredValidator,
      email: null,
      phoneNumber: [validators.requiredValidator, validators.phoneNumberValidator],
      mobilePhoneNumber: validators.mobilePhoneNumberValidator
    };
  }

  /*
  canNotUpdateProfile(userDetails) {
    const hasSignPermission = Object.keys(userDetails.permissionsByOrganisationIds).some(organisationId =>
      userDetails.permissionsByOrganisationIds[organisationId].includes(SIGN_PERMISSION)
    );
    const hasManagerRole = Object.keys(userDetails.roleByOrganisationIds).some(organisationId =>
      userDetails.roleByOrganisationIds[organisationId].includes(MANAGER_ROLE));
    const isSecureConnect = !arrayUtils.isEmpty(userDetails.authorizedClients);
    return hasSignPermission || hasManagerRole || isSecureConnect;
  }
  */

  fetchUserDetails() {
    userService.getUserDetailsById(this.props.user.id)
      .then(userDetails => {
        this.setState({
          formData: userDetails,
          loading: false
          // not Implemented yet
          // canNotUpdateProfile: this.canNotUpdateProfile(userDetails)
        });
      })
      .catch(error => {
        this.setState({error: true});
        captureError('Error while fetching user details', error);
        this.props.actions.addError(<Translate value="error.generic"/>);
      });
  }

  handleSubmitCallback() {
    const userDetailsUpdated = {...this.state.userDetails, ...this.state.formData};
    userService.updateUserDetails(userDetailsUpdated)
      .then(() => {
        this.props.actions.addSuccess(<Translate value="message.changesSaved"/>);
        this.props.onCloseRequest();
      })
      .catch(error => {
        this.props.actions.addError(<Translate value="error.generic"/>);
        captureError('Error while submitting user details form', error);
        this.props.onCloseRequest();
      });
  }

  handleSwitchModal() {
    this.setState(state => {
      return {isModalOpen: !state.isModalOpen};
    });
  }

  // Not yet implemented: display a contact form for MyCRM
  /*
  handleClickContact(event) {
    event.stopPropagation();
    event.preventDefault();
    this.props.history.push(`${this.props.location.pathname}?contact=${userRequestTypes.MYSETTINGS}`);
    this.props.onCloseRequest();
  }
  */

  renderUserDetails() {
    if (this.state.error) {
      return <Translate value="error.generic"/>;
    }

    const currentLocale = configureStore.getStore().getState().i18n.locale;

    return (
      <>
        {this.state.loading && <div className="loading-overlay"/>}
        <form onSubmit={this.handleSubmit}>
          {this.state.canNotUpdateProfile &&
            <div className="box flex-container mb-4" data-id="info-box">
              <span className="flex-shrink-0">
                <Icon icon="info" size={20}/>
              </span>
              <span>
                <Translate value="user.canNotUpdateProfile"/>
                {/* <a onClick={this.handleClickContact} href="#something"> */}
                <Translate value="user.canNotUpdateProfileLinkText"/>
                {/* </a> */}
              </span>
            </div>
          }
          <div className="row">
            <div className="col-sm-6">
              <TextField
                label={this.getFieldLabel('user.firstName', true)}
                error={this.isOnError('firstName')}
                helperText={this.helperText('firstName')}
                id="firstName"
                name="firstName"
                value={this.state.formData.firstName}
                onChange={this.handleChange('firstName')}
                disabled={this.state.canNotUpdateProfile}
              />
            </div>
            <div className="col-sm-6">
              <TextField
                label={this.getFieldLabel('user.lastName', true)}
                error={this.isOnError('lastName')}
                helperText={this.helperText('lastName')}
                id="lastName"
                name="lastName"
                value={this.state.formData.lastName}
                onChange={this.handleChange('lastName')}
                disabled={this.state.canNotUpdateProfile}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 inline-container center">
              <TextField
                label={this.getFieldLabel('user.email', true)}
                id="email"
                name="email"
                value={this.state.formData.email}
                disabled
                InputProps={{
                  endAdornment: (
                    <button
                      type="button"
                      data-id="changeEmailButton"
                      className="large secondary inline-container no-wrap"
                      onClick={this.handleSwitchModal}
                      disabled={this.state.canNotUpdateProfile}
                    >
                      <Translate value="user.changeEmailButton"/>
                      <Icon icon="arrow-right"/>
                    </button>
                  )
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <TextPhoneNumber
                name="phoneNumber"
                label={this.getFieldLabel('user.phoneNumber', true)}
                value={this.state.formData.phoneNumber || ''}
                onChange={this.handleChange('phoneNumber')}
                error={this.isOnError('phoneNumber')}
                helperText={this.helperText('phoneNumber')}
                disabled={this.state.canNotUpdateProfile}
                currentLocale={currentLocale}
              />
            </div>
            <div className="col-lg-6">
              <TextPhoneNumber
                name="mobilePhoneNumber"
                label={this.getFieldLabel('user.mobilePhoneNumber', true)}
                value={this.state.formData.mobilePhoneNumber || ''}
                onChange={this.handleChange('mobilePhoneNumber')}
                error={this.isOnError('mobilePhoneNumber')}
                helperText={this.helperText('mobilePhoneNumber')}
                disabled={this.state.canNotUpdateProfile}
                currentLocale={currentLocale}
              />
            </div>
          </div>
          <div className="actions col-12 inline-container flex-end">
            <button
              className="large primary inline-container"
              data-id="validateButton" type="submit"
              disabled={this.state.submitting || this.state.canNotUpdateProfile}
            >
              <Translate value="action.confirm"/>
              <Icon icon={this.state.submitting ? 'loader' : 'check'}/>
            </button>
          </div>
        </form>
      </>
    );
  }

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.isModalOpen && !this.state.canNotUpdateProfile}
          defaultStyles={{
            overlay: {
              backgroundColor: 'rgba(255,255,255,0.7)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 2000
            },
            content: {
              backgroundColor: '#fff',
              borderRadius: 4,
              boxShadow: 'rgba(0, 0, 0, 0.35) 0px 0px 32px 0px',
              minWidth: 300,
              outline: 0,
              padding: 20,
              position: 'relative'
            }
          }}
          onRequestClose={this.handleSwitchModal}
          ariaHideApp={false}
          shouldCloseOnOverlayClick
        >
          <ChangeEmail
            onClose={this.handleSwitchModal}
            userId={this.props.user.id}
            actions={this.props.actions}
          />
        </Modal>
        {this.renderUserDetails()}
      </>
    );
  }
}

UserDetails.propTypes = {
  user: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  onCloseRequest: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired,
  history: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetails));
