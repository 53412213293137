import 'isomorphic-fetch';
import config from '../../../config';
import {authFetch, checkStatus} from '../../utils/http';

export default class LiveOrganisationService {
  getOrganisationsList(organisationIds) {
    // Remove null and duplicated values in list
    const sanitizedList = [...new Set(organisationIds.filter(Boolean))];
    return sanitizedList.length === 0 ?
      Promise.resolve([]) :
      authFetch(`${config.userOrgaApi}/api/v1/organisations?organisationIds=${sanitizedList}`)
        .then(checkStatus)
        .then(response => response.json());
  }

  getByRegistrationNumberAndCountry(registrationNumber, country) {
    let queryParams = `?registrationNumber=${encodeURIComponent(registrationNumber)}&country=${country}`;

    return authFetch(`${config.userOrgaApi}/api/v1/organisations${queryParams}`)
      .then(checkStatus)
      .then(response => response.status === 204 ? null : response.json());
  }

  search(query) {
    let queryParams = `/search-by-identifiers?query=${encodeURIComponent(query)}`;
    return authFetch(`${config.userOrgaApi}/api/v1/organisations${queryParams}`)
      .then(checkStatus)
      .then(response => response.status === 204 ? null : response.json());
  }

  searchByUser(userId, query = null, withDeactivatedOrganisation = false, withPermissionToken = true) {
    let queryParam = query ? `&query=${encodeURIComponent(query)}` : '';
    queryParam += withDeactivatedOrganisation ? '' : '&enabled=true';
    return authFetch(`${config.userOrgaApi}/api/v1/organisations?userId=${userId}${queryParam}`, null, withPermissionToken)
      .then(checkStatus)
      .then(response => response.status === 204 ? null : response.json());
  }

  createOrganisation(organisation) {
    return authFetch(`${config.userOrgaApi}/api/v1/organisations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(organisation)
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  getDefaultOrganisation(organisationId, ofaOrganisationId) {
    const urlBase = `${config.userOrgaApi}/api/v1/organisations/default`;
    let url = new URL(urlBase);
    let searchParams = new URLSearchParams(url.search);
    let intValue = Number.NaN;

    try {
      intValue = Number.parseInt(organisationId, 10);
      if (!Number.isNaN(intValue)) {
        searchParams.append('organisationId', Number.parseInt(organisationId, 10));
      }
    } catch (error) {
      console.warn('getDefaultOrganisation, parseInt', error);
    }

    if (ofaOrganisationId) {
      intValue = Number.parseInt(ofaOrganisationId, 10);
      try {
        if (!Number.isNaN(intValue)) {
          searchParams.append('ofaOrganisationId', Number.parseInt(ofaOrganisationId, 10));
        }
      } catch (error) {
        console.warn('getDefaultOrganisation, parseInt', error);
      }
    }

    const params = searchParams.toString();
    const queryParam = params.length > 0 ? `?${params}` : params;
    return authFetch(`${urlBase}${queryParam}`)
      .then(checkStatus)
      .then(response => response.status === 204 ? null : response.json());
  }
}
