import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import OrganisationCreditsView from './OrganisationCreditsView';
import SidePanel from 'front-onceforall-core/dist/views/SidePanel';
import {authenticationService} from '../../../services/authentication/AuthenticationService';
import CreditsEditor from './creditsEditor/CreditsEditorForm';
import {Translate} from 'react-redux-i18n';
import {captureError} from '../../../utils/log';

export const OrganisationCreditsContainer = ({actions, organisation}) => {
  const subscription = useSelector(state => state.subscription) || {};
  const credits = subscription.credits || null;
  const loading = subscription.isFetching || false;
  const [error, setError] = React.useState(false);

  const [isPanelOpen, setIsPanelOpen] = React.useState(false);

  const isBackOffice = authenticationService.roles().isBackOffice();

  let triggerClosePanel = () => {
    setIsPanelOpen(false);
  };

  const handleUpdateCredits = updatedCredits => {
    if (updatedCredits.projectCredits === credits?.projectCredits &&
      updatedCredits.requestForApprovalCredits === credits?.requestForApprovalCredits) {
      triggerClosePanel();
      return;
    }

    actions.updateSubscriptionCredits(updatedCredits)
      .then(() => {
        actions.addSuccess(<Translate
          value="organisation.page.updateSuccess"
          organisationName={organisation.fullName}/>);
      })
      .catch(error => {
        captureError('handleUpdateCredits', error);
        actions.addError(<Translate value="error.generic"/>);
      })
      .finally(() => {
        triggerClosePanel();
      });
  };

  const fetchOrganisationCredits = () => {
    actions.fetchSubscription(true)
      .catch(() => {
        setError(true);
      });
  };

  React.useEffect(() => {
    if (organisation) {
      fetchOrganisationCredits();
    } else {
      setError(true);
    }
  }, []);

  return (
    <>
      <OrganisationCreditsView
        organisation={organisation}
        credits={credits}
        loading={loading}
        error={error}
        onPanelAction={setIsPanelOpen}
        isUserBackOffice={isBackOffice}
      />
      {isPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsPanelOpen(false)}
        >
          <CreditsEditor
            credits={credits}
            onCancel={() => triggerClosePanel()}
            onValidate={handleUpdateCredits}
          />
        </SidePanel>
      }
    </>
  );
};

OrganisationCreditsContainer.propTypes = {
  organisation: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

export default OrganisationCreditsContainer;
