import React from 'react';
import PropTypes from 'prop-types';
import {Route, useHistory} from 'react-router-dom';

import {captureError} from '../utils/log';

function PrivateRoute(props) {
  const history = useHistory();

  if (!props.canAccess) {
    // Redirect to access denied page if route cannot be accessed
    captureError(`Access Denied redirection from ${props.path}`);
    history.push('/access-denied');
    return null;
  }

  return <Route {...props}/>;
}

PrivateRoute.propTypes = {
  canAccess: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired
};

export default PrivateRoute;
