import {isEmpty} from 'front-onceforall-core/dist/utils/strings';

export const onContactChange = (index, name, value) => current => {
  const newContacts = [...current.subcontractorContacts];
  newContacts[index][name] = value;
  return {
    subcontractorContacts: newContacts
  };
};

export const addContact = current => {
  const lastContact = current?.subcontractorContacts?.[current?.subcontractorContacts?.length - 1] || null;
  if (lastContact &&
    isEmpty(lastContact.firstName) &&
    isEmpty(lastContact.lastName) &&
    isEmpty(lastContact.jobTitle)
  ) {
    return {};
  }

  return {
    subcontractorContacts: [...current?.subcontractorContacts || [], {firstName: '', lastName: '', jobTitle: ''}]
  };
};
