import {SidePanel} from 'front-onceforall-core';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import {Translate} from 'react-redux-i18n';
import {projectService} from '../../../../services/project/ProjectService';
import ProjectDetails from '../../../projectCreate/projectDetails';
import ProjectInformationView from './ProjectInformationView';

export const ProjectInformationContainer = props => {
  const [isPanelOpen, setIsPanelOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  let triggerClosePanel = () => {};

  const publicProcurementObjectChanged = projectInformation => {
    return projectInformation.publicProcurement &&
    projectInformation.publicProcurementData.publicProcurementObject !== props.project.publicProcurementData.publicProcurementObject;
  };

  const haveDetailsChanged = projectInformation => {
    return projectInformation.name !== props.project.name ||
    !moment(projectInformation.openingDate).isSame(moment(props.project.openingDate), 'day') ||
    !moment(projectInformation.closingDate).isSame(moment(props.project.closingDate), 'day') ||
     projectInformation.address.addressLine !== props.project.address.addressLine ||
     projectInformation.address.city !== props.project.address.city ||
     projectInformation.address.postCode !== props.project.address.postCode ||
    publicProcurementObjectChanged(projectInformation);
  };

  const handleDetailsEdition = projectInformation => {
    if (haveDetailsChanged(projectInformation)) {
      setLoading(true);
      projectService.editDetails(props.project.id, projectInformation)
        .then(response => {
          props.actions.addSuccess(<Translate value="project.edit.success"/>);
          props.onProjectUpdate(response);
        })
        .catch(() => {
          props.actions.addError(<Translate value="project.edit.failed"/>);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    triggerClosePanel();
  };

  return (
    <>
      <ProjectInformationView
        project={props.project}
        isProjectEditionAllowed={props.isProjectEditionAllowed}
        loading={props.loading}
        error={props.error}
        onEditButtonClick={() => setIsPanelOpen(true)}
      />
      {isPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsPanelOpen(false)}
        >
          <ProjectDetails
            onValidate={handleDetailsEdition}
            isOpen={isPanelOpen}
            loading={loading}
            project={props.project}
            onCancel={triggerClosePanel}
            displayEditMessage={true}
            disableProcurementSelection={true}
          />
        </SidePanel>
      }
    </>
  );
};

ProjectInformationContainer.propTypes = {
  actions: PropTypes.object,
  project: PropTypes.object,
  isProjectEditionAllowed: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  onEditButtonClick: PropTypes.func,
  onProjectUpdate: PropTypes.func
};

export default ProjectInformationContainer;
