import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import Tooltip from '@material-ui/core/Tooltip';
import {Icon, stringUtils} from 'front-onceforall-core';

const classes = {
  root: {
    marginBottom: 15,
    border: '1px solid #ccc',
    padding: 16
  },
  cardTodo: {
    minHeight: 'inherit',
    padding: 0,
    marginBottom: 0
  },
  cardTodoContent: {
    alignItems: 'center',
    marginBottom: 0
  }
};

export const ActionView = props => {
  return (
    <div className="box" data-id="todo-user" style={classes.root}>
      <div className="card todo" style={classes.cardTodo}>
        <div className="todo-content" style={classes.cardTodoContent}>
          <div className="information">
            <Icon icon="user-plus"/>
          </div>
          <div className="todo-text">
            <div>
              {stringUtils.getStrongs(I18n.t('dashboard.notification.user.pending', {
                firstName: props.pendingUser.user.firstName,
                lastName: props.pendingUser.user.lastName
              }))}
            </div>
          </div>
        </div>
        <div className="action-bar">
          <Tooltip title={<Translate value="dashboard.notification.actions.acceptRequest"/>} placement="top">
            <button
              type="button"
              className="button-square success"
              data-id="accept"
              onClick={event => props.onAcceptUser(event, props.pendingUser.user.id)}
            >
              <Icon icon="check"/>
            </button>
          </Tooltip>
          <Tooltip title={<Translate value="dashboard.notification.actions.declineRequest"/>} placement="top">
            <button
              type="button"
              className="button-square error"
              data-id="decline"
              onClick={event => props.onDeclineUser(event, props.pendingUser.user.id)}
            >
              <Icon icon="cross"/>
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

ActionView.propTypes = {
  pendingUser: PropTypes.object.isRequired,
  onAcceptUser: PropTypes.func.isRequired,
  onDeclineUser: PropTypes.func.isRequired
};

export default ActionView;
