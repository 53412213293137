import React from 'react';
import PropTypes from 'prop-types';
import ProjectDetailsView from './ProjectDetailsView';
import {PROJECT_ASPECT, USER_ORGANISATION_ROLE} from '../../../utils/constant';
import {projectService} from '../../../services/project/ProjectService';
import {Translate} from 'react-redux-i18n';
import {captureError} from '../../../utils/log';
import ConfirmModal from '../../modal/ConfirmModal';
import Icon from 'front-onceforall-core/dist/components/Icon';
import {authenticationService} from '../../../services/authentication/AuthenticationService';

const allowedEditionRole = [
  USER_ORGANISATION_ROLE.SIGNATORY,
  USER_ORGANISATION_ROLE.VERIFICATOR,
  USER_ORGANISATION_ROLE.VERIFICATOR_SIGNATORY
];

export const ProjectDetailsContainer = props => {
  const [project, setProject] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [isGeneralContractorInvitationPanelOpen, setIsGeneralContractorInvitationPanelOpen] = React.useState(false);
  const [authorizedActions, setAuthorizedActions] = React.useState(false);
  const [confirmDeleteModalOpen, isConfirmDeleteModalOpen] = React.useState(false);
  const [projectEditable, isProjectEditable] = React.useState(false);
  const [isProjectOwner, setIsProjectOwner] = React.useState(false);
  const [isProjectEditionAllowed, setIsProjectEditionAllowed] = React.useState(false);

  const userRoles = authenticationService.roles();
  const isBackOffice = userRoles.isBackOffice();

  const fetchProject = projectId => {
    setLoading(true);
    projectService.get(projectId)
      .then(response => {
        setProject(response);
        isProjectEditable(response?.properties?.aspects?.includes(PROJECT_ASPECT.PROJECT_EDITABLE));
        const isOwner = props.organisation.id === response.creatorOrganisationId;
        setIsProjectOwner(isOwner);

        const canEditProject = isOwner && allowedEditionRole.some(role => userRoles.hasProjectRole(projectId, role));
        setIsProjectEditionAllowed(canEditProject);

        const areActionsAuthorized = [
          response.clientId,
          response.clientDelegateId,
          response.clientAssistantId,
          response.projectManagerId,
          response.creatorOrganisationId
        ].includes(props.organisation.id);
        setAuthorizedActions(areActionsAuthorized);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteProject = () => {
    projectService.delete(project.id)
      .then(() => {
        props.actions.addSuccess(<Translate value="project.page.deleteSuccess"/>);
        props.history.replace('/projects');
      })
      .catch(error => {
        isConfirmDeleteModalOpen(false);
        captureError('[ProjectDetailsContainer] Error while deleting ' + project.id, error);
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const toggleUnlimitedRfa = () => {
    setLoading(true);
    projectService.editProjectUnlimitedRfa(project.id, !project.unlimitedRfa)
      .then(() => {
        fetchProject(project.id);
      })
      .catch(error => {
        captureError('[ProjectDetailsContainer] Error while editProjectUnlimitedRfa ' + project.id, error);
        props.actions.addError(<Translate value="error.generic"/>);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (props.projectId) {
      fetchProject(props.projectId);
    } else {
      setError(true);
    }
  }, []);

  return (
    <>
      <ProjectDetailsView
        project={project}
        projectId={props.projectId}
        loading={loading}
        error={error}
        onManageRolesPanelAction={props.onManageUserRolesPanelOpen}
        openProjectOwnerTagEditionFormPanel={props.openProjectOwnerTagEditionFormPanel}
        isGeneralContractorInvitationPanelOpen={isGeneralContractorInvitationPanelOpen}
        onGeneralContractorInvitationPanelAction={setIsGeneralContractorInvitationPanelOpen}
        authorizedActions={authorizedActions}
        onDeleteProject={() => isConfirmDeleteModalOpen(true)}
        isProjectEditable={projectEditable}
        isProjectEditionAllowed={isProjectEditionAllowed}
        isProjectOwner={isProjectOwner}
        isBackOffice={isBackOffice}
        actions={props.actions}
        organisation={props.organisation}
        onProjectUpdate={setProject}
        toggleUnlimitedRfa={toggleUnlimitedRfa}
      />
      {projectEditable &&
        <ConfirmModal
          data-id="delete-confirm-modal"
          isOpen={confirmDeleteModalOpen}
          onConfirm={() => handleDeleteProject()}
          onClose={() => isConfirmDeleteModalOpen(false)}
          title={<Translate value="project.page.deleteInformationModal.title"/>}
          content={<Translate value="project.page.deleteInformationModal.content"/>}
          submitButtonColor="negative"
          submitButtonBody={<><Icon icon="trash"/><Translate value="action.delete"/></>}
        />
      }
    </>
  );
};

ProjectDetailsContainer.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  onManageUserRolesPanelOpen: PropTypes.func.isRequired,
  openProjectOwnerTagEditionFormPanel: PropTypes.func.isRequired
};

export default ProjectDetailsContainer;
