import {getInvitationsFakeData} from '../../utils/database/invitations';
import {a404HttpError, anHttpNoContent} from '../../utils/fixtures';

const fakeInvitePromise = () => {
  return new Promise(resolve => {
    setTimeout(() => resolve(anHttpNoContent()), 1000);
  });
};

export default class FakeEnrollmentService {
  inviteUnknownSubcontractor() {
    fakeInvitePromise();
  }

  inviteUnknownProjectManager() {
    fakeInvitePromise();
  }

  inviteUnknownClient() {
    fakeInvitePromise();
  }

  inviteUnknownProjectGeneralContractor() {
    fakeInvitePromise();
  }

  inviteUnknownSafetyCoordinator() {
    fakeInvitePromise();
  }

  inviteUnknownClientDelegate() {
    fakeInvitePromise();
  }

  inviteUnknownClientAssistant() {
    fakeInvitePromise();
  }

  getInvitationByCode(code) {
    const invitation = getInvitationsFakeData().find(invitation => invitation.invitationCode === code);
    return invitation ? Promise.resolve(invitation) : Promise.reject(a404HttpError());
  }

  getInvitationByProjectId(projectId) {
    const invitation = getInvitationsFakeData().filter(invitation => invitation.projectId === projectId);
    return invitation ? Promise.resolve(invitation) : Promise.reject(a404HttpError());
  }

  getInvitationByRequestId(requestId) {
    const invitation = getInvitationsFakeData().filter(invitation => invitation.requestForApprovalId === requestId);
    return invitation ? Promise.resolve(invitation) : Promise.reject(a404HttpError());
  }

  deleteProjectParticipantInvitationById(invitationId, projectId) {
    return new Promise(resolve => {
      setTimeout(() => resolve(anHttpNoContent(invitationId, projectId)), 1000);
    });
  }
}
