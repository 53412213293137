import config from '../../../config';
import {authFetch, checkStatus} from '../../utils/http';

export default class LiveSubscriptionService {
  getOrganisationCredits(organisationId) {
    return authFetch(`${config.subscriptionApi}/api/v1/credits/${organisationId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  updateOrganisationCredits(updatedCredits, organisationId) {
    return authFetch(`${config.subscriptionApi}/api/v1/credits/${organisationId}/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updatedCredits)
    })
      .then(checkStatus)
      .then(response => response.json());
  }
}
