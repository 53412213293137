import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import {Translate} from 'react-redux-i18n';
import Document from '../requestDocuments/document';
import {Tooltip} from '@material-ui/core';
import {capitalizeFirstLetter} from '../../../utils/string';

const slash = '/';

export const RequestDocumentsListView = props => {
  const isSuccess = !props.loading && !props.error && props.uploadedDocCount === props.documents.length;
  let cardHeaderColor = 'warning';
  let cardHeaderTranslation = 'request.documents.pandaDocumentsInformation';
  let lastSynchronisationDateInformationMessage;
  let lastSynchronisationDateTooltipMessage;
  if (props.areDocumentsFromOfa) {
    cardHeaderColor = 'blue';
    cardHeaderTranslation = 'request.documents.ofaDocumentsInformation';
    if (props.lastSynchronisationDate && props.lastSynchronisationDate.isValid()) {
      lastSynchronisationDateInformationMessage = capitalizeFirstLetter(props.lastSynchronisationDate.fromNow());
      lastSynchronisationDateTooltipMessage = <Translate
        value={props.synchronisationEnabled ? 'request.documents.ofaDocumentUpdateInformationTooltip' : 'request.documents.ofaDocumentUpdateDisabledTooltip'}
        date={props.lastSynchronisationDate.format('DD/MM/YYYY')}
        time={props.lastSynchronisationDate.format('HH:mm')}
      />;
    }
  }

  return (
    <>
      <div className="flex-container space-between">
        <h2 className="no-wrap">
          <Translate value="request.page.documents"/>
          <span className="separator"/>
          <span className={isSuccess ? 'success' : 'warning'}>
            <strong>
              {props.uploadedDocCount}
              {slash}
              {props.documents.length}
            </strong>
          </span>
        </h2>
        {props.areDocumentsFromOfa &&
          <div className="flex-container">
            {lastSynchronisationDateInformationMessage && lastSynchronisationDateTooltipMessage &&
              <Tooltip data-id="last-synchronisation-date-tooltip" title={lastSynchronisationDateTooltipMessage}>
                <span className="information" style={{textAlign: 'end'}}>{lastSynchronisationDateInformationMessage}</span>
              </Tooltip>
            }
            <span className="symbol symbol-ofa-no-text"/>
          </div>
        }
      </div>
      <div className="card">
        {props.error ?
          <span data-id="error-message"><Translate value="error.generic"/></span> :
          props.loading ?
            <div className="loading-overlay"/> :
            <>
              <div className={`card information inline-container column styled ${cardHeaderColor}`}>
                <Translate value={cardHeaderTranslation} style={{textAlign: 'center'}}/>
                <div style={{paddingTop: '10px', borderBottom: 'solid 2px', width: '80%'}}/>
              </div>
              <div className="flex-container column">
                {props.documents.map((document, index) =>
                  <div key={index}>
                    <Document
                      document={document}
                      fromOfa={props.areDocumentsFromOfa}
                      requestId={props.requestId}
                      onDocumentUpload={props.onDocumentUpload}
                      isUploadEnabled={props.isUploadEnabled}
                    />
                  </div>
                )}
              </div>
            </>
        }
      </div>
    </>
  );
};

RequestDocumentsListView.defaultProps = {
  areDocumentsFromOfa: false,
  lastSynchronisationDate: null,
  synchronisationEnabled: true,
  isUploadEnabled: true,
  loading: true,
  error: false
};

RequestDocumentsListView.propTypes = {
  requestId: PropTypes.number.isRequired,
  areDocumentsFromOfa: PropTypes.bool,
  documents: PropTypes.array.isRequired,
  lastSynchronisationDate: momentPropTypes.momentObj,
  synchronisationEnabled: PropTypes.bool,
  uploadedDocCount: PropTypes.number.isRequired,
  isUploadEnabled: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onDocumentUpload: PropTypes.func.isRequired
};

export default RequestDocumentsListView;
