import React from 'react';
import {requestPropTypes} from '../../../utils/propTypes';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import moment from 'moment-timezone';
import {withRouter} from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import {Icon} from 'front-onceforall-core';
import {isEmpty as isEmptyArray} from 'front-onceforall-core/dist/utils/arrays';
import {PROJECT_ORGANISATION_ROLE, TASK_TYPE, USER_ORGANISATION_ROLE, WORKFLOW_STATUS, WORKFLOW_STATUS_COLOR} from '../../../utils/constant';
import {authenticationService} from '../../../services/authentication/AuthenticationService';
import {capitalizeFirstLetter} from '../../../utils/string';

const classes = {
  root: {
    marginBottom: 15,
    border: '1px solid #ccc',
    padding: 16
  },
  button: {
    marginBottom: 12
  },
  secondText: {
    fontSize: 14
  }
};

export const CardView = props => {
  const request = props.request;
  const workflowStatus = props.workflowStatus;
  const creatorOrganisation = props.creatorOrganisation;
  const subcontractor = props.subcontractor;
  const creationDateString = moment(request.creationDate).format('DD/MM/YYYY');
  const lastSignatoryRole = request.project.clientId ? PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT : PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE;
  const lastSignatoryCondition = request.suspensiveConditions.find(condition => condition.organisationRole === lastSignatoryRole);

  const getBadgeForUserActions = activeTasks => {
    const userRoles = authenticationService.roles();

    // Display none badge when user has no role on project
    if (isEmptyArray(userRoles.getProjectRoles(request.project.id))) {
      return null;
    }

    const isVerificator = userRoles.hasProjectRole(request.project.id, USER_ORGANISATION_ROLE.VERIFICATOR);
    const isSignatory = userRoles.hasProjectRole(request.project.id, USER_ORGANISATION_ROLE.SIGNATORY);

    // Display none badge when user is neither verificator nor signatory
    if (!isVerificator && !isSignatory) {
      return null;
    }

    const activeVerifyTask = activeTasks.find(task => task.type === TASK_TYPE.VERIFY);
    const activeSignTask = activeTasks.find(task => task.type === TASK_TYPE.SIGN);

    if (isSignatory && activeSignTask) {
      if (isVerificator || !activeVerifyTask) {
        // Display sign badge when user is verificator & signatory and active tasks contain the sign task
        // OR Display sign badge when user is signatory only and active tasks contain ONLY the sign task
        return buildBadgeForUserActions('sign', 'sign');
      }

      return null;
    }

    // Display verify badge when user is verificator only and active tasks contain the verify task
    return isVerificator && activeVerifyTask ? buildBadgeForUserActions('verify', 'eye') : null;
  };

  const buildBadgeForUserActions = (taskType, icon) => (
    <Tooltip title={<Translate value={`request.${taskType}.badgeTooltip`}/>}>
      <span data-id={`${taskType}-information`} className="badge" style={{height: '50px', minWidth: '50px'}}>
        <Icon icon={icon}/>
      </span>
    </Tooltip>
  );

  const isActiveTaskForOrganisation = request.activeTasks.length > 0 && request.activeTasks[0].organisationId === props.organisation.id;
  const badgeForUserActions = isActiveTaskForOrganisation ? getBadgeForUserActions(request.activeTasks) : null;

  const rfaStatusMessage = isActiveTaskForOrganisation ?
    <Translate value="request.status.awaited.self" dangerousHTML/> :
    <Translate value="request.status.awaited.participant"
      awaitedOrgRole={I18n.t(`request.status.awaited.${props.awaitedOrganisation?.awaitedOrgRole}`)}
      awaitedOrgName={props.awaitedOrganisation?.awaitedOrgName}
      dangerousHTML/>;

  let rfaStatusElt = capitalizeFirstLetter(I18n.t(`request.status.${workflowStatus}`));

  rfaStatusElt = workflowStatus === WORKFLOW_STATUS.IN_PROGRESS && props.awaitedOrganisation ?
    <Tooltip data-id="progess-tooltip" title={rfaStatusMessage}>
      <span>{rfaStatusElt}</span>
    </Tooltip> : rfaStatusElt;

  rfaStatusElt = (workflowStatus === WORKFLOW_STATUS.APPROVED && lastSignatoryCondition) ?
    <Tooltip data-id="condition-tooltip" title={lastSignatoryCondition.content}>
      <span>{rfaStatusElt} <Translate value="request.page.suspensiveCondition.final.title"/></span>
    </Tooltip> : rfaStatusElt;

  return (
    <div className="clickable box" data-id="request" style={classes.root} onClick={props.onRedirectAction}>
      <div className="flex-container space-between">
        <div style={{width: '45%'}}>
          <h2>
            {subcontractor &&
              <span className="primary" style={{fontWeight: 600}} data-id="subcontractor">
                {subcontractor}
              </span>
            }
          </h2>
          <p className="information" style={classes.secondText}>
            <span data-id="request-project-info">
              <Translate className="information" value={'request.createdFrom'}
                creationDate={creationDateString}
                creatorOrganisation={creatorOrganisation}
                projectName={request.project.name}
                dangerousHTML
              />
            </span>
          </p>
        </div>
        <b className={WORKFLOW_STATUS_COLOR[workflowStatus]}>
          {rfaStatusElt}
        </b>
        <div style={{width: '5%'}}>{subcontractor && badgeForUserActions}</div>
      </div>
    </div>
  );
};

CardView.propTypes = {
  creatorOrganisation: PropTypes.string.isRequired,
  subcontractor: PropTypes.string,
  awaitedOrganisation: PropTypes.object,
  request: requestPropTypes().isRequired,
  workflowStatus: PropTypes.string.isRequired,
  onRedirectAction: PropTypes.func.isRequired,
  organisation: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

export default withRouter(connect(mapStateToProps)(CardView));
