import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import EmptyDashboard from './emptyDashboard/EmptyDashboard';
import PendingUserActions from './pendingUserActions';
import ActiveRequestActions from './activeRequestActions';

export const DashboardView = props => {
  return (
    <>
      <div className="row mt-4 mb-5">
        <h1 data-id="active-requests" className="col-md-6 m-0">
          <Translate value="dashboard.myActiveActions"/>
          <span className="separator information">{props.actionsCount}</span>
        </h1>
      </div>
      {props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
        props.loading ? <div className="loading-overlay"/> :
          props.actionsCount === 0 ? <EmptyDashboard/> : null
      }
      <PendingUserActions
        onDataLoad={props.onPendingUsersDataLoad}
        onDataUpdate={props.onPendingUsersDataUpdate}
        onError={props.onPendingUsersError}
      />
      <ActiveRequestActions
        onDataLoad={props.onRequestsDataLoad}
        onDataUpdate={props.onRequestsDataUpdate}
        onError={props.onRequestsError}
      />
    </>
  );
};

DashboardView.propTypes = {
  actionsCount: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  onPendingUsersDataLoad: PropTypes.func.isRequired,
  onRequestsDataLoad: PropTypes.func.isRequired,
  onPendingUsersDataUpdate: PropTypes.func.isRequired,
  onRequestsDataUpdate: PropTypes.func.isRequired,
  onPendingUsersError: PropTypes.func.isRequired,
  onRequestsError: PropTypes.func.isRequired
};

DashboardView.defaultProps = {
  loading: true,
  error: false
};

export default DashboardView;
