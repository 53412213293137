import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {userService} from '../../../services/user/UserService';
import {TextField, Tooltip} from '@material-ui/core';
import {AbstractFormComponent, Icon} from 'front-onceforall-core';
import {captureError} from '../../../utils/log';
import {emailValidator, phoneNumberValidator, requiredValidator} from 'front-onceforall-core/dist/utils/validators';
import {USER_ORGANISATION_STATUS} from '../../../utils/constant';
import TextPhoneNumber from 'front-onceforall-core/dist/components/TextPhoneNumber';
import {getPhoneLocaleFromCountry} from '../../country/CountrySelector';
import configureStore from '../../../store/configureStore';

export class UserAddView extends AbstractFormComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      loading: false,
      user: null,
      isEmailUnknown: false,
      isInternalUser: false,
      defaultPhoneCountry: getPhoneLocaleFromCountry(props.organisation.country)
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCheckEmail = this.handleCheckEmail.bind(this);
  }

  getFormData() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: ''
    };
  }

  userAlreadyLinked() {
    if (this.props.users.find(user => user.user.email === this.state.formData.email &&
      user.status === USER_ORGANISATION_STATUS.LINKED && this.state.formData.email !== this.props.userEmail)) {
      return 'user.invite.alreadyPartOfOrganisation';
    }

    return null;
  }

  userAlreadyPending() {
    if (this.props.users.find(user => user.user.email === this.state.formData.email &&
    user.status === USER_ORGANISATION_STATUS.PENDING)) {
      return 'user.invite.alreadyWaitingToJoin';
    }

    return null;
  }

  userInviteSelf() {
    if (this.props.userEmail === this.state.formData.email) {
      return 'user.invite.self';
    }

    return null;
  }

  getFormValidators() {
    return {
      email: [
        requiredValidator,
        emailValidator,
        this.userAlreadyLinked.bind(this),
        this.userAlreadyPending.bind(this),
        this.userInviteSelf.bind(this)
      ],
      firstName: this.state.isEmailUnknown ? requiredValidator : null,
      lastName: this.state.isEmailUnknown ? requiredValidator : null,
      phoneNumber: this.state.isEmailUnknown ? [requiredValidator, phoneNumberValidator] : null
    };
  }

  handleEmailChange(event) {
    const value = event.target.value;

    this.setState({
      formData: {
        ...this.state.formData,
        email: value
      },
      user: null,
      isEmailUnknown: false,
      isInternalUser: false
    }, () => {
      this.formValidators = this.getFormValidators();
    });
  }

  async handleCheckEmail() {
    this.setState({submitted: true});
    this.validateField('email', this.state.formData.email);
    if (this.state.fieldErrors.email) {
      return;
    }

    userService.searchByEmail(this.state.formData.email)
      .then(response => {
        // do not allow to invite BO users
        if (response) {
          if (response.internalUser) {
            this.setState({isEmailUnknown: false, isInternalUser: true});
            return;
          }

          this.setState({user: response, isEmailUnknown: false});
          return;
        }

        this.setState({isEmailUnknown: true, submitted: false});
      })
      .finally(() => {
        this.formValidators = this.getFormValidators();
      });
  }

  handleSubmitCallback() {
    this.setState({loading: true});
    if (this.state.isEmailUnknown) {
      return userService.inviteUnknownUserToOrganisation(this.props.organisation.id, this.state.formData)
        .then(() => {
          this.props.onSelectUser();
          this.props.actions.addSuccess(<Translate value="user.invite.inviteSuccess" email={this.state.formData.email}/>);
        })
        .catch(error => {
          captureError('handleSelectUnknownUser', error);
          this.props.actions.addError(<Translate value="error.generic"/>);
        })
        .finally(() => this.setState({loading: false}));
    }

    return userService.linkUserToOrganisation(this.props.organisation.id, this.state.user.id)
      .then(() => {
        this.props.onSelectUser();
        this.props.actions.addSuccess(<Translate value="user.invite.addSuccess"/>);
      })
      .catch(error => {
        captureError('handleSelectKnownUser', error);
        this.props.actions.addError(<Translate value="error.generic"/>);
      })
      .finally(() => this.setState({loading: false}));
  }

  render() {
    const currentLocale = configureStore.getStore().getState().i18n.locale;
    const isUserUnknown = this.state.isEmailUnknown;
    const tooltip = isUserUnknown ? 'user.invite.inviteTooltip' : 'user.invite.tooltip';
    const blockValidate = this.state.loading || (!this.state.user && !isUserUnknown);

    let validateButton =
    <Tooltip
      data-id="add-user-button-disabled-tooltip"
      title={<Translate value={tooltip}/>}
    >
      <button
        type="submit"
        className={'large inline-container no-wrap primary'}
        data-id="add-user-button"
        disabled={blockValidate}
        style={blockValidate ? {pointerEvents: 'none'} : {}}
      >
        <Translate value="action.confirm"/>
        <Icon icon={this.state.loading ? 'loader' : 'check'}/>
      </button>
    </Tooltip>;

    return (
      <>
        <div>
          <form onSubmit={this.handleSubmit}>
            <h2 className="col-12 mb-5"><Translate value="user.invite.title"/></h2>
            <div className="col-12 mt-4">
              <TextField
                autoFocus
                label={this.getFieldLabel('user.email', true)}
                error={this.isOnError('email')}
                helperText={this.helperText('email')}
                data-id="email"
                value={this.state.formData.email}
                onChange={this.handleEmailChange}
                InputProps={{
                  endAdornment: this.state.user ?
                    (<Icon icon="check" color="success"/>) :
                    (<button
                      type="button"
                      data-id="verify-email-button"
                      className="large secondary inline-container no-wrap"
                      onClick={this.handleCheckEmail}
                      disabled={isUserUnknown}
                    >
                      <Translate value="user.invite.checkEmail"/>
                    </button>)
                }}
              />
              {this.state.user &&
              <div data-id="can-add-user" className="col-12 mb-4">
                <span className="information font-size-xs">
                  <Translate value="user.invite.canAdd"/>
                </span>
              </div>
              }
              {this.state.isInternalUser &&
                <div data-id="internal-user-error" className="col-12 mb-4">
                  <span className="error font-size-xs">
                    <Translate value="user.invite.internalUser"/>
                  </span>
                </div>
              }
            </div>
            {isUserUnknown &&
              <>
                <div className="row col-12">
                  <div className="col-12 mb-4">
                    <span className="information font-size-xs">
                      <Translate value="user.invite.notRecognized"/>
                    </span>
                  </div>
                  <div className="col-6">
                    <TextField
                      label={this.getFieldLabel('user.firstName', true)}
                      error={this.isOnError('firstName')}
                      helperText={this.helperText('firstName')}
                      data-id="firstName"
                      value={this.state.formData.firstName}
                      onChange={this.handleChange('firstName')}
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      label={this.getFieldLabel('user.lastName', true)}
                      error={this.isOnError('lastName')}
                      helperText={this.helperText('lastName')}
                      data-id="lastName"
                      value={this.state.formData.lastName}
                      onChange={this.handleChange('lastName')}
                    />
                  </div>
                </div>
                <div className="row col-12 mt-4">
                  <div className="col-12">
                    <TextPhoneNumber
                      label={this.getFieldLabel('user.phoneNumber', true)}
                      value={this.state.formData.phoneNumber}
                      onChange={this.handleChange('phoneNumber')}
                      error={this.isOnError('phoneNumber')}
                      helperText={this.helperText('phoneNumber')}
                      data-id="phoneNumber"
                      defaultLocal={this.state.defaultPhoneCountry}
                      currentLocale={currentLocale}
                    />
                  </div>
                </div>
              </>
            }
            <div className="actions col-12 inline-container space-between">
              <button type="button" className="large secondary inline-container" onClick={this.props.onCancel}>
                <Translate value="action.cancel"/>
                <Icon icon="go-back"/>
              </button>
              {validateButton}
            </div>
          </form>
        </div>
      </>
    );
  }
}

UserAddView.propTypes = {
  actions: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  userEmail: PropTypes.string.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UserAddView;
