import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {Icon, stringUtils, UploadDropzone} from 'front-onceforall-core';

export const UploadPanelView = props => {
  return (
    <form onSubmit={props.onSubmit}>
      <h2><Translate value="request.documents.upload.title"/></h2>
      <p>{stringUtils.getStrongs(I18n.t('request.documents.upload.notice', {documentName: props.document.type.label}))}</p>
      <UploadDropzone
        extensions={'.pdf'}
        onDrop={props.onDrop}
        maxSize={props.maxSize}
        minSize={props.minSize}
        errorFileMessage={props.errorText}
      />
      {props.fileName &&
        <div className="information mt-4">
          <Translate value="document.filename"/><strong>{props.fileName}</strong>
        </div>
      }
      <div className="actions col-12 inline-container space-between">
        <button type="button" className="large secondary inline-container" onClick={props.onClose}>
          <Translate value="action.cancel"/>
          <Icon icon="go-back"/>
        </button>
        <button
          className={'large primary inline-container'}
          id="submit"
          type="submit"
          disabled={props.submitting}
        >
          <Translate value="action.confirm"/>
          <Icon icon={props.submitting ? 'loader' : 'check'}/>
        </button>
      </div>
    </form>
  );
};

UploadPanelView.propTypes = {
  document: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  errorText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  fileName: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  minSize: PropTypes.number.isRequired,
  maxSize: PropTypes.number.isRequired
};

export default UploadPanelView;
