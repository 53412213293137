import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch, useLocation, useHistory} from 'react-router-dom';

import {usePrevious} from 'front-onceforall-core/dist/utils/customHooks';

import AccessDenied from '../components/AccessDenied';
import PageNotFound from '../components/PageNotFound';
import ProjectCreate from '../components/projectCreate';
import Project from '../components/project';
import RequestCreate from '../components/requestCreate';

import Dashboard from '../components/dashboard';
import Requests from '../components/requests';
import Projects from '../components/projects';
import Users from '../components/users';
import Organisation from '../components/organisation';

import RequestGate from './RequestGate';

const DEFAULT_PAGE = '/dashboard';

function OrganisationGate() {
  const [loaded, setLoaded] = React.useState(false);
  const organisation = useSelector(state => state.organisation);
  const location = useLocation();
  const history = useHistory();

  const prevLocation = usePrevious(location);
  const prevOrganisation = usePrevious(organisation);

  function checkOrganisation() {
    if (organisation === null) {
      history.replace('/register-organisation');
    }

    setLoaded(true);
  }

  function isOrganisationDifferent(orga, prevOrga) {
    const organisationId = orga?.organisationId;
    const prevOrganisationId = prevOrga?.organisationId;

    return organisationId !== undefined &&
      (
        prevOrganisationId === undefined ||
        prevOrganisationId !== organisationId
      );
  }

  React.useEffect(() => {
    checkOrganisation();
  }, []);

  React.useEffect(() => {
    const organisationIsDifferent = isOrganisationDifferent(organisation, prevOrganisation);
    if (organisationIsDifferent || prevLocation?.pathname !== location.pathname) {
      checkOrganisation();
    }
  }, [organisation, location.pathname]);

  return (
    <>
      {loaded &&
        <Switch>
          <Redirect from="/" exact to={DEFAULT_PAGE}/>

          <Route exact path="/dashboard" component={Dashboard}/>

          <Route exact path="/projects" component={Projects}/>
          <Route exact path="/projects/new" component={ProjectCreate}/>
          <Route exact path="/project/:projectId([0-9]+)" component={Project}/>
          <Redirect
            from="/project"
            to="/projects"
          />

          <Route exact path="/requests" component={Requests}/>
          <Route exact path="/requests/new" component={RequestCreate}/>
          <Route path="/request/:requestId" component={RequestGate}/>

          <Route exact path="/users" component={Users}/>

          <Route exact path="/organisation" component={Organisation}/>

          <Route
            exact
            path="/access-denied"
            component={AccessDenied}
          />
          <Route component={PageNotFound}/>
        </Switch>
      }
    </>
  );
}

export default OrganisationGate;
