import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import OrganisationCredits from './organisationCredits';
import {bindActionCreators} from 'redux';
import * as snackbarsActions from '../../actions/snackbars';
import * as subscriptionActions from '../../actions/subscription';

export const OrganisationView = ({actions, organisation}) => {
  return (
    <>
      {organisation &&
        <div className="flex-container space-between">
          <div className="mb-5">
            <h1 data-id="request-title" className="ml-6 mb-0">
              {organisation.fullName}
            </h1>
          </div>
        </div>
      }
      <OrganisationCredits
        organisation={organisation}
        actions={actions}
      />
    </>
  );
};

OrganisationView.propTypes = {
  organisation: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({...snackbarsActions, ...subscriptionActions}, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganisationView);
