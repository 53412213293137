import React from 'react';
import PropTypes from 'prop-types';
import {requestService} from '../../../services/request/RequestService';
import RequestSpecificDocumentsListView from './RequestSpecificDocumentsListView';

export const RequestSpecificDocumentsListContainer = props => {
  const [specificDocuments, setSpecificDocuments] = React.useState([]);
  const [uploadedDocCount, setUploadedDocCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const getSpecificDocuments = requestId => {
    setLoading(true);
    requestService.getSpecificDocumentsByRequestId(requestId)
      .then(response => {
        const documents = response;
        const uploadedDocuments = documents.filter(document => document.isUploaded);

        setSpecificDocuments(documents);
        setUploadedDocCount(uploadedDocuments.length);
        if (uploadedDocuments.length > 0) {
          props.onAnyDocumentAvailable(true);
        }
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    const requestId = Number(props.requestId);

    if (requestId) {
      // Little optimisation: reduce amount of unnecessary fetch
      // There is no need to fetch specific documents (that includes payment document) when paymaster is unknown
      if (props.paymaster) {
        getSpecificDocuments(requestId);
      }
    } else {
      setError(true);
    }
  }, [props.paymaster]);

  const handleDocumentUploaded = newDocument => {
    const previousDocumentIndex = specificDocuments.findIndex(document => document.type.code === newDocument.type.code);
    if (previousDocumentIndex > -1) {
      const previousDocument = specificDocuments[previousDocumentIndex];
      specificDocuments[previousDocumentIndex] = newDocument;
      setSpecificDocuments(specificDocuments);

      // Increment uploadedDocCount only when the previous document is not yet uploaded
      if (!previousDocument.isUploaded) {
        setUploadedDocCount(uploadedDocCount + 1);
      }
    }

    props.onAnyDocumentAvailable(true);
  };

  return (
    <RequestSpecificDocumentsListView
      requestId={props.requestId}
      documents={specificDocuments}
      uploadedDocCount={uploadedDocCount}
      rfaStatus={props.rfaStatus}
      loading={loading}
      error={error}
      onDocumentUpload={handleDocumentUploaded}
    />
  );
};

RequestSpecificDocumentsListContainer.propTypes = {
  requestId: PropTypes.number.isRequired,
  paymaster: PropTypes.string,
  rfaStatus: PropTypes.string,
  onAnyDocumentAvailable: PropTypes.func.isRequired
};

export default RequestSpecificDocumentsListContainer;
