import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from '@material-ui/core';
import {Icon} from 'front-onceforall-core';
import {Translate} from 'react-redux-i18n';

export const ProjectEditionButton = props => {
  return (
    <div className="flex-container justify-content-end">
      <Tooltip
        data-id="edit-enabledTooltip-tooltip"
        title={<Translate value="project.edit.enabledTooltip"/>}
      >
        <button
          data-id="edit"
          className="large secondary inline-container"
          onClick={props.onEditButtonClick}
        >
          <Icon icon="edit"/>
        </button>
      </Tooltip>
    </div>
  );
};

ProjectEditionButton.propTypes = {
  onEditButtonClick: PropTypes.func.isRequired
};

export default ProjectEditionButton;
