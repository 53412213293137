import {REQUEST_SUBSCRIPTION, REQUEST_SUBSCRIPTION_ERROR, REQUEST_SUBSCRIPTION_SUCCESS} from '../actions/constant';

export const initialState = {
  isFetching: false,
  error: null,
  credits: null
};

function requestSubscription(state) {
  return {
    ...state,
    isFetching: true,
    error: null
  };
}

function requestSubscriptionSuccess(state, action) {
  return {
    ...state,
    isFetching: false,
    error: null,
    credits: action.subscription.credits
  };
}

function requestSubscriptionError(state, action) {
  return {
    ...state,
    isFetching: false,
    error: action.error,
    credits: null
  };
}

export default function subscription(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_SUBSCRIPTION:
      return requestSubscription(state);
    case REQUEST_SUBSCRIPTION_SUCCESS:
      return requestSubscriptionSuccess(state, action);
    case REQUEST_SUBSCRIPTION_ERROR:
      return requestSubscriptionError(state, action);
    default:
      return state;
  }
}
