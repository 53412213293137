import config from '../../../config';
import keycloakAdapter from 'keycloak-js';
import 'url-polyfill';
import moment from 'moment-timezone';
import {setLocale} from 'react-redux-i18n';
import {setUser} from '../../actions/user';
import {selectOrganisation} from '../../actions/organisation';
import history from '../../history/history';
import RolesAdapter from './RolesAdapter';
import {organisationService} from '../organisation/OrganisationService';
import {userService} from '../user/UserService';
import {BACKOFFICE_ROLE} from '../../utils/constant';
import {captureError, captureLog} from '../../utils/log';

function extractUser(keycloakAdapter) {
  return {
    id: keycloakAdapter.tokenParsed.sub,
    email: keycloakAdapter.tokenParsed.email,
    emailVerified: keycloakAdapter.tokenParsed.email_verified,
    firstName: keycloakAdapter.tokenParsed.given_name,
    lastName: keycloakAdapter.tokenParsed.family_name,
    fullName: keycloakAdapter.tokenParsed.name,
    language: keycloakAdapter.tokenParsed.locale,
    phoneNumber: keycloakAdapter.tokenParsed.phone_number,
    mobilePhoneNumber: keycloakAdapter.tokenParsed.mobile_phone_number,
    termsAndConditionsTimestamp: keycloakAdapter.tokenParsed.general_terms_agreement_timestamp,
    privacyPolicyTimestamp: keycloakAdapter.tokenParsed.privacy_policy_timestamp,
    isInternal: keycloakAdapter.tokenParsed.internal_user || false,
    fromOfa: keycloakAdapter.tokenParsed.from_ofa || false,
    userOrganisations: []
  };
}

export const WRONG_FORCED_ORGA_ERROR = 'Wrong forced organisation';

// If the token expires within MIN_VALIDITY
export const MIN_VALIDITY = 35;
export const FORCE_INVALIDITY = 31536000;

export default class LiveAuthenticationService {
  getKeycloakAdapter() {
    if (!this.keycloakAdapter) {
      this.keycloakAdapter = keycloakAdapter(config.keycloak);
    }

    return this.keycloakAdapter;
  }

  getRolesAdapter() {
    return this.rolesAdapter;
  }

  init(store, location) {
    const keycloakAdapter = this.getKeycloakAdapter();
    if (keycloakAdapter.authenticated) {
      return Promise.resolve();
    }

    this.rolesAdapter = new RolesAdapter(store);

    const searchParams = new URLSearchParams(location.search);
    return new Promise((resolve, reject) => {
      keycloakAdapter.init({
        // Fix infinite reload issue with Chrome browser (@see https://github.com/dasniko/keycloak-reactjs-demo/issues/3)
        checkLoginIframe: false
      })
        .then(authenticated => {
          if (authenticated) {
            return this.authSuccessHandler(store, searchParams.get('forceOrganisationId'), searchParams.get('state')).then(() => resolve());
          }

          captureLog('Init: Redirection to login page');
          keycloakAdapter.login({
            locale: store.getState().i18n.locale,
            idpHint: searchParams.get('authProvider')
          });
        })
        .catch(error => reject(error));
    });
  }

  async authSuccessHandler(store, forceOrganisationId, ofaOrganisationIdAsStringObject) {
    let user = extractUser(this.getKeycloakAdapter());

    if (!user.isInternal) {
      await this.syncUserOrganisationsWithOfa(user.id);
    }

    const userOrganisations = await this.fetchUserOrganisations(user.id);
    user = Object.assign({}, user, {userOrganisations});
    store.dispatch(setUser(user));
    store.dispatch(setLocale(user.language));
    moment.locale(user.language);

    // Store organisationSave before getting role
    let storeOrganisationId = store.getState().organisation ? store.getState().organisation.id : null;
    if (forceOrganisationId) {
      storeOrganisationId = forceOrganisationId;
    }

    store.dispatch(selectOrganisation(null));

    let ofaOrganisationId;
    if (ofaOrganisationIdAsStringObject) {
      try {
        ofaOrganisationId = JSON.parse(ofaOrganisationIdAsStringObject)?.ofaOrganisationId;
      } catch (error) {
        console.warn('JSON malformed: impossible to retrieve thr organisation id', error);
      }
    }

    return new Promise((resolve, reject) => {
      organisationService.getDefaultOrganisation(storeOrganisationId, ofaOrganisationId)
        .then(organisation => {
          store.dispatch(selectOrganisation(organisation));
          this.getRolesAdapter().updateToken(FORCE_INVALIDITY);

          if (organisation) {
            if (Number(storeOrganisationId) === organisation?.id || Number(ofaOrganisationId) === organisation?.ofaConnectId) {
              return resolve();
            }

            window.location.href = '/global-dashboard';
            return reject(new Error(WRONG_FORCED_ORGA_ERROR));
          }

          return resolve();
        })
        .catch(error => {
          captureError('Impossible to fetch user token.', error);
          reject(error);
        });
    });
  }

  logout() {
    const parsedUrl = new URL(window.location.href);
    const keycloakAdapter = this.getKeycloakAdapter();

    if (keycloakAdapter.authenticated) {
      keycloakAdapter.logout({redirectUri: parsedUrl.origin});
    }

    keycloakAdapter.init()
      .then(() => {
        keycloakAdapter.logout({redirectUri: parsedUrl.origin});
      })
      .catch(() => history.push('/'));
  }

  updateTokenKeycloak(force = false) {
    return new Promise(resolve => {
      this.getKeycloakAdapter().updateToken(force ? FORCE_INVALIDITY : MIN_VALIDITY).then(() => {
        return resolve(this.getKeycloakAdapter().token);
      }).catch(error => {
        captureLog('Redirection to login page', error);
        this.getKeycloakAdapter().login();
      });
    });
  }

  updateTokenRoles(force = false) {
    return new Promise((resolve, reject) => {
      this.getRolesAdapter().updateToken(force ? FORCE_INVALIDITY : MIN_VALIDITY).then(() => {
        return resolve(this.getRolesAdapter().getToken());
      }).catch(error => {
        captureError('Impossible to update token roles for the authenticated user.', error);
        return reject(error);
      });
    });
  }

  authRedirect(url) {
    return Promise.all([
      this.updateTokenKeycloak(),
      this.updateTokenRoles()
    ])
      .then(([accessToken, permissionToken]) => {
        const separator = url.indexOf('?') === -1 ? '?' : '&';
        return url + separator + 'access_token=' + accessToken + '&permission=' + permissionToken;
      });
  }

  roles() {
    return {
      getProjectRoles: projectId => this.getRolesAdapter().getProjectRoles(projectId),
      hasProjectRole: (projectId, role) => this.getRolesAdapter().getProjectRoles(projectId).includes(role),
      isBackOffice: () => this.getRolesAdapter().getGlobalRole() === BACKOFFICE_ROLE
    };
  }

  syncUserOrganisationsWithOfa(userId) {
    return new Promise(resolve => {
      userService.syncOrganisationsWithOfa(userId, false)
        .then(() => resolve())
        .catch(error => {
          captureError('userService.syncOrganisationsWithOfa', error);
          // Allow the authentication (and the loading of the page) to continue even if the synchronisation has failed
          return resolve(error);
        });
    });
  }

  fetchUserOrganisations(userId) {
    return new Promise(resolve => {
      organisationService.searchByUser(userId, null, true, false)
        .then(userOrganisations => {
          resolve(userOrganisations);
        })
        .catch(error => {
          captureError('organisationService.searchByUser', error);
        });
    });
  }
}
