export const pathTermsAndConditions = '/resources/termsAndConditions/';
export const pathPrivacyPolicy = '/resources/privacyPolicy/';
import config from '../../config';

export function getTermsAndConditionsPath(locale) {
  let termsAndConditionsFile = 'CGU - PanDA OFA.pdf';
  if (locale === 'fr') {
    termsAndConditionsFile = 'CGU - PanDA OFA.pdf';
  }

  return pathTermsAndConditions + termsAndConditionsFile;
}

export function getPrivacyPolicyPath(locale) {
  let privacyPolicyFile = 'OFA-Politique-de-confidentialité-v2.pdf';
  if (locale === 'fr') {
    privacyPolicyFile = 'OFA-Politique-de-confidentialité-v2.pdf';
  }

  return pathPrivacyPolicy + privacyPolicyFile;
}

export function getNewsUrlFromLocale(locale) {
  if (locale === 'fr') {
    return config.blogUrl;
  }

  return null;
}
