import {captureError} from './log';

function push(organisation) {
  try {
    if (!organisation) {
      return;
    }

    const organisationsHistory = removeOrganisationFromHistory(organisation, getOrganisationHistoryFromLocalStorage());
    organisationsHistory.unshift({id: organisation.id, date: new Date()});

    // We only keep an history for 1000 organisations
    saveOrganisationHistoryIntoLocalStorage(organisationsHistory.slice(0, 1000));
  } catch (error) {
    captureError('Error when pushing last organisation', error);
  }
}

function remove(organisation) {
  try {
    if (!organisation) {
      return;
    }

    const organisationsHistory = removeOrganisationFromHistory(organisation, getOrganisationHistoryFromLocalStorage());
    saveOrganisationHistoryIntoLocalStorage(organisationsHistory);
  } catch (error) {
    captureError('Error when removing organisation ' + organisation.id, error);
  }
}

function get() {
  try {
    return getOrganisationHistoryFromLocalStorage();
  } catch (error) {
    captureError('Error when fetching organisations history. Return an empty array and delete history.', error);
    window.localStorage.removeItem('organisationHistory');
    return [];
  }
}

function getOrganisationHistoryFromLocalStorage() {
  const organisationsHistory = window.localStorage.getItem('organisationHistory');
  return organisationsHistory ? JSON.parse(organisationsHistory) : [];
}

function saveOrganisationHistoryIntoLocalStorage(organisationsHistory) {
  window.localStorage.setItem('organisationHistory', JSON.stringify(organisationsHistory));
}

function removeOrganisationFromHistory(organisation, organisationsHistory = []) {
  return organisationsHistory.filter(
    organisationHistory => organisationHistory.id !== organisation.id
  );
}

const organisationHistory = {
  push,
  remove,
  get
};

export default organisationHistory;
