import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import ProjectEditionButton from '../projectEditionButton/ProjectEditionButton';

export const ProjectDocumentsView = props => {
  const documentsCount = props.documents.length;

  return (
    <>
      <div className="col-4">
        <h2>
          <Translate value="project.documents.title"/>
          <span className="separator information">{documentsCount}</span>
        </h2>
        <div className="card h-100">
          {props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
            props.loading ? <span className="loading-overlay"/> :
              <ul>
                {props.isProjectEditionAllowed && <ProjectEditionButton onEditButtonClick={props.onEditButtonClick}/>}
                {props.documents.map(document =>
                  <li className="mb-5" key={document.code}>{document.label}
                    {document.complementary?.length > 0 ?
                      <ul style={{listStyleType: 'circle'}} >
                        {document.complementary.map(complementary =>
                          <li className="mt-4 ml-4" key={document.code}>{complementary}</li>
                        )}
                      </ul> :
                      null
                    }
                  </li>
                )}
              </ul>
          }
        </div>
      </div>
    </>
  );
};

ProjectDocumentsView.defaultProps = {
  isProjectEditionAllowed: false,
  loading: true,
  error: false
};

ProjectDocumentsView.propTypes = {
  documents: PropTypes.array.isRequired,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  isProjectEditionAllowed: PropTypes.bool,
  onEditButtonClick: PropTypes.func

};

export default ProjectDocumentsView;
