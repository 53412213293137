import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';

import Radio from '../../../buttons/Radio';

import {toBoolean} from './utils';

function RadioYesNo(props) {
  const value = toBoolean(props.value);

  return (
    <div className="d-flex flex-row justify-content-start align-items-center">
      {props.title}
      <Radio
        name={`radio-${props.id}-yes`}
        data-id={`radio-${props.id}-yes`}
        checked={value}
        value={true}
        onChange={props.onChange}
        label={I18n.t('action.yes')}
      />
      <Radio
        name={`radio-${props.id}-no`}
        data-id={`radio-${props.id}-no`}
        checked={value === false}
        value={false}
        onChange={props.onChange}
        label={I18n.t('action.no')}
      />
    </div>
  );
}

RadioYesNo.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number])
};

export default RadioYesNo;
