import React from 'react';
import GlobalHeader from './globalHeader';
import OrganisationHeader from './organisationHeader';

export const HeaderView = () => {
  return (
    <header>
      <GlobalHeader/>
      <OrganisationHeader/>
    </header>
  );
};

export default HeaderView;
