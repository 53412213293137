import React from 'react';
import DashboardView from './DashboardView';

export const DashboardContainer = () => {
  const [pendingUsersCount, setPendingUsersCount] = React.useState(0);
  const [pendingUsersLoading, setPendingUsersLoading] = React.useState(true);
  const [pendingUsersError, setPendingUsersError] = React.useState(false);

  const [requestsCount, setRequestsCount] = React.useState(0);
  const [requestsLoading, setRequestsLoading] = React.useState(true);
  const [requestsError, setRequestsError] = React.useState(false);

  const handlePendingUsersDataLoad = () => {
    setPendingUsersLoading(true);
  };

  const handleRequestsDataLoad = () => {
    setRequestsLoading(true);
  };

  const handlePendingUsersDataUpdate = count => {
    setPendingUsersLoading(false);
    setPendingUsersError(false);
    setPendingUsersCount(count);
  };

  const handleRequestsDataUpdate = count => {
    setRequestsLoading(false);
    setRequestsError(false);
    setRequestsCount(count);
  };

  const handlePendingUsersError = () => {
    setPendingUsersLoading(false);
    setPendingUsersError(true);
  };

  const handleRequestsError = () => {
    setRequestsLoading(false);
    setRequestsError(true);
  };

  const actionsCount = pendingUsersCount + requestsCount;
  const isAtLeastOneLoading = pendingUsersLoading || requestsLoading;
  const isAllActionsInError = pendingUsersError && requestsError;

  return (
    <DashboardView
      actionsCount={actionsCount}
      loading={isAtLeastOneLoading}
      error={isAllActionsInError}
      onPendingUsersDataLoad={handlePendingUsersDataLoad}
      onRequestsDataLoad={handleRequestsDataLoad}
      onPendingUsersDataUpdate={handlePendingUsersDataUpdate}
      onRequestsDataUpdate={handleRequestsDataUpdate}
      onPendingUsersError={handlePendingUsersError}
      onRequestsError={handleRequestsError}
    />
  );
};

export default DashboardContainer;
