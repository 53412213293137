import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {captureError} from '../../utils/log';
import {requestService} from '../../services/request/RequestService';
import {organisationService} from '../../services/organisation/OrganisationService';
import {enrollmentService} from '../../services/enrollment/EnrollmentService';
import {projectService} from '../../services/project/ProjectService';

import RequestsView from './RequestsView';

const pageSize = 10;

export const RequestsContainer = ({organisation}) => {
  const [page, setPage] = React.useState(0);

  const [requests, setRequests] = React.useState([]);
  const [requestsTotalCount, setRequestsTotalCount] = React.useState(0);
  const [requestsLoading, setRequestsLoading] = React.useState(false);
  const [requestsError, setRequestsError] = React.useState(false);
  const [unlimitedRfaProjectExists, setUnlimitedRfaProjectExists] = React.useState(false);

  const [organisations, setOrganisations] = React.useState([]);
  const [invitations, setInvitations] = React.useState([]);

  const handlePageChange = (event, page) => {
    fetchRequests(page);
  };

  const checkInvitation = requestId => {
    enrollmentService.getInvitationByRequestId(requestId)
      .then(response => {
        if (response.length > 0) {
          setInvitations(invitations => [...invitations, response[0]]);
        }
      });
  };

  const fetchOrganisations = requests => {
    let organisationsList = [];
    requests.forEach(request => {
      organisationsList.push(request.project.clientId);
      organisationsList.push(request.project.clientDelegateId);
      organisationsList.push(request.project.clientAssistantId);
      organisationsList.push(request.project.projectManagerId);
      organisationsList.push(request.creatorOrganisationId);

      if (request.subcontractorId) {
        organisationsList.push(request.subcontractorId);
      } else {
        checkInvitation(request.id);
      }
    });

    return organisationService.getOrganisationsList([...new Set(organisationsList)].filter(Boolean))
      .then(response => {
        setOrganisations(response);
      })
      .catch(error => {
        setOrganisations([]);
        throw error;
      });
  };

  const fetchRequests = (page = 0) => {
    setRequestsLoading(true);
    setRequestsError(false);
    setPage(page);

    requestService.findRequestsByOrganisationId(organisation.id, page, pageSize)
      .then(response => {
        setRequests(response.requests);
        setRequestsTotalCount(response.totalCount);
        return response.requests;
      })
      .then(requestsResponse => fetchOrganisations(requestsResponse))
      .catch(() => {
        setRequestsError(true);
        setRequests([]);
        setRequestsTotalCount(0);
      })
      .finally(() => setRequestsLoading(false));
  };

  const fetchProjects = () => {
    projectService.findProjectsReadyForRequestCreationByCurrentOrganisation()
      .then(response => {
        setUnlimitedRfaProjectExists(response.some(p => p.unlimitedRfa));
      })
      .catch(error => {
        captureError('fetchProjects on requests list', error);
        setUnlimitedRfaProjectExists(false);
      });
  };

  React.useEffect(() => {
    fetchRequests();
    fetchProjects();
  }, []);

  return (
    <RequestsView
      organisations={organisations}
      requests={requests}
      requestsTotalCount={requestsTotalCount}
      invitations={invitations}
      onPageChange={handlePageChange}
      pageNumber={page}
      pageSize={pageSize}
      requestsLoading={requestsLoading}
      requestsError={requestsError}
      unlimitedRfaProjectExists={unlimitedRfaProjectExists}
    />
  );
};

RequestsContainer.propTypes = {
  organisation: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

export default connect(mapStateToProps)(RequestsContainer);
