import {
  aRequestVM,
  aRequestWithUnknownSubcontractor,
  aSignTask,
  aVerifyTask,
  aRequestNodeVM,
  aRequestTreeVM,
  aClient
} from '../fixtures';
import {findOrganisation} from './organisations';
import {PAYMASTER, PAYMASTER_DOCUMENT_TYPE, PROJECT_ORGANISATION_ROLE, RFA_STATUS} from '../constant';
import {findProject} from './projects';

/**
 * @TODO: remove those 3 when backend gets rid of documents property in aRequestVM()
 */
const requestForApprovalPDF = {
  id: 69,
  documentTypeCode: 'RFA',
  binaryFileId: 'rfa-8/RFA.pdf'
};

const delegationPayment = {
  id: 23,
  documentTypeCode: PAYMASTER_DOCUMENT_TYPE.CLIENT,
  binaryFileId: `rfa-8/${PAYMASTER_DOCUMENT_TYPE.CLIENT}.pdf`
};

const caution = {
  id: 24,
  documentTypeCode: PAYMASTER_DOCUMENT_TYPE.RFA_OWNER,
  binaryFileId: `rfa-8/${PAYMASTER_DOCUMENT_TYPE.RFA_OWNER}.pdf`
};

const requestsFakeData = [
  aRequestVM({
    id: 1,
    project: findProject(2),
    subcontractorId: findOrganisation(1401).id,
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    amount: 10000,
    documents: aRequestVM().documents.concat([caution]),
    creationDate: '2020-01-15T10:15:30.00Z',
    startingDate: '2020-02-15T23:00:00Z',
    endingDate: '2020-08-18T21:59:59.999Z',
    paymaster: PAYMASTER.RFA_OWNER,
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1401).id}),
      aSignTask({organisationId: findOrganisation(1401).id})
    ],
    status: RFA_STATUS.EDITABLE
  }),
  aRequestWithUnknownSubcontractor({
    id: 2,
    project: findProject(2),
    details: 'Lorem ipsum dolor sit amet.',
    amount: 85756,
    creationDate: '2020-04-05T19:17:30.00Z',
    startingDate: '2020-02-05T23:00:00Z',
    endingDate: '2020-09-15T21:59:59.999Z',
    paymaster: PAYMASTER.RFA_OWNER
  }),
  aRequestVM({
    id: 3,
    project: findProject(2),
    subcontractorId: findOrganisation(1402).id,
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    amount: 100000,
    documents: [],
    creationDate: '2020-01-13T11:15:30.00Z',
    startingDate: '2020-01-13T23:00:00Z',
    endingDate: '2020-01-15T22:59:59.999Z',
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1402).id}),
      aSignTask({organisationId: findOrganisation(1402).id})
    ],
    status: RFA_STATUS.EDITABLE
  }),
  aRequestVM({
    id: 4,
    project: findProject(5),
    subcontractorId: findOrganisation(1403).id,
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    amount: 15000.5,
    creationDate: '2020-07-05T19:17:30.00Z',
    startingDate: '2020-02-19T23:00:00Z',
    endingDate: '2020-09-15T21:59:59.999Z',
    paymaster: PAYMASTER.RFA_OWNER,
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1403).id}),
      aSignTask({organisationId: findOrganisation(1403).id})
    ],
    status: RFA_STATUS.EDITABLE
  }),
  aRequestVM({
    id: 5,
    project: findProject(4),
    creatorOrganisationId: findOrganisation(1302).id,
    subcontractorId: findOrganisation(1404).id,
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    amount: 45000,
    documents: aRequestVM().documents.concat([delegationPayment]),
    creationDate: '2020-01-21T13:12:14.00Z',
    startingDate: '2020-02-18T23:00:00Z',
    endingDate: '2020-02-25T22:59:59.999Z',
    activeTasks: [
      aVerifyTask({
        organisationId: findProject(4).projectManagerId,
        role: PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER
      }),
      aSignTask({
        taskId: '6ab602e6-4106-4b5f-81af-b4af956254a5',
        organisationId: findProject(4).projectManagerId,
        role: PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER
      })
    ],
    refusalOrganisationId: findProject(4).clientId,
    refusalDate: '2020-02-07T15:27:10.00Z',
    refusalReason: 'Je n\'ai pas envie de signer cette demande car je ne suis pas le bon manager de projet'
  }),
  aRequestVM({
    id: 6,
    project: findProject(6),
    subcontractorId: findOrganisation(1402).id,
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    amount: 100000,
    documents: aRequestVM().documents.concat([caution]),
    creationDate: '2020-01-08T11:37:30.00Z',
    startingDate: '2020-01-08T23:00:00Z',
    endingDate: '2020-01-15T22:59:59.999Z',
    paymaster: PAYMASTER.CLIENT,
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1402).id}),
      aSignTask({organisationId: findOrganisation(1402).id})
    ],
    status: RFA_STATUS.EDITABLE
  }),
  aRequestVM({
    id: 7,
    project: findProject(7),
    subcontractorId: findOrganisation(1403).id,
    details: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.',
    amount: 100000,
    documents: aRequestVM().documents.concat([caution, requestForApprovalPDF]),
    creationDate: '2020-02-14T15:27:10.00Z',
    startingDate: '2020-02-14T23:00:00Z',
    endingDate: '2021-01-15T22:59:59.999Z',
    paymaster: PAYMASTER.RFA_OWNER,
    activeTasks: [],
    status: RFA_STATUS.VALIDATED
  }),
  aRequestVM({id: 8}),
  aRequestVM({
    id: 9,
    status: RFA_STATUS.EDITABLE,
    creationDate: '2024-01-09T20:00:00.000Z',
    project: findProject(50),
    publicProcurementData: {
      isCompleted: true
    },
    documents: aRequestVM().documents.concat([caution, requestForApprovalPDF]),
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR}),
      aSignTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR})
    ],
    creatorOrganisationId: aClient().id
  }),
  aRequestVM({
    id: 10,
    creationDate: '2024-01-10T20:00:00.000Z',
    project: findProject(50),
    publicProcurementData: {
      isCompleted: true
    },
    status: RFA_STATUS.READY,
    documents: aRequestVM().documents.concat([caution, requestForApprovalPDF]),
    creatorOrganisationId: aClient().id
  }),
  aRequestVM({
    id: 11,
    creationDate: '2024-01-11T20:00:00.000Z',
    status: RFA_STATUS.EDITABLE,
    project: findProject(51),
    publicProcurementData: {
      isCompleted: false
    },
    documents: aRequestVM().documents.concat([caution, requestForApprovalPDF]),
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR}),
      aSignTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR})
    ],
    creatorOrganisationId: aClient().id
  }),
  aRequestVM({
    id: 12,
    creationDate: '2024-01-12T20:00:00.000Z',
    project: findProject(51),
    publicProcurementData: {
      isCompleted: false
    },
    status: RFA_STATUS.READY,
    documents: aRequestVM().documents.concat([caution, requestForApprovalPDF]),
    creatorOrganisationId: aClient().id
  }),
  aRequestVM({
    id: 13,
    creationDate: '2024-01-13T20:00:00.000Z',
    status: RFA_STATUS.EDITABLE,
    project: findProject(51),
    publicProcurementData: {
      isCompleted: false
    },
    documents: aRequestVM().documents.concat([caution, requestForApprovalPDF]),
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR}),
      aSignTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR})
    ],
    creatorOrganisationId: findOrganisation(1401).id,
    subcontractorId: aClient().id
  }),
  aRequestVM({
    id: 14,
    creationDate: '2024-01-14T20:00:00.000Z',
    status: RFA_STATUS.EDITABLE,
    project: findProject(51),
    publicProcurementData: {
      isCompleted: false
    },
    documents: aRequestVM().documents.concat([caution, requestForApprovalPDF]),
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR}),
      aSignTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR})
    ],
    creatorOrganisationId: findOrganisation(1401).id,
    subcontractorId: findOrganisation(1401).id
  }),
  aRequestVM({
    id: 20,
    project: findProject(32),
    details: 'Request for approval with OFA documents and synchronisation disabled',
    documents: []
  }),
  aRequestVM({
    id: 21,
    project: findProject(32),
    details: 'Request for approval with OFA documents  and synchronisation enabled',
    subcontractorId: findOrganisation(1403).id,
    documents: [],
    activeTasks: [
      /* aVerifyTask({organisationId: findOrganisation(1501).id, role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE}),
      aSignTask({organisationId: findOrganisation(1501).id, role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE}) */
    ],
    suspensiveConditions: [
      {
        organisationRole: PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER,
        content: 'La demande sera levée lorsque tous les documents demandés seront déposé'
      },
      {
        organisationRole: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_ASSISTANT,
        content: 'La demande sera levée lorsque l\'attestation décennale sera déposé'
      },
      {
        organisationRole: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT,
        content: 'La demande sera levée lorsque l\'attestation décennale sera déposé'
      }
    ],
    status: RFA_STATUS.VALIDATED
  }),
  aRequestVM({
    id: 30,
    project: findProject(14),
    details: 'Request for approval validating SC',
    subcontractorId: findOrganisation(1401).id,
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR}),
      aSignTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR})
    ],
    status: RFA_STATUS.READY,
    refusalOrganisationId: findProject(14).clientId,
    refusalDate: '2020-03-12T13:34:54.00Z',
    refusalReason: 'restarting rfa workflow'
  }),
  aRequestVM({
    id: 31,
    project: findProject(14),
    details: 'Other request for approval validating SC',
    creatorOrganisationId: findOrganisation(1302).id,
    subcontractorId: findOrganisation(1401).id,
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR}),
      aSignTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR})
    ],
    amount: 1400,
    paymaster: PAYMASTER.RFA_OWNER
  }),
  aRequestVM({
    id: 32,
    project: findProject(14),
    details: 'Another request for approval validating SC',
    subcontractorId: findOrganisation(1402).id,
    amount: 299000,
    activeTasks: [
      aSignTask({
        taskId: 'c543d050-9fba-4bcf-b55c-41df778d5b5b',
        organisationId: findOrganisation(1301).id,
        role: PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR
      })
    ]
  }),
  aRequestVM({
    id: 301,
    project: findProject(14),
    details: 'A child of RFA 30',
    creatorOrganisationId: findOrganisation(1401).id,
    subcontractorId: findOrganisation(1402).id,
    amount: 1400,
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1402).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR}),
      aSignTask({organisationId: findOrganisation(1402).id, role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR})
    ],
    status: RFA_STATUS.READY
  }),
  aRequestVM({
    id: 302,
    project: findProject(14),
    details: 'Another child of RFA 30',
    creatorOrganisationId: findOrganisation(1401).id,
    subcontractorId: findOrganisation(1403).id,
    amount: 299000,
    paymaster: PAYMASTER.RFA_OWNER,
    activeTasks: [
      aVerifyTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_PARENT_SUBCONTRACTOR}),
      aSignTask({organisationId: findOrganisation(1401).id, role: PROJECT_ORGANISATION_ROLE.RFA_PARENT_SUBCONTRACTOR})
    ]
  }),
  aRequestVM({
    id: 3011,
    project: findProject(14),
    details: 'A subchild of RFA 30 and child of RFA 301',
    creatorOrganisationId: findOrganisation(1402).id,
    subcontractorId: findOrganisation(1404).id,
    amount: 300,
    paymaster: PAYMASTER.RFA_OWNER,
    activeTasks: [
      aVerifyTask({
        organisationId: findOrganisation(1404).id,
        role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR
      }),
      aSignTask({
        taskId: '6ab602e6-4106-4b5f-81af-b4af956254a5',
        organisationId: findOrganisation(1404).id,
        role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR
      })
    ]
  })
];

export const getRequestsFakeData = () => requestsFakeData;

export const findRequest = id => {
  const request = requestsFakeData.find(data => `${data.id}` === `${id}`);
  if (!request) {
    throw new Error(`Request with id ${id} not found`);
  }

  return request;
};

const fakeRequestNodesData = [
  {id: findRequest(1).id, parentId: null},
  {id: findRequest(2).id, parentId: null},
  {id: findRequest(3).id, parentId: null},
  {id: findRequest(4).id, parentId: null},
  {id: findRequest(5).id, parentId: null},
  {id: findRequest(6).id, parentId: null},
  {id: findRequest(7).id, parentId: null},
  {id: findRequest(8).id, parentId: null},
  {id: findRequest(9).id, parentId: null},
  {id: findRequest(10).id, parentId: null},
  {id: findRequest(11).id, parentId: null},
  {id: findRequest(12).id, parentId: null},
  {id: findRequest(13).id, parentId: null},
  {id: findRequest(14).id, parentId: null},
  {id: findRequest(20).id, parentId: null},
  {id: findRequest(21).id, parentId: null},
  {id: findRequest(30).id, parentId: null},
  {id: findRequest(31).id, parentId: null},
  {id: findRequest(32).id, parentId: null},
  {id: findRequest(301).id, parentId: findRequest(30).id},
  {id: findRequest(302).id, parentId: findRequest(30).id},
  {id: findRequest(3011).id, parentId: findRequest(301).id}
];

const buildParentNode = selfNodeData => {
  if (!selfNodeData?.id || !selfNodeData?.parentId) {
    return null;
  }

  const parentNodeData = fakeRequestNodesData.find(node => node.id === selfNodeData.parentId);
  if (!parentNodeData) {
    return null;
  }

  return aRequestNodeVM(parentNodeData.id, buildParentNode(parentNodeData), [selfNodeData.id]);
};

const buildChildrenNode = selfNodeData => {
  if (!selfNodeData?.id) {
    return [];
  }

  const childNodes = [];
  fakeRequestNodesData
    .filter(nodeData => nodeData.parentId === selfNodeData.id)
    .forEach(childNodeData => {
      childNodes.push(aRequestNodeVM(childNodeData.id, selfNodeData.id, buildChildrenNode(childNodeData)));
    });

  return childNodes;
};

const buildNode = nodeData => aRequestNodeVM(nodeData.id, buildParentNode(nodeData), buildChildrenNode(nodeData));

export const getFakeRequestNodesData = () => fakeRequestNodesData;
export const getRequestNodes = () => fakeRequestNodesData.map(nodeData => buildNode(nodeData));

export const findRequestNode = requestId => {
  const nodeData = fakeRequestNodesData.find(node => node.id === requestId);
  if (!nodeData || nodeData === {}) {
    throw new Error(`Request node with requestId ${requestId} not found`);
  }

  return buildNode(nodeData);
};

const buildChildrenTree = request => {
  const childNodeIds = [];
  fakeRequestNodesData
    .filter(nodeData => nodeData.parentId === request.id)
    .forEach(childNodeData => {
      childNodeIds.push(childNodeData.id);
    });

  const children = childNodeIds.map(childId => {
    const childRequest = findRequest(childId);

    return aRequestTreeVM(childRequest, buildChildrenTree(childRequest));
  });

  return children;
};

export const getRequestsTree = requests => {
  let requestsResponse = [];

  const topParentRequests = requests.filter(request => {
    const node = fakeRequestNodesData.find(nodeData => nodeData.id === request.id);

    if (!node || !node.parentId) {
      return true;
    }

    return !requests.some(requestCheck => requestCheck.id === node.parentId);
  });

  requestsResponse = topParentRequests.map(request => aRequestTreeVM(request, buildChildrenTree(request)));

  return requestsResponse;
};
