import React from 'react';
import PropTypes from 'prop-types';
import {Collapse} from '@material-ui/core';
import OrganisationSelector from '../../../organisationHeaderSelector/OrganisationSelector';
import {EntityIllustration} from 'front-onceforall-core';
// import {getOrganisationLogoUrl} from '../../../../utils/organisation';

export const OrganisationSelectorView = props => {
  return (
    <>
      <button
        ref={props.buttonRef}
        type="button"
        className="current-organisation-button"
        onClick={props.onOrganisationSelectorButtonClick}
      >
        <EntityIllustration
          name={props.organisation.fullName}
          // imageUrl={getOrganisationLogoUrl(props.organisation.organisationId)}
          small
        />
        <div className="text">
          <div data-id="organisation-name" className="orga-name">{props.organisation.fullName}</div>
          <div
            data-id="organisation-id"
            className="orga-id no-wrap"
          >
            {props.organisation.registrationNumber}
          </div>
        </div>
        <span className={`caret-${props.isSelectorOpen ? 'raise' : 'drop'}`}/>
      </button>
      <div
        ref={props.selectorRef}
        style={{
          boxShadow: '2px 2px 4px 0 rgba(0,0,0,.08)',
          position: 'absolute',
          left: 0,
          top: 'calc(100% + 1px)'
        }}
        className="col-xl-6 col-lg-8"
      >
        <Collapse in={props.isSelectorOpen}>
          <div
            style={{
              backgroundColor: '#fff',
              padding: '15px 20px'
            }}
          >
            <OrganisationSelector onAction={props.onOrganisationSelectorAction} slicedRowsNumber={3}/>
          </div>
        </Collapse>
      </div>
    </>
  );
};

OrganisationSelectorView.propTypes = {
  buttonRef: PropTypes.object.isRequired,
  selectorRef: PropTypes.object.isRequired,
  isSelectorOpen: PropTypes.bool.isRequired,
  onOrganisationSelectorButtonClick: PropTypes.func.isRequired,
  onOrganisationSelectorAction: PropTypes.func.isRequired,
  organisation: PropTypes.object.isRequired
};

export default OrganisationSelectorView;
