import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {Icon} from 'front-onceforall-core';
import Tooltip from '@material-ui/core/Tooltip';
import {INVITATION_STATUS, INVITATION_STATUS_COLOR, PROJECT_ORGANISATION_ROLE} from '../../../../../utils/constant';

/**
 * Displays in a box with all borders the intees info
 */
export const InviteeView = props => {
  const removeButton = props.authorizedActions && props.role === PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR ?
    <Tooltip
      title={<Translate value="project.remove.generalContractor.invitation.button"/>}
      placement="top"
    >
      <span>
        <button
          type="button"
          className="small secondary"
          onClick={props.onRemoveGCInvitee}
        >
          <Icon icon="trash"/>
        </button>
      </span>
    </Tooltip> : null;

  return (
    props.status === INVITATION_STATUS.PENDING ?
      <div className="box border bottom m-0" style={{width: '100%'}}>
        <div className={'card-header' + (props.isInAccordion ? '' : ' font-size-xl')}>
          <Translate
            color={INVITATION_STATUS_COLOR[props.status]}
            value={`project.invite.${props.status}.${props.role}`}
            dangerousHTML
          />
        </div>
        <div className="flex-container space-between">
          <b className="font-size-xl">{props.organisationName}</b>
          <div className="flex-container">
            <span style={{whiteSpace: 'nowrap'}}>{props.registrationNumber}</span>
            {removeButton}
          </div>
        </div>
      </div> : null
  );
};

InviteeView.defaultProps = {
  isInAccordion: false
};

InviteeView.propTypes = {
  status: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  registrationNumber: PropTypes.string.isRequired,
  authorizedActions: PropTypes.bool.isRequired,
  onRemoveGCInvitee: PropTypes.func.isRequired,
  isInAccordion: PropTypes.bool
};

export default InviteeView;
