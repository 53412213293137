import {combineReducers} from 'redux';
import {i18nReducer} from 'react-redux-i18n';
import snackbars from './snackbars';
import maintenance from './maintenance';
import user from './user';
import organisation from './organisation';
import subscription from './subscription';
import notifications from './notifications';
import {connectRouter} from 'connected-react-router';

const rootReducer = history => combineReducers({
  snackbars,
  i18n: i18nReducer,
  router: connectRouter(history),
  maintenance,
  user,
  organisation,
  subscription,
  notifications
});

export default rootReducer;
