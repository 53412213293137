import React from 'react';
import PropTypes from 'prop-types';
import RejectRequestView from './RejectRequestView';
import {requestService} from '../../../services/request/RequestService';
import {Translate} from 'react-redux-i18n';
import {captureError} from '../../../utils/log';
import {isEmpty} from 'front-onceforall-core/dist/utils/strings';

export const RejectRequestContainer = props => {
  const [reason, setReason] = React.useState('');
  const [emptyReasonError, setEmptyReasonError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleReasonChange = event => {
    setReason(event.target.value);
    setEmptyReasonError(false);
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (isEmpty(reason)) {
      setEmptyReasonError(true);
      return;
    }

    setLoading(true);
    requestService.rejectRequest(props.activeTask, reason)
      .then(response => {
        props.actions.addSuccess(<Translate value="request.page.rejectRequestSuccess" />);
        props.onRejectRequestSubmit();
        props.onRequestReasonUpdate(response);
      })
      .catch(error => {
        captureError('rejectRequest', error);
        props.actions.addError(<Translate value="error.generic"/>);
      })
      .finally(() => setLoading(false));
  };

  return (
    <RejectRequestView
      reason={reason}
      emptyReasonError={emptyReasonError}
      onReasonChange={handleReasonChange}
      onSubmit={handleSubmit}
      onClose={props.onClose}
      loading={loading}
    />
  );
};

RejectRequestContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  activeTask: PropTypes.object.isRequired,
  onRejectRequestSubmit: PropTypes.func.isRequired,
  onRequestReasonUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default RejectRequestContainer;
