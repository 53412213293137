import React from 'react';
import PropTypes from 'prop-types';
import {Radio as MuiRadio} from '@material-ui/core';

function Radio({onChange, label, value, checked, ...rest}) {
  const handleChange = () => {
    onChange(value);
  };

  return (
    <div className="d-flex flex-row align-items-center">
      <MuiRadio
        color="primary"
        size="medium"
        checked={checked}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      {label}
    </div>
  );
}

Radio.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool
};

export default Radio;
