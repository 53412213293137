import config from '../../../config';
import {authFetch, checkStatus, parseHeadersAndJson} from '../../utils/http';

export default class LiveRequestService {
  create(request) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  delete(requestId) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}`, {method: 'DELETE'})
      .then(checkStatus);
  }

  update(requestId, request) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}/details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  verifyRequest(taskId, condition) {
    return authFetch(`${config.rfaApi}/api/v1/tasks/${taskId}/verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({content: condition})
    })
      .then(checkStatus);
  }

  rejectRequest(task, reason) {
    return authFetch(`${config.rfaApi}/api/v1/tasks/${task.taskId}/refuse`, {
      method: 'POST',
      body: reason
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  removeSuspensiveCondition(requestId, role) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}/remove-suspensive-condition/${role}`, {
      method: 'POST'
    })
      .then(checkStatus);
  }

  /**
   * @param {Array.{property: string, direction: 'asc'|'desc'}} sortBy
   */
  findRequestsByOrganisationId(organisationId, page, size, sortBy = [{property: 'creationDate', direction: 'desc'}]) {
    const sort = sortBy.map(item => `&sort=${item.property},${item.direction}`).join('');
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval?organisationId=${organisationId}&page=${page}&size=${size}${sort}`)
      .then(checkStatus)
      .then(parseHeadersAndJson)
      .then(({json, headers}) => ({
        requests: json,
        totalCount: Number(headers.get('X-Total-Count'))
      }));
  }

  /**
   * @param {Array.{property: string, direction: 'asc'|'desc'}} sortBy
   */
  findRequestsWithActiveTasksForOrganisation(organisationId, page, size, sortBy = [{property: 'creationDate', direction: 'desc'}]) {
    const sort = sortBy.map(item => `&sort=${item.property},${item.direction}`).join('');
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/with-active-tasks?organisationId=${organisationId}&page=${page}&size=${size}${sort}`)
      .then(checkStatus)
      .then(parseHeadersAndJson)
      .then(({json, headers}) => ({
        requests: json,
        totalCount: Number(headers.get('X-Total-Count'))
      }));
  }

  /**
   * @param {Array.{property: string, direction: 'asc'|'desc'}} sortBy
   */
  findRequestsTreeByProjectId(projectId, sortBy = [{property: 'creatorOrganisationId', direction: 'asc'}, {property: 'creationDate', direction: 'desc'}]) {
    const sort = sortBy.map(item => `&sort=${item.property},${item.direction}`).join('');
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval?projectId=${projectId}${sort}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  findValidatedSCRequestsByProjectId(projectId) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/completed?projectId=${projectId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  submitDocument(requestId, file, documentTypeCode) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('documentTypeCode', documentTypeCode);

    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}/upload-document`, {
      method: 'POST',
      body: formData
    })
      .then(checkStatus)
      .then(response => response.json());
  }

  downloadDocument(id) {
    return `${config.rfaApi}/api/v1/documents/${id}/file`;
  }

  downloadAllRequestDocuments(id) {
    return `${config.rfaApi}/api/v1/requests-for-approval/${id}/download-documents`;
  }

  getRequestById(requestId) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getWorkflowByRequestId(requestId) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}/workflow`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getSubcontractorDocumentsByRequestId(requestId) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}/subcontractor-documents`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getSpecificDocumentsByRequestId(requestId) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}/specific-documents`)
      .then(checkStatus)
      .then(response => response.json());
  }

  findParentDetailsById(requestId) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}/parent-details`)
      .then(checkStatus)
      .then(response => response.status === 204 ? null : response.json());
  }

  updatePublicProcurementData(requestId, data) {
    return authFetch(`${config.rfaApi}/api/v1/requests-for-approval/${requestId}/public-procurement-data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
      .then(checkStatus)
      .then(response => response.json());
  }
}
