import React from 'react';
import PropTypes from 'prop-types';
import RequestRow from '../requestRow';
import RequestTreeList from './';

const style = {
  treePadding: {
    paddingLeft: '30px'
  },
  childrenGroup: {
    borderLeft: '5px dotted #009EE0'
  }
};

export const RequestTreeListView = props => {
  function hasChildren(request) {
    return request.children?.length > 0;
  }

  const rows = props.organisations && props.invitations ?
    props.requests.map((request, index) => {
      return (
        <div key={index} style={props.treePadding ? style.treePadding : {}}>
          <RequestRow
            history={props.history}
            request={request}
            organisations={props.organisations}
            invitations={props.invitations}
          />
          {// parent component called reccursively to construct requests tree and display until there is no more children
            hasChildren(request) &&
            <div style={style.childrenGroup}>
              <h3 className="mb-4" style={style.treePadding}>
                <span>{props.getRfaOwnerName(request.subcontractorId)}</span>
                <span className="separator"/>
                <span style={{fontWeight: 'normal'}}>{props.getRfaOwnerRegistrationNumber(request.subcontractorId)}</span>
              </h3>
              <RequestTreeList
                history={props.history}
                getRfaOwnerName={props.getRfaOwnerName}
                getRfaOwnerRegistrationNumber={props.getRfaOwnerRegistrationNumber}
                organisations={props.organisations}
                requests={request.children}
                invitations={props.invitations}
                loading={props.loading}
                treePadding={true}
              />
            </div>
          }
        </div>
      );
    }) : false;

  return (
    <>
      {props.loading && <div className="loading-overlay"/>}
      {rows.length > 0 && rows}
    </>
  );
};

RequestTreeListView.propTypes = {
  history: PropTypes.object.isRequired,
  getRfaOwnerName: PropTypes.func.isRequired,
  getRfaOwnerRegistrationNumber: PropTypes.func.isRequired,
  organisations: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  invitations: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  treePadding: PropTypes.bool
};

RequestTreeListView.defaultProps = {
  invitations: [],
  treePadding: false
};

export default RequestTreeListView;
