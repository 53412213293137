import React from 'react';
import {MenuItem, TextField} from '@material-ui/core';
import {Translate} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';
import {authenticationService} from '../../../../services/authentication/AuthenticationService';
import {USER_ORGANISATION_ROLE} from '../../../../utils/constant';
import config from '../../../../../config';

export const BACK_OFFICE_PROJECT_ROLES_KEY = 'backOfficeRoles';
export const OFFLINE_IS_BACK_OFFICE_KEY = 'offlineMode_isBackOffice';

export const AdminActionsView = () => {
  const isBackOffice = authenticationService.roles().isBackOffice();
  const projectRoles = authenticationService.roles().getProjectRoles();

  const handleBackOfficeProjectRolesChange = event => {
    localStorage.setItem(BACK_OFFICE_PROJECT_ROLES_KEY, JSON.stringify(convertFieldRoleToProjectRoles(event.target.value)));
    document.location.reload();
  };

  const handleSwitchIsBackOffice = () => {
    localStorage.setItem(OFFLINE_IS_BACK_OFFICE_KEY, (!isBackOffice).toString());
    document.location.reload();
  };

  /**
   * Return fieldRole when fieldRole = VERIFICATOR or fieldRole = SIGNATORY
   * Return both roles when fieldRole = VERIFICATOR_SIGNATORY
   * Return no role when fieldRole = NONE, fieldRole is empty or contains unknown roles
   *
   * @param fieldRole - role coming from "select-bo-roles" field
   * @return {('VERIFICATOR'|'SIGNATORY')[]} - back-office roles
   */
  const convertFieldRoleToProjectRoles = fieldRole => {
    return fieldRole === USER_ORGANISATION_ROLE.VERIFICATOR_SIGNATORY ?
      [USER_ORGANISATION_ROLE.VERIFICATOR, USER_ORGANISATION_ROLE.SIGNATORY] :
      [USER_ORGANISATION_ROLE.VERIFICATOR, USER_ORGANISATION_ROLE.SIGNATORY].filter(role => role === fieldRole);
  };

  /**
   * Return roles[0] when roles contains only VERIFICATOR or SIGNATORY
   * Return both roles (VERIFICATOR_SIGNATORY) when roles contains VERIFICATOR and SIGNATORY
   * Return no role (NONE) when roles is empty
   *
   * @param roles - back-office roles stored in local storage
   * @return {('NONE'|'VERIFICATOR'|'SIGNATORY'|'VERIFICATOR_SIGNATORY')}
   */
  const convertProjectRolesToFieldRole = roles => {
    if (!roles || roles.length === 0) {
      return USER_ORGANISATION_ROLE.NONE;
    }

    if (roles.length === 2 && [USER_ORGANISATION_ROLE.VERIFICATOR, USER_ORGANISATION_ROLE.SIGNATORY].every((value, index) => value === roles[index])) {
      return USER_ORGANISATION_ROLE.VERIFICATOR_SIGNATORY;
    }

    const authorizedProjectRoles = roles.filter(role => [USER_ORGANISATION_ROLE.VERIFICATOR, USER_ORGANISATION_ROLE.SIGNATORY].includes(role));
    return authorizedProjectRoles.length > 0 ? authorizedProjectRoles[0] : USER_ORGANISATION_ROLE.NONE;
  };

  return (
    <>
      {(config.offline || isBackOffice) &&
        <div className="my-2 mx-4 background-bo">
          <TextField
            data-id="select-project-roles"
            name="select-project-roles"
            select
            value={convertProjectRolesToFieldRole(projectRoles)}
            onChange={handleBackOfficeProjectRolesChange}
            variant="standard"
          >
            <MenuItem value={USER_ORGANISATION_ROLE.NONE}><Translate value="project.userPermissions.none"/></MenuItem>
            <MenuItem value={USER_ORGANISATION_ROLE.VERIFICATOR}><Translate value="project.userPermissions.verificator"/></MenuItem>
            <MenuItem value={USER_ORGANISATION_ROLE.SIGNATORY}><Translate
              value="project.userPermissions.signatory"/></MenuItem>
            <MenuItem value={USER_ORGANISATION_ROLE.VERIFICATOR_SIGNATORY}><Translate
              value="project.userPermissions.verificator_signatory"/></MenuItem>
          </TextField>
        </div>
      }
      {config.offline &&
        <button
          data-id="switch-back-office"
          type="button"
          className="button-square background-bo"
          onClick={handleSwitchIsBackOffice}
          tooltip={isBackOffice ? 'Currently is a back office user' : 'Currently is NOT a back office user'}
          flow="bottom"
        >
          <span className={isBackOffice ? 'success' : 'active'}>
            <Icon icon={isBackOffice ? 'check' : 'cross'}/>
          </span>
        </button>
      }
    </>
  );
};

export default AdminActionsView;
