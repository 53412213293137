import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';
import {connect} from 'react-redux';

import {AbstractFormComponent, Icon} from 'front-onceforall-core';
import {emailValidator, phoneNumberValidator, requiredValidator} from 'front-onceforall-core/dist/utils/validators';
import {isEmpty} from 'front-onceforall-core/dist/utils/strings';
import TextPhoneNumber from 'front-onceforall-core/dist/components/TextPhoneNumber';

import {getPhoneLocaleFromCountry} from '../country/CountrySelector';

export class ProcurementContact extends AbstractFormComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      defaultPhoneCountry: getPhoneLocaleFromCountry(props.organisation.country)
    };
  }

  getFormData() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      jobTitle: ''
    };
  }

  getFormValidators() {
    return {
      email: emailValidator,
      firstName: requiredValidator,
      lastName: requiredValidator,
      phoneNumber: phoneNumberValidator,
      jobTitle: requiredValidator
    };
  }

  handleSubmitCallback() {
    const data = {
      ...this.state.formData,
      email: isEmpty(this.state.formData.email?.trim()) ? null : this.state.formData.email
    };

    this.props.onProcurementContact(data);
  }

  render() {
    return (
      <div className="mt-4">
        <div data-id="notice-procurement-contact" className="py-4">
          <b><Translate value="procurementContact.title" /></b>
        </div>
        {
          <form className="col-md-9 mt-4" style={{padding: 'initial'}} onSubmit={this.handleSubmit}>
            <div className="row col-12">
              <div className="col-6">
                <TextField
                  label={this.getFieldLabel('procurementContact.firstName', true)}
                  error={this.isOnError('firstName')}
                  helperText={this.helperText('firstName')}
                  data-id="form-firstName"
                  value={this.state.formData.firstName}
                  onChange={this.handleChange('firstName')}
                />
              </div>
              <div className="col-6">
                <TextField
                  label={this.getFieldLabel('procurementContact.lastName', true)}
                  error={this.isOnError('lastName')}
                  helperText={this.helperText('lastName')}
                  data-id="form-lastName"
                  value={this.state.formData.lastName}
                  onChange={this.handleChange('lastName')}
                />
              </div>
            </div>
            <div className="row col-12 mt-4">
              <div className="col-12">
                <TextField
                  label={this.getFieldLabel('procurementContact.jobTitle', true)}
                  error={this.isOnError('jobTitle')}
                  helperText={this.helperText('jobTitle')}
                  data-id="form-jobTitle"
                  value={this.state.formData.jobTitle}
                  onChange={this.handleChange('jobTitle')}
                />
              </div>
            </div>
            <div className="row col-12 mt-4">
              <div className="col-12">
                <TextPhoneNumber
                  label={this.getFieldLabel('procurementContact.phoneNumber', false)}
                  value={this.state.formData.phoneNumber}
                  onChange={this.handleChange('phoneNumber')}
                  error={this.isOnError('phoneNumber')}
                  helperText={this.helperText('phoneNumber')}
                  data-id="form-phoneNumber"
                  defaultLocal={this.state.defaultPhoneCountry}
                  currentLocale={this.props.currentLocale}
                />
              </div>
            </div>
            <div className="row col-12 mt-4">
              <div className="col-12">
                <TextField
                  label={this.getFieldLabel('procurementContact.email', false)}
                  error={this.isOnError('email')}
                  helperText={this.helperText('email')}
                  data-id="form-email"
                  value={this.state.formData.email}
                  onChange={this.handleChange('email')}
                />
              </div>
            </div>
            <button
              type="submit"
              className={'large inline-container no-wrap primary mt-4'}
            >
              <Translate value="action.confirm" />
              <Icon icon="check" />
            </button>
          </form>
        }
      </div>
    );
  }
}

ProcurementContact.propTypes = {
  onProcurementContact: PropTypes.func.isRequired,
  organisation: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    currentLocale: state.i18n.locale
  };
}

export default connect(mapStateToProps)(ProcurementContact);
