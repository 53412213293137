import appConfig from '../../config';

let nbMount = 0;
const scriptId = 'tb-ext-app';
const scriptProxyUrl = 'https://teambrain.app/antilop/app/teambrain/externalApp/';
const scriptSrc = 'https://teambrain.app/antilop/app/teambrain/externalApp/front/extAccess/dist/tb-external-v1.js?v=1.0&s=bGJ1SWRYcngyNWxUUjNIcUd1T1RTUT09&c=UkhmclNsTEx0NjJaVnZmMElxRzByby9ycVBVQUt4UFo4Q3JBTzlqYzZhbS84Q0NacnY2cU5XL2d0ZGtHYlh2Yw==&app=SW1LaUM1cVk5RWxuZEZ0Q21QRFZ1Zz09&t=Tm9Jc0d6ODNMMnIrcnZNeHJrQzVkZz09&key=ZzJoOEJNakpKVXJZSUI1MC92ZU51L1lrTnVYdXFlOUxqRGphYys2NGFuSGV1Q1V6SWNlN3VmQUVYSCttUy9sRllYSmV3cGh0S0hCQ3Y2ZUZXbGpHajZCL2pGSFZkUmZwMnBuYy95T0RiMUk9';

const elementsToRemove = [
  document.getElementsByClassName('tb-ae-iconContainer'),
  document.getElementsByClassName('tb-ae-appContainer'),
  document.getElementsByClassName('tb-ae-messagesContainer')
];

function addFAQ() {
  const script = document.createElement('script');
  script.id = scriptId;
  script.setAttribute('data-proxy-url', scriptProxyUrl);
  script.src = scriptSrc;
  script.async = true;
  document.body.appendChild(script);
}

function removeAllElements(elems) {
  Array.from(elems).map(el => document.body.removeChild(el));
}

function removeFAQ() {
  const script = document.getElementById(scriptId);
  document.body.removeChild(script);
  elementsToRemove.map(removeAllElements);
}

export function mountFAQ() {
  if (!appConfig.featureFlags.showTBFAQ) {
    return;
  }

  nbMount += 1;
  if (nbMount === 1) {
    addFAQ();
  }
}

export function unmountFAQ() {
  if (!appConfig.featureFlags.showTBFAQ) {
    return;
  }

  nbMount -= 1;
  if (nbMount <= 0) {
    nbMount = 0;
    removeFAQ();
  }
}
