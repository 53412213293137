import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Tooltip} from '@material-ui/core';

import {Icon, TextCheckbox} from 'front-onceforall-core';

import {PROJECT_ORGANISATION_ROLE as role} from '../../../utils/constant';

import ProjectInformation from './projectInformation';
import ProjectParticipants from './projectParticipants';
import ProjectDocuments from './projectDocuments';
import ProjectRequestList from './projectRequestList';

export const ProjectDetailsView = props => {
  let deleteButton = <button
    data-id="delete"
    className="large secondary inline-container"
    onClick={props.onDeleteProject}
    disabled={!props.isProjectEditable}
    style={props.isProjectEditable ? {} : {pointerEvents: 'none'}}
  >
    <Icon icon="trash"/>
  </button>;

  deleteButton = props.isProjectEditable ? deleteButton :
    <Tooltip
      data-id="delete-disabled-tooltip"
      title={<Translate value={'project.page.buttonsDisabledTooltip'}/>}
    >
      <span>{deleteButton}</span>
    </Tooltip>;

  return (
    <>
      {!props.loading && !props.error && props.project && (
        <div className="flex-container space-between">
          <div className="mb-5">
            <h1 data-id="request-title" className="ml-6 mb-0">
              {props.project.name}
            </h1>
          </div>
          <div className="col-md-5 inline-container flex-end">
            {props.isBackOffice &&
              <TextCheckbox
                checked={props.project.unlimitedRfa}
                onChange={props.toggleUnlimitedRfa}
                name={'unlimitedRfa'}
                label={<Translate style={{color: '#00796b'}} value="project.unlimitedRfa"/>}
              />
            }
            {props.isBackOffice &&
              <button
                type="button"
                data-id="manage-owner"
                className="large bo inline-container"
                onClick={props.openProjectOwnerTagEditionFormPanel}
              >
                <Translate value="project.owner.title"/>
                <Icon icon="cog"/>
              </button>
            }
            <button
              type="button"
              data-id="manage-user-permissions"
              className="large primary inline-container"
              onClick={props.onManageRolesPanelAction}
            >
              <Translate value="project.userPermissions.title"/>
              <Icon icon="cog"/>
            </button>
            {props.authorizedActions &&
              <button
                type="button"
                data-id="invite-general-contractor"
                className="large primary inline-container"
                onClick={() => props.onGeneralContractorInvitationPanelAction(true)}
              >
                <Translate value={`project.invite.${role.PROJECT_GENERAL_CONTRACTOR}.button`}/>
                <Icon icon="plus"/>
              </button>
            }
            {props.isProjectOwner && deleteButton}
          </div>
        </div>
      )
      }
      <div className="row">
        <ProjectInformation
          actions={props.actions}
          project={props.project}
          error={props.error}
          loading={props.loading}
          isProjectEditionAllowed={props.isProjectEditionAllowed}
          onProjectUpdate={props.onProjectUpdate}
        />
        <ProjectDocuments
          projectId={props.projectId}
          isProjectEditionAllowed={props.isProjectEditionAllowed}
          onProjectUpdate={props.onProjectUpdate}
          actions={props.actions}
          error={props.error}
          loading={props.loading}
        />
        <ProjectParticipants
          projectId={props.projectId}
          organisation={props.organisation}
          authorizedActions={props.authorizedActions}
          isProjectEditionAllowed={props.isProjectEditionAllowed && props.isBackOffice }
          isPanelOpen={props.isGeneralContractorInvitationPanelOpen}
          onPanelAction={props.onGeneralContractorInvitationPanelAction}
          actions={props.actions}
        />
      </div>

      <div className="mt-5 pt-5">
        <ProjectRequestList
          projectId={props.projectId}
          project={props.project}
          // allow action badges to display when user roles are modified
          actionBadgesUpdater={() => {}}
        />
      </div>
    </>
  );
};

ProjectDetailsView.propTypes = {
  project: PropTypes.object,
  projectId: PropTypes.number.isRequired,
  onManageRolesPanelAction: PropTypes.func.isRequired,
  openProjectOwnerTagEditionFormPanel: PropTypes.func.isRequired,
  isGeneralContractorInvitationPanelOpen: PropTypes.bool.isRequired,
  onGeneralContractorInvitationPanelAction: PropTypes.func.isRequired,
  authorizedActions: PropTypes.bool.isRequired,
  onDeleteProject: PropTypes.func.isRequired,
  isProjectEditable: PropTypes.bool.isRequired,
  isProjectOwner: PropTypes.bool.isRequired,
  isProjectEditionAllowed: PropTypes.bool.isRequired,
  isBackOffice: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  onProjectUpdate: PropTypes.func,
  toggleUnlimitedRfa: PropTypes.func
};

export default ProjectDetailsView;
