import {anAddress, aProjectVM, aProjectOwnerTagVM, aPublicProjectVM} from '../fixtures';
import {findOrganisation} from './organisations';
import {PROJECT_ASPECT} from '../constant';

const projectsFakeData = [
  aPublicProjectVM({
    name: '50 - project uncomplete dc4',
    id: 51,
    openingDate: '2020-03-31T23:00:00Z',
    closingDate: '2021-11-30T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'INS_RC_PRO',
      'FR_TAX'
    ],
    creatorOrganisationId: findOrganisation(1101).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: null,
    generalContractorIds: [findOrganisation(1301).id]
  }),
  aPublicProjectVM({
    name: '50 - project complete dc4',
    id: 50,
    openingDate: '2020-03-31T23:00:00Z',
    closingDate: '2021-11-30T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'INS_RC_PRO',
      'FR_TAX'
    ],
    creatorOrganisationId: findOrganisation(1101).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: null,
    generalContractorIds: [findOrganisation(1301).id]
  }),
  aProjectVM({
    name: '1 - no PM + no GCs',
    id: 1,
    creatorOrganisationId: findOrganisation(1101).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: null,
    generalContractorIds: [],
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'INS_RC_PRO',
      'FR_INS_DEC',
      'FR_TAX',
      'FR_PAY',
      'FR_QUALIF_CERTIF',
      'ADD_RIB',
      'ADD_SUBCONTRACTING_K',
      'ADD_PROJECT_CHARTERS'
    ]
  }),
  aProjectVM({
    id: 2,
    name: '2 - No PM',
    openingDate: '2020-03-31T23:00:00Z',
    closingDate: '2021-11-30T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'INS_RC_PRO',
      'FR_TAX'
    ],
    creatorOrganisationId: findOrganisation(1102).id,
    clientId: findOrganisation(1102).id,
    projectManagerId: null,
    generalContractorIds: [findOrganisation(1301).id]
  }),
  aProjectVM({
    id: 3,
    name: '3 - Client only',
    address: anAddress({
      addressLine: '3, rue du projet',
      postCode: '69003'
    }),
    openingDate: '2019-12-31T23:00:00Z',
    closingDate: '2021-12-31T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'INS_RC_PRO',
      'FR_INS_DEC',
      'FR_TAX',
      'FR_PAY',
      'FR_QUALIF_CERTIF',
      'EMP_STAFF',
      'ADD_RIB',
      'ADD_SWORN_STAT',
      'ADD_SUBCONTRACTING_K',
      'ADD_PROJECT_CHARTERS'
    ],
    creatorOrganisationId: findOrganisation(1101).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: null,
    generalContractorIds: [],
    clientDelegateId: null,
    clientAssistantId: null,
    safetyCoordinatorId: null
  }),
  aProjectVM({
    id: 4,
    name: '4 - 3 GCs + 2 invited GC',
    address: anAddress({
      addressLine: '4, rue du projet',
      postCode: '69004'
    }),
    openingDate: '2020-01-02T23:00:00Z',
    closingDate: '2021-09-02T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'INS_RC_PRO',
      'FR_TAX',
      'FR_PAY',
      'EMP_STAFF',
      'ADD_RIB',
      'ADD_SUBCONTRACTING_K',
      'ADD_PROJECT_CHARTERS'
    ],
    creatorOrganisationId: findOrganisation(1101).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: findOrganisation(1202).id,
    generalContractorIds: [findOrganisation(1301).id, findOrganisation(1302).id, findOrganisation(1303).id]
  }),
  aProjectVM({
    id: 5,
    name: '5 - PM Owner + No CD, CA, GCs and SafeCo',
    address: anAddress({
      addressLine: '5, rue du projet',
      postCode: '69005'
    }),
    openingDate: '2020-03-03T23:00:00Z',
    closingDate: '2021-11-04T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'FR_INS_DEC',
      'FR_TAX',
      'FR_QUALIF_CERTIF',
      'ADD_RIB',
      'ADD_SUBCONTRACTING_K',
      'ADD_PROJECT_CHARTERS'
    ],
    creatorOrganisationId: findOrganisation(1202).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: findOrganisation(1202).id,
    clientDelegateId: null,
    clientAssistantId: null,
    safetyCoordinatorId: null,
    generalContractorIds: [],
    projectOwnerTag: 'Bouygues',
    projectOwnerTagUpdateUser: 'SOUMER',
    projectOwnerTagUpdateDate: '03/07/2023'
  }),
  aProjectVM({
    id: 6,
    name: '6 - CA owner + invited Client [EDITABLE]',
    address: anAddress({
      addressLine: '6, rue du projet',
      postCode: '69006'
    }),
    openingDate: '2020-02-04T23:00:00Z',
    closingDate: '2021-10-08T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES'
    ],
    creatorOrganisationId: findOrganisation(1601).id,
    clientId: null,
    projectManagerId: findOrganisation(1202).id,
    generalContractorIds: [findOrganisation(1301).id],
    properties: {
      aspects: [PROJECT_ASPECT.PROJECT_EDITABLE]
    }
  }),
  aProjectVM({
    id: 7,
    name: '7 - No CD, invited CA and SafeCo',
    address: anAddress({
      addressLine: '7, rue du projet',
      postCode: '69007'
    }),
    openingDate: '2020-02-04T23:00:00Z',
    closingDate: '2021-10-08T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'FR_INS_DEC',
      'FR_TAX',
      'FR_QUALIF_CERTIF',
      'ADD_RIB',
      'ADD_SUBCONTRACTING_K',
      'ADD_PROJECT_CHARTERS'
    ],
    creatorOrganisationId: findOrganisation(1202).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: findOrganisation(1202).id,
    generalContractorIds: [findOrganisation(1301).id],
    clientDelegateId: null,
    clientAssistantId: null,
    safetyCoordinatorId: null
  }),
  aProjectVM({
    id: 12,
    name: '12 - invited GC',
    address: anAddress({
      addressLine: '12, rue du projet',
      postCode: '69007'
    }),
    openingDate: '2020-02-04T23:00:00Z',
    closingDate: '2021-10-08T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES'
    ],
    creatorOrganisationId: findOrganisation(1202).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: findOrganisation(1202).id,
    generalContractorIds: []
  }),
  aProjectVM({
    id: 13,
    name: '13 - CD owner + 2 GCs + 2 invited GCs 1 invited Client',
    address: anAddress({
      addressLine: '13, rue du projet',
      postCode: '69013'
    }),
    openingDate: '2020-02-04T23:00:00Z',
    closingDate: '2021-10-08T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES'
    ],
    creatorOrganisationId: findOrganisation(1501).id,
    clientId: null,
    projectManagerId: findOrganisation(1202).id,
    generalContractorIds: [findOrganisation(1301).id, findOrganisation(1302).id]
  }),
  aProjectVM({
    id: 14,
    name: '14 - 2 GC validating SC1',
    address: anAddress({
      addressLine: '14, rue du projet',
      postCode: '69014'
    }),
    openingDate: '2020-02-04T23:00:00Z',
    closingDate: '2021-10-08T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES'
    ],
    creatorOrganisationId: findOrganisation(1301).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: findOrganisation(1202).id,
    generalContractorIds: [findOrganisation(1301).id, findOrganisation(1302).id],
    properties: {
      aspects: [
        PROJECT_ASPECT.PROJECT_READY
      ]
    }
  }),
  aProjectVM({
    id: 31,
    name: '31 - invited PM + GC + CD',
    address: anAddress({
      addressLine: '3, rue du projet',
      postCode: '69003'
    }),
    openingDate: '2019-12-31T23:00:00Z',
    closingDate: '2021-12-31T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'INS_RC_PRO',
      'FR_INS_DEC',
      'FR_TAX',
      'FR_PAY',
      'FR_QUALIF_CERTIF',
      'ADD_RIB',
      'ADD_SUBCONTRACTING_K',
      'ADD_PROJECT_CHARTERS'
    ],
    creatorOrganisationId: findOrganisation(1101).id,
    clientId: findOrganisation(1101).id,
    projectManagerId: null,
    generalContractorIds: [],
    clientDelegateId: null,
    properties: {
      aspects: []
    }
  }),
  aProjectVM({
    id: 32,
    name: '32 - Project OFA Documents',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES',
      'INS_RC_PRO',
      'FR_INS_DEC',
      'ADD_SUBCONTRACTING_K',
      'ADD_PROJECT_CHARTERS'
    ]
  }),
  aProjectVM({
    id: 40,
    name: '40 - GC owner + invited all',
    address: anAddress({
      addressLine: '13, rue du projet',
      postCode: '69013'
    }),
    openingDate: '2020-02-04T23:00:00Z',
    closingDate: '2021-10-08T22:59:59.999Z',
    requiredDocumentTypeCodes: [
      'FR_REG',
      'FR_COV',
      'FOREIGN_EMPLOYEES'
    ],
    creatorOrganisationId: findOrganisation(1301).id,
    clientId: null,
    projectManagerId: null,
    clientDelegateId: null,
    clientAssistantId: null,
    safetyCoordinatorId: null,
    generalContractorIds: [findOrganisation(1301).id],
    properties: {
      aspects: [
        PROJECT_ASPECT.PROJECT_READY,
        PROJECT_ASPECT.PROJECT_EDITABLE
      ]
    }
  })
];

const projectsOwnerTagFakeData = {};

export const getProjectsFakeData = () => projectsFakeData;
export const findProject = id =>
  Object.assign({}, projectsFakeData.find(project => project.id === id));

export function getFakeOwnerTag(projectId) {
  return projectsOwnerTagFakeData[projectId];
}

export function updateFakeOwnerTag(projectId, projectOwnerTag) {
  projectsOwnerTagFakeData[projectId] = aProjectOwnerTagVM({projectOwnerTag});
}

export function editFakeProjectUnlimitedRfa(projectId, unlimitedRfa) {
  let project = findProject(projectId);
  project.unlimitedRfa = unlimitedRfa;
  return project;
}

