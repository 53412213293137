import {phoneNumberValidator, emailValidator} from 'front-onceforall-core/dist/utils/validators';

export const onFieldChange = (name, value) => current => {
  let errors = {
    ...current.errors,
    generalContractor: {
      ...current.errors?.generalContractor
    }
  };

  if (name === 'email') {
    errors.generalContractor.email = emailValidator(value);
  } else if (name === 'phoneNumber') {
    errors.generalContractor.phoneNumber = phoneNumberValidator(value);
  }

  if (!errors.generalContractor.email && !errors.generalContractor.phoneNumber) {
    delete errors.generalContractor;
  }

  return {
    generalContractor: {
      ...current.generalContractor,
      [name]: value
    },
    errors
  };
};
