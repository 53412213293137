import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {userService} from '../../../services/user/UserService';
import {authenticationService} from '../../../services/authentication/AuthenticationService';
import {captureError} from '../../../utils/log';
import {Translate} from 'react-redux-i18n';
import {Icon, TextCheckbox} from 'front-onceforall-core';
import {USER_ORGANISATION_ROLE} from '../../../utils/constant';

export class ManageUserPermissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userPermissions: [],
      permissionsError: false,
      submitting: false,
      loading: false,
      error: false
    };

    this.fetchUserPermissions = this.fetchUserPermissions.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(index, roleInput) {
    const userPermissions = this.state.userPermissions;
    const i = userPermissions[index].roles.indexOf(roleInput);
    if (i < 0) {
      userPermissions[index].roles.push(roleInput);
    } else {
      userPermissions[index].roles.splice(i, 1);
    }

    this.setState({userPermissions});
  }

  handleSubmit(event) {
    event.preventDefault();

    /**
     * Verify the following rules for roles:
     *   - At least 1 verificator
     *   - At least 1 signatory
     */
    if (!this.state.userPermissions.some(user => user.roles.includes(USER_ORGANISATION_ROLE.VERIFICATOR)) ||
      !this.state.userPermissions.some(user => user.roles.includes(USER_ORGANISATION_ROLE.SIGNATORY))) {
      this.setState({permissionsError: true});
      return;
    }

    const newPermissions = this.state.userPermissions.map(userPermission => {
      return {userId: userPermission.userId, roles: userPermission.roles};
    });

    this.setState({loading: true});
    userService.updateUserPermissionsByOrganisationProject(this.props.organisationId, this.props.projectId, newPermissions)
      // Force the update of permission token to get changes after modifying the roles in project
      .then(() => authenticationService.updateTokenRoles(true))
      .then(() => {
        this.props.actions.addSuccess(<Translate value="project.userPermissions.updatePermissionsSuccess"/>);
        this.props.onClose();
      })
      .catch(error => {
        captureError('ManageUserPermissions.handleSubmit', error);
        this.props.actions.addError(<Translate value="error.generic"/>);
      })
      .finally(() => this.setState({loading: false}));
  }

  fetchUserPermissions() {
    userService.getUserPermissionsByOrganisationProject(this.props.organisationId, this.props.projectId)
      .then(response => {
        const userPermissions = response.map(user => {
          return {
            fullName: user.userOrganisation.user.fullName,
            userId: user.userOrganisation.user.id,
            roles: user.roles
          };
        });

        this.setState({userPermissions});
      })
      .catch(error => {
        captureError('fetchUserPermissions', error);
        this.setState({error: true});
      })
      .finally(() => this.setState({loading: false}));
  }

  componentDidMount() {
    this.setState({loading: true});
    this.fetchUserPermissions();
  }

  render() {
    return (
      <>
        <div className="flex-container space-between">
          <div className="mb-5">
            <h1 data-id="request-title" className="ml-6 mb-0">
              <Translate value="project.userPermissions.title"/>
            </h1>
          </div>
        </div>
        {this.state.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
          this.state.loading ? <div className="loading-overlay"/> :
            <form className="col-12" onSubmit={this.handleSubmit}>
              <div style={{position: 'relative'}}>
                <table>
                  <thead>
                    <tr>
                      <td><Translate value="project.userPermissions.user"/></td>
                      <td><Translate value="project.userPermissions.verificator"/></td>
                      <td><Translate value="project.userPermissions.signatory"/></td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.userPermissions.map((user, index) =>
                      <tr key={index}>
                        <td>
                          <span data-id="user-fullName">{user.fullName}</span>
                        </td>
                        <td>
                          <TextCheckbox
                            key={index}
                            checked={user.roles.includes(USER_ORGANISATION_ROLE.VERIFICATOR)}
                            onChange={() => this.handleChange(index, USER_ORGANISATION_ROLE.VERIFICATOR)}
                          />
                        </td>
                        <td>
                          <TextCheckbox
                            key={index}
                            checked={user.roles.includes(USER_ORGANISATION_ROLE.SIGNATORY)}
                            onChange={() => this.handleChange(index, USER_ORGANISATION_ROLE.SIGNATORY)}
                          />
                        </td>
                      </tr>)}
                  </tbody>
                </table>
              </div>
              {this.state.permissionsError &&
                <div data-id="permissions-error-message" className="col-12 mt-4">
                  <span className="error font-size-s">
                    <Translate value="project.userPermissions.permissionsError"/>
                  </span>
                </div>
              }
              <div className="actions col-12 inline-container space-between">
                <button type="button" className="large secondary inline-container" onClick={this.props.onClose} data-id="cancel">
                  <Icon icon="go-back"/>
                  <Translate value="action.cancel"/>
                </button>
                <button data-id="submit-button" className="large primary inline-container" type="submit" disabled={this.state.submitting}>
                  <Translate value={'action.confirm'}/>
                  <Icon icon={this.state.submitting ? 'loader' : 'check'}/>
                </button>
              </div>
            </form>
        }
      </>
    );
  }
}

ManageUserPermissions.propTypes = {
  actions: PropTypes.object.isRequired,
  organisationId: PropTypes.number.isRequired,
  projectId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ManageUserPermissions;
