const initialState = [];
const DISMISS_THRESHOLD = 1000;

export default function snackbars(state = initialState, action = '') {
  if (action.type === 'dismiss') {
    return state.filter(notif => notif.id !== action.id);
  }

  if (action.type === 'dismissAll') {
    return state.filter(notif => notif.timestamp > (new Date().getTime() - DISMISS_THRESHOLD));
  }

  if (action.type === 'add') {
    return [
      {
        id: state.reduce((maxId, notif) => Math.max(notif.id, maxId), -1) + 1,
        message: action.message,
        kind: action.kind,
        timestamp: new Date().getTime()
      },
      ...state
    ];
  }

  return state;
}
