import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Nav from './nav';
import OrganisationSelector from './organisationSelector';
import AdminActions from './adminActions';
import {Maintenance} from 'front-onceforall-core';
import {globalContextPages} from '../../../App';

export const OrganisationHeaderView = props => {
  const shouldHideOrganisationHeader = globalContextPages.includes(props.location.pathname);
  return (
    <>
      {props.organisation && !shouldHideOrganisationHeader &&
        <>
          <div className="organisation-bar">
            <div className="flex-container">
              <OrganisationSelector/>
              <Nav/>
            </div>
            <div className="action-bar">
              <AdminActions/>
            </div>
          </div>
          <Maintenance
            class="card mt-4"
            maintenance={props.maintenance}
            currentLocale={props.currentLocale}
          />
        </>
      }
    </>
  );
};

OrganisationHeaderView.propTypes = {
  location: PropTypes.object.isRequired,
  organisation: PropTypes.object,
  shouldHideOrganisationHeader: PropTypes.bool,
  currentLocale: PropTypes.string.isRequired,
  maintenance: PropTypes.object
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation,
    organisationLogo: state.organisationLogo,
    currentLocale: state.i18n.locale,
    maintenance: state.maintenance
  };
}

export default withRouter(connect(mapStateToProps)(OrganisationHeaderView));
