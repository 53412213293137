import React from 'react';
import PropTypes from 'prop-types';
import {Translate, I18n} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';
import moment from 'moment';

import {TextCheckbox} from 'front-onceforall-core';

import {getFieldLabel} from '../../utils';

import {updateWithError, updateAndResetError, updateSubcontractorContractDurationNotApplicable} from './utils';

function SubcontractorContractDuration({rfa, publicProcurementData, onChange}) {
  const handleChange = event => {
    const value = event.target.value.trim();
    if (Number.isInteger(Number(value)) && Number(value) >= 0) {
      onChange(updateAndResetError(value));
    } else {
      onChange(updateWithError(value));
    }
  };

  const handleSubcontractorContractDurationNotApplicableChange = event => {
    onChange(updateSubcontractorContractDurationNotApplicable(event.target.checked));
  };

  React.useEffect(() => {
    if (publicProcurementData.subcontractorContractDurationNotApplicable === null) {
      onChange({subcontractorContractDurationNotApplicable: false});
    }
  }, [publicProcurementData?.subcontractorContractDurationNotApplicable]);

  const hasError = Boolean(publicProcurementData.errors?.subcontractorContractDurationInMonths);

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.subcontractorContractDuration"/></h2>
      </div>
      <div className="flex-container pb-4">
        <Translate value="publicProcurement.subcontractorContractDurationDetails"/>
      </div>
      <div className="pb-4">
        <Translate value="request.page.estimatedDatesTitle"/>&nbsp;
        <Translate
          value="request.page.estimatedDates"
          dateStart={moment(rfa.startingDate).format('L')}
          dateEnd={moment(rfa.endingDate).format('L')}
          style={{textTransform: 'lowercase'}}
        />
      </div>
      <div className="row">
        <div className="col-12 pb-3">
          <TextCheckbox
            id="subcontractorContractDurationNotApplicable"
            name="subcontractorContractDurationNotApplicable"
            label={getFieldLabel('publicProcurement.subcontractorContractDurationNotApplicable', false)}
            checked={Boolean(publicProcurementData.subcontractorContractDurationNotApplicable)}
            onChange={handleSubcontractorContractDurationNotApplicableChange}
          />
        </div>
        <div className="col-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.subcontractorContractDurationInMonths', !publicProcurementData.subcontractorContractDurationNotApplicable)}
            id="subcontractorContractDurationInMonths"
            name="subcontractorContractDurationInMonths"
            value={publicProcurementData.subcontractorContractDurationInMonths || ''}
            onChange={handleChange}
            error={hasError}
            helperText={hasError && I18n.t(publicProcurementData.errors?.subcontractorContractDurationInMonths)}
            disabled={Boolean(publicProcurementData.subcontractorContractDurationNotApplicable)}
          />
        </div>
      </div>
    </div>
  );
}

SubcontractorContractDuration.propTypes = {
  onChange: PropTypes.func.isRequired,
  rfa: PropTypes.shape({
    startingDate: PropTypes.string.isRequired,
    endingDate: PropTypes.string.isRequired
  }).isRequired,
  publicProcurementData: PropTypes.shape({
    subcontractorContractDurationNotApplicable: PropTypes.bool,
    subcontractorContractDurationInMonths: PropTypes.number,
    errors: PropTypes.shape({
      subcontractorContractDurationInMonths: PropTypes.string
    })
  }).isRequired
};

export default SubcontractorContractDuration;
