import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as snackbarsActions from '../../actions/snackbars';
import {Translate} from 'react-redux-i18n';
import RequestCreateView from './RequestCreateView';
import {requestService} from '../../services/request/RequestService';
import {captureError} from '../../utils/log';
import {enrollmentService} from '../../services/enrollment/EnrollmentService';
import {parse} from 'query-string';

const PROJECT_STEP = 0;
const REQUEST_STEP = 1;
const SUBCONTRACTOR_STEP = 2;

export const RequestCreateContainer = props => {
  const [projectId, setProjectId] = React.useState(parse(props.location.search, {parseNumbers: true}).projectId || null);
  const [project, setProject] = React.useState(null);
  const [allowSelectRequest, setAllowSelectRequest] = React.useState(false);
  const [subcontractor, setSubcontractor] = React.useState(null);
  const [subcontractorUser, setSubcontractorUser] = React.useState(null);
  const [request, setRequest] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [currentStep, setCurrentStep] = React.useState(PROJECT_STEP);

  const selectNextStep = skipNextStep => {
    // skip parent request selection if needed
    const supposedNextStep = skipNextStep ? currentStep + 2 : currentStep + 1;

    // skip subcontractor selection if already selected
    const nextStep = supposedNextStep === 2 && subcontractor ? supposedNextStep + 1 : supposedNextStep;
    setCurrentStep(nextStep);
  };

  const selectPreviousStep = selectedStep => {
    if (selectedStep === PROJECT_STEP) {
      setAllowSelectRequest(false);
      setRequest(null);
    }

    setCurrentStep(selectedStep);
  };

  const handleSubmitStep = (data, setter, step, skip = false) => {
    setter(data);
    if (data) {
      selectNextStep(skip);
    } else {
      selectPreviousStep(step);
    }
  };

  const checkIfOrganisationIsValidatedSubcontractor = selectedProject => {
    return ![
      selectedProject.creatorOrganisationId,
      selectedProject.clientId,
      selectedProject.clientDelegateId,
      selectedProject.clientAssistantId,
      selectedProject.projectManagerId,
      ...selectedProject.generalContractorIds
    ].includes(props.organisation.id);
  };

  const handleSelectSubcontractor = selectedSubcontractor => {
    // We skip user form when subcontractor is unknown
    if (selectedSubcontractor && !selectedSubcontractor.id) {
      handleSubmitStep(selectedSubcontractor, setSubcontractor, SUBCONTRACTOR_STEP);
    } else {
      setSubcontractor(selectedSubcontractor);
    }
  };

  const handleSelectProject = selectedProject => {
    let skipRequestStep = true;
    setProjectId(selectedProject ? selectedProject.id : null);
    if (selectedProject && checkIfOrganisationIsValidatedSubcontractor(selectedProject)) {
      skipRequestStep = false;
      setAllowSelectRequest(true);
    }

    handleSubmitStep(selectedProject, setProject, PROJECT_STEP, skipRequestStep);
  };

  const submitRequest = details => {
    setLoading(true);

    const requestCreation = {
      projectId: project.id,
      subcontractorId: subcontractor.id || null,
      subcontractorInvitedUser: subcontractorUser ? {
        id: subcontractorUser.id || null,
        email: subcontractorUser.email,
        firstName: subcontractorUser.firstName,
        lastName: subcontractorUser.lastName,
        phoneNumber: subcontractorUser.phoneNumber
      } : null,
      creatorOrganisationId: props.organisation.id,
      parentId: request ? request.id : null,
      ...details
    };

    requestService.create(requestCreation)
      .then(async response => {
        props.actions.addSuccess(<Translate value="request.create.creationSuccess"/>);

        // Invite the subcontractor if he is unknown
        if (!subcontractor.id) {
          await enrollmentService.inviteUnknownSubcontractor(subcontractor, response.id, props.organisation.id)
            .then(() => {
              props.actions.addSuccess(<Translate
                value="invite.inviteSuccess"
                organisationName={subcontractor.organisationName}
              />);
            })
            .catch(error => {
              captureError('Error while inviting new subcontractor to RFA', error);
              props.actions.addError(<Translate value="invite.inviteFailure"/>);
            });
        }

        props.history.push(`/request/${response.id}`);
      })
      .catch(error => handleSubmitError(error));
  };

  const handleSubmitError = error => {
    error.response.json().then(body => {
      setLoading(false);
      if (error.response.status === 409 && body.errorCode === 'error.insufficientRequestForApprovalCredits') {
        captureError('Error while creating request with no credits', error);
        props.actions.addError(<Translate value="error.insufficientCredits"/>);
        return;
      }

      captureError('Error while creating request', error);
      props.actions.addError(<Translate value="error.generic"/>);
    });
  };

  return (
    <RequestCreateView
      onSelectSubcontractor={handleSelectSubcontractor}
      onSelectSubcontractorUser={data => handleSubmitStep(data, setSubcontractorUser, SUBCONTRACTOR_STEP)}
      onSelectRequest={data => handleSubmitStep(data, setRequest, REQUEST_STEP)}
      onSelectProject={data => handleSelectProject(data)}
      showSelectRequestStep={allowSelectRequest}
      onSubmitDetails={submitRequest}
      currentStep={currentStep}
      projectId={projectId}
      project={project}
      request={request}
      subcontractor={subcontractor}
      subcontractorUser={subcontractorUser}
      loading={loading}
    />
  );
};

RequestCreateContainer.propTypes = {
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string
  }).isRequired
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestCreateContainer);
