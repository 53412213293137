import {
  aClient,
  aClientAssistant,
  aClientDelegate,
  aFrenchVATIdentificationNumber,
  aGeneralContractor,
  anOrganisationVM,
  aProjectManager,
  aSafetyCoordinator,
  aSirenIdentificationNumber,
  aSubcontractor
} from '../fixtures';
import {captureError} from '../log';

const organisationsFakeData = [
  anOrganisationVM(),
  anOrganisationVM({
    id: 1002,
    fullName: 'Organisation 1',
    registrationNumber: '12345678900001',
    groupId: 1002,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 10021,
          value: '123456789'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 10021,
          value: 'FR81123456789'
        })
    ]
  }),
  anOrganisationVM({
    id: 1003,
    fullName: 'Organisation 2 disabled',
    registrationNumber: '98765432100001',
    groupId: 1003,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 10031,
          value: '987654321'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 10032,
          value: 'FR81987654321'
        })
    ],
    enabled: false
  }),
  anOrganisationVM({
    id: 1004,
    fullName: 'Organisation info from ILG',
    registrationNumber: '11223344500001',
    groupId: 1004,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 10041,
          value: '112233445'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 10042,
          value: 'FR81112233445'
        })
    ],
    fromProvider: true
  }),
  anOrganisationVM({
    id: 1005,
    fullName: 'Organisation from ILG disabled',
    registrationNumber: '11223344500888',
    groupId: 1004,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 10041,
          value: '112233445'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 10042,
          value: 'FR81112233445'
        })
    ],
    fromProvider: true,
    enabled: false
  }),
  aClient(),
  aClient({
    id: 1102,
    fullName: 'Client 2',
    registrationNumber: '48685802280772',
    groupId: 1102,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 11021,
          value: '486858022'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 11022,
          value: 'FR81486858022'
        })
    ]
  }),
  aProjectManager(),
  aProjectManager({
    id: 1202,
    fullName: 'Project manager 2',
    registrationNumber: '81326463521220',
    groupId: 1202,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 12021,
          value: '813264635'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 12022,
          value: 'FR81813264635'
        })
    ]
  }),
  aGeneralContractor(),
  aGeneralContractor({
    id: 1302,
    fullName: 'General Contractor 2',
    registrationNumber: '67093769333936',
    groupId: 1302,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 13021,
          value: '670937693'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 13022,
          value: 'FR81670937693'
        })
    ]
  }),
  aGeneralContractor({
    id: 1303,
    fullName: 'General Contractor 3',
    registrationNumber: '38905852017512',
    groupId: 1303,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 13031,
          value: '389058520'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 13032,
          value: 'FR81389058520'
        })
    ]
  }),
  aSubcontractor(),
  aSubcontractor({
    id: 1402,
    fullName: 'Subcontractor 2',
    registrationNumber: '75822360045896',
    groupId: 1402,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 14021,
          value: '758223600'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 14022,
          value: 'FR81758223600'
        })
    ]
  }),
  aSubcontractor({
    id: 1403,
    fullName: 'Subcontractor 3',
    registrationNumber: '48493883446526',
    groupId: 1403,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 14031,
          value: '484938834'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 14032,
          value: 'FR81484938834'
        })
    ]
  }),
  aSubcontractor({
    id: 1404,
    fullName: 'Subcontractor 4',
    registrationNumber: '41521670612422',
    groupId: 1404,
    identificationNumbers: [
      aSirenIdentificationNumber(
        {
          identificationNumberId: 14041,
          value: '415216706'
        }),
      aFrenchVATIdentificationNumber(
        {
          identificationNumberId: 14042,
          value: 'FR81415216706'
        })
    ]
  }),
  aSafetyCoordinator(),
  aClientDelegate(),
  aClientAssistant()
];

export const getOrganisationsFakeData = () => organisationsFakeData;

export const findOrganisation = id => {
  const organisation = organisationsFakeData.find(data => data.id === id);
  if (!organisation) {
    captureError(`Organisation with id ${id} not found`);
    return organisationsFakeData[1];
  }

  return organisation;
};
