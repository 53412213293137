import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import OrganisationSelectorView from './OrganisationSelectorView';
import {countrySelectorId} from '../../../country/CountrySelector';

export const OrganisationSelectorContainer = props => {
  const [isSelectorOpen, setIsSelectorOpen] = React.useState(false);

  const buttonRef = React.useRef(null);
  const selectorRef = React.useRef(null);

  const showOrganisationSelector = () => {
    setIsSelectorOpen(true);
    document.addEventListener('mousedown', handleOrganisationsOuterClick, false);
  };

  const handleOrganisationsOuterClick = React.useCallback(event => {
    if (isEventConnectedToSwitchOrganisation(event)) {
      return;
    }

    setIsSelectorOpen(false);
    document.removeEventListener('mousedown', handleOrganisationsOuterClick, false);
  }, []);

  const hideOrganisationSelector = () => {
    setIsSelectorOpen(false);
    document.removeEventListener('mousedown', handleOrganisationsOuterClick, false);
  };

  const handleOrganisationSelectorButtonClick = () => {
    if (isSelectorOpen) {
      hideOrganisationSelector();
    } else {
      showOrganisationSelector();
    }
  };

  const isEventConnectedToSwitchOrganisation = event => {
    function isOrganisationSelector() {
      return (selectorRef.current && selectorRef.current.contains(event.target)) ||
        (buttonRef.current && buttonRef.current.contains(event.target));
    }

    function isChangeCountryModal(element) {
      if (!element) {
        return false;
      }

      if (element.getAttribute('role') === 'presentation' &&
        element.getAttribute('id') === 'menu-' + countrySelectorId) {
        return true;
      }

      return isChangeCountryModal(element.parentElement);
    }

    return isOrganisationSelector.call(this) || isChangeCountryModal(event.target);
  };

  React.useEffect(() => {
    return () => {
      document.removeEventListener('mousedown', handleOrganisationsOuterClick, false);
    };
  }, [handleOrganisationsOuterClick]);

  return (
    <OrganisationSelectorView
      buttonRef={buttonRef}
      selectorRef={selectorRef}
      isSelectorOpen={isSelectorOpen}
      onOrganisationSelectorButtonClick={handleOrganisationSelectorButtonClick}
      onOrganisationSelectorAction={hideOrganisationSelector}
      organisation={props.organisation}
    />
  );
};

OrganisationSelectorContainer.propTypes = {
  organisation: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

export default connect(mapStateToProps)(OrganisationSelectorContainer);
