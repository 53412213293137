import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {NavLink, withRouter} from 'react-router-dom';

const navItems = [
  {
    label: 'menu.dashboard',
    link: '/dashboard',
    deniedRoles: [],
    showNotificationWithActionsCounter: true
  },
  {
    label: 'menu.projects',
    link: '/projects',
    deniedRoles: []
  },
  {
    label: 'menu.requests',
    link: '/requests'
  },
  {
    label: 'menu.users',
    link: '/users'
  },
  {
    label: 'menu.organisation',
    link: '/organisation'
  }
];

export const NavView = props => {
  const isLinkActive = link => {
    return () => props.location.pathname.startsWith(link.substring(0, link.length - 1));
  };

  const renderNavItems = () => {
    return navItems.map((item, index) => {
      const navLink = (
        <NavLink
          data-id={`link-${index}`}
          to={item.link}
          isActive={isLinkActive(item.link)}
        >
          <div className="badge-container">
            <Translate value={item.label}/>
            {/* item.showNotificationWithActionsCounter && props.notifications.notificationsWithActions.length > 0 &&
              <span className="badge small red">{props.notifications.notificationsWithActions.length}</span>
            */}
          </div>
        </NavLink>
      );

      return (
        <React.Fragment key={index}>
          {navLink}
        </React.Fragment>
      );
    });
  };

  return (
    <div className="nav-container">
      <nav>
        {renderNavItems()}
      </nav>
    </div>
  );
};

NavView.propTypes = {
  location: PropTypes.object.isRequired
  // notifications: PropTypes.object.isRequired
};

export default withRouter(NavView);
