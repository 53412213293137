import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route, Switch, useRouteMatch, useLocation} from 'react-router-dom';

import {usePrevious} from 'front-onceforall-core/dist/utils/customHooks';

import appConfig from '../../config';

import {requestService} from '../services/request/RequestService';
import {RFA_STATUS} from '../utils/constant';
import Request from '../components/request';
import PublicProcurementForm from '../components/PublicProcurementForm';

import PrivateRoute from './PrivateRoute';

function isDefinedAndDifferent(current, prev) {
  return current !== undefined &&
    (
      prev === undefined ||
      prev !== current
    );
}

function RequestGate() {
  const [loaded, setLoaded] = React.useState(false);
  const [canAccessDC4, setCanAccessDC4] = React.useState(false);
  const organisation = useSelector(state => state.organisation);
  const {requestId} = useRouteMatch().params;
  const location = useLocation();

  const prevRequestId = usePrevious(requestId);
  const prevOrganisation = usePrevious(organisation);

  function checkDC4Access() {
    if (
      !organisation?.id ||
      !requestId ||
      !appConfig.featureFlags.isPublicProcurementEnabled
    ) {
      setCanAccessDC4(false);
      setLoaded(true);
      return;
    }

    requestService.getRequestById(requestId)
      .then(request => {
        const isEditable = request.status === RFA_STATUS.EDITABLE;
        const isRfaOwner = organisation.id === request.creatorOrganisationId;
        const isSubcontractor = organisation.id === request.subcontractorId;

        const canAccess = (
          !isEditable ||
          isRfaOwner ||
          isSubcontractor
        );

        setCanAccessDC4(canAccess);
        setLoaded(true);
      })
      .catch(() => {
        setCanAccessDC4(false);
        setLoaded(true);
      });
  }

  React.useEffect(() => {
    if (location.pathname.endsWith('/dc4')) {
      checkDC4Access();
    }
  }, []);

  React.useEffect(() => {
    if (!location.pathname.endsWith('/dc4')) {
      setLoaded(true);
    }

    const organisationIsDifferent = isDefinedAndDifferent(organisation?.id, prevOrganisation?.id);
    const requestIdIsDifferent = isDefinedAndDifferent(requestId, prevRequestId);
    if (organisationIsDifferent || requestIdIsDifferent) {
      if (location.pathname.endsWith('/dc4')) {
        setLoaded(false);
      }

      checkDC4Access();
    }
  }, [organisation, requestId, location.pathname]);

  if (!loaded) {
    return <div className="loading-overlay"/>;
  }

  return (
    <Switch>
      {appConfig.featureFlags.isPublicProcurementEnabled && (
        <PrivateRoute
          exact
          path="/request/:requestId([0-9]+)/dc4"
          component={PublicProcurementForm}
          canAccess={canAccessDC4}
        />
      )}
      <Route exact path="/request/:requestId([0-9]+)" component={Request}/>
      <Redirect
        from="/request/:requestId([0-9]+)"
        to="/request/:requestId([0-9]+)"
      />
      <Redirect
        from="/request"
        to="/requests"
      />
    </Switch>
  );
}

export default RequestGate;
