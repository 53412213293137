import 'isomorphic-fetch';
import config from '../../../config';
import {authFetch, checkStatus} from '../../utils/http';

const getInvitationBody = (invitation, targetId, creatorOrganisationId) => {
  return ({
    creatorOrganisationId,
    organisationName: invitation.organisationName,
    registrationNumber: invitation.registrationNumber,
    email: invitation.email,
    firstName: invitation.firstName,
    lastName: invitation.lastName,
    phoneNumber: invitation.phoneNumber,
    customMessage: invitation.customMessage,
    targetId
  });
};

export default class LiveEnrollmentService {
  inviteUnknownSubcontractor(invitation, rfaId, creatorId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations/subcontractor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(getInvitationBody(invitation, rfaId, creatorId))
    })
      .then(checkStatus);
  }

  inviteUnknownProjectManager(invitation, projectId, creatorId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations/project-manager`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(getInvitationBody(invitation, projectId, creatorId))
    })
      .then(checkStatus);
  }

  inviteUnknownClient(invitation, projectId, creatorId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations/client`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(getInvitationBody(invitation, projectId, creatorId))
    })
      .then(checkStatus);
  }

  inviteUnknownProjectGeneralContractor(invitation, projectId, creatorId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations/project-general-contractor`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(getInvitationBody(invitation, projectId, creatorId))
    })
      .then(checkStatus);
  }

  inviteUnknownSafetyCoordinator(invitation, projectId, creatorId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations/safety-coordinator`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(getInvitationBody(invitation, projectId, creatorId))
    })
      .then(checkStatus);
  }

  inviteUnknownClientDelegate(invitation, projectId, creatorId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations/client-delegate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(getInvitationBody(invitation, projectId, creatorId))
    })
      .then(checkStatus);
  }

  inviteUnknownClientAssistant(invitation, projectId, creatorId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations/client-assistant`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(getInvitationBody(invitation, projectId, creatorId))
    })
      .then(checkStatus);
  }

  getInvitationByCode(code) {
    return fetch(`${config.enrollmentApi}/api/v1/invitations/code/${code}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getInvitationByProjectId(projectId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations?projectId=${projectId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  getInvitationByRequestId(requestId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations?requestForApprovalId=${requestId}`)
      .then(checkStatus)
      .then(response => response.json());
  }

  deleteProjectParticipantInvitationById(invitationId, projectId) {
    return authFetch(`${config.enrollmentApi}/api/v1/invitations/${invitationId}/${projectId}`, {
      method: 'DELETE'
    })
      .then(checkStatus);
  }
}
