import React from 'react';
import PropTypes from 'prop-types';
import {Translate, I18n} from 'react-redux-i18n';

import {TextCheckbox} from 'front-onceforall-core';

import {getFieldLabel} from '../../utils';

const links = {
  art21411To21415: 'https://www.legifrance.gouv.fr/affichCode.do%3Bjsessionid%3DB81BA950929BDC11249DDF8C185D1DE4.tplgfr42s_2?idSectionTA=LEGISCTA000037703589&cidTexte=LEGITEXT000037701019&dateTexte=20190401',
  art21417To214110: 'https://www.legifrance.gouv.fr/affichCode.do?idSectionTA=LEGISCTA000037703603&cidTexte=LEGITEXT000037701019&dateTexte=20190401',
  art23411To23413: 'https://www.legifrance.gouv.fr/affichCode.do%3Bjsessionid%3DB81BA950929BDC11249DDF8C185D1DE4.tplgfr42s_2?idSectionTA=LEGISCTA000037704215&cidTexte=LEGITEXT000037701019&dateTexte=20190401'
};

function linkToArticles(key) {
  return (
    <a
      href={links[key]}
      target="_blank"
      rel="noopener noreferrer"
    >
      {I18n.t(`publicProcurement.subcontractorSwornStatement.links.${key}`)}
    </a>
  );
}

function SubcontractorSwornStatement({publicProcurementData, onChange}) {
  return (
    <div className="card">
      <div>
        <h2><Translate value="publicProcurement.titles.subcontractorSwornStatement"/></h2>
      </div>
      <div className="pb-3">
        <h3><Translate value="publicProcurement.subcontractorSwornStatement.subtitle"/></h3>
      </div>
      <div className="pb-3">
        <Translate value="publicProcurement.subcontractorSwornStatement.line1.part1"/>
        {linkToArticles('art21411To21415')}
        <Translate value="publicProcurement.subcontractorSwornStatement.line1.part2"/>
        {linkToArticles('art21417To214110')}
        <Translate value="publicProcurement.subcontractorSwornStatement.line1.part3"/>
      </div>
      <div className="pb-3">
        <Translate value="publicProcurement.subcontractorSwornStatement.line2.part1"/>
        {linkToArticles('art23411To23413')}
        <Translate value="publicProcurement.subcontractorSwornStatement.line2.part2"/>
        {linkToArticles('art21417To214110')}
        <Translate value="publicProcurement.subcontractorSwornStatement.line2.part3"/>
      </div>
      <div className="row pb-3">
        <div className="col-12">
          <TextCheckbox
            id="subcontractorNotExcludedSwornStatement"
            name="subcontractorNotExcludedSwornStatement"
            label={getFieldLabel('publicProcurement.subcontractorNotExcludedSwornStatement', true)}
            checked={Boolean(publicProcurementData.subcontractorNotExcludedSwornStatement)}
            onChange={e => onChange({subcontractorNotExcludedSwornStatement: e.target.checked})}
          />
        </div>
      </div>
      <div className="pb-3 font-italic">
        <em>
          <Translate value="publicProcurement.subcontractorSwornStatement.line3.part1"/>
          {linkToArticles('art21411To21415')}
          <Translate value="publicProcurement.subcontractorSwornStatement.line3.part2"/>
          {linkToArticles('art21417To214110')}
          <Translate value="publicProcurement.subcontractorSwornStatement.line3.part3"/>
          {linkToArticles('art23411To23413')}
          <Translate value="publicProcurement.subcontractorSwornStatement.line3.part4"/>
        </em>
      </div>
      <div className="pb-3">
        <em>
          <Translate value="publicProcurement.subcontractorSwornStatement.line4.part1"/>
        </em>
      </div>
    </div>
  );
}

SubcontractorSwornStatement.propTypes = {
  onChange: PropTypes.func.isRequired,
  publicProcurementData: PropTypes.shape({
    subcontractorNotExcludedSwornStatement: PropTypes.bool
  }).isRequired
};

export default SubcontractorSwornStatement;
