import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Link, useParams} from 'react-router-dom';

import {Icon} from 'front-onceforall-core';
import SidePanel from 'front-onceforall-core/dist/views/SidePanel';

import * as snackbarsActions from '../../actions/snackbars';
import ManageUserPermissions from './manageUserPermissions/ManageUserPermissions';
import ProjectOwnerTagEditionForm from './ProjectOwnerTagEditionForm';
import ProjectDetails from './projectDetails';

export const ProjectView = props => {
  const [isManageUserPermissionsPanelOpen, setIsManageUserPermissionsPanelOpen] = React.useState(false);
  const [isProjectOwnerTagEditionFormPanelOpen, setIsProjectOwnerTagEditionFormPanelOpen] = React.useState(false);
  const params = useParams();
  const projectId = Number(params.projectId);

  let triggerClosePanel = () => { /* placeholder */ };

  return (
    <>
      <Link to="/projects">
        <div className="inline-container my-4">
          <Icon icon="arrow-left"/>
          <Translate value="project.page.backToProjects"/>
        </div>
      </Link>

      <div className="col-12">
        <ProjectDetails
          history={props.history}
          actions={props.actions}
          organisation={props.organisation}
          projectId={projectId}
          onManageUserRolesPanelOpen={() => setIsManageUserPermissionsPanelOpen(true)}
          openProjectOwnerTagEditionFormPanel={() => setIsProjectOwnerTagEditionFormPanelOpen(true)}
        />
      </div>

      {isManageUserPermissionsPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsManageUserPermissionsPanelOpen(false)}
        >
          <ManageUserPermissions
            actions={props.actions}
            organisationId={props.organisation.id}
            projectId={projectId}
            onClose={() => triggerClosePanel()}
          />
        </SidePanel>
      }
      {isProjectOwnerTagEditionFormPanelOpen &&
        <SidePanel
          setCloseTrigger={closeFunction => {
            triggerClosePanel = closeFunction;
          }}
          onClose={() => setIsProjectOwnerTagEditionFormPanelOpen(false)}
        >
          <ProjectOwnerTagEditionForm
            actions={props.actions}
            organisationId={props.organisation.id}
            projectId={projectId}
            onClose={() => triggerClosePanel()}
          />
        </SidePanel>
      }

    </>
  );
};

ProjectView.propTypes = {
  history: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectView);
