import React from 'react';
import {projectService} from '../../services/project/ProjectService';
import {organisationService} from '../../services/organisation/OrganisationService';
import {captureError} from '../../utils/log';
import ProjectsView from './ProjectsView';

const pageSize = 10;

export const ProjectsContainer = () => {
  const [page, setPage] = React.useState(0);

  const [projects, setProjects] = React.useState([]);
  const [projectsTotalCount, setProjectsTotalCount] = React.useState(0);
  const [projectsLoading, setProjectsLoading] = React.useState(false);
  const [projectsError, setProjectsError] = React.useState(false);

  const [organisations, setOrganisations] = React.useState([]);

  const handlePageChange = (event, page) => {
    fetchProjects(page);
  };

  const fetchOrganisations = projects => {
    const organisationsList = projects.map(project => project.creatorOrganisationId);
    organisationService.getOrganisationsList(organisationsList)
      .then(response => {
        setOrganisations(response);
        setProjectsLoading(false);
      })
      .catch(error => {
        captureError('fetchOrganisations on project list', error);
        setProjectsError(true);
        setProjectsTotalCount(0);
        setProjects([]);
        setOrganisations([]);
      })
      .finally(() => setProjectsLoading(false));
  };

  const fetchProjects = (page = 0) => {
    setProjectsLoading(true);
    projectService.findAllByCurrentOrganisation(page, pageSize)
      .then(response => {
        setProjects(response.projects);
        setProjectsTotalCount(response.totalCount);
        setProjectsError(false);
        fetchOrganisations(response.projects);
      })
      .catch(error => {
        captureError('fetchOrganisations on project list', error);
        setProjectsError(true);
        setProjectsTotalCount(0);
        setProjects([]);
      })
      .finally(() => setPage(page));
  };

  React.useEffect(fetchProjects, []);

  return (
    <ProjectsView
      organisations={organisations}
      projects={projects}
      projectsTotalCount={projectsTotalCount}
      projectsLoading={projectsLoading}
      projectsError={projectsError}
      onPageChange={handlePageChange}
      pageNumber={page}
      pageSize={pageSize}
    />
  );
};

export default ProjectsContainer;
