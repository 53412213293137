import React from 'react';
import {projectPropTypes} from '../../../utils/propTypes';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import moment from 'moment-timezone';
import {stringUtils} from 'front-onceforall-core';
import CreateRequestForApprovalButton from '../../buttons/createRequestForApprovalButton';

const classes = {
  root: {
    marginBottom: 15,
    border: '1px solid #ccc',
    padding: 16
  },
  secondText: {
    fontSize: 14
  }
};

export const CardView = props => {
  const project = props.project;
  const creatorOrganisationName = props.creatorOrganisationName;
  const address = project.address;
  const openingDateString = moment(project.openingDate).format('DD/MM/YYYY');

  return (
    <div className="clickable box" data-id="project" style={classes.root} onClick={props.onClick}>
      <div className="flex-container space-between">
        <div>
          <h2>
            <span className="primary" style={{fontWeight: 600}} data-id="name">{project.name}</span>
            <span className="separator"/>
            <span className="information" data-id="creatorName">{creatorOrganisationName}</span>
          </h2>
          <p className="information" style={classes.secondText}>
            <span data-id="addressLine">{address.addressLine}</span>{' '}
            <span data-id="postCode">{address.postCode}</span>{' '}
            <span data-id="city">{address.city}</span>{' '}
            <span data-id="country"><Translate value={'country.' + project.country}/></span>
            <span className="separator"/>
            <span data-id="openingDate">
              {stringUtils.getStrongs(I18n.t('project.startFrom', {
                openingDate: openingDateString
              }))}
            </span>
          </p>
        </div>
        <CreateRequestForApprovalButton project={project} color="secondary"/>
      </div>
    </div>
  );
};

CardView.propTypes = {
  creatorOrganisationName: PropTypes.string.isRequired,
  project: projectPropTypes().isRequired,
  onClick: PropTypes.func.isRequired
};

export default CardView;
