import React from 'react';
import PropTypes from 'prop-types';
import {Translate, I18n} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';
import {useSelector} from 'react-redux';

import {TextPhoneNumber} from 'front-onceforall-core';

import {getFieldLabel} from '../../utils';

import {onFieldChange} from './utils';

export const PUBLIC_PROCUREMENT_DELEGATE_MAX_LENGTH = 1024;

function GeneralContractor({onChange, publicProcurementData}) {
  const currentLocale = useSelector(state => state.i18n.locale);
  const {
    email: emailError, phoneNumber: phoneNumberError
  } = publicProcurementData.errors?.generalContractor || {};

  const handleChange = name => event => {
    onChange(onFieldChange(name, event.target.value));
  };

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.generalContractor"/></h2>
      </div>
      <div className="row">
        <div className="col-lg-7 col-md-6 col-sm-7 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.fullName', true)}
            id="generalContractor-fullName"
            name="generalContractor-fullName"
            value={publicProcurementData.generalContractor?.fullName || ''}
            onChange={handleChange('fullName')}
          />
        </div>
        <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.legalForm', true)}
            id="generalContractor-legalForm"
            name="generalContractor-legalForm"
            value={publicProcurementData.generalContractor?.legalForm || ''}
            onChange={handleChange('legalForm')}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.registrationNumber', true)}
            id="generalContractor-registrationNumber"
            name="generalContractor-registrationNumber"
            value={publicProcurementData.generalContractor?.registrationNumber || ''}
            onChange={handleChange('registrationNumber')}
          />
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-12">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.address', true)}
            id="generalContractor-address"
            name="generalContractor-address"
            value={publicProcurementData.generalContractor?.address || ''}
            onChange={handleChange('address')}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.email', true)}
            id="generalContractor-email"
            name="generalContractor-email"
            value={publicProcurementData.generalContractor?.email || ''}
            onChange={handleChange('email')}
            error={Boolean(emailError)}
            helperText={emailError ? I18n.t(emailError) : null}
          />
        </div>
        <div className="col-lg-6 col-md-12 pb-3">
          <TextPhoneNumber
            label={getFieldLabel('publicProcurement.contractor.phoneNumber', false)}
            id="generalContractor-phoneNumber"
            name="generalContractor-phoneNumber"
            value={publicProcurementData.generalContractor?.phoneNumber || ''}
            currentLocale={currentLocale}
            defaultLocal={currentLocale}
            onChange={handleChange('phoneNumber')}
            error={Boolean(phoneNumberError)}
            helperText={phoneNumberError ? I18n.t(phoneNumberError) : null}
          />
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-12">
          <TextField
            label={getFieldLabel('publicProcurement.contractor.delegateData', false)}
            id="generalContractor-delegateData"
            name="generalContractor-delegateData"
            value={publicProcurementData.generalContractor?.delegateData || ''}
            onChange={handleChange('delegateData')}
            multiline
            minRows={3}
            maxRows={10}
            inputProps={{
              maxLength: PUBLIC_PROCUREMENT_DELEGATE_MAX_LENGTH
            }}
          />
        </div>
      </div>
    </div>
  );
}

GeneralContractor.propTypes = {
  onChange: PropTypes.func.isRequired,
  publicProcurementData: PropTypes.shape({
    generalContractor: PropTypes.shape({
      fullName: PropTypes.string,
      registrationNumber: PropTypes.string,
      legalForm: PropTypes.string,
      address: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      delegateData: PropTypes.string
    }),
    errors: PropTypes.shape({
      generalContractor: PropTypes.shape({
        email: PropTypes.string,
        phoneNumber: PropTypes.string
      })
    })
  }).isRequired
};

export default GeneralContractor;
