import React from 'react';
import PropTypes from 'prop-types';

import ParentSubcontractor from './ParentSubcontractor';

import {onFieldChange} from './utils';

function ParentSubcontractorsList({publicProcurementData, onChange}) {
  const handleChange = index => (name, value) => {
    onChange(onFieldChange(index, name, value));
  };

  return (
    <>
      {publicProcurementData.parentSubcontractors?.map((parentSubcontractor, index) => (
        <ParentSubcontractor
          key={index}
          parentSubcontractor={parentSubcontractor}
          onChange={handleChange(index)}
          rang={index + 1}
          errors={publicProcurementData.errors?.parentSubcontractors?.[index]}
        />
      ))}
    </>
  );
}

ParentSubcontractorsList.propTypes = {
  onChange: PropTypes.func.isRequired,
  publicProcurementData: PropTypes.shape({
    parentSubcontractors: PropTypes.arrayOf(PropTypes.shape({
      fullName: PropTypes.string,
      registrationNumber: PropTypes.string,
      legalForm: PropTypes.string,
      address: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string
    })),
    errors: PropTypes.shape({
      parentSubcontractors: PropTypes.objectOf(PropTypes.shape({
        email: PropTypes.string,
        phoneNumber: PropTypes.string
      }))
    })
  }).isRequired
};

export default ParentSubcontractorsList;
