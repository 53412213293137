import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import {connect} from 'react-redux';
import SelectOrganisation from '../../selectOrganisation/SelectOrganisation';
import SelectUser from '../../selectUser/SelectUser';
import {Collapse} from '@material-ui/core';
import {stringUtils} from 'front-onceforall-core';
import {PROJECT_ORGANISATION_ROLE} from '../../../utils/constant';

export const SelectSubcontractorView = props => (
  <div className="card">
    <div className="flex-container space-between">
      <div className="flex-container">
        <h2 className="m-0"><Translate value="request.create.subcontractor.title"/></h2>
        {!props.isOpen && props.subcontractorOrga &&
          <div className="chip-l information-primary ml-4">
            {props.subcontractorOrga.organisationName || props.subcontractorOrga.fullName}
            <span className="separator"/>
            {props.subcontractorUser?.email || props.subcontractorOrga?.email}
          </div>
        }
      </div>
      {!props.isOpen && props.subcontractorOrga &&
        <button
          data-id="change-subcontractor"
          className="inline-container small secondary"
          onClick={() => {
            props.onSelectOrganisation(null);
            props.onSelectUser(null);
          }}
        >
          <Translate value="request.create.subcontractor.change"/>
        </button>
      }
    </div>
    <Collapse in={props.isOpen}>
      <div data-id="notice" className="py-4">
        {props.project && stringUtils.getStrongs(I18n.t('request.create.subcontractor.notice', {projectName: props.project.name}))}
      </div>
      <SelectOrganisation
        organisation={props.organisation}
        selectedOrganisation={props.subcontractorOrga}
        onSelectOrganisation={props.onSelectOrganisation}
        selectedOrganisationRole={PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR}
      />
      {props.subcontractorOrga?.id &&
        <SelectUser
          organisation={props.organisation}
          selectedUser={props.subcontractorUser}
          onSelectUser={props.onSelectUser}
          invitedOrgaName={props.subcontractorOrga.fullName}
        />
      }
    </Collapse>
  </div>
);

SelectSubcontractorView.defaultProps = {
  isOpen: true,
  onSelectOrganisation: () => null,
  onSelectUser: () => null
};

SelectSubcontractorView.propTypes = {
  organisation: PropTypes.object.isRequired,
  project: PropTypes.object,
  subcontractorOrga: PropTypes.object,
  subcontractorUser: PropTypes.object,
  isOpen: PropTypes.bool,
  onSelectOrganisation: PropTypes.func,
  onSelectUser: PropTypes.func
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

export default connect(mapStateToProps)(SelectSubcontractorView);
