import React from 'react';
import PropTypes from 'prop-types';
import {EntityIllustration} from 'front-onceforall-core';

const OrganisationItem = props => {
  const organisation = props.organisation;
  const address = organisation.address;
  const organisationItem = (
    <div className="row">
      <div className="col-12">
        <div
          className={'box my-2 flex-container clickable'}
        >
          <div className="col-sm-2 inline-container column justify-content-center">
            <EntityIllustration
              // imageUrl={getOrganisationLogoUrl(props.organisation.organisationId)}
              country={props.organisation.country}
              name={props.organisation.fullName}
              small
            />
          </div>
          <div className="col-sm-5 inline-container column justify-content-center">
            <strong data-id="fullName">{organisation.fullName}</strong>
            <span data-id="registrationNumber">{organisation.registrationNumber}</span>
          </div>
          <div className="col-sm-5 inline-container column justify-content-center">
            <span data-id="addressLine">{address.addressLine}</span>
            <span data-id="city">{address.city}</span>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {organisationItem}
    </>
  );
};

OrganisationItem.propTypes = {
  organisation: PropTypes.object.isRequired
};

export default OrganisationItem;
