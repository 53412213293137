import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import UserMenuView from './UserMenuView';
import UserSettings from './userSettings';
import {getTermsAndConditionsPath, getPrivacyPolicyPath} from '../../../../utils/path';
import {authenticationService} from '../../../../services/authentication/AuthenticationService';

export const UserMenuContainer = props => {
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState(false);
  const [isUserPanelOpen, setIsUserPanelOpen] = React.useState(false);

  const handleOpenTermsAndConditions = () => {
    window.open(getTermsAndConditionsPath(props.currentLocale));
  };

  const handleOpenPrivacyPolicy = () => {
    window.open(getPrivacyPolicyPath(props.currentLocale));
  };

  const handleLogout = () => {
    authenticationService.logout();
  };

  return (
    <>
      <UserMenuView
        onUserSettingsSelect={() => setIsUserPanelOpen(isUserPanelOpen => !isUserPanelOpen)}
        onOpenTermsAndConditions={handleOpenTermsAndConditions}
        onOpenPrivacyPolicy={handleOpenPrivacyPolicy}
        onLogout={handleLogout}
        onSwitchUserMenu={() => setIsUserMenuOpen(isUserMenuOpen => !isUserMenuOpen)}
        isUserMenuOpen={isUserMenuOpen}
      />
      <UserSettings
        isUserPanelOpen={isUserPanelOpen}
        onClose={() => setIsUserPanelOpen(isUserPanelOpen => !isUserPanelOpen)}
      />
    </>
  );
};

UserMenuContainer.propTypes = {
  currentLocale: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    currentLocale: state.i18n.locale
  };
}

export default connect(mapStateToProps)(UserMenuContainer);
