import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {userService} from '../../services/user/UserService';
import {Icon, validators, AbstractFormComponent} from 'front-onceforall-core';
import TextField from '@material-ui/core/TextField';
import {captureError} from '../../utils/log';

class ChangeEmail extends AbstractFormComponent {
  getFormData() {
    return {
      email: '',
      emailConfirmation: ''
    };
  }

  getFormValidators() {
    return {
      email: [
        validators.requiredValidator,
        validators.emailValidator
      ],
      emailConfirmation: [
        validators.requiredValidator,
        validators.emailValidator,
        value => value !== this.state.formData.email && 'error.emailConfirmation'
      ]
    };
  }

  handleSubmitCallback() {
    userService.requestUpdateEmail(this.props.userId, this.state.formData.email)
      .then(() => {
        this.props.actions.addSuccess(<Translate value="user.emailChanged" newEmail={this.state.formData.email}/>);
        this.props.onClose();
      }).catch(error => {
        captureError('userService.requestUpdateEmail', error);
        this.props.actions.addError(<Translate value="error.generic"/>);
        this.props.onClose();
      });
  }

  render() {
    return (
      <form onSubmit={this.handleSubmit}>
        <h2><Translate value={'user.changeEmailAddress'}/></h2>
        <TextField
          label={this.getFieldLabel('user.email', true)}
          error={this.isOnError('email')}
          helperText={this.helperText('email')}
          id="email"
          name="email"
          value={this.state.formData.email}
          onChange={this.handleChange('email')}
          autoFocus
        />
        <TextField
          label={this.getFieldLabel('user.emailConfirmation', true)}
          error={this.isOnError('emailConfirmation')}
          helperText={this.helperText('emailConfirmation')}
          id="emailConfirmation"
          name="emailConfirmation"
          value={this.state.formData.emailConfirmation}
          onChange={this.handleChange('emailConfirmation')}
        />

        <div className="actions col-12 inline-container space-between">
          <button type="button" className="large secondary inline-container" onClick={this.props.onClose} data-id="cancel">
            <Translate value="action.cancel"/>
            <Icon icon="go-back"/>
          </button>
          <button
            className="large primary inline-container"
            type="submit"
            disabled={this.state.submitting}
          >
            <Translate value="action.confirm"/>
            <Icon icon={this.state.submitting ? 'loader' : 'check'}/>
          </button>
        </div>
      </form>
    );
  }
}

ChangeEmail.propTypes = {
  onClose: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired
};

export default ChangeEmail;
