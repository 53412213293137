import React from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {I18n} from 'react-redux-i18n';

import {useStateHook} from 'front-onceforall-core/dist/utils/customHooks';
import {isEmpty as isEmptyArray} from 'front-onceforall-core/dist/utils/arrays';

import {RFA_STATUS} from '../../utils/constant';
import {requestService} from '../../services/request/RequestService';
import {authenticationService} from '../../services/authentication/AuthenticationService';
import {addSuccess, addError} from '../../actions/snackbars';

import PublicProcurementFormView from './PublicProcurementFormView';

function PublicProcurementFormContainer() {
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);
  const [submitAllowed, setSubmitAllowed] = React.useState(false);
  const [projectPublicProcurementData, setProjectPublicProcurementData] = React.useState(null);
  const [publicProcurementData, setPublicProcurementData] = useStateHook({});
  const [rfa, setRfa] = React.useState(null);
  const requestId = useParams().requestId;
  const history = useHistory();
  const dispatch = useDispatch();

  function handleSubmit() {
    setSubmitting(true);
    let data = {...publicProcurementData};
    delete data.errors;
    requestService.updatePublicProcurementData(requestId, data)
      .then(() => {
        setSubmitting(false);
        dispatch(addSuccess(I18n.t('publicProcurement.update.success')));
        history.push(`/request/${requestId}`);
      })
      .catch(() => {
        setSubmitting(false);
        dispatch(addError(I18n.t('publicProcurement.update.failed')));
      });
  }

  React.useEffect(() => {
    if (requestId) {
      setLoading(true);
      requestService.getRequestById(requestId)
        .then(request => {
          const projectPpData = request?.project?.publicProcurementData;
          const ppData = request?.publicProcurementData;
          setProjectPublicProcurementData(projectPpData);
          setPublicProcurementData(ppData);
          setRfa(request);
        })
        .catch()
        .finally(() => setLoading(false));
    }
  }, [requestId]);

  React.useEffect(() => {
    const hasErrors = Object.keys(publicProcurementData?.errors || {}).length > 0;
    const isEditable = rfa?.status === RFA_STATUS.EDITABLE;
    const roles = authenticationService.roles().getProjectRoles(rfa?.project?.id);
    setSubmitAllowed(isEditable && !hasErrors && !isEmptyArray(roles));
  }, [publicProcurementData?.errors, rfa?.status]);

  if (loading) {
    return <div className="loading-overlay"/>;
  }

  return (
    <PublicProcurementFormView
      loading={submitting}
      onSubmit={handleSubmit}
      projectPublicProcurementData={projectPublicProcurementData}
      publicProcurementData={publicProcurementData}
      updatePublicProcurementData={setPublicProcurementData}
      submitAllowed={submitAllowed}
      rfa={rfa}
    />
  );
}

export default PublicProcurementFormContainer;
