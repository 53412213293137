import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Translate} from 'react-redux-i18n';
import {parse} from 'query-string';
import {bindActionCreators} from 'redux';

import * as snackbarsActions from '../../../actions/snackbars';
import {requestService} from '../../../services/request/RequestService';
import {organisationService} from '../../../services/organisation/OrganisationService';
import {signatureService} from '../../../services/signature/SignatureService';
import {SIGNATURE_TRANSACTION_STATUS} from '../../../utils/constant';
import {captureError} from '../../../utils/log';

import ActiveRequestActionsView from './ActiveRequestActionsView';

export const DEFAULT_PAGE_SIZE = 5;

export const ActiveRequestActionsContainer = props => {
  const [organisations, setOrganisations] = React.useState([]);
  const [requests, setRequests] = React.useState([]);
  const [requestsCount, setRequestsCount] = React.useState(0);
  const [error, setError] = React.useState(false);

  const pageSize = parseInt(parse(props.location.search).pageSize, 10) || DEFAULT_PAGE_SIZE;
  const page = parseInt(parse(props.location.search).page, 10) || 0;
  const taskId = parse(props.location.search).taskId || null;
  const transactionId = parse(props.location.search).transactionId || null;

  const handleSignRequestQueryParams = () => {
    if (!transactionId || !taskId) {
      return Promise.resolve();
    }

    props.onDataLoad();

    return signatureService.callbackSignRequest(taskId, transactionId)
      .then(result => {
        if (result !== null) {
          if (result.signatureStatus === SIGNATURE_TRANSACTION_STATUS.COMPLETED) {
            props.actions.addSuccess(<Translate value="request.sign.successMessage"/>);
          } else if (result.signatureStatus === SIGNATURE_TRANSACTION_STATUS.CANCELED) {
            props.actions.addSuccess(<Translate value="request.sign.cancelledMessage"/>);
          } else if (result.signatureStatus === SIGNATURE_TRANSACTION_STATUS.FAILED) {
            props.actions.addError(<Translate value="error.generic"/>);
          }
        }

        // Remove transaction query parameters to avoid another call to callbackSignRequest when page is reloaded
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete('taskId');
        queryParams.delete('transactionId');
        queryParams.delete('forceOrganisationId');
        props.history.replace({search: queryParams.toString()});
      })
      .catch(exception => {
        captureError('callbackSignedDocument', exception);
        props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  const handlePageChange = (_event, page) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set('page', page);
    props.history.replace({search: queryParams.toString()});
    fetchRequests(page);
  };

  const fetchOrganisations = requests => {
    const organisationsList = [];
    requests.forEach(request => {
      if (!organisationsList.includes(request.creatorOrganisationId)) {
        organisationsList.push(request.creatorOrganisationId);
      }

      if (request.subcontractorId && !organisationsList.includes(request.subcontractorId)) {
        organisationsList.push(request.subcontractorId);
      }
    });
    return organisationService.getOrganisationsList(organisationsList)
      .then(response => {
        setOrganisations(response);
        return requests;
      });
  };

  const fetchRequests = async (page = 0) => {
    props.onDataLoad();
    try {
      const {requests, totalCount} = await requestService.findRequestsWithActiveTasksForOrganisation(props.organisation.id, page, pageSize);

      if (!requests.length && page > 0 && totalCount > 0) {
        handlePageChange(null, 0);
        return;
      }

      setRequests(requests);
      setRequestsCount(totalCount);
      setError(false);

      if (requests.length) {
        await fetchOrganisations(requests);
      }

      props.onDataUpdate(totalCount);
    } catch (error) {
      captureError('loadRequests', error);
      setError(true);
      props.onError();

      if (page > 0) {
        handlePageChange(null, 0);
      }
    }
  };

  React.useEffect(() => {
    if (transactionId && taskId) {
      handleSignRequestQueryParams()
        .then(() => fetchRequests(page));
    } else {
      fetchRequests(page);
    }
  }, []);

  return (
    <ActiveRequestActionsView
      history={props.history}
      organisations={organisations}
      requests={requests}
      requestsCount={requestsCount}
      page={page}
      pageSize={pageSize}
      error={error}
      onPageChange={handlePageChange}
    />
  );
};

ActiveRequestActionsContainer.propTypes = {
  history: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string
  }).isRequired,
  onDataLoad: PropTypes.func,
  onDataUpdate: PropTypes.func,
  onError: PropTypes.func
};

ActiveRequestActionsContainer.defaultProps = {
  onDataLoad: () => {
  },
  onDataUpdate: () => {
  },
  onError: () => {
  }
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ActiveRequestActionsContainer));
