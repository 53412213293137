import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import * as snackbarsActions from '../../actions/snackbars';
import {projectService} from '../../services/project/ProjectService';
import {PROJECT_ORGANISATION_ROLE as orgaRole} from '../../utils/constant';
import {enrollmentService} from '../../services/enrollment/EnrollmentService';
import {captureError} from '../../utils/log';
import {ProjectCreateView} from './ProjectCreateView';
import appConfig from '../../../config';

const availableRoles = [
  orgaRole.PROJECT_CLIENT,
  orgaRole.PROJECT_CLIENT_DELEGATE,
  orgaRole.PROJECT_CLIENT_ASSISTANT,
  orgaRole.PROJECT_MANAGER,
  orgaRole.PROJECT_SAFETY_COORDINATOR
];

export const ProjectCreateContainer = props => {
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [projectDetails, setProjectDetails] = useState(null);
  const [projectDocuments, setProjectDocuments] = useState([]);
  const [projectRole, setProjectRole] = useState(null);
  const [mandatoryInvitee, setMandatoryInvitee] = useState(null);
  const [optionalInvitees, setOptionalInvitees] = useState([]);
  const [remainingRoles, setRemainingRoles] = useState(availableRoles);
  const [procurementContact, setProcurementContact] = useState(null);

  /* PROJECT DETAILS */
  function handleSelectProjectDetails(details) {
    setCurrentStep(2);
    setProjectDetails(details);
  }

  function handleChangeProjectDetails() {
    setCurrentStep(1);
    setProjectRole(null);
    setMandatoryInvitee(null);
    setOptionalInvitees([]);
    setRemainingRoles(availableRoles);
  }

  /* PROJECT DOCUMENTS */
  function handleSelectProjectRequiredDocuments(documents) {
    setCurrentStep(3);
    setProjectDocuments(documents);
  }

  function handleChangeProjectRequiredDocuments() {
    setCurrentStep(2);
    setProjectDocuments([]);
    setProjectRole(null);
    setMandatoryInvitee(null);
    setOptionalInvitees([]);
    setRemainingRoles(availableRoles);
  }

  /* PROJECT ROLES */
  function handleSelectProjectRole(role) {
    setCurrentStep(4);
    setProjectRole(role);
    if (remainingRoles.includes(role)) {
      updateRemainingRolesList([role]);
    }
  }

  function handleChangeProjectRole() {
    setCurrentStep(3);
    setProjectRole(null);
    setMandatoryInvitee(null);
    setOptionalInvitees([]);
    setRemainingRoles(availableRoles);
  }

  /* PROJECT MANDATORY */
  function handleSelectMandatory(invitee) {
    setCurrentStep(5);
    setMandatoryInvitee(invitee);
    if (remainingRoles.includes(invitee?.role)) {
      updateRemainingRolesList([invitee.role]);
    }
  }

  function handleProcurementContact(contact) {
    setProcurementContact(contact);
  }

  function handleChangeMandatory() {
    setCurrentStep(4);
    setOptionalInvitees([]);

    // Reset remaining roles array minus the project role
    let array = [...availableRoles];
    const index = array.indexOf(projectRole);
    if (index !== -1) {
      array.splice(index, 1);
    }

    setRemainingRoles(array);
  }

  /* PROJECT OPTIONALS */
  function handleSelectOptionals(invitees) {
    setCurrentStep(isRoleMandatory() ? 5 : 6);
    setOptionalInvitees(invitees);
    updateRemainingRolesList(invitees.map(invitee => invitee.role));
  }

  function handleChangeOptionals(step) {
    setCurrentStep(step);
  }

  /* UTILS */
  const updateRemainingRolesList = roles => {
    let array = [...remainingRoles];
    roles.forEach(role => {
      const index = array.indexOf(role);
      if (index !== -1) {
        array.splice(index, 1);
      }
    });
    setRemainingRoles(array);
  };

  const isRoleMandatory = () => projectRole === orgaRole.PROJECT_CLIENT || projectRole === orgaRole.PROJECT_CLIENT_DELEGATE;

  const getMandatoryOrga = optionRole => {
    return projectRole === optionRole ? props.organisation : mandatoryInvitee?.role === optionRole ? mandatoryInvitee : null;
  };

  const getMandatoryUser = optionRole => {
    return mandatoryInvitee?.role === optionRole ? mandatoryInvitee?.user : null;
  };

  const getOptionalOrga = optionRole => {
    return projectRole === optionRole ? props.organisation : optionalInvitees.find(invitee => invitee.role === optionRole) || null;
  };

  const getOptionalUser = optionRole => {
    return optionalInvitees.find(invitee => invitee.role === optionRole)?.user || null;
  };

  /* INVITE UNKNOWN ORGANISATIONS */
  const handleInviteUnknownOrganisations = async (projectInfo, projectCreatedId, clientOrga, clientDelegateOrga) => {
    if (!projectInfo.clientId && Boolean(clientOrga)) {
      await enrollmentService.inviteUnknownClient(clientOrga, projectCreatedId, props.organisation.id);
    }

    if (!projectInfo.clientDelegateId && Boolean(clientDelegateOrga)) {
      await enrollmentService.inviteUnknownClientDelegate(clientDelegateOrga, projectCreatedId, props.organisation.id);
    }

    if (!projectInfo.clientAssistantId && Boolean(getOptionalOrga(orgaRole.PROJECT_CLIENT_ASSISTANT))) {
      await enrollmentService.inviteUnknownClientAssistant(getOptionalOrga(orgaRole.PROJECT_CLIENT_ASSISTANT), projectCreatedId, props.organisation.id);
    }

    if (!projectInfo.projectManagerId && Boolean(getOptionalOrga(orgaRole.PROJECT_MANAGER))) {
      await enrollmentService.inviteUnknownProjectManager(getOptionalOrga(orgaRole.PROJECT_MANAGER), projectCreatedId, props.organisation.id);
    }

    if (!projectInfo.safetyCoordinatorId && Boolean(getOptionalOrga(orgaRole.PROJECT_SAFETY_COORDINATOR))) {
      await enrollmentService.inviteUnknownSafetyCoordinator(getOptionalOrga(orgaRole.PROJECT_SAFETY_COORDINATOR), projectCreatedId, props.organisation.id);
    }
  };

  function getPublicProcurementData() {
    const isNotPublicProcurementEnabled = !appConfig.featureFlags.isPublicProcurementEnabled;
    if (isNotPublicProcurementEnabled || !projectDetails.publicProcurement) {
      return {
        publicProcurement: undefined,
        publicProcurementData: undefined
      };
    }

    return {
      publicProcurementData: {
        ...projectDetails.publicProcurementData,
        clientContact: procurementContact
      }
    };
  }

  /* SUBMIT FUNCTION */
  function handleProjectCreation() {
    if (loading) {
      return;
    }

    setLoading(true);

    // A client or delegate must be present in the project
    // When the creator is not one of those two, he has to invite at least one of them as a mandatory invite
    if (!isRoleMandatory() && !mandatoryInvitee) {
      setLoading(false);
      props.actions.addError(<Translate value="project.invite.mandatoryNoticeFieldError"/>);
      return;
    }

    const clientOrga = getMandatoryOrga(orgaRole.PROJECT_CLIENT) || getOptionalOrga(orgaRole.PROJECT_CLIENT);
    const clientDelegateOrga = getMandatoryOrga(orgaRole.PROJECT_CLIENT_DELEGATE) || getOptionalOrga(orgaRole.PROJECT_CLIENT_DELEGATE);
    const projectInfo = {
      ...projectDetails,
      requiredDocumentTypeCodes: Object.assign({}, ...projectDocuments.map(doc => ({[doc.code]: doc.complementary}))),
      creatorOrganisationId: props.organisation.id,
      clientId: clientOrga?.id || null,
      clientInvitedUser: getMandatoryUser(orgaRole.PROJECT_CLIENT) || getOptionalUser(orgaRole.PROJECT_CLIENT),
      clientDelegateId: clientDelegateOrga?.id || null,
      clientDelegateInvitedUser: getMandatoryUser(orgaRole.PROJECT_CLIENT_DELEGATE) || getOptionalUser(orgaRole.PROJECT_CLIENT_DELEGATE),
      clientAssistantId: getOptionalOrga(orgaRole.PROJECT_CLIENT_ASSISTANT)?.id || null,
      clientAssistantInvitedUser: getOptionalUser(orgaRole.PROJECT_CLIENT_ASSISTANT),
      projectManagerId: getOptionalOrga(orgaRole.PROJECT_MANAGER)?.id || null,
      projectManagerInvitedUser: getOptionalUser(orgaRole.PROJECT_MANAGER),
      safetyCoordinatorId: getOptionalOrga(orgaRole.PROJECT_SAFETY_COORDINATOR)?.id || null,
      safetyCoordinatorInvitedUser: getOptionalUser(orgaRole.PROJECT_SAFETY_COORDINATOR),
      generalContractorId: projectRole === orgaRole.PROJECT_GENERAL_CONTRACTOR_OWNER ? props.organisation.id : null,
      ...getPublicProcurementData()
    };
    projectService.create(projectInfo)
      .then(response => {
        handleInviteUnknownOrganisations(projectInfo, response.id, clientOrga, clientDelegateOrga);
        props.actions.addSuccess(<Translate value="project.create.creationSuccess"
          name={projectDetails.name}/>);
        props.history.push('/projects');
      })
      .catch(error => handleSubmitError(error));
  }

  function handleSubmitError(error) {
    error.response.json().then(body => {
      setLoading(false);
      if (error.response.status === 409 && body.errorCode === 'error.insufficientProjectCredits') {
        captureError('Error while creating project with no credits', error);
        props.actions.addError(<Translate value="error.insufficientCredits"/>);
        return;
      }

      captureError('Error while creating new project', error);
      props.actions.addError(<Translate value="error.generic"/>);
    });
  }

  return (
    <ProjectCreateView
      organisation={props.organisation}
      loading={loading}
      currentStep={currentStep}
      projectDetails={projectDetails}
      projectDocuments={projectDocuments}
      projectRole={projectRole}
      mandatoryInvitee={mandatoryInvitee}
      optionalInvitees={optionalInvitees}
      remainingRoles={remainingRoles}
      onSelectProjectDetails={handleSelectProjectDetails}
      onChangeProjectDetails={handleChangeProjectDetails}
      onSelectProjectRequiredDocuments={handleSelectProjectRequiredDocuments}
      onChangeProjectRequiredDocuments={handleChangeProjectRequiredDocuments}
      onSelectProjectRole={handleSelectProjectRole}
      onChangeProjectRole={handleChangeProjectRole}
      onSelectMandatory={handleSelectMandatory}
      onChangeMandatory={handleChangeMandatory}
      onSelectOptionals={handleSelectOptionals}
      onChangeOptionals={handleChangeOptionals}
      onProjectCreation={handleProjectCreation}
      onDefineProcurementContact={handleProcurementContact}
    />
  );
};

ProjectCreateContainer.propTypes = {
  organisation: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectCreateContainer));
