import React from 'react';
import moment from 'moment-timezone';
import {SynchronousPromise} from 'synchronous-promise';
import {COUNTRY} from 'front-onceforall-core/dist/utils/country';
import {
  DEFAULT_ROLE,
  DOCUMENT_STATUS,
  INVITATION_STATUS,
  PAYMASTER,
  PAYMASTER_DOCUMENT_TYPE,
  PROJECT_ASPECT,
  PROJECT_ORGANISATION_ROLE,
  RFA_STATUS,
  SIGNATURE_TRANSACTION_STATUS,
  REQUEST_FOR_APPROVAL_OBJECT,
  TASK_STATUS,
  TASK_TYPE,
  USER_ORGANISATION_ROLE,
  USER_ORGANISATION_STATUS
} from './constant';
import {findDocumentType, getDocumentTypesFiltered} from './database/documentTypes';

// https://github.com/facebook/jest/issues/2157#issuecomment-279171856
export const flushPromises = () => {
  return new Promise(resolve => setImmediate(resolve));
};

export const anEmptyFunction = () => () => {
};

// ---------------------------- ADDRESS ----------------------------
export const anAddress = custom => Object.assign({}, {
  addressLine: '20, Boulevard Eugène Deruelle',
  city: 'Lyon',
  postCode: '69003'
}, custom);

// ---------------------------- USER ----------------------------
/**
 * A user coming from api-user-organisation
 */
export const aUserVM = custom => Object.assign({}, {
  id: '2dff3852-ee1c-40d5-a2d0-b5d7d5619169',
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'firstName.lastName@mail.com',
  fullName: 'FirstName LastName',
  password: null,
  language: 'fr',
  phoneNumber: '+33412345678',
  mobilePhoneNumber: '+33612345678',
  createdDate: '2018-03-28T15:00:24.799Z',
  deactivatedDate: null,
  enabled: true,
  internalUser: false,
  fromOfa: false
}, custom);

export const anInvitedUserVM = custom => Object.assign({}, {
  id: null,
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'firstName.lastName@mail.com',
  phoneNumber: '+33412345678'
}, custom);

/**
 * The current user coming from Keycloak service
 * The data structure is defined in LiveAuthenticationService.extractUser()
 */
export const anAuthenticatedUser = custom => Object.assign({}, {
  id: 'some-uuid',
  email: 'logged.user@onceforall.com',
  emailVerified: true,
  firstName: 'Logged',
  lastName: 'User',
  fullName: 'Logged User',
  language: 'fr',
  phoneNumber: '+33123456789',
  mobilePhoneNumber: '+33123456789',
  privacyPolicyTimestamp: 1577872800,
  termsAndConditionsTimestamp: 1577872800,
  isInternal: false,
  fromOfa: false,
  userOrganisations: []
}, custom);

/**
 * The current user coming from api-user-orga
 */
export const aLoggedUser = custom => Object.assign(aUserVM(), {
  id: anAuthenticatedUser().id,
  firstName: anAuthenticatedUser().firstName,
  lastName: anAuthenticatedUser().lastName,
  fullName: anAuthenticatedUser().fullName,
  email: anAuthenticatedUser().email
}, custom);

export const aUserOrganisationVM = (user, organisationId, custom) => Object.assign({}, {
  organisationId: organisationId,
  linkingDate: user.createdDate,
  status: USER_ORGANISATION_STATUS.LINKED,
  user: {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: user.fullName,
    email: user.email,
    phoneNumber: user.phoneNumber,
    enabled: user.enabled
  }
}, custom);

export const aUserOrganisationProjectVM = (id, userOrganisation, projectId, custom) => Object.assign({}, {
  id,
  userOrganisation,
  projectId,
  roles: [USER_ORGANISATION_ROLE.VERIFICATOR, USER_ORGANISATION_ROLE.SIGNATORY]
}, custom);

/**
 * @return {JwtPermissionToken}
 */
export const aPermissionTokenVM = custom => Object.assign({}, {
  userId: anAuthenticatedUser().id,
  expirationDate: moment().add(30, 'm').toISOString(),
  issuedAtTime: moment().toISOString(),
  role: DEFAULT_ROLE,
  userOrganisationPermission: aUserOrganisationPermissionVM()
}, custom);

/**
 * @return {UserOrganisationPermission}
 */
export const aUserOrganisationPermissionVM = custom => Object.assign({}, {
  userId: anAuthenticatedUser().id,
  organisationId: anOrganisationVM().id,
  globalRoles: [],
  rolesByProjectId: {}
}, custom);

export const anInvitedUserForm = custom => Object.assign({}, {
  email: 'invited@user.com',
  firstName: 'Invited',
  lastName: 'User',
  phoneNumber: '+33123456789'
}, custom);

// ---------------------------- ORGANISATION ----------------------------
export const aSiretTypeVM = custom => Object.assign({}, {
  identificationNumberTypeId: 5,
  code: 'REGISTRATION_NUMBER',
  country: COUNTRY.FRANCE,
  format: '^\\d{9}$',
  required: true
}, custom);

export const aSirenTypeVM = custom => Object.assign({}, {
  identificationNumberTypeId: 4,
  code: 'GROUP_NUMBER',
  country: COUNTRY.FRANCE,
  format: '^\\d{9}$',
  required: true
}, custom);

export const aFrenchVATTypeVM = custom => Object.assign({}, {
  identificationNumberTypeId: 3,
  code: 'VAT_IDENTIFICATION_NUMBER',
  country: COUNTRY.FRANCE,
  format: '^FR\\d{11}$',
  required: true
}, custom);

export const anIdentificationNumbers = custom => Object.assign({}, {
  GROUP_NUMBER: '527737738',
  VAT_IDENTIFICATION_NUMBER: 'FR81527737738'
}, custom);

export const aSirenIdentificationNumber = custom => Object.assign({}, {
  identificationNumberId: 14011,
  value: '527737738'
}, custom);

export const aFrenchVATIdentificationNumber = custom => Object.assign({}, {
  identificationNumberId: 14012,
  value: 'FR81527737738'
}, custom);

/**
 * An organisation coming from api-user-organisation
 */
export const anOrganisationVM = custom => Object.assign({}, {
  id: 1001,
  country: COUNTRY.FRANCE,
  businessLine: 'CONSTRUCTION',
  fullName: 'ALG',
  registrationNumber: '52773773800023',
  groupId: 1001,
  address: anAddress(),
  identificationNumbers: anIdentificationNumbers(),
  enabled: true,
  headquarter: false,
  deactivationReason: null,
  deactivationDate: null,
  fromProvider: false,
  status: true
}, custom);

export const aClient = custom => Object.assign(anOrganisationVM(), {
  id: 1101,
  fullName: 'Client 1',
  registrationNumber: '34071105048089',
  groupId: 1101,
  identificationNumbers: anIdentificationNumbers({GROUP_NUMBER: '340711050', VAT_IDENTIFICATION_NUMBER: 'FR81340711050'})
}, custom);

export const aProjectManager = custom => Object.assign(anOrganisationVM(), {
  id: 1201,
  fullName: 'Project manager 1',
  registrationNumber: '30634400953223',
  groupId: 1201,
  identificationNumbers: anIdentificationNumbers({GROUP_NUMBER: '306344009', VAT_IDENTIFICATION_NUMBER: 'FR81306344009'})
}, custom);

export const aGeneralContractor = custom => Object.assign(anOrganisationVM(), {
  id: 1301,
  fullName: 'General Contractor 1',
  registrationNumber: '89395463039258',
  groupId: 1301,
  identificationNumbers: anIdentificationNumbers({GROUP_NUMBER: '893954630', VAT_IDENTIFICATION_NUMBER: 'FR81293954631'})
}, custom);

export const aSafetyCoordinator = custom => Object.assign(anOrganisationVM(), {
  id: 10001,
  fullName: 'Safety Coordinator 1',
  registrationNumber: '91012931811139',
  groupId: 10001,
  identificationNumbers: anIdentificationNumbers({GROUP_NUMBER: '910129318', VAT_IDENTIFICATION_NUMBER: 'FR91012931831'})
}, custom);

export const aSubcontractor = custom => Object.assign(anOrganisationVM(), {
  id: 1401,
  fullName: 'Subcontractor 1',
  registrationNumber: '29395463039258',
  groupId: 1401,
  identificationNumbers: anIdentificationNumbers()
}, custom);

export const anInvitedOrganisationForm = custom => Object.assign({}, {
  organisationName: 'Invited organisation',
  email: 'firstname.lastname@mail.com',
  firstName: 'firstname',
  lastName: 'lastname',
  registrationNumber: '00000000000000',
  country: COUNTRY.FRANCE,
  phoneNumber: '+33678542376'
}, custom);

export const aClientDelegate = custom => Object.assign(anOrganisationVM(), {
  id: 1501,
  fullName: 'Delegate 1',
  registrationNumber: '34071101111111',
  groupId: 1501,
  identificationNumbers: anIdentificationNumbers({GROUP_NUMBER: '340711011', VAT_IDENTIFICATION_NUMBER: 'FR11111111111'})
}, custom);

export const aClientAssistant = custom => Object.assign(anOrganisationVM(), {
  id: 1601,
  fullName: 'Assistant 1',
  registrationNumber: '34071102222222',
  groupId: 1601,
  identificationNumbers: anIdentificationNumbers({GROUP_NUMBER: '340711022', VAT_IDENTIFICATION_NUMBER: 'FR22222222222'})
}, custom);

// ----------------- LINK PANDA USER ACCOUNT TO OFA ----------------

export const aLinkUserToOfaUrl = custom => Object.assign({}, {
  url: 'https://identity.integration.onceforall.com/auth/realms/DA/broker/keycloak-oidc/link?client_id=front-da&redirect_uri=https://identity.integration.onceforall.com'
}, custom);

// ----------------------------- TOOLS -----------------------------
const createSpy = () => {
  // eslint-disable-next-line no-undef
  if (jasmine) {
    // eslint-disable-next-line no-undef
    return jasmine.createSpy();
  }

  return () => {
  };
};

export const mockedEvent = (value = undefined, custom = {}) => Object.assign({}, {
  preventDefault: createSpy(),
  stopPropagation: createSpy(),
  target: {
    value
  }
}, custom);

export const mockedUseEffect = (times = 1) => {
  const spy = jest.spyOn(React, 'useEffect');
  for (let i = 0; i < times; i++) {
    spy.mockImplementationOnce(f => f());
  }
};

// ---------------------------- RESPONSE ----------------------------
export const aCheckedResponse = (jsonBody = {}) => SynchronousPromise.resolve(jsonBody);

export const anHttpNoContent = () => SynchronousPromise.resolve({
  response: {
    status: 204,
    json: () => SynchronousPromise.resolve({})
  }
});

export const anHttpError = (status = 500, jsonBody = {}) => (SynchronousPromise.reject({
  response: {
    status,
    json: () => SynchronousPromise.resolve(jsonBody)
  }
}));

export const a404HttpError = (status = 404, jsonBody = {}) => (SynchronousPromise.reject({
  response: {
    status,
    json: () => SynchronousPromise.resolve(jsonBody)
  }
}));

export const a400HttpError = (status = 400, jsonBody = {}) => (SynchronousPromise.reject({
  response: {
    status,
    json: () => SynchronousPromise.resolve(jsonBody)
  }
}));

export const aLocationProp = custom => Object.assign({}, {
  pathname: '/',
  search: ''
}, custom);

export const anHistory = () => ({
  push: createSpy(),
  goBack: createSpy(),
  replace: createSpy()
});

export const aMatch = custom => Object.assign({}, {
  path: '/',
  url: '/',
  params: {},
  isExact: true
}, custom);

// ---------------------------- PROJECT ----------------------------
export const aProjectDetails = custom => Object.assign({}, {
  name: 'InCity',
  address: anAddress(),
  country: COUNTRY.FRANCE,
  openingDate: '2020-01-31T23:00:00.000Z',
  closingDate: '2021-11-30T22:59:59.999Z'
}, custom);

export const aProjectCreationForm = custom => Object.assign({}, {
  name: 'InCity',
  address: anAddress(),
  country: COUNTRY.FRANCE,
  openingDate: '2020-01-31T23:00:00.000Z',
  closingDate: '2021-11-30T22:59:59.999Z',
  publicProcurement: false,
  publicProcurementData: {
    publicProcurementObject: null,
    clientContact: null
  },
  creatorOrganisationId: aClient().id,
  requiredDocumentTypeCodes: {
    FR_REG: [],
    FR_COV: [],
    FOREIGN_EMPLOYEES: [],
    INS_RC_PRO: [],
    FR_INS_DEC: [],
    FR_TAX: [],
    FR_PAY: [],
    FR_QUALIF_CERTIF: [],
    EMP_STAFF: [],
    ADD_RIB: [],
    ADD_SWORN_STAT: [],
    ADD_SUBCONTRACTING_K: [],
    ADD_PROJECT_CHARTERS: ['The Women\'s Health Strategy for England']
  },
  clientId: aClient().id,
  clientInvitedUser: anInvitedUserForm(),
  clientDelegateId: aClientDelegate().id,
  clientDelegateInvitedUser: anInvitedUserForm(),
  clientAssistantId: aClientAssistant().id,
  clientAssistantInvitedUser: anInvitedUserForm(),
  projectManagerId: aProjectManager().id,
  projectManagerInvitedUser: anInvitedUserForm(),
  generalContractorId: null,
  safetyCoordinatorId: aSafetyCoordinator().id,
  safetyCoordinatorInvitedUser: anInvitedUserForm()
}, custom);

export const aProcurementContactForm = custom => Object.assign({}, {
  firstName: 'FirstName',
  lastName: 'LastName',
  email: 'firstName.lastName@mail.com',
  phoneNumber: '+33678542376',
  jobTitle: 'a job title'
}, custom);

/**
 * A project coming from api-rfa
 */
export const aProjectVM = custom => Object.assign({}, {
  id: 12,
  // creatorUserId: aUserVM().id,
  name: 'InCity',
  address: anAddress(),
  country: COUNTRY.FRANCE,
  openingDate: '2020-01-31T23:00:00.000Z',
  closingDate: '2021-11-30T22:59:59.999Z',
  creatorOrganisationId: aClient().id,
  clientId: aClient().id,
  clientDelegateId: aClientDelegate().id,
  clientAssistantId: aClientAssistant().id,
  projectManagerId: aProjectManager().id,
  generalContractorIds: [],
  safetyCoordinatorId: aSafetyCoordinator().id,
  requiredDocumentTypeCodes: Object.keys(aProjectCreationForm().requiredDocumentTypeCodes),
  projectOwnerTag: 'Bouygues',
  projectOwnerTagUpdateUser: 'SOUMER',
  projectOwnerTagUpdateDate: '03/07/2023',
  properties: {
    aspects: [
      PROJECT_ASPECT.PROJECT_READY
    ]
  },
  unlimitedRfa: false,
  publicProcurement: false,
  publicProcurementData: null
}, custom);

export const aPublicProjectVM = custom => Object.assign(aProjectVM(), {
  publicProcurement: true,
  publicProcurementData: {
    publicProcurementObject: 'Public market',
    clientContact: aProcurementContactForm()
  }
}, custom);

export const aProjectOwnerTagVM = custom => Object.assign({}, {
  projectOwnerTag: 'The REAL owner',
  projectOwnerTagUpdateUser: 'Bob',
  projectOwnerTagUpdateDate: '2023-07-09T14:32:44.807Z'
}, custom);

export const aProjectParticipantsVM = custom => Object.assign({}, {
  creatorOrganisationId: aClient().id,
  clientId: aClient().id,
  clientDelegateId: aClientDelegate().id,
  clientAssistantId: aClientAssistant().id,
  projectManagerId: aProjectManager().id,
  generalContractorIds: [aGeneralContractor().id],
  safetyCoordinatorId: aSafetyCoordinator().id,
  removableGeneralContractorIds: []
}, custom);

export const aGeneralContractorAdditionForm = custom => Object.assign({}, {
  generalContractorId: aGeneralContractor().id,
  generalContractorInvitedUser: anInvitedUserVM()
}, custom);

export const anInviteeObject = (role = PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT, orga = aClient(), user = anInvitedUserForm()) =>
  user ? {...orga, role, user} : {...orga, role};

// ---------------------------- TASK ----------------------------
export const aTaskVM = custom => Object.assign({}, {
  taskId: '9954f4f2-decf-4b26-9f80-1bd04d43dcb0',
  type: TASK_TYPE.SIGN,
  organisationId: aSubcontractor().id,
  role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR,
  state: TASK_STATUS.ACTIVE
}, custom);

export const anActiveTask = custom => Object.assign(aTaskVM({
  state: TASK_STATUS.ACTIVE
}), {}, custom);

export const aSignTask = custom => Object.assign(anActiveTask({
  type: TASK_TYPE.SIGN
}), {}, custom);

export const aVerifyTask = custom => Object.assign(anActiveTask({
  taskId: 'b64607b7-6e8d-4d54-88c3-064a0ac6b828',
  type: TASK_TYPE.VERIFY
}), custom);

// ---------------------------- REQUEST FOR APPROVAL ----------------------------
export const aRequestDetailsForm = custom => Object.assign({}, {
  details: 'Lorem Ipsum',
  amount: 15000,
  startingDate: '2020-02-18T23:00:00.000Z',
  endingDate: '2021-09-25T21:59:59.999Z',
  paymaster: PAYMASTER.CLIENT
}, custom);

export const aRequestCreationForm = custom => Object.assign(aRequestDetailsForm(), {
  projectId: aProjectVM().id,
  subcontractorId: aSubcontractor().id,
  subcontractorInvitedUser: anInvitedUserForm({id: aUserVM().id, email: 'subcontractor@invited.com'}),
  creatorOrganisationId: aGeneralContractor().id,
  paymaster: PAYMASTER.CLIENT,
  parentId: null
}, custom);

export const aRequestCreated = custom => Object.assign(aRequestCreationForm(), {
  id: 8,
  project: aProjectVM(),
  creatorUserId: aUserVM().id,
  creationDate: '2020-02-18T23:00:00.000Z',
  status: RFA_STATUS.EDITABLE,
  documents: [],
  activeTasks: [aVerifyTask(), aSignTask()],
  validatedDate: null,
  refusalReason: null,
  refusalDate: null,
  refusalOrganisationId: null,
  projectId: undefined,
  parentId: undefined
}, custom);

/**
 * A request for approval coming from api-rfa
 */
export const aRequestVM = custom => Object.assign(aRequestCreated(), {
  status: RFA_STATUS.READY,
  documents: [
    aDocument(),
    aDocument({id: 23, documentTypeCode: 'FR_COV', binaryFileId: 'rfa-8/FR_COV.pdf'})
  ],
  activeTasks: [
    aVerifyTask({
      organisationId: 1301,
      role: PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR
    }),
    aSignTask({
      taskId: 'c543d050-9fba-4bcf-b55c-41df778d5b5b',
      organisationId: 1301,
      role: PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR
    })
  ],
  suspensiveConditions: []
}, custom);

export const aRequestParentDetailsVM = request => {
  request = typeof request === 'undefined' ? aRequestVM() : request;

  return {
    details: request?.details,
    startingDate: request?.startingDate,
    endingDate: request?.endingDate
  };
};

export const aRequestWithUnknownSubcontractor = custom => Object.assign(aRequestVM(), {
  id: 11,
  subcontractorId: null,
  activeTasks: [aVerifyTask({organisationId: null}), aSignTask({organisationId: null})],
  status: RFA_STATUS.EDITABLE
}, custom);

export const aRequestWithSignatureRefusal = custom => Object.assign(aRequestVM(), {
  refusalOrganisationId: anOrganisationVM().id,
  refusalDate: '2020-02-18T23:00:00.000Z',
  refusalReason: 'reason'
}, custom);

export const aRequestWorkflowVM = (request, activeRole = PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR, parentRequests = []) => {
  request = typeof request === 'undefined' ? aRequestVM() : request;

  const participantsTasks = [];

  // Subcontractor step
  participantsTasks.push([aSignTask({organisationId: request.subcontractorId})]);

  // Subcontractor of parent RFAs steps (N-tier RFA)
  if (parentRequests && parentRequests.length > 0) {
    parentRequests.forEach(parentRequest => {
      participantsTasks.push([
        aSignTask({
          taskId: '179de55e-a461-4d27-80a8-1efa7f11d0c0',
          organisationId: parentRequest.subcontractorId,
          role: PROJECT_ORGANISATION_ROLE.RFA_PARENT_SUBCONTRACTOR
        })
      ]);
    });
  }

  // General contractor step
  const generalContractorId = parentRequests && parentRequests.length > 0 ?
    // General contractor of the last parent RFA (N-tier RFA)
    parentRequests[parentRequests.length - 1].creatorOrganisationId :
    // General contractor of the current RFA (classic RFA)
    request.creatorOrganisationId;

  participantsTasks.push([
    aSignTask({
      organisationId: generalContractorId,
      role: PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR,
      taskId: 'c543d050-9fba-4bcf-b55c-41df778d5b5b'
    })
  ]);

  // Project manager step (optional)
  if (request.project?.projectManagerId) {
    participantsTasks.push([
      aSignTask({
        organisationId: request.project?.projectManagerId,
        role: PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER,
        taskId: '6ab602e6-4106-4b5f-81af-b4af956254a5'
      })
    ]);
  }

  // Assistant step (optional)
  if (request.project?.clientDelegateId) {
    participantsTasks.push([
      aSignTask({
        organisationId: request.project?.clientAssistantId,
        role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_ASSISTANT,
        taskId: 'c66569ce-c140-476d-94dc-d13de182db99'
      })
    ]);
  }

  // Delegate step (can be optional)
  if (request.project?.clientAssistantId) {
    participantsTasks.push([
      aSignTask({
        organisationId: request.project?.clientDelegateId,
        role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE,
        taskId: 'f3065b9f7-9ec9-4edd-8cd9-239f18ed2a81'
      })
    ]);
  }

  // Client step (can be optional)
  if (request.project?.clientId) {
    participantsTasks.push([
      aSignTask({
        organisationId: request.project?.clientId,
        role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT,
        taskId: 'f2af0900-42da-41f6-a714-2797253066df'
      })
    ]);
  }

  // Define task state: past (VALIDATED), active (CREATED) OR future
  let activeTaskIndex = -1;
  return participantsTasks.map((participantTasks, index) => {
    participantTasks.forEach(task => {
      // Set this task as current/active task
      if (activeRole === task.role) {
        task.state = TASK_STATUS.ACTIVE;
        activeTaskIndex = index;
        return task;
      }

      // Set this task as a completed previous tasks
      if (activeTaskIndex < 0 || index < activeTaskIndex) {
        task.state = TASK_STATUS.VALIDATED;
        task.userId = anAuthenticatedUser().id;
        task.startDate = '2020-02-18T23:00:00.000Z';
        task.endDate = '2021-09-25T21:59:59.999Z';
        return task;
      }

      // Set this task as a future tasks (those that not yet exist in BPM database)
      task.taskId = null;
      task.state = null;
      task.type = null;
      return task;
    });

    if (participantTasks[0].state === TASK_STATUS.ACTIVE) {
      participantTasks.splice(0, 0, aVerifyTask({
        organisationId: participantTasks[0].organisationId,
        role: participantTasks[0].role,
        state: request.activeTasks.length > 1 ? TASK_STATUS.ACTIVE : TASK_STATUS.VALIDATED,
        userId: request.activeTasks.length > 1 ? null : anAuthenticatedUser().id,
        startDate: '2020-02-18T23:00:00.000Z',
        endDate: request.activeTasks.length > 1 ? null : '2021-09-25T21:59:59.999Z'
      }));
    }

    return participantTasks;
  });
};

/**
 * A request for approval node coming from api-rfa
 * Not present in frontend's features, but like backend, used to build request tree
 */
export const aRequestNodeVM = (id, parent = null, children = []) => ({
  rfaId: id,
  parent: parent,
  children: children
});

export const aRequestTreeVM = (request, children = []) => Object.assign({}, request, {
  children: children
});

// ---------------------------- DOCUMENT ---------------------------
/**
 * used in FakeRequestService to build uploaded documents on a request
 * used to download rfa documents
 * @TODO: remove this when backend gets rid of this property in aRequestVM
 */
export const aDocument = custom => Object.assign({}, {
  id: 22,
  documentTypeCode: 'FR_REG',
  binaryFileId: 'rfa-8/FR_REG.pdf'
}, custom);

/**
 * a document details from api-rfa
 */
export const aDocumentDetailsVM = custom => Object.assign({}, {
  id: 96,
  type: findDocumentType('FR_REG'),
  state: DOCUMENT_STATUS.UPLOADED,
  isUploaded: true,
  isUploadedAfterRfaValidation: false,
  ofaDocumentMetadata: null
}, custom);

export const aSubcontractorDocumentDetails = custom => Object.assign(aDocumentDetailsVM(), custom);

export const aMissingSubcontractorDocumentDetails = custom => Object.assign(aDocumentDetailsVM(), {
  id: null,
  state: DOCUMENT_STATUS.MISSING,
  isUploaded: false
}, custom);

export const aPaymentDocumentDetails = custom => Object.assign(aDocumentDetailsVM(), {
  id: 100,
  type: findDocumentType('ADD_DELEGATION_PAY')
}, custom);

export const aMissingPaymentDocumentDetails = custom => Object.assign(aDocumentDetailsVM(), {
  id: null,
  type: findDocumentType('ADD_CAUTION'),
  state: DOCUMENT_STATUS.MISSING,
  isUploaded: false
}, custom);

export const aSpecificDocumentDetails = custom => Object.assign(aDocumentDetailsVM(), {
  id: 100,
  type: findDocumentType('ADD_SUBCONTRACTING_K')
}, custom);

export const aMissingSpecificDocumentDetails = custom => Object.assign(aDocumentDetailsVM(), {
  id: null,
  type: findDocumentType('ADD_PROJECT_CHARTERS'),
  state: DOCUMENT_STATUS.MISSING,
  isUploaded: false
}, custom);

/**
 * an OFA document metadata from ofa-connect
 */
export const anOFADocumentMetadata = custom => Object.assign({}, {
  ofaConnectId: 11,
  label: 'Justificatif d\'Immatriculation K Bis (Extrait RCS)',
  documentState: DOCUMENT_STATUS.VALID,
  typeCode: 'REG_KBIS',
  validityStartDate: '2020-02-18T23:00:00.000Z',
  validityEndDate: '2020-02-20T23:00:00.000Z'
}, custom);

export const anOFASubcontractorDocumentDetails = custom => Object.assign(aSubcontractorDocumentDetails({
  id: 101,
  state: DOCUMENT_STATUS.VALID,
  isUploaded: true,
  ofaDocumentMetadata: anOFADocumentMetadata()
}), custom);

export const aMissingOFASubcontractorDocumentDetails = custom => Object.assign(anOFASubcontractorDocumentDetails(), {
  id: 101,
  state: DOCUMENT_STATUS.MISSING,
  isUploaded: false,
  ofaDocumentMetadata: anOFADocumentMetadata({
    documentState: DOCUMENT_STATUS.MISSING,
    validityStartDate: null,
    validityEndDate: null
  })
}, custom);

export const aProjectDocumentsList = () => {
  return getDocumentTypesFiltered(documentType =>
    !['RFA', PAYMASTER_DOCUMENT_TYPE.RFA_OWNER, PAYMASTER_DOCUMENT_TYPE.CLIENT].includes(documentType.code));
};

/**
 * a subcontractor document list from api-rfa
 */
export const aSubcontractorDocumentsListVM = custom => Object.assign({}, {
  lastSynchronisationDate: null,
  fromOfa: false,
  documentsDetails: [
    aSubcontractorDocumentDetails(),
    aMissingSubcontractorDocumentDetails({
      type: findDocumentType('FR_COV')
    }),
    aMissingSubcontractorDocumentDetails({
      type: findDocumentType('INS_RC_PRO')
    })
  ]
}, custom);

const now = new Date();
const priorDate = new Date().setDate(now.getDate() - 30);

/**
 * an OFA subcontractor document list from api-rfa
 */
export const anOFASubcontractorDocumentsList = custom => Object.assign(aSubcontractorDocumentsListVM(), {
  lastSynchronisationDate: priorDate,
  fromOfa: true,
  documentsDetails: [
    anOFASubcontractorDocumentDetails(),
    aMissingOFASubcontractorDocumentDetails({
      id: 102,
      type: findDocumentType('FR_COV'),
      state: DOCUMENT_STATUS.REJECTED,
      ofaDocumentMetadata: anOFADocumentMetadata({
        ofaConnectId: 12,
        label: 'Attestation de vigilance',
        documentState: DOCUMENT_STATUS.REJECTED,
        typeCode: 'FR_COV'
      })
    }),
    anOFASubcontractorDocumentDetails({
      id: 103,
      type: findDocumentType('FOREIGN_EMPLOYEES'),
      state: DOCUMENT_STATUS.ALMOST_EXPIRED,
      ofaDocumentMetadata: anOFADocumentMetadata({
        ofaConnectId: 13,
        label: 'Liste des salariés étrangers soumis à autorisation de travail pour entreprise interopérée',
        documentState: DOCUMENT_STATUS.ALMOST_EXPIRED,
        typeCode: 'FOREIGN_EMPLOYEES_INTEROP'
      })
    }),
    anOFASubcontractorDocumentDetails({
      id: 104,
      type: findDocumentType('INS_RC_PRO'),
      state: DOCUMENT_STATUS.NOT_APPLICABLE,
      ofaDocumentMetadata: anOFADocumentMetadata({
        ofaConnectId: 14,
        label: 'Assurance RC Professionnelle',
        documentState: DOCUMENT_STATUS.NOT_APPLICABLE,
        typeCode: 'INS_RC_PRO'
      })
    }),
    anOFASubcontractorDocumentDetails({
      id: 104,
      type: findDocumentType('INS_RC_PRO'),
      state: DOCUMENT_STATUS.NOT_APPLICABLE,
      ofaDocumentMetadata: anOFADocumentMetadata({
        ofaConnectId: 14,
        label: 'Assurance RC Professionnelle',
        documentState: DOCUMENT_STATUS.NOT_APPLICABLE,
        typeCode: 'INS_RC_PRO',
        validityStartDate: '',
        validityEndDate: ''
      })
    }),
    aMissingOFASubcontractorDocumentDetails({
      id: 105,
      type: findDocumentType('FR_INS_DEC'),
      state: DOCUMENT_STATUS.AWAITING_GATHERING,
      ofaDocumentMetadata: anOFADocumentMetadata({
        ofaConnectId: 15,
        label: 'Attestation Décennale Standard En qualité de Locateur et de Sous-Traitants',
        documentState: DOCUMENT_STATUS.AWAITING_GATHERING,
        typeCode: 'INS_DEC_LOC_ST'
      })
    }),
    aMissingOFASubcontractorDocumentDetails({
      id: 106,
      type: findDocumentType('FR_TAX'),
      state: DOCUMENT_STATUS.MISSING,
      ofaDocumentMetadata: anOFADocumentMetadata({
        ofaConnectId: 16,
        label: 'Attestation Fiscale Société fille',
        documentState: DOCUMENT_STATUS.MISSING,
        typeCode: 'TAX_DAUGHTER'
      })
    }),
    anOFASubcontractorDocumentDetails({
      id: 107,
      type: findDocumentType('FR_PAY'),
      state: DOCUMENT_STATUS.AWAITING_VALIDATION,
      ofaDocumentMetadata: anOFADocumentMetadata({
        ofaConnectId: 17,
        label: 'Attestation Congés payés',
        documentState: DOCUMENT_STATUS.AWAITING_VALIDATION,
        typeCode: 'FR_PAY'
      })
    }),
    aMissingOFASubcontractorDocumentDetails({
      id: null,
      type: findDocumentType('ADD_RIB'),
      state: DOCUMENT_STATUS.MISSING,
      ofaDocumentMetadata: {
        ofaConnectId: null,
        label: null,
        documentState: null,
        typeCode: null,
        validityStartDate: null,
        validityEndDate: null
      }
    }),
    aMissingOFASubcontractorDocumentDetails({
      id: null,
      type: findDocumentType('ADD_RIB'),
      state: DOCUMENT_STATUS.MISSING,
      ofaDocumentMetadata: null
    })
  ]
}, custom);

export const aSynchronizedOFASubcontractorDocumentsList = custom => Object.assign(anOFASubcontractorDocumentsList(), {
  lastSynchronisationDate: now
}, custom);

export const aSpecificDocumentsList = () => [
  aPaymentDocumentDetails(),
  aSpecificDocumentDetails(),
  aMissingSpecificDocumentDetails()
];

// ---------------------------- SIGNATURE --------------------------
export const aCallbackSignRequest = custom => Object.assign({}, {
  id: '3d603b5d-db57-4c43-b330-ef0d47b291d9',
  url: 'https://sign.test.universign.eu/sig/#/?id=3d603b5d-db57-4c43-b330-ef0d47b291d9',
  signatureStatus: SIGNATURE_TRANSACTION_STATUS.COMPLETED
}, custom);

// ---------------------------- ENROLLMENT ----------------------------
/**
 * an unknown user + organisation invitation from api-enrollment
 */
export const anInvitationVM = custom => Object.assign({}, {
  id: 1,
  status: INVITATION_STATUS.PENDING,
  creatorOrganisationId: aGeneralContractor().id,
  creatorUserId: aUserVM().id,
  organisationName: 'Invited organisation',
  country: COUNTRY.FRANCE,
  registrationNumber: '10494531987260',
  email: 'test1@email.com',
  firstName: 'firstname1',
  lastName: 'lastname1',
  phoneNumber: '+33412345678',
  customMessage: 'custom message',
  invitationCode: '3ee03f22-1f74-481b-97c9-cf848ce355dd'
}, custom);

export const aSubcontractorInvitation = custom => Object.assign(anInvitationVM(), {
  requestForApprovalId: 11,
  role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR
}, custom);

/**
 * modified subcontractor invitation VM defined after RequestWorkflowContainer.fetchSubcontractorInvitation() promise
 * role enum is modified if tier subcontractor
 * roleLevel is added to define his tier level
 */
export const aTierSubcontractorInvitation = custom => Object.assign(anInvitationVM(), {
  requestForApprovalId: 11,
  role: PROJECT_ORGANISATION_ROLE.RFA_SUBCONTRACTOR_N,
  roleLevel: 2
}, custom);

export const aProjectManagerInvitation = custom => Object.assign(anInvitationVM(), {
  projectId: 12,
  role: PROJECT_ORGANISATION_ROLE.PROJECT_MANAGER
}, custom);

export const aClientInvitation = custom => Object.assign(anInvitationVM(), {
  projectId: 6,
  role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT
}, custom);

export const aProjectGeneralContractorInvitation = custom => Object.assign(anInvitationVM(), {
  projectId: 12,
  role: PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR
}, custom);

export const aSafetyCoordinatorInvitation = custom => Object.assign(anInvitationVM(), {
  projectId: 12,
  role: PROJECT_ORGANISATION_ROLE.PROJECT_SAFETY_COORDINATOR
}, custom);

export const aClientDelegateInvitation = custom => Object.assign(anInvitationVM(), {
  projectId: 12,
  role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_DELEGATE
}, custom);

export const aClientAssistantInvitation = custom => Object.assign(anInvitationVM(), {
  projectId: 12,
  role: PROJECT_ORGANISATION_ROLE.PROJECT_CLIENT_ASSISTANT
}, custom);

// ---------------------------- SUBSCRIPTION -----------------------------
/**
 * an organisation credits from api-subscription
 */
export const anOrganisationCreditsVM = custom => Object.assign({}, {
  organisationId: 1001,
  projectCredits: 1,
  requestForApprovalCredits: 1
}, custom);

export const anEmptyOrganisationCredits = custom => Object.assign(anOrganisationCreditsVM(), {
  projectCredits: 0,
  requestForApprovalCredits: 0
}, custom);

// ---------------------------- LOCAL STORAGE ----------------------------
export const aStore = (organisation = anOrganisationVM(), user = anAuthenticatedUser()) => ({
  getState: () => ({
    user,
    organisation
  }),
  dispatch: jest.fn(),
  replaceReducer: jest.fn()
});

// @see src/app/reducers/subscription.js -> initialState
export const anInitialSubscriptionState = (custom = {}) => ({
  isFetching: false,
  error: null,
  credits: null,
  ...custom
});

export const aSubscriptionState = (credits = anOrganisationCreditsVM(), custom = {}) => anInitialSubscriptionState({
  credits,
  ...custom
});

// ---------------------------- ACTIONS ----------------------------
export const allActions = () => ({
  setLocale: createSpy(),
  setUser: createSpy(),
  addSuccess: createSpy(),
  addError: createSpy(),
  selectOrganisation: createSpy(),
  removeOrganisation: createSpy(),
  fetchSubscription: createSpy().and.returnValue(aCheckedResponse()),
  updateSubscriptionCredits: createSpy().and.returnValue(aCheckedResponse())
});

// ---------------------------- PublicProcurementData ----------------------------
export const aProjectPublicProcurementData = custom => Object.assign({}, {
  clientContact: {
    firstName: 'Bob',
    lastName: 'Bob’s last name',
    jobTitle: 'Bob’s job title',
    email: 'bob@yopmail.com',
    phoneNumber: '+33612345678'
  },
  publicProcurementObject: 'Objet du marché public de Bob'
}, custom);

export const aPublicProcurementData = custom => Object.assign({}, {
  client: {
    fullName: 'OFA',
    registrationNumber: '12345678912345',
    address: '20 bld Deruelle'
  },
  requestForApprovalObject: REQUEST_FOR_APPROVAL_OBJECT.SPECIAL_ACT_MODIFICATION,
  originalRequestForApprovalDate: '2018-03-28T15:00:24.799Z',
  generalContractor: {
    fullName: 'inviting organisation name',
    registrationNumber: '23456789123456',
    legalForm: 'SAS',
    address: 'a random address',
    email: 'invite.orga@yopmail.com',
    phoneNumber: '+33611111111',
    delegateData: 'informations about the delegate'
  },
  parentSubcontractors: [
    {
      fullName: 'First subcontractor name',
      registrationNumber: '11111111111111',
      legalForm: 'SA',
      address: 'a random address',
      email: 'sub.1@yopmail.com',
      phoneNumber: null
    },
    {
      fullName: 'Second subcontractor name',
      registrationNumber: '22222222222222',
      legalForm: 'SA',
      address: 'a random address',
      email: null,
      phoneNumber: '+33622222222'
    }
  ],
  subcontractor: {
    fullName: 'the subcontractor name',
    registrationNumber: '22222222222222',
    legalForm: 'SA',
    address: 'the subcontractor’s address',
    email: 'sub.contractor@yopmail.com',
    phoneNumber: '+33600000000'
  },
  subcontractorContacts: [
    {
      firstName: 'John',
      lastName: 'Smith',
      jobTitle: 'A job title'
    }
  ],
  isSubcontractorASmallOrganisation: false,
  isSubcontractorLinkedToHolder: false,
  subcontractedServicesDetails: 'details of the subcontracted services',
  personalDataProcessing: {
    isSubcontracted: true,
    services: 'the services',
    duration: 'the duration',
    process: 'the process',
    purpose: 'the purpose',
    dataType: 'the data type',
    targets: 'the targets',
    personalDataProtectionAgreement: false,
    personalDataRegulationAgreement: false
  },
  subcontractedServicesLocation: 'the location of the subcontracted services',
  isClientPayingVAT: false,
  amounts: {
    vatRate: '10%',
    amountExcludingVAT: '100',
    amountIncludingVAT: '110'
  },
  priceVariation: 'the price variation',
  directPayment: null,
  iban: 'FR123456789123',
  bankName: 'the name of the bank',
  accountNumber: '456733456',
  advancePayRequired: null,
  subcontractorContractDurationNotApplicable: null,
  subcontractorContractDurationInMonths: null,
  subcontractorNotExcludedSwornStatement: null,
  isModificationAct: null,
  withCertificate: null
}, custom);
