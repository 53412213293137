import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {requestService} from '../../../services/request/RequestService';
import RequestDocumentsListView from './RequestDocumentsListView';

export const RequestDocumentsListContainer = props => {
  const [documents, setDocuments] = React.useState([]);
  const [lastSynchronisationDate, setLastSynchronisationDate] = React.useState(null);
  const [uploadedDocCount, setUploadedDocCount] = React.useState(0);
  const [areDocumentsFromOfa, setAreDocumentsFromOfa] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const getDocuments = requestId => {
    setLoading(true);
    requestService.getSubcontractorDocumentsByRequestId(requestId)
      .then(response => {
        const documents = response.documentsDetails;
        const uploadedDocuments = documents.filter(document => document.isUploaded);

        setDocuments(documents);
        setAreDocumentsFromOfa(response.fromOfa);
        setUploadedDocCount(uploadedDocuments.length);
        if (uploadedDocuments.length > 0) {
          props.onAnyDocumentAvailable(true);
        }

        if (response.lastSynchronisationDate && moment(response.lastSynchronisationDate).isValid()) {
          setLastSynchronisationDate(moment(response.lastSynchronisationDate));
        }
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    const requestId = Number(props.requestId);

    if (requestId) {
      getDocuments(requestId);
    } else {
      setError(true);
    }
  }, []);

  const handleDocumentUploaded = newDocument => {
    const previousDocumentIndex = documents.findIndex(document => document.type.code === newDocument.type.code);
    if (previousDocumentIndex > -1) {
      const previousDocument = documents[previousDocumentIndex];
      documents[previousDocumentIndex] = newDocument;
      setDocuments(documents);

      // Increment uploadedDocCount only when the previous document is not yet uploaded
      if (!previousDocument.isUploaded) {
        setUploadedDocCount(uploadedDocCount + 1);
      }
    }

    props.onAnyDocumentAvailable(true);
  };

  return (
    <RequestDocumentsListView
      requestId={props.requestId}
      areDocumentsFromOfa={areDocumentsFromOfa}
      documents={documents}
      lastSynchronisationDate={lastSynchronisationDate}
      synchronisationEnabled={props.isEditable}
      uploadedDocCount={uploadedDocCount}
      isUploadEnabled={props.isEditable}
      loading={loading}
      error={error}
      onDocumentUpload={handleDocumentUploaded}
    />
  );
};

RequestDocumentsListContainer.defaultProps = {
  isEditable: true
};

RequestDocumentsListContainer.propTypes = {
  requestId: PropTypes.number.isRequired,
  isEditable: PropTypes.bool,
  onAnyDocumentAvailable: PropTypes.func.isRequired
};

export default RequestDocumentsListContainer;
