import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {INVITATION_STATUS, PROJECT_ORGANISATION_ROLE as orgaRole} from '../../../../utils/constant';
import ParticipantView from './participant/ParticipantView';
import ParticipantsAccordion from './participantsAccordion';
import InviteeView from './invitee/InviteeView';
import ProjectEditionButton from '../projectEditionButton/ProjectEditionButton';

export const ProjectParticipantsView = props => {
  const participantsCount = props.participants.length + props.invitations.length;
  const totalGeneralContractors = [...props.participants.filter(orga => orga.role === orgaRole.PROJECT_GENERAL_CONTRACTOR),
    ...props.invitations.filter(invitee => invitee.role === orgaRole.PROJECT_GENERAL_CONTRACTOR)];

  const buildParticipant = role => {
    const participant = props.participants.find(orga => orga.role === role);
    const pendingInvitation = props.invitations.find(invitee => invitee.role === role && invitee.status === INVITATION_STATUS.PENDING);

    if (participant?.id) {
      return <ParticipantView
        role={participant.role}
        organisationName={participant.organisation.fullName}
        registrationNumber={participant.organisation.registrationNumber}
        authorizedActions={{authorizedActions: props.authorizedActions, isRemovable: participant.isRemovable}}
        onRemoveGC={() => props.onRemoveGC(participant.id)}
      />;
    }

    if (pendingInvitation) {
      return <InviteeView
        status={pendingInvitation.status}
        role={pendingInvitation.role}
        organisationName={pendingInvitation.organisationName}
        registrationNumber={pendingInvitation.registrationNumber}
        authorizedActions={props.authorizedActions}
        onRemoveGCInvitee={() => props.onRemoveGCInvitee(pendingInvitation.id)}
      />;
    }

    return null;
  };

  const surroundWithEditButton = participantComponent => {
    return (
      <div className="flex-container space-between">
        {participantComponent}
        {props.isProjectEditionAllowed && <ProjectEditionButton onEditButtonClick={props.onEditButtonClick}/>}
      </div>
    );
  };

  return (
    <>
      <div className="col-4">
        <h2>
          <Translate value="project.page.participants"/>
          <span className="separator information">{participantsCount}</span>
        </h2>
        <div className="card h-100 flex-container column">
          {props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
            props.loading ? <span className="loading-overlay"/> : (
              <>
                {surroundWithEditButton(buildParticipant(orgaRole.PROJECT_CLIENT))}
                {buildParticipant(orgaRole.PROJECT_CLIENT_DELEGATE)}
                {buildParticipant(orgaRole.PROJECT_CLIENT_ASSISTANT)}
                {buildParticipant(orgaRole.PROJECT_MANAGER)}
                {buildParticipant(orgaRole.PROJECT_GENERAL_CONTRACTOR_OWNER)}
                {totalGeneralContractors.length > 1 ?
                  <ParticipantsAccordion
                    participants={props.participants.filter(orga => orga.role === orgaRole.PROJECT_GENERAL_CONTRACTOR)}
                    invitations={props.invitations.filter(
                      invitee => invitee.role === orgaRole.PROJECT_GENERAL_CONTRACTOR && invitee.status === INVITATION_STATUS.PENDING
                    )}
                    authorizedActions={props.authorizedActions}
                    onRemoveGCInvitee={props.onRemoveGCInvitee}
                    onRemoveGC={props.onRemoveGC}
                  /> : buildParticipant(orgaRole.PROJECT_GENERAL_CONTRACTOR)
                }
                {buildParticipant(orgaRole.PROJECT_SAFETY_COORDINATOR)}
              </>
            )}
        </div>
      </div>
    </>
  );
};

ProjectParticipantsView.propTypes = {
  creatorOrganisationId: PropTypes.number,
  participants: PropTypes.array.isRequired,
  invitations: PropTypes.array.isRequired,
  authorizedActions: PropTypes.bool.isRequired,
  isProjectEditionAllowed: PropTypes.bool,
  onEditButtonClick: PropTypes.func,
  onRemoveGCInvitee: PropTypes.func.isRequired,
  onRemoveGC: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired
};

export default ProjectParticipantsView;
