import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import {setUser} from '../../actions/user';
import * as snackbarsActions from '../../actions/snackbars';
import {organisationService} from '../../services/organisation/OrganisationService';
import OrganisationSelector from '../../components/organisationHeaderSelector/OrganisationSelector';
import {withRouter} from 'react-router-dom';
import {parse} from 'query-string';
import {InformationModal, Maintenance} from 'front-onceforall-core';
import {captureError} from '../../utils/log';

export class GlobalDashboardView extends Component {
  constructor(props) {
    super(props);

    const organisationRegistrationSuccessful = Boolean(
      parse(this.props.location.search).organisationRegistrationSuccessful);

    this.state = {
      userOrganisationsLoading: true,
      organisationRegistrationSuccessful
    };

    if (organisationRegistrationSuccessful) {
      // We want to see the modal only one time
      props.history.replace('/global-dashboard');
    }
  }

  componentDidMount() {
    // The 2 following service calls are not grouped together in a Promise.all statement because counting subcontractors
    // may be a long operation, and we don't want to block organisation selection while subcontractors count is still
    // pending.
    organisationService.searchByUser(this.props.user.id, null, true)
      .then(userOrganisations => {
        this.props.actions.setUser(Object.assign({}, this.props.user, {userOrganisations}));
        this.setState({userOrganisationsLoading: false});
      })
      .catch(error => {
        captureError('organisationService.searchByUser', error);
        this.props.actions.addError(<Translate value="error.generic"/>);
        this.setState({userOrganisationsLoading: false});
      });
  }

  render() {
    return (
      <>
        <InformationModal
          isOpen={this.state.organisationRegistrationSuccessful}
          onClose={() => {
            this.setState({organisationRegistrationSuccessful: false});
          }}
        >
          <>
            <div className="inline-container center success my-5">
              <h2 className="m-0" style={{fontWeight: 600, textAlign: 'center'}}>
                <Translate value="globalDashboard.organisationRegistrationSuccessful.line1"/>
              </h2>
            </div>
            <p className="p-0 m-0">
              <Translate value="globalDashboard.organisationRegistrationSuccessful.line2"/>
            </p>
          </>
        </InformationModal>
        <div>
          <Maintenance maintenance={this.props.maintenance} class="mb-5" currentLocale={this.props.currentLocale}/>
          <div className="col-xl">
            <div className="row" style={{height: '100%'}}>
              <div className="col-12 col-xl-6">
                <div className="box shadow mb-3">
                  {this.state.userOrganisationsLoading && <div className="loading-overlay"/>}
                  <div className="box-header">
                    <div className="box-title">
                      <Translate value="organisation.select.myOrganisations"/>
                    </div>
                  </div>
                  <div className="px-5">
                    <OrganisationSelector showAllOrganisationsOnClick/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

GlobalDashboardView.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  maintenance: PropTypes.object,
  currentLocale: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
    maintenance: state.maintenance,
    currentLocale: state.i18n.locale
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, {setUser}, snackbarsActions), dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalDashboardView));
