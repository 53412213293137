import {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import ReactGA from 'react-ga4';
import config from '../../config';

class PageChange extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
      if (config.analytics) {
        ReactGA.send({
          hitType: 'pageview',
          page: window.location.hostname + window.location.pathname
        });
      }
    }
  }

  render() {
    return this.props.children;
  }
}

PageChange.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  location: PropTypes.object.isRequired
};

export default withRouter(PageChange);
