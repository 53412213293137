import React from 'react';
import PropTypes from 'prop-types';
import Card from './card';
import {PROJECT_ORGANISATION_ROLE, RFA_STATUS_TO_WORKFLOW_STATUS, WORKFLOW_STATUS} from '../../utils/constant';

export const RowView = props => {
  const request = props.request;
  const organisations = props.organisations;

  const creatorOrganisation = organisations.find(orga => orga.id === request.creatorOrganisationId);
  const subcontractor = organisations.find(orga => orga.id === request.subcontractorId);
  const invitation = props.invitations.find(invite => request.id === invite.requestForApprovalId);

  const getSubcontractorName = () =>
    subcontractor ? subcontractor.fullName : invitation.organisationName;

  const getWorkflowStatus = () =>
    subcontractor ? RFA_STATUS_TO_WORKFLOW_STATUS[request.status] : WORKFLOW_STATUS.WAITING_SC;

  const awaitedOrganisation = () => {
    if (subcontractor && request.activeTasks.length > 0) {
      const task = request.activeTasks[0];
      const currentAwaitedOrg = organisations.find(orga => orga.id === task.organisationId);
      if (task.role === PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR && task.organisationId === props.request.project.creatorOrganisationId) {
        task.role = PROJECT_ORGANISATION_ROLE.PROJECT_GENERAL_CONTRACTOR_OWNER;
      }

      // currentAwaitedOrg maybe null in case of dashboard when awaited org is neither sc or creator
      // it doesn't matter since in the dashboard only self actions are requested
      return {awaitedOrgRole: task.role, awaitedOrgName: currentAwaitedOrg?.fullName};
    }

    return null;
  };

  const handleLinkRequest = () =>
    event => {
      event.preventDefault();
      event.stopPropagation();
      props.history.push(`/request/${request.id}`);
    };

  if (creatorOrganisation && (subcontractor || invitation)) {
    return <Card
      key={request.id}
      creatorOrganisation={creatorOrganisation.fullName}
      subcontractor={getSubcontractorName()}
      awaitedOrganisation={awaitedOrganisation()}
      request={request}
      workflowStatus={getWorkflowStatus()}
      onRedirectAction={handleLinkRequest()}
    />;
  }

  return null;
};

RowView.propTypes = {
  history: PropTypes.object.isRequired,
  organisations: PropTypes.array.isRequired,
  request: PropTypes.object.isRequired,
  invitations: PropTypes.array
};

RowView.defaultProps = {
  invitations: []
};

export default RowView;
