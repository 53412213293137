import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {PROJECT_ORGANISATION_ROLE as orgaRole} from '../../../utils/constant';
import ProjectSelectRoleCard from '../ProjectSelectRoleCard/ProjectSelectRoleCard';
import ProcurementContact from '../../ProcurementContact/ProcurementContact';

const availableProjectRoles = [
  orgaRole.PROJECT_CLIENT,
  orgaRole.PROJECT_CLIENT_DELEGATE,
  orgaRole.PROJECT_CLIENT_ASSISTANT,
  orgaRole.PROJECT_MANAGER,
  orgaRole.PROJECT_GENERAL_CONTRACTOR_OWNER
];

const ProjectRoleForm = props => {
  const [currRole, setCurrRole] = useState(props.projectRole);

  function shouldDisplayProcurementContact(role) {
    return props.projectDetails?.publicProcurement && [
      orgaRole.PROJECT_CLIENT,
      orgaRole.PROJECT_CLIENT_DELEGATE
    ].includes(role);
  }

  function handleSelectedRole(role) {
    setCurrRole(role);
    if (!shouldDisplayProcurementContact(role)) {
      props.onValidate(role);
    }
  }

  function onDefineProcurementContact(pc) {
    props.onProcurementContact(pc);
    props.onValidate(currRole);
  }

  return (
    <>
      <p>
        <Translate value="project.role.notice"/>
      </p>
      <div className="row">
        {availableProjectRoles.map((role, index) => {
          const isSelected = currRole === role;
          return (
            <ProjectSelectRoleCard
              key={index}
              isSelected={isSelected}
              onRoleSelection={handleSelectedRole}
              role={role}
              data-id={`${role}-selection`}
            />
          );
        })}
      </div>
      { shouldDisplayProcurementContact(currRole) && <ProcurementContact
        onProcurementContact={onDefineProcurementContact}
        organisation={props.organisation}
        data-id="procurement-contact-selection"
      />
      }
    </>
  );
};

ProjectRoleForm.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onProcurementContact: PropTypes.func.isRequired,
  projectDetails: PropTypes.object,
  organisation: PropTypes.object,
  projectRole: PropTypes.oneOf(Object.values(orgaRole))
};

export default ProjectRoleForm;
