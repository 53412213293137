import React from 'react';
import PropTypes from 'prop-types';
import {I18n} from 'react-redux-i18n';
import {connect} from 'react-redux';
import CountryFlag from './CountryFlag';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import {PHONE_INTERNATIONAL_VALUE} from 'front-onceforall-core/dist/components/TextPhoneNumber';
import {COUNTRY, COUNTRY_CODE} from 'front-onceforall-core/dist/utils/country';

export function getPhoneLocaleFromCountry(country) {
  if (COUNTRY_CODE[country]) {
    return COUNTRY_CODE[country];
  }

  return PHONE_INTERNATIONAL_VALUE;
}

export const countrySelectorId = 'countrySelect';

export const CountrySelector = props => {
  const menuItems = props.countriesToShow
    .sort((country, previousCountry) => {
      return I18n.t(`country.${country}`).localeCompare(
        I18n.t(`country.${previousCountry}`), props.currentLocale, {ignorePunctuation: true});
    })
    .map(country => <MenuItem key={country} value={country}><CountryFlag country={country} size={16}/></MenuItem>);
  return (
    <TextField
      name={countrySelectorId}
      id={countrySelectorId}
      select
      disabled={props.disabledCountrySelector}
      value={props.value}
      onChange={props.onChange}
    >
      {menuItems}
    </TextField>
  );
};

CountrySelector.defaultProps = {
  disabledCountrySelector: false,
  countriesToShow: [COUNTRY.FRANCE]
};

CountrySelector.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabledCountrySelector: PropTypes.bool,
  countriesToShow: PropTypes.array,
  currentLocale: PropTypes.string.isRequired //eslint-disable-line
};

function mapStateToProps(state) {
  return {
    currentLocale: state.i18n.locale
  };
}

export default connect(mapStateToProps)(CountrySelector);
