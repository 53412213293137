import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';
import {Icon} from 'front-onceforall-core';

export const RejectRequestView = props => (
  <>
    <div className="flex-container space-between">
      <div className="mb-5">
        <h1 data-id="request-title" className="ml-6 mb-0">
          <Translate value="request.page.rejectRequest"/>
        </h1>
      </div>
    </div>
    <form onSubmit={props.onSubmit}>
      <TextField
        label={<span><Translate value="request.page.rejectRequestReasonLabel"/> *</span>}
        error={props.emptyReasonError}
        helperText={props.emptyReasonError ? <Translate value="error.required"/> : null}
        multiline
        minRows={3}
        maxRows={20}
        inputProps={{maxLength: 1024}}
        value={props.reason}
        onChange={props.onReasonChange}
      />
      <div className="actions col-12 inline-container space-between">
        <button type="button" className="large secondary inline-container" onClick={props.onClose} data-id="cancel">
          <Icon icon="go-back"/>
          <Translate value="action.cancel"/>
        </button>
        <button data-id="submit-button" className="large primary inline-container" type="submit" disabled={props.loading}>
          <Translate value={'action.confirm'}/>
          <Icon icon={props.loading ? 'loader' : 'check'}/>
        </button>
      </div>
    </form>
  </>
);

RejectRequestView.propTypes = {
  reason: PropTypes.string,
  emptyReasonError: PropTypes.bool.isRequired,
  onReasonChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default RejectRequestView;
