import {COUNTRY} from 'front-onceforall-core/dist/utils/country';
import {REGISTRATION_NUMBER_CODE, GROUP_NUMBER_CODE, VAT_IDENTIFICATION_NUMBER_CODE} from 'front-onceforall-core/dist/utils/organisations';

// Orders are defined in back-end, but not exposed in API resources
const identificationNumberTypesFakeData = [
  {
    code: REGISTRATION_NUMBER_CODE,
    country: COUNTRY.FRANCE,
    format: '^\\d{14}$',
    inProgress: '^\\d{0,14}$',
    required: true
  },
  {
    code: GROUP_NUMBER_CODE,
    country: COUNTRY.FRANCE,
    format: '^\\d{9}$',
    inProgress: '^\\d{0,9}$',
    required: true
  },
  {
    code: VAT_IDENTIFICATION_NUMBER_CODE,
    country: COUNTRY.FRANCE,
    format: '^FR\\d{11}$',
    inProgress: '^(F|FR)\\d{0,11}$',
    required: true
  }
];

export const getIdentificationNumberTypesFakeData = () => identificationNumberTypesFakeData;
export const findIdentificationNumberType = id =>
  Object.assign({}, identificationNumberTypesFakeData.find(identificationNumberType => identificationNumberType.identificationNumberTypeId === id));
