import {jss} from 'react-jss';
import 'front-onceforall-ui-kit/main-panda.css';
jss.setup();

const style = {
  '@global': {
    '.modal-container-upload': {
      height: 'calc(100% - 80px)',
      borderRadius: '4px',
      backgroundColor: '#fff',
      boxShadow: '0 16px 32px 0 rgba(0, 0, 0, 0.32)',
      overflowY: 'scroll',
      position: 'fixed',
      top: 'calc(50% + 20px)',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '@media (min-height: 700px)': {
        height: 'auto',
        overflow: 'hidden'
      }
    }
  }
};
jss.setup();
jss.createStyleSheet(style).attach();
