import React from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Snackbars from './layout/Snackbars';
import HeaderView from './components/header';

import OrganisationGate from './gates/OrganisationGate';

import OrganisationRegisterView from './views/organisationRegister/OrganisationRegisterView';
import GlobalDashboardView from './views/globalDashboard/GlobalDashboardView';

import {mountFAQ, unmountFAQ} from './utils/tbFAQ';

export const globalContextPages = ['/global-dashboard', '/register-organisation', '/access-denied'];

function App() {
  const currentLocale = useSelector(state => state.i18n.locale);
  const location = useLocation();

  React.useEffect(() => {
    if (currentLocale === 'fr') {
      mountFAQ();
    }

    return () => {
      unmountFAQ();
    };
  }, [currentLocale]);

  const noLeftHeader = ['/register-organisation'].includes(location.pathname);
  let background = ' background-panda';
  if (['/global-dashboard'].includes(location.pathname)) {
    background = ' background-white';
  } else if (noLeftHeader) {
    background = ' background-white no-padding p-5';
  }

  return (
    <div style={noLeftHeader ? {paddingLeft: 50} : {}}>
      <div className={`main-template${background}`}>
        <Snackbars/>
        <HeaderView/>
        <Switch>
          <Route exact path="/register-organisation" component={OrganisationRegisterView}/>
          <Route exact path="/global-dashboard" component={GlobalDashboardView}/>
          <Route component={OrganisationGate}/>
        </Switch>
      </div>
    </div>
  );
}

export default App;
