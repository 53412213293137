import React from 'react';
import PropTypes from 'prop-types';
import {Translate, I18n} from 'react-redux-i18n';
import {AbstractFormComponent, TextDatePicker, Icon} from 'front-onceforall-core';
import {requiredValidator} from 'front-onceforall-core/dist/utils/validators';
import {Collapse, TextField, MenuItem, InputAdornment} from '@material-ui/core';
import moment from 'moment-timezone';

export class RequestDetailsForm extends AbstractFormComponent {
  constructor(props) {
    super(props);

    if (props.request) {
      this.state.formData = {
        details: props.request.details,
        startingDate: moment(props.request.startingDate),
        endingDate: moment(props.request.endingDate),
        amount: this.convertFloatAmountToString(props.request.amount),
        paymaster: props.request.paymaster
      };
    }
  }

  getFormData() {
    return {
      details: '',
      startingDate: null,
      endingDate: null,
      amount: '',
      paymaster: 'RFA_OWNER'
    };
  }

  getFormValidators() {
    return {
      details: requiredValidator,
      startingDate: requiredValidator,
      endingDate: requiredValidator,
      amount: [requiredValidator, this.amountValidator.bind(this)],
      paymaster: requiredValidator
    };
  }

  amountValidator(value) {
    if (!value.trim().match(/^(\d{1,3} ?)*\d{1,3}(,\d{2})?$/)) {
      return 'error.invalidAmount';
    }
  }

  convertAmountToFloat(value) {
    // Based on FR currency format
    return value.replace(/ +/g, '').replace(',', '.');
  }

  convertFloatAmountToString(num) {
    const dec = num.toString().split('.')[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    return Number(num).toFixed(len).toString().replace('.', ',');
  }

  handleSubmitCallback() {
    const data = {
      ...this.state.formData,
      amount: this.convertAmountToFloat(this.state.formData.amount),
      startingDate: this.state.formData.startingDate.startOf('day').toISOString(),
      endingDate: this.state.formData.endingDate.endOf('day').toISOString()
    };
    this.props.onSubmit(data);
  }

  render() {
    return (
      <div className={this.props.request ? '' : 'card'}>
        <div className={'flex-container space-between' + (this.props.request ? ' mb-5' : '')}>
          <div className="flex-container">
            <h2 className="m-0"><Translate value="request.create.details.title"/></h2>
          </div>
        </div>
        <Collapse in={this.props.isOpen}>
          <form onSubmit={this.handleSubmit}>
            <TextField
              label={this.getFieldLabel('request.create.details.details', true)}
              error={this.isOnError('details')}
              helperText={this.helperText('details')}
              id="details"
              name="details"
              multiline
              minRows={3}
              maxRows={20}
              inputProps={{maxLength: 1024}}
              value={this.state.formData.details}
              onChange={this.handleChange('details')}
            />

            <div className="row">
              <div className="col-12 col-lg-6">
                <TextDatePicker
                  name="startingDate"
                  label={this.getFieldLabel('request.create.details.startingDate', true)}
                  value={this.state.formData.startingDate}
                  onChange={this.handleChange('startingDate')}
                  placeholder={I18n.t('datetime.shortDate')}
                  helperText={this.helperText('startingDate')}
                  error={this.isOnError('startingDate')}
                />
              </div>

              <div className="col-12 col-lg-6">
                <TextDatePicker
                  name="endingDate"
                  label={this.getFieldLabel('request.create.details.endingDate', true)}
                  value={this.state.formData.endingDate}
                  onChange={this.handleChange('endingDate')}
                  placeholder={I18n.t('datetime.shortDate')}
                  helperText={this.helperText('endingDate')}
                  error={this.isOnError('endingDate')}
                />
              </div>
            </div>

            <TextField
              label={this.getFieldLabel('request.create.details.amount', true)}
              error={this.isOnError('amount')}
              helperText={this.helperText('amount')}
              id="amount"
              name="amount"
              value={this.state.formData.amount}
              onChange={this.handleChange('amount')}
              InputProps={{
                startAdornment: <InputAdornment position="start">€</InputAdornment>
              }}
            />

            <TextField
              label={this.getFieldLabel('request.create.details.paymaster.title', true)}
              error={this.isOnError('paymaster')}
              helperText={this.helperText('paymaster')}
              select
              id="paymaster"
              name="paymaster"
              value={this.state.formData.paymaster}
              onChange={this.handleChange('paymaster')}
            >
              <MenuItem value="RFA_OWNER"><Translate value="request.create.details.paymaster.RFA_OWNER"/></MenuItem>
              <MenuItem value="CLIENT"><Translate value="request.create.details.paymaster.CLIENT"/></MenuItem>
            </TextField>

            {this.props.request &&
              <span className="information font-size-s">
                <Translate value="request.create.details.edit.paymentDocumentInformation"/>
              </span>
            }

            <div className="actions col-12 inline-container space-between">
              {this.props.request &&
                <button
                  type="button"
                  className="large secondary inline-container"
                  onClick={this.props.onClose}
                  data-id="cancel"
                >
                  <Translate value="action.back"/>
                  <Icon icon="go-back"/>
                </button>
              }

              <button
                className="large primary inline-container"
                type="submit"
                disabled={this.props.loading}
              >
                <Translate value="action.confirm"/>
                <Icon icon={this.props.loading ? 'loader' : 'check'}/>
              </button>
            </div>
          </form>
        </Collapse>
      </div>
    );
  }
}

RequestDetailsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  request: PropTypes.object,
  onClose: PropTypes.func
};

RequestDetailsForm.defaultProps = {
  onClose: () => {}
};

export default RequestDetailsForm;
