import React from 'react';
import PropTypes from 'prop-types';
import {MenuItem, Menu} from '@material-ui/core';
import {Translate, I18n} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';

export const UserMenuView = props => {
  const userMenuBtnRef = React.useRef(null);

  return (
    <>
      <button
        type="button"
        ref={userMenuBtnRef}
        className="button-square"
        onClick={props.onSwitchUserMenu}
        title={I18n.t('menu.profile')}
      >
        <Icon icon="user"/>
      </button>
      <Menu
        id="user-menu"
        anchorEl={userMenuBtnRef.current}
        open={props.isUserMenuOpen}
        onClose={props.onSwitchUserMenu}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        <div
          data-id="account"
          onClick={props.onUserSettingsSelect}
        >
          <MenuItem
            name="account"
            onClick={props.onSwitchUserMenu}
          >
            <span className="inline-container">
              <Icon icon="cog"/>
              <Translate value="user.account"/>
            </span>
          </MenuItem>
        </div>
        <div
          data-id="terms"
          onClick={props.onOpenTermsAndConditions}
        >
          <MenuItem
            name="terms"
            onClick={props.onSwitchUserMenu}
          >
            <span className="inline-container">
              <Icon icon="info"/>
              <Translate value="textsToValidate.termsAndConditions"/>
            </span>
          </MenuItem>
        </div>
        <div
          data-id="privacyPolicy"
          onClick={props.onOpenPrivacyPolicy}
        >
          <MenuItem
            name="privacyPolicy"
            onClick={props.onSwitchUserMenu}
          >
            <span className="inline-container">
              <Icon icon="info"/>
              <Translate value="textsToValidate.privacyPolicy"/>
            </span>
          </MenuItem>
        </div>
        <div
          data-id="logout"
          onClick={props.onLogout}
        >
          <MenuItem
            name="logout"
            onClick={props.onSwitchUserMenu}
          >
            <span className="inline-container">
              <Icon icon="exit"/>
              <Translate value="action.logout"/>
            </span>
          </MenuItem>
        </div>
      </Menu>
    </>
  );
};

UserMenuView.propTypes = {
  onUserSettingsSelect: PropTypes.func.isRequired,
  onOpenTermsAndConditions: PropTypes.func.isRequired,
  onOpenPrivacyPolicy: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  onSwitchUserMenu: PropTypes.func.isRequired,
  isUserMenuOpen: PropTypes.bool.isRequired
};

export default UserMenuView;
