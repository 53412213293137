import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';

import {Icon} from 'front-onceforall-core';

import {onContactChange, addContact} from './utils';
import Contact from './Contact';

function ContactList(props) {
  const handleContactChange = index => name => event => {
    let value = event.target.value;
    props.onChange(onContactChange(index, name, value));
  };

  const handleAddContact = () => {
    props.onChange(addContact);
  };

  const handleDeleteContact = index => () => {
    props.onChange(current => {
      if (current.subcontractorContacts?.length > 1) {
        const contacts = [...current.subcontractorContacts];
        contacts.splice(index, 1);
        return {subcontractorContacts: contacts};
      }

      return {};
    });
  };

  React.useEffect(() => {
    if (!props.contacts?.length) {
      handleAddContact();
    }
  }, [props.contacts]);

  return (
    <div>
      <div className="flex-container pb-3">
        <Translate value="publicProcurement.titles.subcontractorContacts"/>
        <button
          className="small primary"
          id="add-contact"
          type="button"
          onClick={handleAddContact}
        >
          <Icon icon="plus"/>
        </button>
      </div>
      {props.contacts?.map((contact, index) => (
        <Contact
          key={`contact-${index}`}
          data-id={`contact-${index}`}
          onChange={handleContactChange(index)}
          deleteContact={handleDeleteContact(index)}
          contact={contact}
        />
      )
      )}
    </div>
  );
}

ContactList.propTypes = {
  onChange: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    jobTitle: PropTypes.string
  }))
};

export default ContactList;
