import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getNewsUrlFromLocale} from '../../../utils/path';
import {GlobalHeaderView} from './GlobalHeaderView';
import {authenticationService} from '../../../services/authentication/AuthenticationService';

const canNotGoBackUrls = ['/register-organisation', '/register-user', '/activate-account', '/update-email', '/settings', '/global-subcontractors'];

export const GlobalHeaderContainer = props => {
  const [canGoBack, setCanGoBack] = React.useState(false);

  const newsUrl = getNewsUrlFromLocale(props.currentLocale);
  const isGoBack = props.organisation && props.location.pathname === '/global-dashboard';

  const handleLogoClick = () => {
    if (!props.organisation) {
      return;
    }

    if (props.location.pathname === '/global-dashboard') {
      if (canGoBack) {
        props.history.goBack();
      } else {
        props.history.push('/dashboard');
      }

      setCanGoBack(false);
      return;
    }

    setCanGoBack(!canNotGoBackUrls.includes(props.location.pathname));
    if (authenticationService.roles().isBackOffice() ||
      props.user.userOrganisations.filter(userOrganisation => userOrganisation.enabled).length === 1) {
      props.history.push('/');
    } else {
      props.history.push('/global-dashboard');
    }
  };

  return (
    <GlobalHeaderView
      onLogoClick={handleLogoClick}
      isGoBack={isGoBack}
      newsUrl={newsUrl}
    />
  );
};

GlobalHeaderContainer.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  organisation: PropTypes.object,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

function mapStateToProps(state) {
  return {
    currentLocale: state.i18n.locale,
    organisation: state.organisation,
    user: state.user
  };
}

export default withRouter(connect(mapStateToProps)(GlobalHeaderContainer));
