import ReactGA from 'react-ga4';

import config from '../../config';

export function recordGAEvent(category, action, label, value) {
  if (config.analytics) {
    ReactGA.event({category, action, label, value});
  }
}

export function recordGAReloadEvent(pathname) {
  recordGAEvent('Navigation', 'reload', pathname);
}
