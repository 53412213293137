import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';
import TooltipButton from '../TooltipButton';

const CreateProjectButtonView = ({color, icon, disabled, tooltipMessage, onClick}) => (
  <TooltipButton
    color={color}
    disabled={disabled}
    tooltipMessage={tooltipMessage}
    onClick={onClick}
  >
    <Translate value="project.create.button"/>
    <Icon icon={icon}/>
  </TooltipButton>
);

CreateProjectButtonView.defaultProps = {
  icon: 'arrow-right',
  disabled: false
};

CreateProjectButtonView.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  tooltipMessage: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  onClick: PropTypes.func.isRequired
};

export default CreateProjectButtonView;
