import {phoneNumberValidator, emailValidator} from 'front-onceforall-core/dist/utils/validators';

export const onFieldChange = (name, value) => current => {
  let errors = {
    ...current.errors,
    subcontractor: {
      ...current.errors?.subcontractor
    }
  };

  if (name === 'email') {
    errors.subcontractor.email = emailValidator(value);
  } else if (name === 'phoneNumber') {
    errors.subcontractor.phoneNumber = phoneNumberValidator(value);
  }

  if (!errors.subcontractor.email && !errors.subcontractor.phoneNumber) {
    delete errors.subcontractor;
  }

  return {
    subcontractor: {
      ...current.subcontractor,
      [name]: value
    },
    errors
  };
};
