export const UNLIMITED_VALUE = 2147483647; //  unlimited value is equal to java.lang.Integer.MAX_VALUE = 2^31-1
export const INVITATION_STATUS = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED'
};
export const INVITATION_STATUS_COLOR = {
  [INVITATION_STATUS.PENDING]: 'warning',
  [INVITATION_STATUS.ACCEPTED]: 'success'
};
export const PROJECT_ORGANISATION_ROLE = {
  PROJECT_CLIENT: 'PROJECT_CLIENT',
  PROJECT_MANAGER: 'PROJECT_MANAGER',
  PROJECT_GENERAL_CONTRACTOR: 'PROJECT_GENERAL_CONTRACTOR',
  PROJECT_SAFETY_COORDINATOR: 'PROJECT_SAFETY_COORDINATOR',
  PROJECT_CLIENT_DELEGATE: 'PROJECT_CLIENT_DELEGATE',
  PROJECT_CLIENT_ASSISTANT: 'PROJECT_CLIENT_ASSISTANT',
  RFA_SUBCONTRACTOR: 'RFA_SUBCONTRACTOR',
  RFA_PARENT_SUBCONTRACTOR: 'RFA_PARENT_SUBCONTRACTOR',
  // FRONT SPECIFIC ROLE, DO NOT EXIST IN BACK-END
  RFA_SUBCONTRACTOR_N: 'RFA_SUBCONTRACTOR_N',
  PROJECT_GENERAL_CONTRACTOR_OWNER: 'PROJECT_GENERAL_CONTRACTOR_OWNER'
};
export const PROJECT_ASPECT = {
  PROJECT_READY: 'PROJECT_READY',
  PROJECT_EDITABLE: 'PROJECT_EDITABLE',
  RFA_CREATION_ALLOWED: 'RFA_CREATION_ALLOWED'
};
export const BACKOFFICE_ROLE = 'BACK_OFFICE';
export const DEFAULT_ROLE = 'DEFAULT';
export const USER_ORGANISATION_ROLE = {
  VERIFICATOR: 'VERIFICATOR',
  SIGNATORY: 'SIGNATORY',
  // Back-Office roles only used by front-end
  VERIFICATOR_SIGNATORY: 'VERIFICATOR_SIGNATORY',
  // Back-Office roles only used by front-end
  NONE: 'NONE'
};
export const USER_ORGANISATION_STATUS = {
  PENDING: 'PENDING',
  LINKED: 'LINKED'
};
export const USER_UNLINKABILITY_STATUS = {
  OK: 'OK',
  NOT_LINKED: 'NOT_LINKED',
  LAST_USER: 'LAST_USER',
  LAST_MANDATORY_ROLES: 'LAST_MANDATORY_ROLES'
};
export const SIGNATURE_TRANSACTION_STATUS = {
  INITIALIZED: 'INITIALIZED',
  PROCESSED: 'PROCESSED',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED'
};
export const DOCUMENT_STATUS = {
  // Panda + OFA
  MISSING: 'MISSING',

  // Panda only
  UPLOADED: 'UPLOADED',

  // OFA only
  CREATED: 'CREATED',
  REJECTED: 'REJECTED',
  EXPIRED: 'EXPIRED',
  AWAITING_SIGNATURE: 'AWAITING_SIGNATURE',
  ALMOST_EXPIRED: 'ALMOST_EXPIRED',
  VALID: 'VALID',
  SIGNED: 'SIGNED',
  AWAITING_VALIDATION: 'AWAITING_VALIDATION',
  AWAITING_GATHERING: 'AWAITING_GATHERING',
  NOT_APPLICABLE: 'NOT_APPLICABLE',
  AWAITING_COMPLETION: 'AWAITING_COMPLETION'
};
export const PAYMASTER = {
  CLIENT: 'CLIENT',
  RFA_OWNER: 'RFA_OWNER'
};
export const PAYMASTER_DOCUMENT_TYPE = {
  CLIENT: 'ADD_DELEGATION_PAY',
  RFA_OWNER: 'ADD_CAUTION'
};
export const DOCUMENT_CATEGORY = {
  SIGNATURE: 'SIGNATURE',
  MANDATORY: 'MANDATORY',
  OPTIONAL: 'OPTIONAL',
  SPECIFIC: 'SPECIFIC'
};
export const RFA_STATUS = {
  EDITABLE: 'EDITABLE',
  READY: 'READY',
  VALIDATED: 'VALIDATED'
};
export const WORKFLOW_STATUS = {
  WAITING_SC: 'WAITING_SC',
  IN_PROGRESS: 'IN_PROGRESS',
  APPROVED: 'APPROVED'
};
export const WORKFLOW_STATUS_COLOR = {
  [WORKFLOW_STATUS.WAITING_SC]: 'error',
  [WORKFLOW_STATUS.IN_PROGRESS]: 'warning',
  [WORKFLOW_STATUS.APPROVED]: 'success'
};
export const RFA_STATUS_TO_WORKFLOW_STATUS = {
  [RFA_STATUS.EDITABLE]: WORKFLOW_STATUS.IN_PROGRESS,
  [RFA_STATUS.READY]: WORKFLOW_STATUS.IN_PROGRESS,
  [RFA_STATUS.VALIDATED]: WORKFLOW_STATUS.APPROVED
};
export const TASK_TYPE = {
  SIGN: 'SIGN',
  VERIFY: 'VERIFY'
};
export const TASK_STATUS = {
  ACTIVE: 'ACTIVE',
  VALIDATED: 'VALIDATED',
  REFUSED: 'REFUSED'
};
export const RFA_RESTART_MESSAGE = {
  MESSAGE: 'restarting rfa workflow'
};
export const REQUEST_FOR_APPROVAL_OBJECT = {
  SPECIAL_ACT_AGREEMENT: 'SPECIAL_ACT_AGREEMENT',
  SPECIAL_ACT_MODIFICATION: 'SPECIAL_ACT_MODIFICATION'
};
