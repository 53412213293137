import {DOCUMENT_CATEGORY} from '../constant';

const documentTypesFakeData = [
  {
    code: 'RFA',
    label: 'Request for approval',
    description: '',
    category: DOCUMENT_CATEGORY.SIGNATURE,
    weight: 0,
    complementary: []
  },
  {
    code: 'FR_REG',
    label: 'Justificatif d\'immatriculation',
    description: '(Kbis ou extrait de l’inscription au répertoire des métiers de moins de 3 mois)',
    category: DOCUMENT_CATEGORY.MANDATORY,
    weight: 100,
    complementary: []
  },
  {
    code: 'FR_COV',
    label: 'Attestation de vigilance',
    description: '(URSSAF conforme au décret du 21/11/2011, MSA, RSI de moins de 6 mois)',
    category: DOCUMENT_CATEGORY.MANDATORY,
    weight: 200,
    complementary: []
  },
  {
    code: 'FOREIGN_EMPLOYEES',
    label: 'Liste des salariés étrangers soumis à autorisation de travail',
    description: '(conforme à l’article D 8254-2 du code du travail)',
    category: DOCUMENT_CATEGORY.MANDATORY,
    weight: 300,
    complementary: []
  },
  {
    code: 'INS_RC_PRO',
    label: 'Assurance RC Professionnelle',
    description: 'en cours de validité',
    category: DOCUMENT_CATEGORY.OPTIONAL,
    weight: 400,
    complementary: []
  },
  {
    code: 'FR_INS_DEC',
    label: 'Attestation Décennale Standard',
    description: 'en cours de validité',
    category: DOCUMENT_CATEGORY.OPTIONAL,
    weight: 500,
    complementary: []
  },
  {
    code: 'FR_TAX',
    label: 'Attestation fiscale',
    description: 'en cours de validité',
    category: DOCUMENT_CATEGORY.OPTIONAL,
    weight: 600,
    complementary: []
  },
  {
    code: 'FR_PAY',
    label: 'Attestation Congés payés',
    description: 'en cours de validité',
    category: DOCUMENT_CATEGORY.OPTIONAL,
    weight: 700,
    complementary: []
  },
  {
    code: 'FR_QUALIF_CERTIF',
    label: 'Qualifications ou Capacités de l\'entreprise',
    description: 'en cours de validité',
    category: DOCUMENT_CATEGORY.OPTIONAL,
    weight: 800,
    complementary: []
  },
  {
    code: 'EMP_STAFF',
    label: 'Liste du personnel	',
    description: 'en cours de validité',
    category: DOCUMENT_CATEGORY.OPTIONAL,
    weight: 900,
    complementary: []
  },
  {
    code: 'ADD_RIB',
    label: 'RIB',
    description: '',
    category: DOCUMENT_CATEGORY.OPTIONAL,
    weight: 1000,
    complementary: []
  },
  {
    code: 'ADD_SWORN_STAT',
    label: 'Attestation sur l’honneur	',
    description: 'en cours de validité',
    category: DOCUMENT_CATEGORY.OPTIONAL,
    weight: 1100,
    complementary: []
  },
  {
    code: 'ADD_CAUTION',
    label: 'Caution bancaire',
    description: '',
    category: DOCUMENT_CATEGORY.SPECIFIC,
    weight: 1200,
    complementary: []
  },
  {
    code: 'ADD_DELEGATION_PAY',
    label: 'Délégation de paiement',
    description: '',
    category: DOCUMENT_CATEGORY.SPECIFIC,
    weight: 1300,
    complementary: []
  },
  {
    code: 'ADD_SUBCONTRACTING_K',
    label: 'Contrat de sous-traitance',
    description: '',
    category: DOCUMENT_CATEGORY.SPECIFIC,
    weight: 1400,
    complementary: []
  },
  {
    code: 'ADD_PROJECT_CHARTERS',
    label: 'Autre(s) document(s) demandé(s) sur l\'opération',
    description: '',
    category: DOCUMENT_CATEGORY.SPECIFIC,
    weight: 1500,
    complementary: []
  }
];

export const getDocumentTypes = () => documentTypesFakeData;
export const getDocumentTypesFiltered = predicate => documentTypesFakeData.filter(predicate);

export const findDocumentType = code =>
  Object.assign({}, documentTypesFakeData.find(documentType => documentType.code === code));
