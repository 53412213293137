import organisationHistory from '../utils/organisationHistory';

export function selectOrganisation(organisation) {
  organisationHistory.push(organisation);
  return {
    type: 'selectOrganisation',
    organisation
  };
}

export function removeOrganisation(organisation) {
  organisationHistory.remove(organisation);
  return {
    type: 'removeOrganisation'
  };
}
