import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {Icon} from 'front-onceforall-core';

export const SelectedInviteBlockView = props => (
  <div className="col-11">
    <div className="flex-container box" style={{border: '3px solid #eee'}}>
      <div className="col-md-3 box border right">
        <strong><Translate value={`project.role.${props.invitee.role}.title`} /></strong>
      </div>
      <div className="col-md-3 box border right">
        <div className="inline-container column">
          <strong>{props.invitee.fullName || props.invitee?.organisationName}</strong>
          <span>{props.invitee.registrationNumber}</span>
        </div>
      </div>
      <div className="col-md-3 box border right">
        <div className="inline-container column">
          <span>{props.invitee.address?.addressLine || props.invitee.phoneNumber}</span>
          {props.invitee.address && <span>{props.invitee.address?.postCode} {props.invitee.address?.city}</span>}
        </div>
      </div>
      <div className="col-md-3 box border none">
        <div className="inline-container column">
          <span>{props.invitee.email || props.invitee.user.email}</span>
          <span>
            {props.invitee.firstName || props.invitee.user.firstName} {props.invitee.lastName || props.invitee.user.lastName}
          </span>
        </div>
      </div>
      <button
        type="button"
        data-id="delete-selected-invitee"
        className="large secondary"
        onClick={() => props.onRemoveInviteeClick(props.invitee)}
      >
        <Icon icon="trash"/>
      </button>
    </div>
  </div>
);

SelectedInviteBlockView.propTypes = {
  invitee: PropTypes.object.isRequired,
  onRemoveInviteeClick: PropTypes.func.isRequired
};

export default SelectedInviteBlockView;
