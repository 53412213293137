import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {DOCUMENT_STATUS} from '../../../../../utils/constant';

const symbols = new Map();
symbols.set(DOCUMENT_STATUS.ALMOST_EXPIRED, 'symbol-status-warning');
symbols.set(DOCUMENT_STATUS.AWAITING_COMPLETION, 'symbol-status-cross');
symbols.set(DOCUMENT_STATUS.AWAITING_GATHERING, 'symbol-status-clock');
symbols.set(DOCUMENT_STATUS.AWAITING_SIGNATURE, 'symbol-status-cross');
symbols.set(DOCUMENT_STATUS.AWAITING_VALIDATION, 'symbol-status-clock');
symbols.set(DOCUMENT_STATUS.CREATED, 'symbol-status-clock');
symbols.set(DOCUMENT_STATUS.EXPIRED, 'symbol-status-cross');
symbols.set(DOCUMENT_STATUS.MISSING, 'symbol-status-cross');
symbols.set(DOCUMENT_STATUS.NOT_APPLICABLE, 'symbol-status-not-applicable');
symbols.set(DOCUMENT_STATUS.REJECTED, 'symbol-status-cross');
symbols.set(DOCUMENT_STATUS.SIGNED, 'symbol-status-check');
symbols.set(DOCUMENT_STATUS.VALID, 'symbol-status-check');

export const DocumentState = props => {
  const state = props.document.state ?
    DOCUMENT_STATUS[props.document.state] :
    DOCUMENT_STATUS.MISSING;
  return (
    <div className="flex-container">
      <div style={{width: '20%'}} data-id="symbol" className={'symbol ' + symbols.get(state)}/>
      <div style={{width: '80%'}}>
        <Translate value={'request.documents.ofaStates.' + state}/>
      </div>
    </div>
  );
};

DocumentState.propTypes = {
  document: PropTypes.object.isRequired
};

export default DocumentState;
