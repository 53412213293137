import {aLoggedUser, aUserOrganisationProjectVM, aUserOrganisationVM, aUserVM} from '../fixtures';
import moment from 'moment-timezone';
import {USER_ORGANISATION_STATUS} from '../constant';
import {getProjectsFakeData} from './projects';
import {authorizeRelatedOrganisation} from '../../services/project/FakeProjectService';

const loggedUser = aLoggedUser();

const usersFakeData = [
  aUserVM(),
  loggedUser,
  aUserVM({
    id: '9231a32d-1163-444d-8587-e2680ee4a3ab',
    firstName: 'Bruce',
    lastName: 'Wayne',
    fullName: 'Bruce Wayne',
    email: 'bruce.wayne@waynecorporation.com',
    phoneNumber: '+33123456790'
  }),
  aUserVM({
    id: '9231a32d-1163-444d-8587-e2680ee4a3cd',
    firstName: 'Joker',
    lastName: '',
    fullName: 'Joker',
    email: 'joker@chaos.com',
    language: 'fr',
    phoneNumber: '+33123456791'
  }),
  aUserVM({
    id: '9231a32d-1163-444d-8587-e2680ee4a3ce',
    firstName: 'Red',
    lastName: 'Robin',
    fullName: 'Red Robin',
    email: 'red.robin@waynecorporation.com',
    language: 'fr',
    phoneNumber: '+33123456792'
  }),
  aUserVM({
    id: '9231a32d-1163-444d-8587-e2680ee4a3fd',
    firstName: 'Thomas',
    lastName: 'Wayne',
    fullName: 'Thomas Wayne',
    email: 'thomas.wayne@waynecorporation.com',
    language: 'fr',
    phoneNumber: '+33123456790'
  }),
  aUserVM({
    id: '9231a32d-1163-444d-8587-e2680ee4a3gh',
    firstName: 'Thomas',
    lastName: 'Robin',
    fullName: 'Thomas Robin',
    email: 'thomas.robin@waynecorporation.com',
    language: 'fr',
    phoneNumber: '+33123456791'
  }),
  aUserVM({
    id: '9231a32d-1163-444d-8587-e2680ee4a3ij',
    firstName: 'Poison',
    lastName: 'Ivy',
    fullName: 'Poison Ivy',
    email: 'poison.ivy@waynecorporation.com',
    language: 'fr',
    phoneNumber: '+33123456792'
  }),
  aUserVM({
    id: '9231a32d-1163-444d-8587-e2680ee4a3ff',
    firstName: 'Deactivated',
    lastName: 'User',
    fullName: 'Deactivated User',
    email: 'deactivated.user@deactivated.com',
    language: 'fr',
    phoneNumber: '+33123456791',
    enabled: false,
    deactivatedDate: moment().get('years') + '-03-26T15:00:24.799Z'
  }),
  aUserVM({
    id: '9231a32d-1163-444d-8587-e2680ee4a9ui',
    firstName: 'Unrelated',
    lastName: 'User',
    fullName: 'Unrelated User',
    email: 'unrelated.user@unrelated.com',
    language: 'fr',
    phoneNumber: '+33123456093',
    internalUser: true
  })
];

export const getLoggedUser = () => loggedUser;
export const getUsersFakeData = () => usersFakeData;
export const findUser = id =>
  Object.assign({}, usersFakeData.find(user => user.id === id));

const userOrganisationFakeData = [
  aUserOrganisationVM(findUser('some-uuid'), 1101, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('some-uuid'), 1001, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('some-uuid'), 1002, {status: USER_ORGANISATION_STATUS.PENDING}),
  aUserOrganisationVM(findUser('some-uuid'), 1003, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('some-uuid'), 1004, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('some-uuid'), 1102, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('some-uuid'), 1202, {status: USER_ORGANISATION_STATUS.PENDING}),
  aUserOrganisationVM(findUser('some-uuid'), 1301, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('some-uuid'), 1401, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('some-uuid'), 1403, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('some-uuid'), 1404, {status: USER_ORGANISATION_STATUS.PENDING}),

  aUserOrganisationVM(findUser('2dff3852-ee1c-40d5-a2d0-b5d7d5619169'), 1101, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('2dff3852-ee1c-40d5-a2d0-b5d7d5619169'), 1001, {status: USER_ORGANISATION_STATUS.PENDING}),
  aUserOrganisationVM(findUser('2dff3852-ee1c-40d5-a2d0-b5d7d5619169'), 1301, {status: USER_ORGANISATION_STATUS.PENDING}),

  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3ab'), 1101, {status: USER_ORGANISATION_STATUS.PENDING}),
  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3ab'), 1401, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3ab'), 1301, {status: USER_ORGANISATION_STATUS.LINKED}),

  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3cd'), 1101, {status: USER_ORGANISATION_STATUS.PENDING}),
  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3cd'), 1401, {status: USER_ORGANISATION_STATUS.LINKED}),

  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3ce'), 1101, {status: USER_ORGANISATION_STATUS.LINKED}),
  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3ce'), 1401, {status: USER_ORGANISATION_STATUS.PENDING}),
  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3fd'), 1101, {status: USER_ORGANISATION_STATUS.PENDING}),

  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3gh'), 1101, {status: USER_ORGANISATION_STATUS.PENDING}),

  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3ij'), 1101, {status: USER_ORGANISATION_STATUS.LINKED}),

  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3ff'), 1101, {status: USER_ORGANISATION_STATUS.LINKED}),

  aUserOrganisationVM(findUser('9231a32d-1163-444d-8587-e2680ee4a3ij'), 1401, {status: USER_ORGANISATION_STATUS.PENDING})
];

const userOrganisationProjectFakeData =
  getProjectsFakeData().map(project => {
    const userOrganisations = userOrganisationFakeData.filter(userOrga => authorizeRelatedOrganisation(project, userOrga.organisationId) &&
      userOrga.status === USER_ORGANISATION_STATUS.LINKED);

    return {
      projectId: project.id,
      userOrganisations: userOrganisations.map((userOrganisation, index) => aUserOrganisationProjectVM(index, userOrganisation, project.id))
    };
  });

export const getUserOrganisationsFakeData = () => userOrganisationFakeData;

export const getUserOrganisationsProjectFakeData = () => userOrganisationProjectFakeData;
