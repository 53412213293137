import {dateValidator, requiredValidator} from 'front-onceforall-core/dist/utils/validators';

import {REQUEST_FOR_APPROVAL_OBJECT} from '../../../../utils/constant';

export const onDateChange = value => current => {
  let result = {
    errors: {
      ...current.errors
    }
  };

  if (current.requestForApprovalObject === REQUEST_FOR_APPROVAL_OBJECT.SPECIAL_ACT_MODIFICATION) {
    result.errors.originalRequestForApprovalDate = dateValidator(value) || requiredValidator(value);
    if (!result.errors.originalRequestForApprovalDate) {
      delete result.errors.originalRequestForApprovalDate;
    }
  } else {
    delete result.errors.originalRequestForApprovalDate;
  }

  if (value) {
    result.originalRequestForApprovalDate = value.startOf('day').toISOString();
  }

  return result;
};

export const onObjectChange = value => current => {
  let result = {
    requestForApprovalObject: value,
    errors: {
      ...current.errors
    }
  };

  if (value === REQUEST_FOR_APPROVAL_OBJECT.SPECIAL_ACT_AGREEMENT) {
    result.originalRequestForApprovalDate = null;
    delete result.errors.originalRequestForApprovalDate;
  } else {
    const currentDate = current?.originalRequestForApprovalDate;
    result.errors.originalRequestForApprovalDate = dateValidator(currentDate) || requiredValidator(currentDate);
    if (!result.errors.originalRequestForApprovalDate) {
      delete result.errors.originalRequestForApprovalDate;
    }
  }

  return result;
};
