import {createBrowserHistory} from 'history';
import configureStore from '../../store/configureStore';
import {findProject, getProjectsFakeData, getFakeOwnerTag, updateFakeOwnerTag, editFakeProjectUnlimitedRfa} from '../../utils/database/projects';
import {a404HttpError, anHttpNoContent, aProjectVM, aCheckedResponse} from '../../utils/fixtures';
import {findDocumentType} from '../../utils/database/documentTypes';
import {authorizeRelatedProject, hasValidatedSubcontractor} from '../request/FakeRequestService';
import {getRequestsFakeData} from '../../utils/database/requests';
import {PROJECT_ASPECT} from '../../utils/constant';

const history = createBrowserHistory();
configureStore.init(history);

export default class FakeProjectService {
  get(id) {
    const store = configureStore.getStore().getState();
    const currentOrganisationId = store.organisation ? store.organisation.id : null;
    const project = findProject(id);

    if (project.generalContractorIds.includes(currentOrganisationId) || authorizeValidatedSubcontractorInAnyRfa(project, currentOrganisationId)) {
      project.properties.aspects.push(PROJECT_ASPECT.RFA_CREATION_ALLOWED);
    }

    return Promise.resolve(findProject(id));
  }

  findAllByCurrentOrganisation(page, size, direction = 'DESC', property = 'openingDate') {
    const filteredProjects = this.filterAndSortProjectsFakeData(direction, property);
    return Promise.resolve({
      projects: filteredProjects.slice(page * size, (page + 1) * size),
      totalCount: filteredProjects.length
    });
  }

  findProjectsReadyForRequestCreationByCurrentOrganisation() {
    let filteredProjects = this.filterAndSortProjectsFakeData('DESC', 'openingDate');

    filteredProjects = filteredProjects.filter(project =>
      project.properties.aspects.includes(PROJECT_ASPECT.PROJECT_READY) && project.properties.aspects.includes(PROJECT_ASPECT.RFA_CREATION_ALLOWED));

    return Promise.resolve(filteredProjects);
  }

  create(project) {
    const isOwnerGC = project.generalContractorId;
    const response = Object.assign(
      // needed to get the general contractor when going to create project page
      isOwnerGC ? aProjectVM({generalContractorIds: [project.generalContractorId]}) : aProjectVM(),
      {id: getProjectsFakeData().length + 100},
      project);
    getProjectsFakeData().push(response);
    return Promise.resolve(response);
  }

  editDetails(projectId, projectInformation) {
    let project = getProjectsFakeData().find(project => project.id === projectId);
    const newProject = Object.assign({}, project, {
      name: projectInformation.name,
      openingDate: projectInformation.openingDate,
      closingDate: projectInformation.closingDate,
      address: projectInformation.address
    });

    return Promise.resolve(newProject);
  }

  editDocumentTypes(projectId, documentTypes) {
    let project = getProjectsFakeData().find(project => project.id === projectId);
    const newProject = Object.assign({}, project, {requiredDocumentTypeCodes: documentTypes.map(doc => doc.code)});

    return Promise.resolve(newProject);
  }

  editParticipants(projectId, participants) {
    const project = getProjectsFakeData().find(project => project.id === projectId);
    project.clientId = participants.clientId;
    project.clientDelegateId = participants.clientDelegateId;
    project.clientAssistantId = participants.clientAssistantId;
    project.projectManagerId = participants.projectManagerId;
    project.safetyCoordinatorId = participants.safetyCoordinatorId;
    return Promise.resolve(project);
  }

  delete(projectId) {
    const index = getProjectsFakeData().findIndex(project => project.id === projectId);
    if (index < 0) {
      return new Promise((resolve, reject) => {
        setTimeout(() => reject(a404HttpError()), 100);
      });
    }

    getProjectsFakeData().splice(index, 1);
    return new Promise(resolve => {
      setTimeout(() => resolve(anHttpNoContent()), 250);
    });
  }

  addGeneralContractor(projectId, generalContractorAddition) {
    const index = getProjectsFakeData().findIndex(project => project.id === projectId);
    getProjectsFakeData()[index].generalContractorIds.push(generalContractorAddition.generalContractorId);
    const projectUpdated = getProjectsFakeData().find(project => project.id === projectId);
    return Promise.resolve(projectUpdated);
  }

  removeGeneralContractor(projectId, generalContractorId) {
    const projectIndex = getProjectsFakeData().findIndex(project => project.id === projectId);
    const generalContractorIndex = getProjectsFakeData()[projectIndex].generalContractorIds.findIndex(id => id === generalContractorId);
    getProjectsFakeData()[projectIndex].generalContractorIds.splice(generalContractorIndex, 1);
    const projectUpdated = getProjectsFakeData().find(project => project.id === projectId);
    return Promise.resolve(projectUpdated);
  }

  getParticipantsByProjectId(projectId) {
    const project = findProject(projectId);
    const rem = JSON.parse(JSON.stringify(project.generalContractorIds));
    const response = {
      creatorOrganisationId: project.creatorOrganisationId,
      clientId: project.clientId,
      clientDelegateId: project.clientDelegateId,
      clientAssistantId: project.clientAssistantId,
      projectManagerId: project.projectManagerId,
      safetyCoordinatorId: project.safetyCoordinatorId,
      generalContractorIds: project.generalContractorIds,
      removableGeneralContractorIds: rem.splice(0, 1)
    };

    return Promise.resolve(response);
  }

  getRequiredDocumentTypesByProjectId(projectId) {
    const project = findProject(projectId);
    const response = project.requiredDocumentTypeCodes.map(code => code === 'ADD_PROJECT_CHARTERS' ?
      Object.assign(findDocumentType(code), {complementary: ['The Women\'s Health Strategy for England']}) :
      findDocumentType(code));

    return Promise.resolve(response);
  }

  filterAndSortProjectsFakeData(direction, property) {
    let projects = getProjectsFakeData().sort((a, b) => (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0);
    if (direction === 'DESC') {
      projects = projects.reverse();
    }

    const store = configureStore.getStore().getState();
    const currentOrganisationId = store.organisation ? store.organisation.id : null;
    let filteredProjects = projects.filter(project =>
      authorizeRelatedOrganisation(project, currentOrganisationId));

    filteredProjects = filteredProjects.map(project => {
      if (project.generalContractorIds.includes(currentOrganisationId) || authorizeValidatedSubcontractorInAnyRfa(project, currentOrganisationId)) {
        let updatedProject = project;
        updatedProject.properties.aspects.push(PROJECT_ASPECT.RFA_CREATION_ALLOWED);
        return updatedProject;
      }

      return project;
    });

    return filteredProjects;
  }

  getOwnerTag(id) {
    return aCheckedResponse(getFakeOwnerTag(id));
  }

  updateOwnerTag(id, projectOwnerTag) {
    updateFakeOwnerTag(id, projectOwnerTag);

    return Promise.resolve();
  }

  editProjectUnlimitedRfa(id, unlimitedRfa) {
    return Promise.resolve(editFakeProjectUnlimitedRfa(id, unlimitedRfa));
  }
}

export const authorizeRelatedOrganisation = (project, organisationId) => {
  return (organisationId && [
    project.creatorOrganisationId,
    project.clientId,
    project.clientDelegateId,
    project.clientAssistantId,
    project.projectManagerId,
    project.safetyCoordinatorId,
    ...project.generalContractorIds
  ].includes(organisationId)) || authorizeValidatedSubcontractorInAnyRfa(project, organisationId);
};

export const authorizeValidatedSubcontractorInAnyRfa = (project, organisationId) => {
  const filteredRequests = getRequestsFakeData().filter(request => hasValidatedSubcontractor(request, organisationId))
    .filter(request => authorizeRelatedProject(request, project.id));
  return organisationId && filteredRequests.map(request => request.subcontractorId).includes(organisationId);
};
