/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {INVITATION_STATUS, INVITATION_STATUS_COLOR} from '../../../../utils/constant';
import {Icon} from 'front-onceforall-core';
import {Tooltip} from '@material-ui/core';

export const InvitationView = props => {
  return (
    <>
      {props.invitation.status === INVITATION_STATUS.PENDING &&
        <div className="box border bottom m-0">
          <div className="card-header">
            <div className="font-size-xl">
              <div className="inline-container">{props.position}</div>
              &nbsp;{'/'}&nbsp;
              <Translate
                color={INVITATION_STATUS_COLOR.PENDING}
                value={`project.invite.${props.invitation.status}.${props.invitation.role}`}
                level={props.invitation.roleLevel}
                dangerousHTML
              />
              <Tooltip
                data-id="invitation-pending-tooltip"
                title={<Translate value="project.invite.PENDING.tooltip" firstName={props.invitation.firstName}
                  lastName={props.invitation.lastName} email={props.invitation.email}/>}
              >
                <span className="info" style={{marginLeft: '5px'}}>
                  <Icon icon="info" />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="flex-column" style={{paddingLeft: 'calc(100% / 25)'}}>
            <b className="font-size-xl">{props.invitation.organisationName}</b>
            <div className="information">{props.invitation.registrationNumber}</div>
          </div>
        </div>
      }
    </>
  );
};

InvitationView.propTypes = {
  invitation: PropTypes.shape({
    status: PropTypes.string.isRequired,
    organisationName: PropTypes.string,
    role: PropTypes.string,
    roleLevel: PropTypes.number,
    registrationNumber: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  position: PropTypes.number.isRequired
};

export default InvitationView;
