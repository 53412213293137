import React from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {I18n, Translate} from 'react-redux-i18n';
import {withRouter} from 'react-router-dom';
import {AbstractFormComponent, Icon, InfoAdornment, InformationModal, validators} from 'front-onceforall-core';
import * as snackbarsActions from '../../actions/snackbars';
import {organisationService} from '../../services/organisation/OrganisationService';
import {identificationNumberService} from '../../services/identificationNumber/IdentificationNumberService';
import {userService} from '../../services/user/UserService';
import {selectOrganisation} from '../../actions/organisation';
// import {setOrganisationLogo} from '../../actions/organisationLogo';
import {setUser} from '../../actions/user';
import CountrySelector from '../../components/country/CountrySelector';
import TextField from '@material-ui/core/TextField';
import {COUNTRY, getCountryByLanguage} from 'front-onceforall-core/dist/utils/country';
import {captureError, consoleError} from '../../utils/log';
import {isEmpty} from 'front-onceforall-core/dist/utils/strings';
import {REGISTRATION_NUMBER_CODE, GROUP_NUMBER_CODE} from 'front-onceforall-core/dist/utils/organisations';
import {getRegistrationNumberValidatorFunction} from '../../utils/validators';
import {USER_ORGANISATION_STATUS} from '../../utils/constant';

// When internationalization will be fully supported, all countries shall be displayed.
const countriesToShow = [COUNTRY.FRANCE];
const ADDRESS_MAX_LENGTH = 255;

export class OrganisationRegisterView extends AbstractFormComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      loading: false,
      organisation: null,
      error: null,
      types: [],
      isUserLinkRequestPending: false
    };
  }

  componentDidMount() {
    this.fetchIdentificationNumberTypes(this.state.formData.country);
  }

  getFormData(registrationNumber = '') {
    const country = this.state ? this.state.formData.country : getCountryByLanguage(this.props.currentLocale);

    return {
      registrationNumber,
      country,
      businessLine: 'CONSTRUCTION',
      fullName: '',
      addressLine: '',
      city: '',
      postCode: '',
      accessCode: ''
    };
  }

  getFormValidators() {
    return {
      registrationNumber: [
        validators.requiredValidator,
        getRegistrationNumberValidatorFunction
      ],
      country: validators.requiredValidator,
      businessLine: validators.requiredValidator,
      fullName: validators.requiredValidator,
      addressLine: validators.requiredValidator,
      city: validators.requiredValidator,
      postCode: validators.requiredValidator,
      accessCode: null
    };
  }

  fetchIdentificationNumberTypes(country) {
    identificationNumberService.types(country)
      .then(types => {
        const formData = {...this.state.formData};
        const formValidators = {...this.formValidators};
        const identificationNumberTypes = types.filter(identificationNumberType => identificationNumberType.code !== REGISTRATION_NUMBER_CODE);
        identificationNumberTypes.forEach(type => {
          formData[type.code] = '';
          formValidators[type.code] = this.identificationNumberValidator(type);
        });
        this.setState({
          formData,
          types: identificationNumberTypes
        });
        this.formValidators = formValidators;
      })
      .catch(() => this.props.actions.addError(<Translate value="error.generic"/>));
  }

  identificationNumberValidator(type) {
    const typeValidators = [value => isEmpty(value) || validators.isIdentificationNumberValid(type, value) ? null : 'error.invalidIdentificationNumber'];
    if (type.required) {
      typeValidators.push(validators.requiredValidator);
    }

    return typeValidators;
  }

  handleChangeCallback(name, value) {
    if (name === 'country') {
      this.setState({
        formData: this.getFormData(),
        submitted: false
      }, () => {
        this.formValidators = this.getFormValidators();
        this.fetchIdentificationNumberTypes(value);
      });
    }

    if (name === 'registrationNumber') {
      const country = this.state.formData.country;
      if (!getRegistrationNumberValidatorFunction(value)) {
        this.searchOrganisation(value, country);
      }
    }

    // Force uppercase in VAT number field
    if (name === 'VAT_IDENTIFICATION_NUMBER') {
      const formData = {...this.state.formData};
      formData[name] = value ? value.toUpperCase() : '';
      this.setState({formData});
    }
  }

  handleRegistrationNumberChange = event => {
    const value = event.target.value;
    if (getRegistrationNumberValidatorFunction(value, true)) {
      return;
    }

    this.handleChange('registrationNumber')(event);
  };

  searchOrganisation(registrationNumber, country) {
    this.setState({loading: true, submitted: false});

    organisationService.getByRegistrationNumberAndCountry(registrationNumber, country)
      .then(organisation => {
        let state = {...this.state};
        state.organisation = organisation;
        state.loading = false;
        if (organisation) {
          const {addressLine, city, postCode} = organisation.address;
          state.formData = {
            ...state.formData,
            // businessLine from ILG is not yet (maybe never ?) implemented
            businessLine: organisation.businessLine ? organisation.businessLine : state.formData.businessLine,
            fullName: organisation.fullName,
            addressLine,
            city,
            postCode
          };

          Object.keys(organisation.identificationNumbers).forEach(code => {
            state.formData[code] = organisation.identificationNumbers[code];
          });
        }

        this.setState(state);
        return organisation;
      })
      .catch(e => {
        this.handleSubmitError(e);
      })
      .then(organisation => {
        if (!organisation) {
          let state = {...this.state};

          state.types.forEach(type => {
            const isSiren = type.code === GROUP_NUMBER_CODE && type.country === COUNTRY.FRANCE;
            state.formData[type.code] = isSiren ? state.formData.registrationNumber.substring(0, 9) : '';
          });
          state.formData = {
            ...state.formData,
            ...this.getFormData(registrationNumber)
          };

          this.setState(state);
        }
      });
  }

  async handleSubmitError(error) {
    if (error.response && (error.response.status === 500 || error.response.status === 400 || error.response.status === 409)) {
      if (error.response.status !== 409) {
        this.props.actions.addError(<Translate value="error.generic"/>);
      }

      await error.response.json().then(data => {
        consoleError('OrganisationRegisterView service error : ', data.errorCode);
        this.setState({error: data.errorCode});
      });
    } else {
      captureError('OrganisationRegisterView', error);
    }

    this.setState({loading: false, submitting: false});
  }

  shouldShowForm() {
    const formData = this.state.formData;
    return (!this.state.loading || this.state.submitting) &&
      !getRegistrationNumberValidatorFunction(formData.registrationNumber);
  }

  isOrganisationNew() {
    return !this.state.organisation || this.state.organisation?.fromProvider;
  }

  isTypeSameThanRegistrationNumber(type) {
    return type.code === REGISTRATION_NUMBER_CODE;
  }

  isTypeSiren(type) {
    return type.country === COUNTRY.FRANCE && type.code === GROUP_NUMBER_CODE;
  }

  async handleSubmitCallback() {
    try {
      const organisation = this.isOrganisationNew() ? await this.createOrganisation(this.state.formData) : this.state.organisation;

      // If the user is not from BO, request a link between him and the organisation
      if (!this.props.user.isInternal) {
        const userOrganisationRequest = await this.linkOrganisationAndUser(organisation.id);

        // If userOrganisation request is in PENDING status, display a modal information
        if (userOrganisationRequest.status === USER_ORGANISATION_STATUS.PENDING) {
          this.setState({isUserLinkRequestPending: true});
          return;
        }
      }

      if (this.isOrganisationNew()) {
        this.props.actions.addSuccess(<Translate value="organisation.created"/>);
      } else if (!this.props.user.isInternal) {
        this.props.actions.addSuccess(<Translate value="organisation.isUserLinkRequestSuccessful"/>);
      }

      this.props.actions.selectOrganisation(organisation);
      this.props.history.push('/');
    } catch (error) {
      this.handleSubmitError(error);
    }
  }

  linkOrganisationAndUser(organisationId) {
    return new Promise((resolve, reject) => {
      userService.linkOrganisationOnRegister(organisationId)
        .then(userOrganisation => resolve(userOrganisation))
        .catch(error => reject(error));
    });
  }

  createOrganisation = data => {
    return new Promise((resolve, reject) => {
      const organisation = {
        registrationNumber: data.registrationNumber,
        country: data.country,
        businessLine: data.businessLine,
        address: {
          addressLine: data.addressLine,
          city: data.city,
          postCode: data.postCode
        },
        accessCode: data.accessCode,
        fullName: data.fullName,
        identificationNumbers: {}
      };

      this.state.types.filter(type => !isEmpty(data[type.code]))
        .forEach(type => {
          organisation.identificationNumbers[type.code] = data[type.code];
        });

      organisationService.createOrganisation(organisation)
        .then(organisation => resolve(organisation))
        .catch(error => reject(error));
    });
  };

  getIdentificationNumbersFields = () => this.state.types.map((type, index) => (this.isTypeSameThanRegistrationNumber(type) ? null : (
    <div
      key={`id-number-${index}`}
      className="col-sm-6"
    >
      <TextField
        label={this.getFieldLabel(`identificationNumber.${type.code}.name`, type.required)}
        error={this.isOnError(type.code)}
        helperText={this.helperText(type.code)}
        id={type.code}
        name={type.code}
        value={this.state.formData[type.code]}
        onChange={this.handleChange(type.code)}
        disabled={!this.isOrganisationNew() || this.isTypeSiren(type)}
        InputProps={{
          endAdornment: (
            <InfoAdornment
              error={this.isOnError(type.code)}
              text={I18n.t(`identificationNumber.${type.code}.tooltip`)}
            />
          )
        }}
      />
    </div>
  )))

  getMessage = () => {
    const organisation = this.state.organisation;
    const formData = this.state.formData;

    if (this.state.loading || getRegistrationNumberValidatorFunction(formData.registrationNumber)) {
      return {message: null};
    }

    if (this.state.error) {
      return {
        message: I18n.t(`${this.state.error}`),
        isErrorMessage: true
      };
    }

    if (organisation) {
      if (!organisation.enabled) {
        return {
          message: I18n.t('organisation.createOrganisationDeactivated', {organisationName: organisation.fullName}),
          isErrorMessage: true
        };
      }

      if (!organisation.status) {
        return {message: I18n.t('organisation.inactive'), isErrorMessage: true};
      }

      if (organisation.fromProvider) {
        return {message: I18n.t('organisation.createOrganisationFoundInProvider')};
      }

      return {message: I18n.t('organisation.createOrganisationFound')};
    }

    if (formData.country !== COUNTRY.FRANCE) {
      return {message: null};
    }

    return {message: I18n.t('organisation.createOrganisationNotFound'), isErrorMessage: false};
  };

  render() {
    const blockDisabledOrganisation = this.state.organisation && !this.state.organisation.enabled;
    const {message, isErrorMessage = false} = this.getMessage();

    return (
      <>
        <InformationModal
          isOpen={this.state.isUserLinkRequestPending}
          onClose={() => this.props.history.replace('/global-dashboard')}
        >
          <>
            <div className="inline-container center success my-5">
              <h2 className="m-0" style={{fontWeight: 600, textAlign: 'center'}}>
                <Translate value="organisation.isUserLinkRequestPending.line1"/>
              </h2>
            </div>
            <p className="p-0 m-0">
              <Translate value="organisation.isUserLinkRequestPending.line2"/>
            </p>
          </>
        </InformationModal>

        <div className="row justify-content-center row-eq-height">
          <div className="col-lg-8">
            <div className="box shadow" style={{height: '100%'}}>
              <div className="row">
                <div className="col-12">
                  <form onSubmit={this.handleSubmit}>
                    <h1 className="mt-0"><Translate value="organisation.create.title"/></h1>

                    <div className="mb-5">
                      <Translate value="organisation.create.requiredNotice"/>
                    </div>

                    {message &&
                      <div className={`box flex-container mb-5 ${isErrorMessage && 'rejected error'}`}>
                        <span className="flex-shrink-0">
                          <Icon icon={isErrorMessage ? 'cross' : 'info'} size={20}/>
                        </span>
                        <span data-id="form-message">{message}</span>
                      </div>
                    }

                    <div className="row">
                      <div className="col-sm-4">
                        <CountrySelector
                          onChange={this.handleChange('country')}
                          value={this.state.formData.country}
                          countriesToShow={countriesToShow}
                          disabledCountrySelector
                        />
                      </div>

                      <div className="col-sm-8">
                        <TextField
                          autoFocus
                          error={this.isOnError('registrationNumber')}
                          helperText={this.helperText('registrationNumber')}
                          label={this.getFieldLabel(`registrationNumber.${this.state.formData.country}.name`, true)}
                          id="registrationNumber"
                          name="registrationNumber"
                          onChange={this.handleRegistrationNumberChange}
                          value={this.state.formData.registrationNumber}
                          InputProps={{
                            readOnly: this.state.loading,
                            endAdornment: (
                              <InfoAdornment
                                text={I18n.t('registrationNumber.' + this.state.formData.country + '.tooltip')}
                              />
                            )
                          }}
                        />
                      </div>
                    </div>
                    {this.shouldShowForm() &&
                      <>
                        <TextField
                          label={this.getFieldLabel('organisation.fullName', true)}
                          error={this.isOnError('fullName')}
                          helperText={this.helperText('fullName')}
                          id="fullName"
                          name="fullName"
                          value={this.state.formData.fullName}
                          onChange={this.handleChange('fullName')}
                          disabled={!this.isOrganisationNew()}
                        />
                        <TextField
                          label={this.getFieldLabel('address.addressLine', true)}
                          error={this.isOnError('addressLine')}
                          helperText={this.helperText('addressLine')}
                          id="addressLine"
                          name="addressLine"
                          multiline
                          minRows={3}
                          maxRows={20}
                          value={this.state.formData.addressLine}
                          onChange={this.handleChange('addressLine')}
                          disabled={!this.isOrganisationNew()}
                          inputProps={{
                            maxLength: ADDRESS_MAX_LENGTH
                          }}
                        />

                        <div className="row">
                          <div className="col-sm-4">
                            <TextField
                              label={this.getFieldLabel('address.postCode', true)}
                              error={this.isOnError('postCode')}
                              helperText={this.helperText('postCode')}
                              id="postCode"
                              name="postCode"
                              value={this.state.formData.postCode}
                              onChange={this.handleChange('postCode')}
                              disabled={!this.isOrganisationNew()}
                              inputProps={{
                                maxLength: ADDRESS_MAX_LENGTH
                              }}
                            />
                          </div>
                          <div className="col-sm-8">
                            <TextField
                              label={this.getFieldLabel('address.city', true)}
                              error={this.isOnError('city')}
                              helperText={this.helperText('city')}
                              id="city"
                              name="city"
                              value={this.state.formData.city}
                              onChange={this.handleChange('city')}
                              disabled={!this.isOrganisationNew()}
                              inputProps={{
                                maxLength: ADDRESS_MAX_LENGTH
                              }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          {this.getIdentificationNumbersFields()}
                        </div>
                      </>
                    }

                    <div className="actions col-12 inline-container space-between">
                      <button type="button" className="large secondary" onClick={this.props.history.goBack}>
                        <Translate value="action.cancel"/>
                      </button>
                      <button
                        className="large primary inline-container"
                        type="submit"
                        disabled={this.state.submitting || this.state.loading || blockDisabledOrganisation}
                      >
                        <Translate value="action.continue"/>
                        <Icon icon={(this.state.submitting || this.state.loading) ? 'loader' : 'arrow-right'}/>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-4">
            <div
              className="ml-5 d-flex align-items-center justify-content-between flex-column"
              style={{height: '100%', width: 'min-content', textAlign: 'center'}}
            >
              <div className="d-flex flex-column align-items-center">
                <h2><Translate value="organisation.create.step1"/></h2>
                <h3 className="m-0"><Translate value="organisation.create.step1Text"/></h3>
                <img alt="register organisation" src="/resources/svg/register-organisation-1.svg" width="300"/>
              </div>
              {this.shouldShowForm() &&
                <div className="d-flex flex-column align-items-center">
                  <h2><Translate value="organisation.create.step2"/></h2>
                  <h3 className="m-0"><Translate value="organisation.create.step2Text"/></h3>
                  <img alt="register organisation" src="/resources/svg/register-organisation-2.svg" width="300"/>
                </div>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}

OrganisationRegisterView.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  currentLocale: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    user: state.user,
    currentLocale: state.i18n.locale
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, snackbarsActions, {selectOrganisation}/* , {setOrganisationLogo} */, {setUser}), dispatch)
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganisationRegisterView));
