import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import moment from 'moment-timezone';
import {Icon} from 'front-onceforall-core';
import Tooltip from '@material-ui/core/Tooltip';
import {USER_ORGANISATION_STATUS, USER_UNLINKABILITY_STATUS} from '../../utils/constant';

const statusStyles = {
  [USER_ORGANISATION_STATUS.PENDING]: {
    lineClass: null,
    lineStyle: {
      backgroundColor: 'rgb(38, 56, 69, 0.04)'
    },
    chipClass: 'warning',
    chipLabel: <Translate value={'users.status.PENDING'}/>
  },
  [USER_ORGANISATION_STATUS.LINKED]: {
    lineClass: null,
    lineStyle: null,
    chipClass: null,
    chipLabel: <Translate value={'users.status.LINKED'}/>
  },
  DEACTIVATED: {
    lineClass: 'italic information',
    lineStyle: null,
    chipClass: 'information-light',
    chipLabel: <Translate value={'users.status.DEACTIVATED'}/>
  }
};

export const UsersView = props => {
  const renderChip = userOrganisation => {
    const {chipClass, chipLabel} = statusStyles[userOrganisation.user.enabled ? userOrganisation.status : 'DEACTIVATED'];
    return (
      <span className={`chip ${chipClass ? chipClass : ''}`} style={{display: 'flex', justifyContent: 'center'}}>
        {chipLabel}
      </span>
    );
  };

  const renderActions = (index, userOrganisation) => {
    const actionButtons = [];
    const userId = userOrganisation.user.id;
    let message = 'users.actions.unlinkUserImpossibility';
    const isUserUnlinkable = userOrganisation.unlinkStatus === USER_UNLINKABILITY_STATUS.OK;
    if (isUserUnlinkable) {
      message = 'users.actions.unlinkUser';
    } else if (userOrganisation.unlinkStatus === USER_UNLINKABILITY_STATUS.LAST_MANDATORY_ROLES) {
      message = 'users.actions.unlinkUserLastMandatoryRoles';
    }

    // "Unlink user" button
    actionButtons.push(
      <Tooltip key={index + '-' + actionButtons.length}
        title={<Translate value={message}/>}
        placement="top"
      >
        <span>
          <button
            type="button"
            className="button-square clickable"
            data-id={'unlink-user-' + index}
            onClick={event => props.onUnlinkUser(event, userId)}
            style={isUserUnlinkable ? {} : {pointerEvents: 'none'}}
          >
            <Icon icon="unlink" color={isUserUnlinkable ? '' : 'information-light'}/>
          </button>
        </span>
      </Tooltip>
    );

    if (userOrganisation.status === USER_ORGANISATION_STATUS.PENDING) {
      // "Accept pending user request" button
      actionButtons.push(
        <Tooltip key={index + '-' + actionButtons.length}
          title={<Translate value="users.actions.acceptPendingUserRequest"/>}
          placement="top"
        >
          <button
            type="button"
            className="button-square success"
            data-id={'accept-pending-user-' + index}
            onClick={event => props.onAcceptPendingUser(event, userId)}
          >
            <Icon icon="check"/>
          </button>
        </Tooltip>
      );

      // "Decline pending user request" button
      actionButtons.push(
        <Tooltip key={index + '-' + actionButtons.length}
          title={<Translate value="users.actions.declinePendingUserRequest"/>}
          placement="top"
        >
          <button
            type="button"
            className="button-square error"
            data-id={'decline-pending-user-' + index}
            onClick={event => props.onDeclinePendingUser(event, userId)}
          >
            <Icon icon="cross"/>
          </button>
        </Tooltip>
      );
    }

    if (actionButtons.length) {
      return (
        <div className="actions inline-container" style={{justifyContent: 'space-evenly'}}>
          {actionButtons}
        </div>
      );
    }
  };

  return (
    <>
      <div className="flex-container space-between">
        <div className="row mt-4 mb-5">
          <h1 data-id="organisation-users" className="md-6 m-0">
            <Translate value="users.users"/>
            <span className="separator information">{props.users.length}</span>
          </h1>
        </div>
        <div className="md-6 inline-container flex-end">
          <button
            type="button"
            data-id="invite-user-button"
            className="large primary inline-container"
            onClick={() => props.onClickInviteButton(true)}
          >
            <Icon icon="plus"/>
            <Translate value="user.invite.button"/>
          </button>
        </div>
      </div>
      <div className="card col-12">
        {props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
          props.loading ? <span className="loading-overlay"/> :
            props.users.length > 0 ?
              <table>
                <thead>
                  <tr>
                    <td><Translate value="user.firstName"/></td>
                    <td><Translate value="user.lastName"/></td>
                    <td><Translate value="user.email"/></td>
                    <td><Translate value="user.phoneNumber"/></td>
                    <td><Translate value="users.organisation.linkingDate"/></td>
                    <td/>
                    <td/>
                  </tr>
                </thead>
                <tbody>
                  {props.users.map((userOrganisation, index) => {
                    const {lineClass, lineStyle} = statusStyles[userOrganisation.user.enabled ? userOrganisation.status : 'DEACTIVATED'];
                    return (
                      <tr key={index} className={lineClass}
                        style={lineStyle}>
                        <td>{userOrganisation.user.firstName}</td>
                        <td>{userOrganisation.user.lastName}</td>
                        <td>{userOrganisation.user.email}</td>
                        <td>{userOrganisation.user.phoneNumber}</td>
                        <td>{moment(userOrganisation.linkingDate).format('DD/MM/YYYY')}</td>
                        <td className="no-wrap" style={{width: '1%'}}>{renderChip(userOrganisation)}</td>
                        <td>{renderActions(index, userOrganisation)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table> :
              <div className="table-without-result">
                <Translate value="users.organisation.noUsers"/>
              </div>
        }
      </div>
    </>
  );
};

UsersView.propTypes = {
  users: PropTypes.array,
  onAcceptPendingUser: PropTypes.func.isRequired,
  onDeclinePendingUser: PropTypes.func.isRequired,
  onUnlinkUser: PropTypes.func.isRequired,
  onClickInviteButton: PropTypes.func.isRequired,
  error: PropTypes.bool,
  loading: PropTypes.bool
};

UsersView.defaultProps = {
  users: [],
  loading: true,
  error: false
};

export default UsersView;
