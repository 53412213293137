import React from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@material-ui/core';
import {I18n, Translate} from 'react-redux-i18n';

import {TextCheckbox} from 'front-onceforall-core';

import Radio from '../../../buttons/Radio';

import {getFieldLabel} from '../../utils';

const SUBCONTRACTED_SERVICES_PRICE_VARIATION_MAX_LENGTH = 1024;

function SubcontractedServicesPrice({publicProcurementData, onChange}) {
  const handleChange = (name, value) => {
    onChange(current => {
      return {
        amounts: {
          ...current.amounts,
          [name]: value
        }
      };
    });
  };

  const handleComplexChange = name => event => {
    handleChange(name, event.target.value);
  };

  const handleSimpleChange = name => event => {
    onChange({[name]: event.target.value});
  };

  const handleChangeRadio = name => value => {
    onChange({[name]: value});
  };

  const handleChangeCheckbox = name => event => {
    onChange({[name]: event.target.checked});
  };

  const displayAmountsFields = () => {
    if (publicProcurementData.isClientPayingVAT === null) {
      return null;
    }

    const tradPath = publicProcurementData.isClientPayingVAT ? 'publicProcurement.subcontractedServicesPrice.fieldsB' : 'publicProcurement.subcontractedServicesPrice.fieldsA';

    return (
      <div className="row pb-3">
        <div className="col-lg-4 col-xs-12">
          <TextField
            label={getFieldLabel(`${tradPath}.vatRate`, true)}
            id="subcontractedServicesPrice-vatRate"
            name="subcontractedServicesPrice-vatRate"
            value={publicProcurementData.amounts?.vatRate || ''}
            onChange={handleComplexChange('vatRate')}
          />
        </div>
        <div className="col-lg-4 col-xs-12">
          <TextField
            label={getFieldLabel(`${tradPath}.amountExcludingVAT`, true)}
            id="subcontractedServicesPrice-amountExcludingVAT"
            name="subcontractedServicesPrice-amountExcludingVAT"
            value={publicProcurementData.amounts?.amountExcludingVAT || ''}
            onChange={handleComplexChange('amountExcludingVAT')}
          />
        </div>
        {!publicProcurementData.isClientPayingVAT && (
          <div className="col-lg-4 col-xs-12">
            <TextField
              label={getFieldLabel(`${tradPath}.amountIncludingVAT`, true)}
              id="subcontractedServicesPrice-amountIncludingVAT"
              name="subcontractedServicesPrice-amountIncludingVAT"
              value={publicProcurementData.amounts?.amountIncludingVAT || ''}
              onChange={handleComplexChange('amountIncludingVAT')}
              disabled={Boolean(publicProcurementData.isClientPayingVAT)}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.subcontractedServicesPrice"/></h2>
      </div>
      <div className="flex-container pb-3">
        <Translate value="publicProcurement.subcontractedServicesPrice.details"/>
      </div>
      <div className="row pb-3">
        <div className="col-12">
          <Radio
            id="subcontractedServicesPrice-isClientPayingVAT-false"
            name="subcontractedServicesPrice-isClientPayingVAT-false"
            label={getFieldLabel('publicProcurement.subcontractedServicesPrice.a', true)}
            value={false}
            checked={publicProcurementData.isClientPayingVAT === false}
            onChange={handleChangeRadio('isClientPayingVAT')}
          />
        </div>
        <div className="col-12">
          <Radio
            id="subcontractedServicesPrice-isClientPayingVAT-true"
            name="subcontractedServicesPrice-isClientPayingVAT-true"
            label={(
              <div>
                <Translate value="publicProcurement.subcontractedServicesPrice.b.start"/>
                <a href="https://legifrance.gouv.fr/affichCodeArticle.do?idArticle=LEGIARTI000028418301&cidTexte=LEGITEXT000006069577" target="_blank" rel="noopener noreferrer">{I18n.t('publicProcurement.subcontractedServicesPrice.b.link')}</a>
                {' *'}
              </div>
            )}
            value={true}
            checked={Boolean(publicProcurementData.isClientPayingVAT)}
            onChange={handleChangeRadio('isClientPayingVAT')}
          />
        </div>
      </div>
      {displayAmountsFields()}
      <div className="row pb-3">
        <div className="col-12">
          <TextField
            label={getFieldLabel('publicProcurement.priceVariation', true)}
            id="subcontractedServicesPrice-priceVariation"
            name="subcontractedServicesPrice-priceVariation"
            value={publicProcurementData.priceVariation || ''}
            onChange={handleSimpleChange('priceVariation')}
            multiline
            minRows={3}
            maxRows={10}
            inputProps={{
              maxLength: SUBCONTRACTED_SERVICES_PRICE_VARIATION_MAX_LENGTH
            }}
          />
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-12">
          <TextCheckbox
            id="subcontractedServicesPrice-directPayment"
            name="subcontractedServicesPrice-directPayment"
            label={I18n.t('publicProcurement.directPayment')}
            checked={Boolean(publicProcurementData.directPayment)}
            onChange={handleChangeCheckbox('directPayment')}
          />
        </div>
      </div>
    </div>
  );
}

SubcontractedServicesPrice.propTypes = {
  onChange: PropTypes.func.isRequired,
  publicProcurementData: PropTypes.shape({
    isClientPayingVAT: PropTypes.bool,
    priceVariation: PropTypes.string,
    directPayment: PropTypes.bool,
    amounts: PropTypes.shape({
      vatRate: PropTypes.string,
      amountExcludingVAT: PropTypes.string,
      amountIncludingVAT: PropTypes.string
    })
  }).isRequired
};

export default SubcontractedServicesPrice;
