import config from '../../../config';
import {authFetch, checkStatus} from '../../utils/http';

export default class LiveDocumentTypeService {
  getDocumentTypesForProjectCreation() {
    return authFetch(`${config.rfaApi}/api/v1/document-types/project-creation`)
      .then(checkStatus)
      .then(response => response.json());
  }
}
