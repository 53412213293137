import React from 'react';
import PropTypes from 'prop-types';
import {Translate, I18n} from 'react-redux-i18n';
import moment from 'moment-timezone';

import {TextDatePicker} from 'front-onceforall-core';

import {REQUEST_FOR_APPROVAL_OBJECT} from '../../../../utils/constant';

import {getFieldLabel} from '../../utils';

import Radio from '../../../buttons/Radio';

import {onDateChange, onObjectChange} from './utils';

function RequestForApprovalObject({onChange, publicProcurementData}) {
  const isDateMandatory = publicProcurementData.requestForApprovalObject === REQUEST_FOR_APPROVAL_OBJECT.SPECIAL_ACT_MODIFICATION;

  const originalRequestForApprovalDate = publicProcurementData.originalRequestForApprovalDate ? moment(publicProcurementData.originalRequestForApprovalDate) : null;

  const dateError = publicProcurementData.errors?.originalRequestForApprovalDate;

  const handleDateChange = event => {
    let value = event.target.value;
    onChange(onDateChange(value));
  };

  const handleObjectChange = value => {
    onChange(onObjectChange(value));
  };

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.requestForApprovalObject"/></h2>
      </div>
      <div>
        <div className="row pl-2">
          <Radio
            name="requestForApprovalObject-SPECIAL_ACT_AGREEMENT"
            data-id="requestForApprovalObject-SPECIAL_ACT_AGREEMENT"
            label={getFieldLabel('publicProcurement.requestForApprovalObject.SPECIAL_ACT_AGREEMENT', true)}
            value={REQUEST_FOR_APPROVAL_OBJECT.SPECIAL_ACT_AGREEMENT}
            checked={publicProcurementData.requestForApprovalObject === REQUEST_FOR_APPROVAL_OBJECT.SPECIAL_ACT_AGREEMENT}
            onChange={handleObjectChange}
          />
        </div>
        <div className="row pl-2 d-flex align-items-start">
          <div className="d-flex align-items-center mr-3" style={{height: '56px'}}>
            <Radio
              name="requestForApprovalObject-SPECIAL_ACT_MODIFICATION"
              data-id="requestForApprovalObject-SPECIAL_ACT_MODIFICATION"
              label={getFieldLabel('publicProcurement.requestForApprovalObject.SPECIAL_ACT_MODIFICATION', true)}
              value={REQUEST_FOR_APPROVAL_OBJECT.SPECIAL_ACT_MODIFICATION}
              checked={publicProcurementData.requestForApprovalObject === REQUEST_FOR_APPROVAL_OBJECT.SPECIAL_ACT_MODIFICATION}
              onChange={handleObjectChange}
            />
          </div>
          <div className="col-lg-3 col-md-12">
            <TextDatePicker
              name="originalRequestForApprovalDate"
              data-id="originalRequestForApprovalDate"
              label={getFieldLabel('publicProcurement.requestForApprovalObject.originalDate', isDateMandatory)}
              value={originalRequestForApprovalDate}
              onChange={handleDateChange}
              placeholder={I18n.t('datetime.shortDate')}
              disabled={!isDateMandatory}
              helperText={dateError ? I18n.t(dateError) : null}
              error={Boolean(dateError)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

RequestForApprovalObject.propTypes = {
  publicProcurementData: PropTypes.shape({
    requestForApprovalObject: PropTypes.string,
    originalRequestForApprovalDate: PropTypes.string,
    errors: PropTypes.shape({
      originalRequestForApprovalDate: PropTypes.string
    })
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default RequestForApprovalObject;
