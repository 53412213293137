import React from 'react';
import PropTypes from 'prop-types';
import {organisationService} from '../../../services/organisation/OrganisationService';
import {captureError} from '../../../utils/log';
import RefusalMessageView from './RefusalMessageView';

export const RefusalMessageContainer = props => {
  const [organisation, setOrganisation] = React.useState(null);

  React.useEffect(() => {
    organisationService.getOrganisationsList([props.organisationId])
      .then(response => setOrganisation(response[0]))
      .catch(error => captureError('Error while fetching organisation details in refusal message', error));
  }, [props.organisationId]);

  if (!organisation) {
    return null;
  }

  return (
    <RefusalMessageView
      organisation={organisation}
      date={props.date}
      reason={props.reason}
    />
  );
};

RefusalMessageContainer.propTypes = {
  organisationId: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired
};

export default RefusalMessageContainer;
