// Polyfills for IE11 + stable
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as Sentry from '@sentry/browser';
import ReactGA from 'react-ga4';
import svg4everybody from 'svg4everybody';
import moment from 'moment-timezone';

import config from './config';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import {createBrowserHistory} from 'history';

import UserRegisterView from './app/views/userRegister/UserRegisterView';
import UserActivationView from './app/views/userActivation/UserActivationView';
import AuthenticationGate from './app/gates/AuthenticationGate';
import PageChange from './app/layout/PageChange';
import {ThemeProvider} from '@material-ui/core/styles';
import {theme} from 'front-onceforall-ui-kit/theme-panda.js';
import './theme';
import maintenanceService from './app/services/maintenance/MaintenanceService';
import {setMaintenance} from './app/actions/maintenance';
import configureStore from './app/store/configureStore';
import {captureError} from './app/utils/log';
import {recordGAReloadEvent} from './app/utils/analytics';

// Used to allow IE11 to handle svg use with external resource href
svg4everybody();

const history = createBrowserHistory();
configureStore.init(history);
const store = configureStore.getStore();

if (config.sentry && config.log === 'sentry') {
  Sentry.init({
    dsn: config.sentry.url,
    environment: config.sentry.environment
  });
}

if (config.titlePrefix) {
  document.title = `${config.titlePrefix} ${document.title}`;
}

const link = document.createElement('link');
link.rel = 'icon';
link.type = 'image/png';
link.href = `/favicons/${config.favicon || 'favicon.png'}`;
document.head.appendChild(link);

if (config.analytics) {
  ReactGA.initialize(config.analytics);

  const pageAccessedByReload = (
    (window.performance.navigation && window.performance.navigation.type === 1) ||
    window.performance
      .getEntriesByType('navigation')
      .map(nav => nav.type)
      .includes('reload')
  );

  if (pageAccessedByReload) {
    recordGAReloadEvent(window.location.pathname);
  }
}

function checkMaintenanceDate() {
  try {
    maintenanceService.get().then(response => {
      if (!response || !response.da) {
        return;
      }

      const maintenance = response.da;
      // add maintenance.id because front-core requires it (but doesn't use it !)
      // @TODO fix front-core to remove this unecessary id
      maintenance.id = 0;
      store.dispatch(setMaintenance(maintenance));
      let maintenanceDate = maintenance['maintenance-date'];
      if (maintenanceDate) {
        maintenanceDate = moment(maintenanceDate);
        if (!maintenanceDate.isValid()) {
          captureError('maintenance date is invalid !');
        } else if (maintenanceDate.isBefore(moment())) {
          window.location = maintenance.maintenanceUrl;
        }
      }
    })
      .catch(error => captureError('maintenanceService.get', error));

    // Every minute, we check again
    setTimeout(checkMaintenanceDate, 60000);
  } catch (error) {
    captureError('An error occurred during the initialization of the checkMaintenanceDate routine', error);
  }
}

checkMaintenanceDate();

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ThemeProvider theme={theme}>
        <PageChange>
          <Switch>
            <Route exact path="/register-user" component={UserRegisterView}/>
            <Route exact path="/activate-account" component={UserActivationView}/>
            <Route render={() => <AuthenticationGate store={store}/>}/>
          </Switch>
        </PageChange>
      </ThemeProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
