import React from 'react';
import PropTypes from 'prop-types';

import {Icon} from 'front-onceforall-core';

const types = {
  error: {
    icon: <div className="error"><Icon
      icon="error"
      size={100}
    /></div>,
    backgroundColor: '#FFEFEF80'
  }
};

const OverlayMessageView = props => (
  <div
    className="overlay d-flex justify-content-center align-items-start"
    style={{padding: '200px 45px 0 95px', zIndex: 2, ...props.containerStyle}}
  >
    <div
      className="flex-container column center w-100 p-5"
      style={{backgroundColor: types[props.type].backgroundColor}}
    >
      {types[props.type].icon}
      <h2 className="mt-4">{props.message}</h2>
      {props.children}
    </div>
  </div>
);

OverlayMessageView.defaultProps = {
  containerStyle: {}
};

OverlayMessageView.propTypes = {
  type: PropTypes.oneOf(['error']).isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  containerStyle: PropTypes.object
};

export default OverlayMessageView;
