import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {projectPropertiesPropTypes} from '../../../../utils/propTypes';
import RequestTreeList from '../../../requestTreeList';
import {useHistory} from 'react-router-dom';
import Icon from 'front-onceforall-core/dist/components/Icon';
import CreateRequestForApprovalButton from '../../../buttons/createRequestForApprovalButton';

export const ProjectRequestListView = props => {
  const history = useHistory();
  const groupedRequests = sortedRequests(Object.entries(groupByRfaOwner(props.requests)));

  function sortedRequests(requests) {
    return requests.map(group => {
      return {
        rfaOwnerName: getOrganisationName(group[0]),
        rfaOwnerRegistrationNumber: getOrganisationRegistrationNumber(group[0]),
        rfaOwnerRequests: group[1]
      };
    })
      .sort((a, b) => {
        return a.rfaOwnerName < b.rfaOwnerName ? -1 : a.rfaOwnerName > b.rfaOwnerName ? 1 : 0;
      });
  }

  function groupByRfaOwner(requests) {
    return requests.reduce((accumulator, value) => {
      (accumulator[value.creatorOrganisationId] = accumulator[value.creatorOrganisationId] || []).push(value);
      return accumulator;
    }, {});
  }

  function getOrganisationName(id) {
    return props.organisations.find(organisation => organisation.id === Number(id))?.fullName;
  }

  function getOrganisationRegistrationNumber(id) {
    return props.organisations.find(organisation => organisation.id === Number(id))?.registrationNumber;
  }

  const groupedList = groupedRequests.map((rfaOwner, index) => {
    return <div key={index} className="card col-12">
      {props.organisations.length > 0 &&
        <h3 className="mb-4">
          <span>{rfaOwner.rfaOwnerName}</span>
          <span className="separator"/>
          <span style={{fontWeight: 'normal'}}>{rfaOwner.rfaOwnerRegistrationNumber}</span>
        </h3>
      }
      <RequestTreeList
        history={history}
        getRfaOwnerName={getOrganisationName}
        getRfaOwnerRegistrationNumber={getOrganisationRegistrationNumber}
        organisations={props.organisations}
        requests={rfaOwner.rfaOwnerRequests}
        invitations={props.invitations}
        loading={props.loading}
      />
    </div>;
  });

  const content = props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> : groupedList;

  return (
    <>
      <div className="flex-container space-between row">
        <div className="row col-12 mb-3 inline-container space-between">
          <h2 data-id="active-requests">
            <Translate value="project.page.requests"/>
            <span className="separator information">{props.requestsCount}</span>
          </h2>
          <div className="inline-container">
            {props.project && <CreateRequestForApprovalButton project={props.project} icon="folder-plus"/>}

            {!props.loading && props.requests?.length > 0 &&
              <button
                type="button"
                data-id="xlsxBtn"
                className="large primary inline-container"
                onClick={() => props.onDownloadXlsx()}
              >
                <Translate value="project.exportExcel.title"/>
                <Icon icon="download"/>
              </button>}
          </div>
        </div>
        {content}
      </div>
    </>
  );
};

ProjectRequestListView.propTypes = {
  organisations: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  invitations: PropTypes.array.isRequired,
  requestsCount: PropTypes.number.isRequired,
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    properties: projectPropertiesPropTypes().isRequired
  }),
  onDownloadXlsx: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired
};

export default ProjectRequestListView;
