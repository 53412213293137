export const updateWithError = value => current => ({
  subcontractorContractDurationInMonths: value,
  errors: {
    ...current.errors,
    subcontractorContractDurationInMonths: 'error.validation'
  }
});

export const updateAndResetError = value => current => {
  let errors = {...current.errors};
  delete errors.subcontractorContractDurationInMonths;
  return {
    subcontractorContractDurationInMonths: value,
    errors
  };
};

export const updateSubcontractorContractDurationNotApplicable = value => current => {
  let errors = {...current.errors};
  delete errors.subcontractorContractDurationInMonths;
  return {
    subcontractorContractDurationInMonths: null,
    subcontractorContractDurationNotApplicable: value,
    errors
  };
};
