const initialState = {
  isFetching: false,
  informationNotifications: {
    notifications: [],
    numberOfNewNotifications: 0,
    totalCount: 0
  },
  notificationsWithActions: [],
  organisationId: null
};

export default function notifications(state = initialState, action = undefined) {
  function receivedNotifications() {
    if (action.organisationId !== state.organisationId) {
      // If we have switch of organisation ==> Reset of state for this organisation
      return Object.assign({}, state, {isFetching: false}, action.notifications, {organisationId: action.organisationId});
    }

    // Only add newest notifications, because we want to locally keep the information "viewed" state of the old notifications
    const oldInformationNotifications = state.informationNotifications.notifications;
    const oldInformationNotificationsIds = oldInformationNotifications.map(
      oldInformationNotification => oldInformationNotification.notificationId);
    const receivedNotifications = action.notifications;
    receivedNotifications.informationNotifications.notifications = receivedNotifications.informationNotifications.notifications
      .filter(notification => !oldInformationNotificationsIds.includes(notification.notificationId))
      .concat(oldInformationNotifications);
    return Object.assign({}, state, {
      isFetching: false
    }, receivedNotifications);
  }

  switch (action.type) {
    case 'REQUEST_NOTIFICATIONS':
      return Object.assign({}, state, {
        isFetching: true
      });
    case 'RECEIVE_NOTIFICATIONS':
      return receivedNotifications();
    case 'HIDE': {
      const modifiedList = state.notificationsWithActions
        .map(notification => notification.notificationId === action.notificationId ?
          Object.assign({}, notification, {isHidden: true}) :
          notification
        );

      return Object.assign({}, state, {
        notifications: modifiedList
      });
    }

    case 'REMOVE': {
      const filteredNotificationsList = state.notificationsWithActions
        .filter(notification => notification.notificationId !== action.notificationId);
      return Object.assign({}, state, {
        notificationsWithActions: filteredNotificationsList
      });
    }

    case 'VIEWED': {
      // Set existing notification to 'viewed'
      state.informationNotifications.notifications
        .find(notification => notification.notificationId === action.notificationId)
        .viewed = true;
      return Object.assign({}, state);
    }

    case 'UPDATE_LAST_SEEN': {
      state.informationNotifications.numberOfNewNotifications = 0;
      return Object.assign({}, state);
    }

    default:
      return state;
  }
}
