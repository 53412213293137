import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import Icon from 'front-onceforall-core/dist/components/Icon';

export const OrganisationCreditsView = props => (
  <>
    <div className="flex-container space-between pb-4">
      <h2 data-id="request-title" className="ml-6 mb-0">
        <Translate value="organisation.page.credits"/>
      </h2>
      <div className="col-md-3 inline-container flex-end">
        {props.isUserBackOffice && props.credits &&
          <button
            type="button"
            data-id="modify-organisation-credits"
            className="large bo inline-container"
            onClick={() => props.onPanelAction(true)}
          >
            <Translate value="organisation.page.modifyCredits"/>
            <Icon icon="key"/>
          </button>
        }
      </div>
    </div>
    <div className="card col-12">
      {props.error ? <span data-id="error-message"><Translate value="error.generic"/></span> :
        props.loading ? <div className="loading-overlay"/> :
          props.credits &&
          <div className="flex-container column">
            <div className="inline-container">
              <strong className="font-size-xxl">{props.credits.projectCredits}</strong>
              <Translate value="organisation.page.remainingProjectCredits"/>
            </div>
            <div className="inline-container">
              <strong className="font-size-xxl">{props.credits.requestForApprovalCredits}</strong>
              <Translate value="organisation.page.remainingRequestForApprovalCredits"/>
            </div>
          </div>
      }
    </div>
  </>
);

OrganisationCreditsView.propTypes = {
  organisation: PropTypes.object.isRequired,
  credits: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  onPanelAction: PropTypes.func.isRequired,
  isUserBackOffice: PropTypes.bool.isRequired
};

export default OrganisationCreditsView;
