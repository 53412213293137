import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Translate} from 'react-redux-i18n';
import * as subscriptionActions from '../../../actions/subscription';
import CreateProjectButtonView from './CreateProjectButtonView';

const CreateProjectButtonContainer = ({color, icon}) => {
  const history = useHistory();
  const globalDispatch = useDispatch();

  const subscription = useSelector(state => state.subscription) || {};
  const credits = subscription.credits || null;
  const creditsLoading = subscription.isFetching || false;
  const creditsError = subscription.error || false;

  const hasEnoughCredits = credits && !isNaN(credits.projectCredits) && credits.projectCredits > 0;

  // The button is disabled when loading data or current organisation has not enough credits
  const disabled = creditsLoading || creditsError || !hasEnoughCredits;
  let tooltipMessageTranslation = null;
  if (disabled) {
    tooltipMessageTranslation = creditsError ? 'subscription.credits.error' : 'project.noCredits';
  }

  const fetchOrganisationCredits = () => {
    globalDispatch(subscriptionActions.fetchSubscription());
  };

  const handleCreateProjectButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    history.push('/projects/new');
  };

  React.useEffect(fetchOrganisationCredits, []);

  return (
    <CreateProjectButtonView
      icon={icon}
      color={color}
      disabled={disabled}
      tooltipMessage={tooltipMessageTranslation && <Translate value={tooltipMessageTranslation}/>}
      onClick={handleCreateProjectButtonClick}
    />
  );
};

CreateProjectButtonContainer.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string
};

export default CreateProjectButtonContainer;
