import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {TextField} from '@material-ui/core';

import {getFieldLabel} from '../../utils';

import RadioYesNo from '../RadioYesNo';

function PaymentTerms({publicProcurementData, onChange}) {
  const handleSimpleChange = name => event => {
    onChange({[name]: event.target.value});
  };

  const handleChangeRadio = name => value => {
    onChange({[name]: value});
  };

  return (
    <div className="card">
      <div className="flex-container">
        <h2><Translate value="publicProcurement.titles.paymentTerms"/></h2>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.iban', true)}
            id="paymentTerms-iban"
            name="paymentTerms-iban"
            value={publicProcurementData.iban || ''}
            onChange={handleSimpleChange('iban')}
          />
        </div>
        <div className="col-lg-4 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.bankName', true)}
            id="paymentTerms-bankName"
            name="paymentTerms-bankName"
            value={publicProcurementData.bankName || ''}
            onChange={handleSimpleChange('bankName')}
          />
        </div>
        <div className="col-lg-4 col-md-12 pb-3">
          <TextField
            label={getFieldLabel('publicProcurement.accountNumber', true)}
            id="paymentTerms-accountNumber"
            name="paymentTerms-accountNumber"
            value={publicProcurementData.accountNumber || ''}
            onChange={handleSimpleChange('accountNumber')}
          />
        </div>
      </div>
      <div className="row pb-3">
        <div className="col-12">
          <RadioYesNo
            id="paymentTerms-advancePayRequired"
            name="paymentTerms-advancePayRequired"
            title={getFieldLabel('publicProcurement.advancePayRequired', true)}
            value={publicProcurementData.advancePayRequired}
            onChange={handleChangeRadio('advancePayRequired')}
          />
        </div>
      </div>
    </div>
  );
}

PaymentTerms.propTypes = {
  onChange: PropTypes.func.isRequired,
  publicProcurementData: PropTypes.shape({
    iban: PropTypes.string,
    bankName: PropTypes.string,
    accountNumber: PropTypes.string,
    advancePayRequired: PropTypes.bool
  }).isRequired
};

export default PaymentTerms;
