import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {PROJECT_ORGANISATION_ROLE as orgaRole} from '../../../utils/constant';
import {Tooltip} from '@material-ui/core';
import {Icon} from 'front-onceforall-core';

function ProjectSelectRoleCard({isSelected, role, onRoleSelection}) {
  return (
    <div
      className="col-lg-4 mb-2"
      data-id={`${role}-selection`}
    >
      <div data-id="card-role-selection" className="box flex-container column space-between"
        style={ isSelected ? {border: '1px solid', height: '100%'} : {height: '100%'} }
      >
        <div className="flex-container column">
          <h3 className="active">
            <Translate value={`project.role.${role}.title`}/>
          </h3>
          <ul className="mb-5 mt-0">
            <li><Translate value={`project.role.${role}.firstPoint`}/></li>
            <li><Translate value={`project.role.${role}.secondPoint`}/></li>
            <li><Translate value={`project.role.${role}.thirdPoint`}/></li>
          </ul>
        </div>
        <div className="inline-container space-between">
          <Tooltip
            data-id={`${role}-project-tooltip`}
            title={<Translate value={`project.role.${role}.tooltip`}/>}
          >
            <span>
              <button
                type="submit"
                className="large primary inline-container"
                data-id={`${role}-button`}
                onClick={() => onRoleSelection(role)}
              >
                <Translate value="project.role.choose"/>
                <Icon icon={'check'}/>
              </button>
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}

ProjectSelectRoleCard.propTypes = {
  isSelected: PropTypes.bool,
  role: PropTypes.oneOf(Object.values(orgaRole)),
  onRoleSelection: PropTypes.func.isRequired
};

export default ProjectSelectRoleCard;
