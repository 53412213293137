import React from 'react';
import {Translate} from 'react-redux-i18n';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Paginator} from 'front-onceforall-core';
import RequestRow from '../requestRow';
import CreateRequestForApprovalButton from '../buttons/createRequestForApprovalButton';

export const RequestListView = props => {
  const rows = props.organisations && props.invitations ? props.requests.map(request => {
    return <RequestRow
      key={request.id}
      history={props.history}
      request={request}
      organisations={props.organisations}
      invitations={props.invitations}
    />;
  }) : false;

  return (
    <>
      {props.loading && <div className="loading-overlay"/>}
      {rows.length > 0 &&
        <>
          {rows}
          <Paginator
            totalItems={props.requestsTotalCount}
            pageSize={props.pageSize}
            currentPage={props.pageNumber}
            onChangePage={props.onPageChange}
          />
        </>
      }
      {rows.length === 0 &&
        <div className="table-without-result">
          <div className="flex-container column center">
            <div className="no-request-message mb-5">
              <Translate value="request.noRequest"/>
            </div>
            <CreateRequestForApprovalButton icon="folder-plus"/>
          </div>
        </div>
      }
    </>
  );
};

RequestListView.propTypes = {
  history: PropTypes.object.isRequired,
  organisations: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  requestsTotalCount: PropTypes.number.isRequired,
  invitations: PropTypes.array,
  onPageChange: PropTypes.func.isRequired,
  pageNumber: PropTypes.number,
  pageSize: PropTypes.number,
  loading: PropTypes.bool.isRequired
};

RequestListView.defaultProps = {
  pageNumber: 0,
  pageSize: 10,
  invitations: []
};

export default withRouter(RequestListView);
