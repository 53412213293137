import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import Icon from 'front-onceforall-core/dist/components/Icon';
import {TextField} from '@material-ui/core';
import {AbstractFormComponent, validators} from 'front-onceforall-core';

export class CreditsEditorForm extends AbstractFormComponent {
  getFormData() {
    return {
      projectCredits: this.props.credits?.projectCredits,
      requestForApprovalCredits: this.props.credits?.requestForApprovalCredits
    };
  }

  getFormValidators() {
    return {
      projectCredits: [
        validators.requiredValidator,
        validators.numberValidator
      ],
      requestForApprovalCredits: [
        validators.requiredValidator,
        validators.numberValidator
      ]
    };
  }

  handleSubmitCallback() {
    this.props.onValidate({
      projectCredits: Number(this.state.formData.projectCredits),
      requestForApprovalCredits: Number(this.state.formData.requestForApprovalCredits)
    });
  }

  render() {
    return (<>
      <span className="flex-container mb-5">
        <h1><Translate value="organisation.page.modifyCredits"/></h1>
      </span>
      {this.props.credits &&
        <form onSubmit={this.handleSubmit}>
          <div className="row mb-5">
            <div className="col-6 d-flex align-items-center">
              <Translate value="organisation.page.projectCreditsModifier"/>
            </div>
            <div className="col-6">
              <TextField
                id="project-credits"
                name="project-credits"
                type="number"
                error={this.isOnError('projectCredits')}
                helperText={this.helperText('projectCredits')}
                value={this.state.formData.projectCredits}
                onChange={this.handleChange('projectCredits')}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  inputProps: {
                    min: 0
                  }
                }}
                variant="outlined"
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-6 d-flex align-items-center">
              <Translate value="organisation.page.requestForApprovalCreditsModifier"/>
            </div>
            <div className="col-6">
              <TextField
                id="request-credits"
                name="request-credits"
                type="number"
                error={this.isOnError('requestForApprovalCredits')}
                helperText={this.helperText('requestForApprovalCredits')}
                value={this.state.formData.requestForApprovalCredits}
                onChange={this.handleChange('requestForApprovalCredits')}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  inputProps: {
                    min: 0
                  }
                }}
                variant="outlined"
              />
            </div>
          </div>

          <div className="actions col-12 inline-container space-between">
            <button
              type="button"
              className="large secondary inline-container"
              onClick={this.props.onCancel}
              data-id="cancel"
            >
              <Translate value="action.back"/>
              <Icon icon="go-back"/>
            </button>

            <button
              className="large primary inline-container"
              type="submit"
              disabled={this.state.submitting}
            >
              <Translate value="action.confirm"/>
              <Icon icon={this.state.submitting ? 'loader' : 'check'}/>
            </button>
          </div>
        </form>
      }
    </>);
  }
}

CreditsEditorForm.propTypes = {
  credits: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired
};

export default CreditsEditorForm;
