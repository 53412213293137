import React from 'react';
import PropTypes from 'prop-types';
import {I18n, Translate} from 'react-redux-i18n';
import TextField from '@material-ui/core/TextField';

import {Icon, stringUtils} from 'front-onceforall-core';

const ProjectOwnerTagEditionView = props => {
  const displayLastUpdateInfo = () => {
    if (!props.projectUserUpdate) {
      return null;
    }

    return (
      <p data-id="onwerTag-updateInfo">
        {stringUtils.getStrongs(
          I18n.t('project.owner.updateInfo', {
            user: props.projectUserUpdate,
            date: I18n.l(props.projectLastUpdate, {dateFormat: 'datetime.shortDate'})
          })
        )}
      </p>
    );
  };

  return (
    <div>
      {props.loading &&
        <div className="loading-overlay"/>
      }
      <div className="col-12">
        <div className="mb-5">
          <h1
            data-id="request-title"
            className="ml-6 mb-0"
          >
            <Translate value="project.owner.title"/>
          </h1>
        </div>
        <div style={{position: 'relative'}}>
          <TextField
            label={I18n.t('project.owner.owner')}
            id="ownerTag"
            data-id="ownerTag-input"
            name="ownerTag"
            value={props.projectOwnerTag}
            onChange={props.onChangeValue}
            disabled={props.loadingError}
          />
          {displayLastUpdateInfo()}
        </div>
        <div className="actions col-12 inline-container space-between pl-0 pt-5">
          <button
            type="button"
            className="large secondary inline-container"
            onClick={props.onClose}
            data-id="cancel"
          >
            <Icon icon="go-back"/>
            <Translate value="action.cancel"/>
          </button>
          <button
            data-id="submit-button"
            className="large primary inline-container"
            type="button"
            onClick={props.onSubmit}
            disabled={props.loadingError || props.loading}
          >
            <Translate value={'action.confirm'}/>
            <Icon icon={props.loading ? 'loader' : 'check'}/>
          </button>
        </div>
      </div>
    </div>
  );
};

ProjectOwnerTagEditionView.propTypes = {
  projectOwnerTag: PropTypes.string,
  projectUserUpdate: PropTypes.string,
  projectLastUpdate: PropTypes.string,
  onChangeValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  loadingError: PropTypes.bool
};

export default ProjectOwnerTagEditionView;
