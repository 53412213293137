import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {DebouncedSearch, Icon, SidePanel} from 'front-onceforall-core';
import {organisationService} from '../../services/organisation/OrganisationService';
import {TextField} from '@material-ui/core';
import {isEmpty as isEmptyArray} from 'front-onceforall-core/dist/utils/arrays';
import {isEmpty} from 'front-onceforall-core/dist/utils/strings';
import {captureError} from '../../utils/log';
import InviteUnknownOrganisation from '../inviteUnknownOrganisation/InviteUnknownOrganisation';
import {PROJECT_ORGANISATION_ROLE as orgaRole} from '../../utils/constant';

export class SelectOrganisation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      waiting: false,
      loading: false,
      organisations: [],
      error: false,
      isUnknownInvitationOpen: false
    };

    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.doSearch = this.doSearch.bind(this);
  }

  componentDidMount() {
    this.debouncedSearch = new DebouncedSearch(this.doSearch, waiting => this.setState({waiting}));
  }

  handleChangeValue(value) {
    this.props.onChangeValue(value);
    this.debouncedSearch.update(value);
    this.setState({value});
  }

  getTranslatedLabel(value) {
    if (this.props.isRequired) {
      return <span><Translate value={value}/> *</span>;
    }

    return <Translate value={value}/>;
  }

  doSearch() {
    if (isEmpty(this.state.value)) {
      this.setState({loading: false, organisations: [], error: false});
      return;
    }

    if (this.state.value) {
      this.setState({loading: true, error: false});
    }

    organisationService.search(this.state.value)
      .then(organisations => {
        if (organisations && organisations.length === 1 && organisations[0].id === this.props.organisation.id) {
          this.setState({organisations: [], error: true});
          return;
        }

        this.setState({organisations});
        if (isEmptyArray(organisations)) {
          this.setState({error: true});
        }
      })
      .catch(error => {
        captureError('organisationService.search', error);
        this.setState({error: true});
      })
      .finally(() => this.setState({loading: false}));
  }

  handleSelectUnknownOrganisation = invitedOrganisation => {
    this.setState({value: ''});
    this.setState({organisations: []});
    this.setState({isUnknownInvitationOpen: false});
    this.props.onSelectOrganisation(invitedOrganisation);
  };

  getOrganisationCardElt = (organisation, isClickable) => {
    const clickable = isClickable ? 'clickable' : '';
    return <div className={`col-9 box ${clickable}`}>
      <div className="row">
        <div className="col-md-6 inline-container">
          <div className="ml-2 inline-container column">
            <strong>{organisation.fullName || organisation.organisationName}</strong>
            <span>{organisation.registrationNumber}</span>
          </div>
        </div>
        {organisation.address &&
          <div className="col-md-6">
            <div className="inline-container column">
              <span>{organisation.address.addressLine}</span>
              <span>{organisation.address.postCode} {organisation.address.city}</span>
            </div>
          </div>
        }
        {organisation.email &&
          <div className="col-md-6">
            <div className="inline-container column">
              <span>{organisation.email}</span>
              <span>{organisation.firstName} {organisation.lastName}</span>
            </div>
          </div>
        }
      </div>
    </div>;
  }

  getInviteUnknownComponent = () => {
    const invitationComponent =
    <InviteUnknownOrganisation
      onCancel={() => this.setState({isUnknownInvitationOpen: false})}
      onValidate={this.handleSelectUnknownOrganisation}
      defaultValue={this.state.value}
      invitingOrganisationCountry={this.props.organisation.country}
    />;

    return this.props.isParticipantsUpdate ? invitationComponent :
      <SidePanel
        setCloseTrigger={closeFunction => {
          this.triggerClosePanel = closeFunction;
        }}
        big
        onClose={() => this.setState({isUnknownInvitationOpen: false})}
      >
        {invitationComponent}
      </SidePanel>;
  }

  render() {
    const selectedOrganisation = this.props.selectedOrganisation;
    let inviteButtonMessageValue;
    switch (this.props.selectedOrganisationRole) {
      case orgaRole.PROJECT_CLIENT:
        inviteButtonMessageValue = `project.invite.${orgaRole.PROJECT_CLIENT}.invite`;
        break;
      case orgaRole.PROJECT_CLIENT_DELEGATE:
        inviteButtonMessageValue = `project.invite.${orgaRole.PROJECT_CLIENT_DELEGATE}.invite`;
        break;
      case orgaRole.PROJECT_CLIENT_ASSISTANT:
        inviteButtonMessageValue = `project.invite.${orgaRole.PROJECT_CLIENT_ASSISTANT}.invite`;
        break;
      case orgaRole.PROJECT_MANAGER:
        inviteButtonMessageValue = `project.invite.${orgaRole.PROJECT_MANAGER}.invite`;
        break;
      case orgaRole.PROJECT_SAFETY_COORDINATOR:
        inviteButtonMessageValue = `project.invite.${orgaRole.PROJECT_SAFETY_COORDINATOR}.invite`;
        break;
      case orgaRole.RFA_SUBCONTRACTOR:
        inviteButtonMessageValue = 'request.create.subcontractor.invite';
        break;
      default:
        inviteButtonMessageValue = 'organisation.select.selectOrganisation.button';
        break;
    }

    let inviteButtonMessage = <Translate value={inviteButtonMessageValue} name={this.state.value}/>;

    return (
      <>
        <div className="mt-4">
          {selectedOrganisation &&
            <div
              data-id={'selected-organisation-' + selectedOrganisation.id}
              key={selectedOrganisation.id}
              className="row mb-4"
            >
              <div className="col-md-9 inline-container">
                {this.getOrganisationCardElt(selectedOrganisation, false)}
                {this.props.isEditionEnabled && <button
                  type="button"
                  data-id="button-edit-selected-organisation"
                  className="large secondary"
                  onClick={() => this.props.onSelectOrganisation(null)}
                >
                  <Icon icon="edit"/>
                </button>
                }
              </div>
            </div>
          }
          {!selectedOrganisation &&
            <div className="row">
              <div className="col-md-9">
                <TextField
                  data-id="search-field"
                  error={Boolean(this.props.fieldErrorText)}
                  helperText={this.props.fieldErrorText}
                  autoFocus
                  value={this.state.value}
                  label={this.getTranslatedLabel('organisation.search.placeholder')}
                  onChange={event => this.handleChangeValue(event.target.value)}
                />
              </div>
            </div>
          }
          <div
            className={'col-12' + (isEmptyArray(this.state.organisations) ? '' : ' mt-4')}
            style={{position: 'relative'}}
          >
            {(this.state.loading || this.state.waiting) && <div className="loading-overlay"/>}
            {!selectedOrganisation &&
              this.state.organisations
                .filter(item => item.id !== this.props.organisation.id)
                .map(organisation => (
                  <div
                    data-id={'organisation-' + organisation.id}
                    key={organisation.id}
                    className="row mb-2"
                    onClick={() => {
                      this.setState({value: ''});
                      this.setState({organisations: []});
                      this.props.onSelectOrganisation(organisation);
                    }}
                  >
                    {this.getOrganisationCardElt(organisation, true)}
                  </div>
                ))
            }
            {!isEmpty(this.state.value) && !this.props.selectedOrganisation &&
              <div className="row mb-2">
                <button
                  type="button"
                  data-id="unknown-organisation"
                  className="col-9 mt-3 large secondary inline-container justify-content-center"
                  onClick={() => this.setState({isUnknownInvitationOpen: true})}
                >
                  {inviteButtonMessage}
                  <Icon icon="plus"/>
                </button>
              </div>
            }
            {!isEmpty(this.state.value) && this.state.error && !this.props.selectedOrganisation &&
              <div data-id="select-error" className="row error">
                <Translate value="organisation.select.notFound"/>
              </div>
            }
          </div>
        </div>
        {this.state.isUnknownInvitationOpen && this.getInviteUnknownComponent()}
      </>
    );
  }
}

SelectOrganisation.defaultProps = {
  isRequired: false,
  isParticipantsUpdate: false,
  isEditionEnabled: true,
  onChangeValue: () => {}
};

SelectOrganisation.propTypes = {
  organisation: PropTypes.object.isRequired,
  selectedOrganisation: PropTypes.object,
  onSelectOrganisation: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func,
  selectedOrganisationRole: PropTypes.oneOf(Object.values(orgaRole)),
  isRequired: PropTypes.bool,
  fieldErrorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isParticipantsUpdate: PropTypes.bool,
  isEditionEnabled: PropTypes.bool
};

export default SelectOrganisation;
