import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {userService} from '../../services/user/UserService';
import {TextField, Tooltip} from '@material-ui/core';
import {AbstractFormComponent, Icon} from 'front-onceforall-core';
import {emailValidator, phoneNumberValidator, requiredValidator} from 'front-onceforall-core/dist/utils/validators';
import TextPhoneNumber from 'front-onceforall-core/dist/components/TextPhoneNumber';
import {getPhoneLocaleFromCountry} from '../country/CountrySelector';
import configureStore from '../../store/configureStore';

export class SelectUser extends AbstractFormComponent {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      user: null,
      isEmailUnknown: false,
      isInternalUser: false,
      defaultPhoneCountry: getPhoneLocaleFromCountry(props.organisation.country)
    };

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCheckEmail = this.handleCheckEmail.bind(this);
  }

  getFormData() {
    return {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: ''
    };
  }

  getFormValidators() {
    return {
      email: [requiredValidator, emailValidator],
      firstName: this.state.isEmailUnknown ? requiredValidator : null,
      lastName: this.state.isEmailUnknown ? requiredValidator : null,
      phoneNumber: this.state.isEmailUnknown ? [requiredValidator, phoneNumberValidator] : null
    };
  }

  handleEmailChange(event) {
    const value = event.target.value;

    this.setState({
      formData: {
        ...this.state.formData,
        email: value
      },
      user: null,
      isEmailUnknown: false,
      isInternalUser: false
    }, () => {
      this.formValidators = this.getFormValidators();
    });
  }

  async handleCheckEmail() {
    this.setState({submitted: true});
    this.validateField('email', this.state.formData.email);
    if (this.state.fieldErrors.email) {
      return;
    }

    userService.searchByEmail(this.state.formData.email)
      .then(response => {
        // do not allow to invite BO users
        if (response) {
          if (response.internalUser) {
            this.setState({isEmailUnknown: false, isInternalUser: true});
            return;
          }

          this.setState({user: response, isEmailUnknown: false});
          return;
        }

        this.setState({isEmailUnknown: true, submitted: false});
      })
      .finally(() => {
        this.formValidators = this.getFormValidators();
      });
  }

  handleSubmitCallback() {
    this.props.onSelectUser(this.state.isEmailUnknown ? this.state.formData : this.state.user);
  }

  render() {
    const selectedUser = this.props.selectedUser;
    const currentLocale = configureStore.getStore().getState().i18n.locale;
    const isUserUnknown = this.state.isEmailUnknown;
    const tooltip = isUserUnknown ? 'user.invite.inviteTooltip' : 'user.invite.tooltip';
    const blockValidate = !this.state.user && !isUserUnknown;

    let addUserButton = <button
      type="submit"
      className={'large inline-container no-wrap primary mt-4'}
      data-id="add-user-button"
      disabled={blockValidate}
      style={blockValidate ? {pointerEvents: 'none'} : {}}
    >
      <Translate value="action.confirm"/>
      <Icon icon="check"/>
    </button>;

    addUserButton = blockValidate ?
      <Tooltip
        data-id="add-user-button-disabled-tooltip"
        title={<Translate value={tooltip}/>}
      >
        <span>{addUserButton}</span>
      </Tooltip> : addUserButton;

    return (
      <div className="mt-4">
        <div data-id="notice-user" className="py-4">
          <b><Translate value="project.invite.noticeUser" orgaName={this.props.invitedOrgaName}/></b>
        </div>
        {selectedUser ?
          <div
            data-id={'selected-user'}
            className="row mb-4"
          >
            <div className="col-md-6 inline-container">
              <div className={'col-9 box'}>
                <div className="row">
                  <div className="col-md-6 inline-container">
                    <div className="ml-2 inline-container column">
                      <strong>{selectedUser.email}</strong>
                      <span>{selectedUser.firstName} {selectedUser.lastName}</span>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="inline-container column">
                      <span>{selectedUser.phoneNumber}</span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                data-id="button-edit-selected-user"
                className="large secondary"
                onClick={() => this.props.onSelectUser(null)}
              >
                <Icon icon="edit"/>
              </button>
            </div>
          </div> :
          <form className="col-md-9 mt-4" style={{padding: 'initial'}} onSubmit={this.handleSubmit}>
            <TextField
              autoFocus
              label={this.getFieldLabel('user.email', true)}
              error={this.isOnError('email')}
              helperText={this.helperText('email')}
              data-id="email"
              value={this.state.formData.email}
              onChange={this.handleEmailChange}
              InputProps={{
                endAdornment: this.state.user ?
                  (<Icon icon="check" color="success"/>) :
                  (<button
                    type="button"
                    data-id="verify-email-button"
                    className="large secondary inline-container no-wrap"
                    onClick={this.handleCheckEmail}
                    disabled={isUserUnknown}
                  >
                    <Translate value="user.invite.checkEmail"/>
                  </button>)
              }}
            />
            {this.state.user &&
              <div data-id="can-add-user" className="col-12 mb-4">
                <span className="information font-size-xs">
                  <Translate value="user.invite.canAdd"/>
                </span>
              </div>
            }
            {this.state.isInternalUser &&
              <div data-id="internal-user-error" className="col-12 mb-4">
                <span className="error font-size-xs">
                  <Translate value="user.invite.internalUser"/>
                </span>
              </div>
            }
            {isUserUnknown &&
            <>
              <div className="col-12 mb-4">
                <span className="information font-size-xs">
                  <Translate value="user.invite.notRecognized"/>
                </span>
              </div>
              <div className="row col-12">
                <div className="col-6">
                  <TextField
                    label={this.getFieldLabel('user.firstName', true)}
                    error={this.isOnError('firstName')}
                    helperText={this.helperText('firstName')}
                    data-id="firstName"
                    value={this.state.formData.firstName}
                    onChange={this.handleChange('firstName')}
                  />
                </div>
                <div className="col-6">
                  <TextField
                    label={this.getFieldLabel('user.lastName', true)}
                    error={this.isOnError('lastName')}
                    helperText={this.helperText('lastName')}
                    data-id="lastName"
                    value={this.state.formData.lastName}
                    onChange={this.handleChange('lastName')}
                  />
                </div>
              </div>
              <div className="row col-12 mt-4">
                <div className="col-12">
                  <TextPhoneNumber
                    label={this.getFieldLabel('user.phoneNumber', true)}
                    value={this.state.formData.phoneNumber}
                    onChange={this.handleChange('phoneNumber')}
                    error={this.isOnError('phoneNumber')}
                    helperText={this.helperText('phoneNumber')}
                    data-id="phoneNumber"
                    defaultLocal={this.state.defaultPhoneCountry}
                    currentLocale={currentLocale}
                  />
                </div>
              </div>
            </>
            }
            {addUserButton}
          </form>
        }
      </div>
    );
  }
}

SelectUser.propTypes = {
  organisation: PropTypes.object.isRequired,
  selectedUser: PropTypes.object,
  onSelectUser: PropTypes.func.isRequired,
  invitedOrgaName: PropTypes.string.isRequired
};

export default SelectUser;
