import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {Icon} from 'front-onceforall-core';

class ActionBar extends Component {
  constructor(props, context) {
    super(props, context);
    this.handleArrowBackClick = this.handleArrowBackClick.bind(this);
  }

  handleArrowBackClick() {
    if (this.props.customUrl) {
      this.props.history.push(this.props.customUrl);
    } else {
      this.props.history.goBack();
    }
  }

  render() {
    return (
      <div
        style={{
          padding: '5px 45px',
          margin: '0px -45px',
          backgroundColor: '#fafafa'
        }}
      >
        <div className="inline-container col-12">
          {this.props.showBackButton &&
          <button type="button" className="mr-4 small secondary" onClick={this.handleArrowBackClick}>
            <Icon icon="go-back"/>
          </button>
          }
          {this.props.children}
        </div>
      </div>
    );
  }
}

ActionBar.defaultProps = {
  showBackButton: false,
  customUrl: null,
  children: null
};

ActionBar.propTypes = {
  showBackButton: PropTypes.bool,
  customUrl: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  history: PropTypes.object.isRequired
};

export default withRouter(ActionBar);
