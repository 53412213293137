import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {projectService} from '../../../services/project/ProjectService';
import {captureError} from '../../../utils/log';

import SelectProjectView from './SelectProjectView';

const SelectProjectContainer = props => {
  const [loading, setLoading] = React.useState(false);
  const [projects, setProjects] = React.useState([]);

  const subscription = useSelector(state => state.subscription) || {};
  const credits = subscription.credits || null;

  const hasEnoughCredits = credits && !isNaN(credits.requestForApprovalCredits) && credits.requestForApprovalCredits > 0;

  const getAllProjects = () => {
    setLoading(true);
    projectService.findProjectsReadyForRequestCreationByCurrentOrganisation()
      .then(response => {
        if (hasEnoughCredits) {
          setProjects(response);
        } else {
          setProjects(response.filter(p => p.unlimitedRfa));
        }
      })
      .catch(error => captureError('Error while fetching all projects', error))
      .finally(() => setLoading(false));
  };

  // Fetch and select predefined project in a useEffect() that will be called only one time to prevent
  // infinite loop when calling props.onSelectProject()
  React.useEffect(() => {
    if (props.projectId) {
      setLoading(true);

      projectService.get(props.projectId)
        .then(response => {
          props.onSelectProject(response);
          setLoading(false);
        })
        .catch(() => {
          getAllProjects();
        });
    }
  }, []);

  // Fetch all user projects when the predefined project is missing
  React.useEffect(() => {
    if (!props.projectId) {
      getAllProjects();
    }
  }, [props.projectId]);

  return (
    <SelectProjectView
      onSelectProject={props.onSelectProject}
      projects={projects}
      project={props.project}
      loading={loading}
      isOpen={props.isOpen}
    />
  );
};

SelectProjectContainer.propTypes = {
  onSelectProject: PropTypes.func.isRequired,
  projectId: PropTypes.number,
  project: PropTypes.object,
  isOpen: PropTypes.bool.isRequired
};

export default SelectProjectContainer;
