import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {authenticationService} from '../services/authentication/AuthenticationService';
import TextsValidationForm from '../components/user/TextsValidationForm';
import LinkOfaAccount from '../components/user/LinkOfaAccount';
import {InitialLoading} from 'front-onceforall-core';
import App from '../App';
import {getPrivacyPolicyPath, getTermsAndConditionsPath} from '../utils/path';
import {userService} from '../services/user/UserService';
import {captureError} from '../utils/log';
import {WRONG_FORCED_ORGA_ERROR} from '../services/authentication/LiveAuthenticationService';
import {useHistory, useLocation} from 'react-router-dom';

const texts = [
  {
    name: 'privacyPolicy',
    timestampName: 'privacyPolicyTimestamp',
    getUrl: currentLocale => getPrivacyPolicyPath(currentLocale),
    lastUpdateMethod: () => userService.getPrivacyPolicyLastUpdateTimestamp(),
    updateProperty: 'hasPrivacyPolicy'
  },
  {
    name: 'termsAndConditions',
    timestampName: 'termsAndConditionsTimestamp',
    getUrl: currentLocale => getTermsAndConditionsPath(currentLocale),
    lastUpdateMethod: () => userService.getGeneralTermsLastUpdateTimestamp(),
    updateProperty: 'hasGeneralTerms'
  }
];

const AuthenticationGate = props => {
  const [state, updateState] = React.useState({
    error: false,
    loading: true,
    textsToValidate: [],
    shouldLinkToOfa: false
  });
  const location = useLocation();
  const history = useHistory();

  const setState = update => updateState(current => ({
    ...current,
    ...update
  }));

  const checkAuthentication = location => {
    authenticationService.init(props.store, location)
      .then(() => {
        if (!props.store.getState().user.emailVerified) {
          history.replace({pathname: '/activate-account'});
        }

        return Promise.all([
          getTextsToValidate(),
          shouldLinkPandaAndOfaAccount()
        ]);
      })
      .then(([textsToValidate, shouldLinkToOfa]) => setState({textsToValidate, shouldLinkToOfa, loading: false}))
      .catch(error => {
        if (error.message === WRONG_FORCED_ORGA_ERROR) {
          return;
        }

        captureError('Authentication error', error);
        setState({
          error: true,
          loading: false
        });
      });
  };

  const getTextsToValidate = () => {
    const getTextPromises = [];

    for (const text of texts) {
      // Get authenticated user's validation timestamp for this text
      const userValidationTimestamp = props.store.getState().user[text.timestampName] || 0;

      // Has this text to be validated ?
      // * OR the user has already validated the text but the last update date has changed
      // * OR the user has not yet validated the text
      const hasTextToBeValidatedPromise = userValidationTimestamp ?
        text.lastUpdateMethod().then(lastUpdateTimestamp => lastUpdateTimestamp && lastUpdateTimestamp > userValidationTimestamp) :
        Promise.resolve(true);

      // Get text if it has to be validated
      const getTextPromise = hasTextToBeValidatedPromise.then(hasTextToBeValidated => hasTextToBeValidated ? text : null);
      getTextPromises.push(getTextPromise);
    }

    return Promise.all(getTextPromises)
      // Clean empty text when getTextPromise() return a null value
      .then(textsToValidate => textsToValidate.filter(Boolean));
  };

  const shouldLinkPandaAndOfaAccount = () => {
    return Promise.resolve(userService.shouldLinkUserToOfa())
      .catch(error => {
        captureError('shouldLinkPandaAndOfaAccount', error);
        return false;
      });
  };

  React.useEffect(() => {
    checkAuthentication(location);
  }, []);

  return (
    <>
      <InitialLoading
        show={state.loading || state.error}
        text={state.error ? <Translate value="initialLoading.error"/> : <Translate value="initialLoading.message"/>}
      />
      {!state.loading && !state.error &&
        (state.textsToValidate.length > 0 ?
          <TextsValidationForm texts={state.textsToValidate}/> :
          state.shouldLinkToOfa ?
            <LinkOfaAccount onClose={() => setState({shouldLinkToOfa: false})}/> :
            <App/>)
      }
    </>
  );
};

AuthenticationGate.propTypes = {
  store: PropTypes.object.isRequired
};

export default AuthenticationGate;
