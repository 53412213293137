import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as snackbarsActions from '../actions/snackbars';
import {Snackbars as CoreSnackBars} from 'front-onceforall-core';

export class Snackbars extends Component {
  render() {
    return (
      <CoreSnackBars notifications={this.props.snackbars} actions={this.props.actions}/>
    );
  }
}

Snackbars.propTypes = {
  snackbars: PropTypes.array.isRequired,
  actions: PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return {
    snackbars: state.snackbars
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign({}, snackbarsActions), dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Snackbars);
