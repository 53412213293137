import React from 'react';
import PropTypes from 'prop-types';
import {Translate} from 'react-redux-i18n';
import {DebouncedSearch, Icon} from 'front-onceforall-core';
import {organisationService} from '../../../../../services/organisation/OrganisationService';
import {TextField} from '@material-ui/core';
import {isEmpty as isEmptyArray} from 'front-onceforall-core/dist/utils/arrays';
import {isEmpty} from 'front-onceforall-core/dist/utils/strings';
import {captureError} from '../../../../../utils/log';
import InviteUnknownOrganisation from '../../../../inviteUnknownOrganisation/InviteUnknownOrganisation';
import {projectService} from '../../../../../services/project/ProjectService';
import {enrollmentService} from '../../../../../services/enrollment/EnrollmentService';
import SelectUser from '../../../../selectUser/SelectUser';
import {PROJECT_ORGANISATION_ROLE as role} from '../../../../../utils/constant';

export class ProjectInviteGeneralContractor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      waiting: false,
      loading: false,
      organisations: [],
      error: false,
      isExternalInvite: false,
      selectedOrganisation: null
    };

    this.handleChangeValue = this.handleChangeValue.bind(this);
    this.doSearch = this.doSearch.bind(this);
  }

  componentDidMount() {
    this.debouncedSearch = new DebouncedSearch(this.doSearch, waiting => this.setState({waiting}));
  }

  handleChangeValue(value) {
    this.debouncedSearch.update(value);
    this.setState({value});
  }

  doSearch() {
    if (isEmpty(this.state.value)) {
      this.setState({loading: false, organisations: [], error: false});
      return;
    }

    if (this.state.value) {
      this.setState({loading: true, error: false});
    }

    organisationService.search(this.state.value)
      .then(response => {
        const organisations = response.filter(orga => !this.props.participants.includes(orga.id));

        if (organisations && organisations.length === 1 && organisations[0].id === this.props.organisation.id) {
          this.setState({organisations: [], error: true});
          return;
        }

        this.setState({organisations});
        if (isEmptyArray(organisations)) {
          this.setState({error: true});
        }
      })
      .catch(error => {
        captureError('organisationService.search', error);
        this.setState({error: true});
      })
      .finally(() => this.setState({loading: false}));
  }

  getOrganisationCardElt = (organisation, isClickable) => {
    const clickable = isClickable ? 'clickable' : '';
    return <div className={`col-9 box ${clickable}`}>
      <div className="row">
        <div className="col-md-6 inline-container">
          <div className="ml-2 inline-container column">
            <strong>{organisation.fullName}</strong>
            <span>{organisation.registrationNumber}</span>
          </div>
        </div>
        <div className="col-md-6">
          <div className="inline-container column">
            <span>{organisation.address.addressLine}</span>
            <span>{organisation.address.postCode} {organisation.address.city}</span>
          </div>
        </div>
      </div>
    </div>;
  }

  handleSelectKnownOrganisation = organisation => {
    this.setState({selectedOrganisation: organisation});
    this.setState({organisations: []});
  };

  handleSelectUser = user => {
    const generalContractorAddition = {
      generalContractorId: this.state.selectedOrganisation.id,
      generalContractorInvitedUser: {
        id: user?.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
      }
    };

    this.handleSelectOrganisation(generalContractorAddition, false);
  };

  handleSelectOrganisation = (organisation, isInvited) => {
    this.setState({loading: true});
    let response;
    let successValue;
    let errorType;
    if (isInvited) {
      response = enrollmentService.inviteUnknownProjectGeneralContractor(organisation, this.props.projectId, this.props.organisation.id);
      successValue = 'inviteSuccess';
      errorType = 'handleSelectUnknownOrganisation';
    } else {
      response = projectService.addGeneralContractor(this.props.projectId, organisation);
      successValue = 'addSuccess';
      errorType = 'handleAddGeneralContractor';
    }

    response
      .then(() => {
        this.setState({loading: false});
        this.props.onSelectOrganisation();
        this.props.actions.addSuccess(<Translate value={`project.invite.${role.PROJECT_GENERAL_CONTRACTOR}.${successValue}`} organisationName={organisation.organisationName}/>);
      })
      .catch(error => {
        this.setState({loading: false});
        captureError(errorType, error);
        this.props.actions.addError(<Translate value="error.generic"/>);
      });
  };

  render() {
    const inviteButtonMessage = <Translate value={`project.invite.${role.PROJECT_GENERAL_CONTRACTOR}.invite`} name={this.state.value}/>;

    return (
      <>
        {this.state.isExternalInvite ?
          <InviteUnknownOrganisation
            onCancel={() => this.setState({isExternalInvite: false})}
            onValidate={org => this.handleSelectOrganisation(org, true)}
            defaultValue={this.state.value}
            invitingOrganisationCountry={this.props.organisation.country}
          /> :
          <>
            <h2 className="pb-5"><Translate value={`project.invite.${role.PROJECT_GENERAL_CONTRACTOR}.title`}/></h2>
            {this.state.selectedOrganisation ?
              <>
                <div className="flex-container">
                  {this.getOrganisationCardElt(this.state.selectedOrganisation, false)}
                  <button
                    data-id="edit"
                    className="large secondary"
                    onClick={() => this.setState({selectedOrganisation: null})}
                  >
                    <Icon icon="edit"/>
                  </button>
                </div>
                <SelectUser
                  organisation={this.props.organisation}
                  onSelectUser={this.handleSelectUser}
                  invitedOrgaName={this.state.selectedOrganisation.fullName}
                />
              </> :
              <div className="mt-4">
                <div className="row">
                  <div className="col-md-9">
                    <TextField
                      data-id="search-field"
                      error={Boolean(this.props.fieldErrorText)}
                      helperText={this.props.fieldErrorText}
                      autoFocus
                      value={this.state.value}
                      label={<Translate value={'organisation.search.placeholder'}/>}
                      onChange={event => this.handleChangeValue(event.target.value)}
                    />
                  </div>
                </div>
                <div
                  className={'col-12' + (isEmptyArray(this.state.organisations) ? '' : ' mt-4')}
                  style={{position: 'relative'}}
                >
                  {(this.state.loading || this.state.waiting) && <div className="loading-overlay"/>}
                  {this.state.organisations
                    .filter(item => item.id !== this.props.organisation.id)
                    .map(organisation => (
                      <div
                        data-id={'organisation-' + organisation.id}
                        key={organisation.id}
                        className="row mb-2"
                        onClick={() => this.handleSelectKnownOrganisation(organisation)}
                      >
                        {this.getOrganisationCardElt(organisation, true)}
                      </div>
                    ))
                  }
                  {!isEmpty(this.state.value) &&
                    <div className="row mb-2">
                      <button
                        type="button"
                        data-id="unknown-organisation"
                        className="col-9 mt-3 large secondary inline-container justify-content-center"
                        onClick={() => this.setState({isExternalInvite: true})}
                      >
                        {inviteButtonMessage}
                        <Icon icon="plus"/>
                      </button>
                    </div>
                  }
                  {!isEmpty(this.state.value) && this.state.error &&
                    <div data-id="select-error" className="row error">
                      <Translate value="organisation.select.notFound"/>
                    </div>
                  }
                </div>
              </div>
            }
          </>
        }
      </>
    );
  }
}

ProjectInviteGeneralContractor.propTypes = {
  actions: PropTypes.object.isRequired,
  organisation: PropTypes.object.isRequired,
  projectId: PropTypes.number.isRequired,
  onSelectOrganisation: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  fieldErrorText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default ProjectInviteGeneralContractor;
